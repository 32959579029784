import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import qs from 'querystring';
import * as sidebarActions from '../../../../actions/sidebar';
import * as tiledPromoActions from '../../../../actions/tiledPromos';
import * as propertyActions from '../../../../actions/properties';
import Layout from '../../../../layout';
import SearchBar from '../../../../components/common/SearchBar';
import Input from '../../../../components/common/Input';
import {
  Tabs,
  Tab,
  TabList,
  TabPill,
  TabPanel
} from '../../../../components/common/Tabs';
import CheckboxList from '../../../../components/common/List/CheckboxList';
import { ListItem, List } from '../../../../components/common/List';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  hotels: PropTypes.array.isRequired,
  meetings: PropTypes.array.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchProperties: PropTypes.func.isRequired,
  fetchTiledPromos: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  cloneTiledPromos: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

/**
 * singularize is a would-be singularize function to strip of the last s. This
 * won't go very far...
 *
 * @param {String} str
 * @returns {String}
 * @api private
 */

const singularize = str => {
  return str.replace(/s$/, '');
};

/**
 * getTypeLable returns the human readable format for the type value
 *
 * @param {String} v
 * @returns {String}
 * @api private
 */

const getTypeLabel = v => {
  return {
    meetings: 'Meetings',
    weddings: 'Weddings',
    dining: 'Private Dining',
    misc: 'Misc',
    misc2: 'Misc2',
    misc3: 'Misc3'
  }[v];
};

const Clone = ({
  hotel,
  hotels,
  meetings,
  fetchProperties,
  fetchProperty,
  fetchTiledPromos,
  updateSidebar,
  cloneTiledPromos,
  match: {
    url,
    params: { hotelid, type }
  },
  location: { search },
  history
}) => {
  const [searchMeetings, setSearchMeetings] = useState('');
  const [searchHotels, setSearchHotels] = useState('');
  const [checked, setChecked] = useState({});
  const [meetingsChecked, setMeetingsChecked] = useState({});
  useEffect(() => {
    const { selected } = qs.parse(search.slice(1));
    if (selected) {
      setMeetingsChecked({
        ...meetingsChecked,
        [selected]: !meetingsChecked[selected]
      });
      history.replace(url);
    }
    fetchProperties({ type: 'hotel', per_page: 200 });
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchTiledPromos(hotelid, {
      type: singularize(type),
      per_page: 1000,
      order_by: 'sort_index:ASC'
    });
    return () => {};
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();

    const ids = Object.keys(meetingsChecked)
      .map(v => {
        return meetingsChecked[v] ? v : undefined;
      })
      .filter(v => v);

    const toPropertyIds = Object.keys(checked)
      .map(v => {
        return checked[v] ? v : undefined;
      })
      .filter(v => v);

    if (!ids.length || !toPropertyIds.length) {
      // TODO alert or notification that both items and properties to clone to
      // must have at least one item checkd
      return;
    }

    cloneTiledPromos(hotelid, ids, toPropertyIds).then(() => {
      history.push(`/hotels/${hotelid}/tiled/${type}`);
    });
  };

  // FIXME this should be reworked to use pagination style + tab switching
  // requests
  const filteredMeetings = matchSorter(meetings, searchMeetings, {
    keys: ['title']
  });
  const currentMeetings = filteredMeetings.filter(meeting => !meeting.archived);
  const archivedMeetings = filteredMeetings.filter(meeting => meeting.archived);
  const filteredHotels = matchSorter(hotels, searchHotels, { keys: ['name'] });

  const typeLabel = getTypeLabel(type);

  return (
    <Layout metaTitle={`Clone a ${typeLabel} Promo`}>
      <div className="w-full max-w-3xl">
        <Link
          to={`/hotels/${hotel.id}/tiled/${type}`}
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to {typeLabel} Promos
        </Link>
        <h1 className="mb-6">Clone a {typeLabel} Promo</h1>
        <div className="mb-16">
          Use this page to Clone {typeLabel} Promos on {hotel.name}.
        </div>
        <div className="flex flex-wrap justify-between mb-16">
          <div style={{ width: 440 }}>
            <SearchBar
              id="filter-meetings"
              className="mb-4"
              placeholder={`Filter ${typeLabel} Promos...`}
              value={searchMeetings}
              onChange={({ value }) => setSearchMeetings(value)}
            />
            <Tabs>
              <TabList>
                <Tab>
                  <span className="mr-2">Current</span>
                  <TabPill id="current-pill">{currentMeetings.length}</TabPill>
                </Tab>
                <Tab>
                  <span className="mr-2">Archived</span>
                  <TabPill id="archived-pill">
                    {archivedMeetings.length}
                  </TabPill>
                </Tab>
              </TabList>

              <TabPanel>
                <List id="current-list">
                  {currentMeetings.map(meeting => (
                    <ListItem id={meeting.id} key={meeting.id}>
                      <Input
                        id={meeting.id}
                        type="checkbox"
                        className="mr-4"
                        input={{
                          checked: !!meetingsChecked[meeting.id],
                          onChange: () =>
                            setMeetingsChecked({
                              ...meetingsChecked,
                              [meeting.id]: !meetingsChecked[meeting.id]
                            })
                        }}
                      />
                      <Link
                        to={`/hotels/${hotelid}/tiled/meetings/${meeting.id}/edit`}
                      >
                        {meeting.title}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
              <TabPanel>
                <List id="archived-list">
                  {archivedMeetings.map(meeting => (
                    <ListItem id={meeting.id} key={meeting.id}>
                      <Input
                        id={meeting.id}
                        type="checkbox"
                        className="mr-4"
                        input={{
                          checked: !!meetingsChecked[meeting.id],
                          onChange: () =>
                            setMeetingsChecked({
                              ...meetingsChecked,
                              [meeting.id]: !meetingsChecked[meeting.id]
                            })
                        }}
                      />
                      <Link
                        to={`/hotels/${hotelid}/tiled/${type}/${meeting.id}/edit`}
                      >
                        {meeting.title}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
            </Tabs>
          </div>
          <div style={{ width: 440 }}>
            <SearchBar
              id="filter-hotels"
              className="mb-16"
              placeholder="Filter Hotels..."
              value={searchHotels}
              onChange={({ value }) => setSearchHotels(value)}
            />
            <CheckboxList
              id="checkbox-hotels"
              title="Hotels"
              data={filteredHotels.map(hotelItem => ({
                id: hotelItem.id,
                name: hotelItem.name,
                displayName: item => (
                  <Link to={`/hotels/${item.id}`} className="mr-4">
                    {item.name}
                  </Link>
                ),
                checked: checked[hotelItem.id],
                onChange: () => {
                  setChecked({
                    ...checked,
                    [hotelItem.id]: !checked[hotelItem.id]
                  });
                }
              }))}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button type="button" className="btn" onClick={onSubmit}>
            Clone
          </button>
        </div>
      </div>
    </Layout>
  );
};

Clone.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    hotels: state.properties.data,
    // FIXME rename meetings to tiledPromos as this is a resuable component of
    // TiledPromos
    meetings: state.tiledPromos.data,
    isLoading:
      state.property.data ||
      state.properties.isLoading ||
      state.tiledPromos.isLoading
  }),
  {
    ...sidebarActions,
    ...propertyActions,
    ...tiledPromoActions
  }
)(Clone);
