import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../../layout';
import * as sidebarActions from '../../../actions/sidebar';
import { Tabs, TabList, Tab, TabPill } from '../../../components/common/Tabs';
import Table from '../../../components/common/Table';
import More, { MoreMenu, MoreMenuItem } from '../../../components/common/More';
import * as modalActions from '../../../actions/modals';
import * as propertyActions from '../../../actions/properties';
import * as menuActions from '../../../actions/menus';
import Paginate from '../../../components/Paginate';
import { fetchHandlerFunc } from '../../../utils/fetch-helpers';
import { onDrop } from '../../../utils/sort-helpers';

const propTypes = {
  updateSidebar: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchMenus: PropTypes.func.isRequired,
  sortMenus: PropTypes.func.isRequired,
  moveMenu: PropTypes.func.isRequired,
  updateMenu: PropTypes.func.isRequired,
  countMenus: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  restaurant: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  menus: PropTypes.array.isRequired,
  match: PropTypes.object,
  meta: PropTypes.object
};

const defaultProps = {
  restaurant: {},
  match: {},
  meta: {}
};

const condfn = val => {
  const cond = { order_by: 'sort_index:ASC' };
  if (val) {
    cond.title = `i%%${val}`;
  }

  return cond;
};

const Menu = ({
  updateSidebar,
  fetchProperty,
  fetchMenus,
  countMenus,
  sortMenus,
  moveMenu,
  updateMenu,
  showModal,
  restaurant,
  isLoading,
  match: {
    url,
    params: { restaurantId }
  },
  menus,
  meta
}) => {
  const [refresh, setRefresh] = useState(0);
  const [activeCount, setActivCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [selected, setSelected] = useState(0);

  const tabsconf = [
    {
      qry: { archived: false },
      cb: setActivCount
    },
    {
      qry: { archived: true },
      cb: setArchivedCount
    },
  ];

  const fetchfn = fetchHandlerFunc(
    fetchMenus,
    countMenus,
    tabsconf,
    restaurantId
  );

  useEffect(() => {
    fetchProperty(restaurantId).then(item => {
      updateSidebar({ titles: { Restaurant: item.name } });
    });

    return () => {};
  }, []);

  useEffect(() => {
    fetchfn(selected, condfn());
    return () => {};
  }, [selected, refresh]);

  const columns = [
    {
      id: 'title',
      Header: 'Menu Title',
      accessor: ({ id, title }) => <Link to={`${url}/${id}/edit`}>{title}</Link>
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: data => (
        <span className="capitalize">
          {data.published ? 'Published' : 'Unpublished'}
        </span>
      ),
      width: 120
    },
    {
      id: 'more',
      Header: () => <span />,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-archive`}
              onClick={() =>
                updateMenu(restaurantId, data.id, {
                  archived: !data.archived
                }).then(setRefresh(refresh + 1))}
            >
              {data.archived ? 'Unarchive' : 'Archive'}
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={() =>
                showModal(
                  'DeleteMenuModal',
                  {
                    restaurantId,
                    id: data.id,
                    title: data.title
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )}
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      ),
      width: 40
    },
  ];

  return (
    <Layout metaTitle="Menu">
      <div className="w-full max-w-3xl">
        <h1 className="mb-4">Menus</h1>
        <div className="mb-8">
          Use this page to create and manage Menu for{' '}
          {isLoading ? 'Restaurant' : restaurant.name}.
        </div>
        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <div>
            <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
              <Link
                to={`${url}/new`}
                className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              >
                + New Menu
              </Link>
            </div>

            <Tabs selectedIndex={selected} onSelect={i => setSelected(i)}>
              <TabList>
                <Tab>
                  <span className="mr-2">Active</span>
                  <TabPill id="active-pill">{activeCount}</TabPill>
                </Tab>
                <Tab>
                  <span className="mr-2">Archived</span>
                  <TabPill id="archived-pill">{archivedCount}</TabPill>
                </Tab>
              </TabList>
              <Table
                id="archived-menu-table"
                data={menus}
                columns={columns}
                moveRow={(drag, hover) => moveMenu(drag, hover)}
                onDrop={(evt, moved) => {
                  if (moved) {
                    onDrop(evt, menus, sortMenus.bind(null, restaurantId), {
                      archived: selected ? true : false // eslint-disable-line no-unneeded-ternary
                    });
                  }
                }}
                isDraggable
              />
            </Tabs>
            <Paginate
              meta={meta}
              fetchHandler={(_, link) => fetchMenus(null, null, link)}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

Menu.propTypes = propTypes;
Menu.defaultProps = defaultProps;

export default connect(
  (state) => ({
    restaurant: state.property.data,
    menus: state.menus.data,
    meta: state.menus.meta,
    isLoading: state.property.isLoading || state.menus.isLoading
  }),
  {
    ...sidebarActions,
    ...modalActions,
    ...propertyActions,
    ...menuActions
  }
)(Menu);
