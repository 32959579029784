import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import Layout from '../../../layout';
import Table from '../../../components/common/Table';
import Paginate from '../../../components/Paginate';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as imageActions from '../../../actions/images';
import * as userActions from '../../../actions/users';
import RestaurantSettingsForm from '../../../components/RestaurantSettingsForm';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  restaurant: PropTypes.object.isRequired,
  restaurantLogo: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  usersMeta: PropTypes.object.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired,
  fetchPropertyUsers: PropTypes.func.isRequired
};

const Settings = ({
  match: {
    params: { restaurantId }
  },
  history: { push },
  restaurant,
  restaurantLogo,
  fetchProperty,
  updateSidebar,
  updateProperty,
  setCurrentImage,
  fetchPropertyUsers,
  users,
  usersMeta
}) => {
  useEffect(() => {
    fetchProperty(restaurantId, true).then(item => {
      updateSidebar({ titles: { Restaurant: item.name } });
    });
    fetchPropertyUsers(restaurantId, { per_page: 30 });
    return () => {};
  }, []);

  useEffect(() => {
    const { logo } = restaurant;
    setCurrentImage('restaurant-logo-image', logo || {});
    return () => {};
  }, [restaurant]);

  const handleSubmit = data => {
    const body = {
      ...data,
      logo: restaurantLogo.file
        ? { assetId: restaurantLogo.file.id, alt: restaurantLogo.alt }
        : null
    };
    return updateProperty(restaurantId, body).then(() =>
      push(`/restaurants/${restaurantId}`)
    );
  };

  return (
    <Layout metaTitle="Settings">
      <div className="w-full max-w-3xl">
        <h1 className="mb-6">Settings</h1>
        <div className="mb-16">
          Use this page to manage the Settings for {restaurant.name}.
        </div>
        <RestaurantSettingsForm
          id="restaurant-settings"
          initialValues={restaurant}
          enableReinitialize
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
        />
        <hr />
        <h3>Users Associated to {restaurant.name}</h3>
        <br />
        <Table
          id="property-suers"
          columns={[
            {
              id: 'name',
              Header: 'Name',
              accessor: data => (
                <Link to={`/users/${data.id}/edit`}>
                  {`${data.firstName} ${data.lastName}`}
                </Link>
              ),
              className: 'text-left'
            },
            { Header: 'Email', accessor: 'email' },
          ]}
          data={users}
        />
        <Paginate
          meta={usersMeta}
          fetchHandler={(_, link) => fetchPropertyUsers(null, null, link)}
        />
      </div>
    </Layout>
  );
};

Settings.propTypes = propTypes;

export default connect(
  (state) => ({
    restaurant: state.property.data,
    restaurantLogo: get(state, 'images.restaurant-logo-image.items.0', {}),
    users: state.users.data,
    usersMeta: state.users.meta
  }),
  { ...propertyActions, ...sidebarActions, ...imageActions, ...userActions }
)(Settings);
