import {
  FETCH_RESET_PASSWORD_REQUEST,
  FETCH_RESET_PASSWORD_FAILURE,
  FETCH_RESET_PASSWORD_SUCCESS,
  UPDATE_RESET_PASSWORD_REQUEST,
  UPDATE_RESET_PASSWORD_FAILURE,
  UPDATE_RESET_PASSWORD_SUCCESS
} from '../actions/resetPassword';

const initialState = {
  data: [],
  isLoading: true,
  error: ''
};

// eslint-disable-next-line default-param-last
export default function resetPassword(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_RESET_PASSWORD_REQUEST:
    case UPDATE_RESET_PASSWORD_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_RESET_PASSWORD_SUCCESS:
    case UPDATE_RESET_PASSWORD_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };
    case FETCH_RESET_PASSWORD_FAILURE:
    case UPDATE_RESET_PASSWORD_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
