import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Input from '../common/Input';
import FormErrorBox from '../common/FormErrorBox';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  error: undefined
};

const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required!';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Email must be in email format';
  }

  // don't validate password unless there is a password or confirmPassword
  if (!values.editMode || values.password || values.confirmPassword) {
    if (!values.password) {
      errors.password = 'Required!';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Password must match confirmation';
    }
  }

  return errors;
};

const ResetPasswordForm = ({ id, error, handleSubmit, onCancel }) => (
  <form id={id} className="" onSubmit={handleSubmit}>
    <div className="flex flex-wrap bg-white p-10 rounded-sm shadow-md border-t-2 border-blue-lighter mb-10">
      <div className="p-3 w-1/3">
        <label htmlFor="password">
          <div className="font-normal text-base mb-2">New Password*:</div>
          <Field
            id="password"
            type="password"
            className="w-full"
            placeholder="**********"
            name="password"
            component={Input}
          />
        </label>
      </div>
      <div className="p-3 w-1/3">
        <label htmlFor="confirmPassword">
          <div className="font-normal text-base mb-2">Confirm Password*:</div>
          <Field
            id="confirmPassword"
            type="password"
            className="w-full"
            placeholder="**********"
            name="confirmPassword"
            component={Input}
          />
        </label>
      </div>
    </div>
    <div className="p-3 flex flex-wrap my-4 flex-row-reverse w-full">
      <button className="btn" type="submit">
        Submit
      </button>
      <button type="button" className="btn btn-clear mr-4" onClick={onCancel}>
        Cancel
      </button>
    </div>
    {error && <FormErrorBox id="user-form-error">{error}</FormErrorBox>}
  </form>
);

ResetPasswordForm.propTypes = propTypes;
ResetPasswordForm.defaultProps = defaultProps;

export default reduxForm({ form: 'ResetPasswordForm', validate })(
  ResetPasswordForm
);
