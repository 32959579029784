import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Input from '../../common/Input';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required!';
  }

  return errors;
};

const NewFaqCategoryForm = ({ id, handleSubmit, onClose }) => (
  <form id={id} onSubmit={handleSubmit}>
    <div className="my-12 justify-center text-grey-darker">
      <div className="mx-3 w-3/5 ml-auto mr-auto">
        <div className="mb-3">New FAQ Category name</div>
        <Field id="name" name="name" component={Input} className="w-full" />
      </div>
    </div>
    <div className="flex justify-center my-6">
      <button type="button" className="btn btn-clear" onClick={onClose}>
        Cancel
      </button>
      <button type="submit" className="btn ml-4">
        Save
      </button>
    </div>
  </form>
);

NewFaqCategoryForm.propTypes = propTypes;

export default reduxForm({ form: 'NewFaqCategoryForm', validate })(
  NewFaqCategoryForm
);
