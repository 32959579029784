const categoriesData = [
  'Home Promos',
  'Specials',
  'Specials Detail',
  'Tiled Promos',
  'Accommodations',
  'Calendars',
  'Hotel Logo',
];

export default categoriesData;
