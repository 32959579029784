import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';

export const FETCH_RESET_PASSWORD_REQUEST = 'FETCH_RESET_PASSWORD_REQUEST';
export const FETCH_RESET_PASSWORD_FAILURE = 'FETCH_RESET_PASSWORD_FAILURE';
export const FETCH_RESET_PASSWORD_SUCCESS = 'FETCH_RESET_PASSWORD_SUCCESS';

export const UPDATE_RESET_PASSWORD_REQUEST = 'UPDATE_RESET_PASSWORD_REQUEST';
export const UPDATE_RESET_PASSWORD_FAILURE = 'UPDATE_RESET_PASSWORD_FAILURE';
export const UPDATE_RESET_PASSWORD_SUCCESS = 'UPDATE_RESET_PASSWORD_SUCCESS';

export const fetchResetPassword = resetToken => dispatch => {
  dispatch({
    type: FETCH_RESET_PASSWORD_REQUEST
  });
  return fetch(`/passwords/${resetToken}`)
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: FETCH_RESET_PASSWORD_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: FETCH_RESET_PASSWORD_FAILURE,
        payload: e
      });
      return Promise.reject(e);
    });
};

export const updateResetPassword = (resetToken, update) => dispatch => {
  dispatch({
    type: UPDATE_RESET_PASSWORD_REQUEST
  });
  return fetch(`/passwords/${resetToken}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: UPDATE_RESET_PASSWORD_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_RESET_PASSWORD_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
