// A file containing any constants for re-use throughout the project
// Simple examples for now

// Site title
export const siteTitle = 'SWCMS';

// Any colors that are reused
export const COLORS = {
  // the colors can be semantic
  primary: '#fff',
  secondary: '#000',
  tertiary: 'red',
  // or they can be defined by their weights/shades
  // completely up to the design system whether there needs to be so many variations
  red: {
    '500': '#ff4b2b',
    '700': '#c41d00',
    '900': '#5b0400'
  }
};
