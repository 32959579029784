import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../../../css/components/input.css';

const propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  type: PropTypes.string,
  name: PropTypes.string,
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object
};

const defaultProps = {
  type: 'text',
  name: '',
  inputRef: null,
  className: '',
  style: {},
  placeholder: undefined,
  autocomplete: undefined,
  disabled: false,
  meta: {}
};

class Input extends Component {
  render() {
    const {
      id,
      name,
      inputRef,
      type,
      className,
      style,
      placeholder,
      autocomplete,
      input,
      disabled,
      meta: { touched, error }
    } = this.props;
    const classname = classnames('Input', {
      'Input--error': touched && error,
      'w-full': type !== 'checkbox'
    });
    return (
      <div className={className}>
        <input
          id={id}
          type={type}
          name={name}
          ref={inputRef}
          className={classname}
          style={style}
          placeholder={placeholder}
          autoComplete={autocomplete}
          {...input}
          disabled={disabled}
        />
        {touched && error && <div className="text-red mt-2">{error}</div>}
      </div>
    );
  }
}

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default Input;
