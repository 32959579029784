// import firebase from 'firebase';

export const FETCH_SHARED_REQUEST = 'FETCH_SHARED_REQUEST';
export const FETCH_SHARED_SUCCESS = 'FETCH_SHARED_SUCCESS';
export const FETCH_SHARED_FAILURE = 'FETCH_SHARED_FAILURE';

export const ADD_SHARED_SPECIAL_REQUEST = 'ADD_SHARED_SPECIAL_REQUEST';
export const ADD_SHARED_SPECIAL_SUCCESS = 'ADD_SHARED_SPECIAL_SUCCESS';
export const ADD_SHARED_SPECIAL_FAILURE = 'ADD_SHARED_SPECIAL_FAILURE';

export const fetchShared = () => dispatch => {
  dispatch({
    type: FETCH_SHARED_REQUEST
  });
  // return firebase
  //   .database()
  //   .ref('/shared')
  //   .once('value')
  //   .then(snapshot => {
  //     const shared = snapshot.val();
  //     dispatch({
  //       type: FETCH_SHARED_SUCCESS,
  //       payload: shared
  //     });
  //     return shared;
  //   })
  //   .catch(e => {
  //     dispatch({
  //       type: FETCH_SHARED_FAILURE,
  //       payload: e.message
  //     });
  //     return Promise.reject(e);
  //   });
};

export const addSharedSpecial = special => dispatch => { /* eslint-disable-line no-unused-vars */
  dispatch({
    type: ADD_SHARED_SPECIAL_REQUEST
  });
  // return firebase
  //   .database()
  //   .ref(`/shared/specials/${special.id}`)
  //   .set(special)
  //   .then(() => {
  //     dispatch({
  //       type: ADD_SHARED_SPECIAL_SUCCESS,
  //       payload: special
  //     });
  //     return special;
  //   })
  //   .catch(e => {
  //     dispatch({
  //       type: ADD_SHARED_SPECIAL_FAILURE,
  //       payload: e.message
  //     });
  //     return Promise.reject(e);
  //   });
};
