import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_ALERTS_REQUEST = 'FETCH_ALERTS_REQUEST';
export const FETCH_ALERTS_SUCCESS = 'FETCH_ALERTS_SUCCESS';
export const FETCH_ALERTS_FAILURE = 'FETCH_ALERTS_FAILURE';

export const FETCH_ALERT_REQUEST = 'FETCH_ALERT_REQUEST';
export const FETCH_ALERT_SUCCESS = 'FETCH_ALERT_SUCCESS';
export const FETCH_ALERT_FAILURE = 'FETCH_ALERT_FAILURE';

export const CREATE_ALERT_REQUEST = 'CREATE_ALERT_REQUEST';
export const CREATE_ALERT_SUCCESS = 'CREATE_ALERT_SUCCESS';
export const CREATE_ALERT_FAILURE = 'CREATE_ALERT_FAILURE';

export const UPDATE_ALERT_REQUEST = 'UPDATE_ALERT_REQUEST';
export const UPDATE_ALERT_SUCCESS = 'UPDATE_ALERT_SUCCESS';
export const UPDATE_ALERT_FAILURE = 'UPDATE_ALERT_FAILURE';

export const DELETE_ALERT_REQUEST = 'DELETE_ALERT_REQUEST';
export const DELETE_ALERT_SUCCESS = 'DELETE_ALERT_SUCCESS';
export const DELETE_ALERT_FAILURE = 'DELETE_ALERT_FAILURE';

export const MOVE_ALERT = 'MOVE_ALERT';

export const fetchAlerts = (qry, path) => dispatch => {
  dispatch({
    type: FETCH_ALERTS_REQUEST
  });
  return fetch(path || `/alerts?${fromObj(qry)}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_ALERTS_SUCCESS,
        payload: data,
        meta
      });
      return { data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_ALERTS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
export const countAlerts = params => dispatch => {
  return fetch(`/alerts${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchAlert = alertId => dispatch => {
  dispatch({
    type: FETCH_ALERT_REQUEST
  });
  return fetch(`/alerts/${alertId}`)
    .then(response => {
      const alert = convertToJS(response.data);
      dispatch({
        type: FETCH_ALERT_SUCCESS,
        payload: alert
      });
      return alert;
    })
    .catch(e => {
      dispatch({
        type: FETCH_ALERT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createAlert = alert => dispatch => {
  dispatch({
    type: CREATE_ALERT_REQUEST
  });
  return fetch(`/alerts`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(alert))
  })
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: CREATE_ALERT_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: CREATE_ALERT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateAlert = (alertId, update) => dispatch => {
  dispatch({
    type: UPDATE_ALERT_REQUEST
  });
  return fetch(`/alerts/${alertId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const alert = convertToJS(response.data);
      dispatch({
        type: UPDATE_ALERT_SUCCESS,
        payload: alert
      });
      return alert;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_ALERT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteAlert = alertId => dispatch => {
  dispatch({
    type: DELETE_ALERT_REQUEST
  });
  return fetch(`/alerts/${alertId}`, {
    method: 'DELETE'
  })
    .then(response => {
      dispatch({
        type: DELETE_ALERT_SUCCESS,
        payload: alertId
      });
      return alertId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_ALERT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveAlert = (source, target) => ({
  type: MOVE_ALERT,
  payload: { source, target }
});

export const silentUpdateAlerts = (dispatch, getState) => {
  const {
    alerts: { data: alerts }
  } = getState();
  const updates = alerts
    .filter(alert => alert.modified)
    .map(alert =>
      fetch(`/alerts/${alert.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ sort_index: alert.sortIndex })
      })
    );
  return Promise.all(updates).catch(e => {
    // FIXME this needs to actually be handled
    console.error(e); // eslint-disable-line
  });
};
