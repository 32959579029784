import React from 'react';
import PropTypes from 'prop-types';
import placeholderPng from '../../../assets/placeholder.png';

const propTypes = {
  id: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  moreMenu: PropTypes.node.isRequired,
  onClick: PropTypes.func
};

const defaultProps = {
  onClick: () => {}
};

const ImageCard = ({ id, src, title, onClick, moreMenu }) => (
  <div id={id} className="border-t-2 border-blue-light rounded shadow bg-white">
    <div
      id={`${id}-header`}
      className="flex h-10 items-center justify-end mx-3"
    >
      {moreMenu}
    </div>
    <div
      id={`${id}-body`}
      role="button"
      tabIndex={0}
      className="outline-none h-24"
      onClick={onClick}
    >
      <img
        id={`${id}-img`}
        alt={title}
        src={src}
        className="block object-contain p-4 m-auto max-h-full"
        onError={e => {
          e.target.src = placeholderPng;
        }}
      />
    </div>
    <div
      id={`${id}-title`}
      className="text-center py-4 px-2 font-light text-grey text-xs"
    >
      {title}
    </div>
  </div>
);

ImageCard.propTypes = propTypes;
ImageCard.defaultProps = defaultProps;

export default ImageCard;
