import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as tokenActions from '../../actions/tokens';
import * as modalActions from '../../actions/modals';

import Layout from '../../layout';
import More, { MoreMenu, MoreMenuItem } from '../../components/common/More';
import Paginate from '../../components/Paginate';
import Table from '../../components/common/Table';
import { Tab, Tabs, TabList } from '../../components/common/Tabs';

const TokenCell = ({ token }) => {
  const [show, setShow] = useState(false);
  const toggleToken = () => {
    setShow(true);
  };
  const handleCopy = tokenString => {
    return evt => {
      evt.preventDefault();
      navigator.clipboard.writeText(tokenString);
    };
  };
  return (
    <span style={{ display: 'flex' }}>
      {!show && (
        <a onClick={toggleToken} role="button" tabIndex={0}>
          Show Token
        </a>
      )}
      {show && (
        <>
          <input type="text" value={token} className="mr-2" />
          <button
            type="button"
            className="btn no-underline min-w-0"
            onClick={handleCopy(token)}
          >
            Copy
          </button>
        </>
      )}
    </span>
  );
};

TokenCell.propTypes = {
  token: PropTypes.object.isRequired
};

const NameCell = ({ data: { name, permissions } }) => {
  const [show, setShow] = useState(false);
  const toggleProperties = () => {
    setShow(!show);
  };
  const allProperties = perms => {
    return perms.length === 1 && perms[0].property === null;
  };
  const listProperties = perms => {
    if (allProperties(perms)) {
      return <li className="font-bold">All Properties</li>;
    }

    return perms.map(v => <li className="p-2 pl-0">{v.property.name}</li>);
  };
  return (
    <>
      <div className="flex">
        <span>{name}</span>
        <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
          <a onClick={toggleProperties} role="button" tabIndex={0}>
            {show && 'Hide Properties'}
            {!show && 'Show Properties'}
          </a>
        </div>
      </div>
      {show && (
        <div className="mt-4">
          <small className="font-bold block mb-2">Properties</small>
          <ul className="ml-0 pl-5">{listProperties(permissions)}</ul>
        </div>
      )}
    </>
  );
};

NameCell.propTypes = {
  data: PropTypes.object.isRequired
};

const Tokens = ({
  tokens,
  meta,
  isLoading,
  fetchTokens,
  showModal
}) => {
  const [refresh, setRefresh] = useState(0);

  // FIXME search is not possible using the VIRTUAL field 'name'
  // const [searchInput, setSearchInput] = useState('');

  // const tabsconf = [
  //   {
  //     qry: {},
  //     cb: setActivCount
  //   }
  // ];
  // const fetchfn = fetchHandlerFunc(fetchTokens, countTokens, tabsconf);
  // const searchHandler = inputHandlerFunc(
  //   setSearchInput,
  //   useCallback(debounce(fetchfn, 500), []),
  //   condfn
  // );

  useEffect(() => {
    // fetchfn(0, condfn(searchInput));
    fetchTokens();
    return () => {};
  }, [refresh]);

  const columns = [
    {
      id: 'name',
      Header: 'Name',
      accessor: data => <NameCell data={data} />
    },
    {
      id: 'token',
      Header: 'Token',
      /* eslint-disable prettier/prettier */
      accessor: data => <TokenCell token={data.sessions.token} />,
      width: 340
    },
    {
      id: 'expiresOn',
      Header: 'Expires On',
      /* eslint-disable prettier/prettier */
      accessor: data => (
        <span>{moment(data.sessions.expiresOn).format('Y-MM-DD')}</span>
      ),
      width: 140
    },
    {
      id: 'more',
      Header: <span />,
      width: 40,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={
                () => showModal(
                  'DeleteTokenModal',
                  {
                    id: data.id,
                    title: data.name
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )
              }
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      )
    },
  ];

  return (
    <Layout metaTitle="API Tokens">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <h1 className="mb-8">API Tokens</h1>
          <div className="mb-12">
            Here are all the API Tokens that are currently in use.
          </div>
          <div className="flex flex-col flex-col-reverse md:flex-row mb-3 sm:mb-6 md:mb-12">
            {/* <SearchBar
              id="tokens-filtering"
              className="flex-1 sm:mr-2 lg:mt-1"
              value={searchInput}
              placeholder="Filter Tokens..."
              onChange={({ value }) => searchHandler(value, 0)}
              onClick={() => {}}
            /> */}
            <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
              <Link
                to="/tokens/new"
                className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              >
                + New Token
              </Link>
            </div>
          </div>
          <div>
            <Tabs selectedIndex={0}>
              <TabList>
                <Tab>
                  <span className="mr-2">Active</span>
                  {/* <TabPill id="active">{activeCount}</TabPill> */}
                </Tab>
              </TabList>

              <Table id="tokens" data={tokens} columns={columns} />
            </Tabs>
            <Paginate meta={meta} fetchHandler={fetchTokens} />
          </div>
        </div>
      )}
    </Layout>
  );
};

Tokens.propTypes = { 
  fetchTokens: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  tokens: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    tokens: state.tokens.data,
    meta: state.tokens.meta,
    isLoading: state.tokens.isLoading
  }),
  { ...tokenActions, ...modalActions }
)(Tokens);
