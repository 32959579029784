import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import EditableLinkForm from './EditableLinkForm';
import PencilIcon from '../PencilIcon/PencilIcon';

const propTypes = {
  id: PropTypes.string.isRequired,
  link: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  className: PropTypes.string,
  target: PropTypes.string,
  isEditable: PropTypes.bool
};

const defaultProps = {
  link: '',
  className: '',
  target: '',
  isEditable: false
};

const EditableLink = ({
  id,
  link,
  className,
  onSubmit,
  target,
  isEditable
}) => {
  const [edit, setEdit] = useState(false);
  return (
    <div id={id} className={classnames(className, 'flex')}>
      {edit ? (
        <EditableLinkForm
          id={`${id}-form`}
          onSubmit={data => {
            onSubmit(data);
            setEdit(false);
          }}
          onClose={() => setEdit(false)}
          initialValues={{ link }}
          edit={edit}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      ) : (
        <Fragment>
          <a id={`${id}-link`} href={link} target={target}>
            {link}
          </a>
          {isEditable && (
            <PencilIcon
              id={`${id}-pencil`}
              className="ml-2 cursor-pointer h-4 w-4"
              onClick={() => {
                setEdit(true);
              }}
            >
              Edit
            </PencilIcon>
          )}
        </Fragment>
      )}
    </div>
  );
};

EditableLink.propTypes = propTypes;
EditableLink.defaultProps = defaultProps;

export default EditableLink;
