import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import DatePicker from '../DatePicker';

const propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  meta: PropTypes.object.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string
};

const defaultProps = {
  className: '',
  disabled: false,
  placeholder: ''
};

const FormDatePicker = ({
  id,
  input,
  className,
  disabled,
  placeholder,
  meta: { touched, error },
  ...props
}) => (
  <div id={id} className={className}>
    <DatePicker
      id={`${id}-picker`}
      selected={input.value}
      dateFormat="MM/dd/yyyy"
      placeholderText={placeholder}
      className={classnames(
        'border border-grey font-light px-4 py-3 rounded-sm',
        { 'border-grey-light': disabled, 'border-red': touched && error }
      )}
      onChange={date => input.onChange(date)}
      disabled={disabled}
      {...props}
    />
    {touched && error && <div className="text-red mt-2">{error}</div>}
  </div>
);

FormDatePicker.propTypes = propTypes;
FormDatePicker.defaultProps = defaultProps;

export default FormDatePicker;
