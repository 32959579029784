// 
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Link } from 'react-router-dom';
import qs from 'querystring';
import classnames from 'classnames';
import capitalize from 'lodash/capitalize';

import Layout from '../../layout';
import DashboardTable from '../../components/DashboardTable';
import ImageCard from '../../components/common/ImageCard';
import More, { MoreMenu, MoreMenuItem } from '../../components/common/More';
import * as propertyActions from '../../actions/properties';
import * as modalActions from '../../actions/modals';
import Paginate from '../../components/Paginate';

const propTypes = {
  properties: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  fetchProperties: PropTypes.func.isRequired,
  countProperties: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  profilePermissions: PropTypes.object.isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object
};

const defaultProps = {
  match: {},
  location: {},
  history: {}
};

const Dashboard = ({
  match: { url },
  location: { search },
  history: { push },
  fetchProperties,
  countProperties,
  properties,
  meta,
  updateProperty,
  showModal,
  profilePermissions
}) => {
  const { tab } = qs.parse(search.slice(1)) || { tab: 'list' };
  const { list } = qs.parse(search.slice(1)) || { list: 'active' };
  const [activeCount, setActiveCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);

  const propertiesTabsc = [
    {
      qry: { archived: false, order_by: 'name:ASC' },
      cb: setActiveCount
    },
    {
      qry: { archived: true, order_by: 'name:ASC' },
      cb: setArchivedCount
    },
  ];

  const fetchfn = selected => {
    propertiesTabsc.forEach((v, i) => {
      if (i === selected) {
        fetchProperties(v.qry).then(p => v.cb(p.meta.count));
      } else {
        countProperties(v.qry).then(p => v.cb(p.count));
      }
    });
  };

  useEffect(() => {
    if (tab === 'list') {
      fetchfn(list === 'archived' ? 1 : 0);
    } else if (tab === 'grid') {
      fetchProperties({ archived: false, order_by: 'name:ASC' });
    } else {
      fetchProperties({ type: 'hotel', archived: false, order_by: 'name:ASC' });
    }

    return () => change('properties', []);
  }, [list, tab]);

  // FIXME
  const canAddProperty =
    profilePermissions.findIndex(p => {
      return (
        !p.permissionable_id && !p.permissionable_type && p.resource === '*'
      );
    }) > -1;

  return (
    <Layout metaTitle="Dashboard">
      <div className="w-full max-w-3xl">
        <div className="flex items-baseline justify-between mb-24">
          <h1>Dashboard</h1>
          <div>
            <Link
              to={`${url}?tab=grid-hotel`}
              className={classnames('mx-2 no-underline', {
                'text-grey': tab === 'grid' || tab === 'list',
                'text-black': tab !== 'grid' && tab !== 'list'
              })}
            >
              Hotel Only
            </Link>
            <Link
              to={`${url}?tab=grid`}
              className={classnames('mx-2 no-underline', {
                'text-grey': tab !== 'grid',
                'text-black': tab === 'grid'
              })}
            >
              Grid
            </Link>
            <Link
              to={`${url}?tab=list`}
              className={classnames('ml-2 no-underline', {
                'text-grey': tab !== 'list',
                'text-black': tab === 'list'
              })}
            >
              List
            </Link>
          </div>
        </div>
        <div>
          {tab === 'list' ? (
            <DashboardTable
              properties={properties}
              activeCount={activeCount}
              archivedCount={archivedCount}
            />
          ) : (
            <div className="flex flex-wrap -mx-3">
              {properties.map(property => (
                <div
                  key={property.id}
                  className="p-3 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 md:min-w-200 sm:min-h-200"
                >
                  <ImageCard
                    id={property.id}
                    src={(property.logo || {}).url || ''}
                    title={property.name}
                    onClick={() => push(`/${property.type}s/${property.id}`)}
                    moreMenu={
                      canAddProperty ? (
                        <More id={`${property.id}-more`}>
                          <MoreMenu id={`${property.id}-moremenu`}>
                            <MoreMenuItem
                              id={`${property.id}-settings`}
                              onClick={() => {
                                push(
                                  `/${property.type}s/${property.id}/settings`
                                );
                              }}
                            >
                              Settings
                            </MoreMenuItem>
                            <MoreMenuItem
                              id={`${property.id}-${property.archived}`}
                              onClick={() =>
                                updateProperty(property.id, {
                                  archived: !property.archived
                                })}
                            >
                              {property.archived ? 'Unarchive' : 'Archive'}
                            </MoreMenuItem>
                            <MoreMenuItem
                              id={`${property.id}-delete`}
                              onClick={() =>
                                showModal(
                                  `Delete${capitalize(property.type)}Modal`,
                                  {
                                    id: property.id,
                                    title: property.name
                                  }
                                )}
                            >
                              Delete
                            </MoreMenuItem>
                          </MoreMenu>
                        </More>
                      ) : null
                    }
                  />
                </div>
              ))}
            </div>
          )}
          <Paginate meta={meta} fetchHandler={fetchProperties} />
        </div>
      </div>
    </Layout>
  );
};

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

export default connect(
  state => ({
    isAuthenticated: state.auth.isAuthenticated,
    properties: state.properties.data,
    meta: state.properties.meta,
    profilePermissions: state.auth.permissions
  }),
  { ...propertyActions, ...modalActions }
)(Dashboard);
