import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import * as propertyActions from '../../../actions/properties';
import * as listActions from '../../../actions/lists';
import * as sidebarActions from '../../../actions/sidebar';
import HotelListForm from '../../../components/HotelListForm';
import Layout from '../../../layout';
import FormNavigator from '../../../components/FormNavigator';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  createList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'preview', title: 'Preview + Publish' },
];

const ListsNew = ({
  match: {
    params: { hotelid }
  },
  history: { push },
  createList,
  hotel,
  isLoading,
  fetchProperty,
  updateSidebar
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    return () => {};
  }, []);

  const handleSubmit = data => {
    let { doesNotEnd, endsOn } = data;
    if (doesNotEnd) {
      endsOn = new Date(2120, 0, 1);
    }
    const list = {
      ...data,
      startsOn: data.startsOn.toJSON(),
      endsOn: endsOn ? endsOn.toJSON() : endsOn
    };
    return createList(hotelid, list)
      .then(() => {
        push(`/hotels/${hotelid}/lists`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout type="Lists" metaTitle="New List">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to={`/hotels/${hotel.id}/lists`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Lists
          </Link>
          <h1 className="mb-6">New List</h1>
          <div className="mb-16">
            Use this page to create a new List for {hotel.name}.
          </div>

          <HotelListForm
            id="new-lists"
            hotel={hotel}
            initialValues={{
              doesNotEnd: true,
              pageDisplayedOn: [],
              archived: false
            }}
            onSubmit={handleSubmit}
          />
        </div>
      )}
      <FormNavigator
        id="new-specials-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

ListsNew.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    lists: state.lists.data,
    isLoading: state.property.isLoading
  }),
  { ...propertyActions, ...listActions, ...sidebarActions }
)(ListsNew);
