import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import ChevronDownIcon from '../common/ChevronDownIcon';

const propTypes = {
  data: PropTypes.array.isRequired
};

const defaultProps = {};

// FIXME with pagination this should be implemented using a fetch request to get
// only the N promos that meet the criteria. Which will eliminate the filter
// function requirement as well as be a true representation of a frontend
// integration as you will not be calling a fullset of promos and then filtering
// that down as we are doing here.

/**
 * forPreview is a filter function to filter out previewable promos
 *
 * @param {Object} promo
 * @returns {Boolean}
 * @api private
 */

const forPreview = (promo) => {
  return (
    promo.published &&
    moment(promo.startsOn) < moment() &&
    (promo.doesNotEnd || moment(promo.endsOn) > moment())
  );
};

const HomePromosPreview = ({ data }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <div className="mb-12">
      <div
        id="homepromos-preview-header"
        role="presentation"
        className="flex w-full items-center cursor-pointer"
        onClick={() => setExpanded(!expanded)}
      >
        <h2 className="font-light mr-3">Preview</h2>{' '}
        <div className="mr-3">
          <ChevronDownIcon
            id="edit-chevron"
            style={{
              height: 20,
              transform: expanded && 'rotate(180deg)'
            }}
            fill="#606f7b"
          />
        </div>
      </div>
      {expanded && (
        <div>
          <div className="font-light mb-3 mt-3 text-base leading-normal preview-note">
            <p>
              <i>
                Note: Preview below is a live preview of what is displayed on
                the site's homepage. It may not reflect the exact size and
                proportions displayed on the site, so please check the live site
                for 100% accuracy.
              </i>
            </p>
          </div>
          <div className="activeHomePromoPreview" id="preview">
            {data
              .filter(forPreview)
              .slice(0, 3)
              .map((promo) => (
                <div
                  className="flex flex-col-reverse preview-homepromo"
                  style={{
                    backgroundImage: `url(${promo.images.map(image => image.url)})`
                  }}
                >
                  {promo.promoFlag === 'None' || promo.promoFlag == null ? (
                    ''
                  ) : (
                    <div className="ribbon-callout">{promo.promoFlag}</div>
                  )}
                  <div className="p-6 text-white preview-homepromo-text">
                    {!promo.hideTitle && (
                      <div>
                        <h3>{promo.title}</h3>

                        {(promo.paragraph == null ||
                          promo.paragraph === '' ||
                          promo.paragraph === '<p><br></p>') && (
                          <p>
                            <span className="arrow-link"> </span>
                          </p>
                        )}
                      </div>
                    )}
                    {promo.hideTitle && (
                      <div>
                        <h3
                          dangerouslySetInnerHTML={{ __html: promo.paragraph }}
                        />
                        <h3>
                          <span className="arrow-link"> </span>
                        </h3>
                      </div>
                    )}
                    {!promo.hideTitle && (
                      <div>
                        <p
                          dangerouslySetInnerHTML={{ __html: promo.paragraph }}
                        />
                        {promo.paragraph && (
                          <p>
                            <span className="arrow-link"> </span>
                          </p>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="bg_gradient_feature" />
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
};

HomePromosPreview.propTypes = propTypes;
HomePromosPreview.defaultProps = defaultProps;

export default HomePromosPreview;
