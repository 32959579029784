import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import uuidV4 from 'uuid/v4';
import TinyMCE from '../../../components/common/form/TinyMCE';
import Layout from '../../../layout';
import * as sidebarActions from '../../../actions/sidebar';
import * as modalActions from '../../../actions/modals';
import * as propertyActions from '../../../actions/properties';

import Dropdown, { DropdownItem } from '../../../components/common/Dropdown';
import Table from '../../../components/common/Table';
import More, { MoreMenu, MoreMenuItem } from '../../../components/common/More';
import HoursInput from './HoursInput';
import MealTypeInput from './MealTypeInput';
import * as hourActions from '../../../actions/hours';
import SwitchButton from '../../../components/common/SwitchButton/SwitchButton';
import FormSection from '../../../components/common/FormSection';

const mealTypes = [
  'Breakfast',
  'Brunch',
  'Lunch',
  'Dinner',
  'Happy Hour',
  'Bar',
  'Other',
];
const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const hoursStarts = [
  'Other',
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

const hoursEnds = [
  'Other',
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
];

class Hours extends Component {
  componentDidMount() {
    const {
      updateSidebar,
      fetchProperty,
      fetchHours,
      match: {
        params: { restaurantId }
      }
    } = this.props;
    fetchProperty(restaurantId).then(restaurant => {
      updateSidebar({ titles: { Restaurant: restaurant.name } });
    });
    fetchHours(restaurantId);
  }

  saveHours = () => {
    const {
      hours,
      createHour,
      updateHour,
      match: {
        params: { restaurantId }
      }
    } = this.props;
    const data = hours.length && hours[0];

    // timeSlots need to have their ids removed
    const cleanData = {
      ...data,
      timeSlots: data.timeSlots.map((v, i) => {
        // NOTE setting sort index here before saving data
        const w = { ...v, sortIndex: i };
        delete w.id;
        return w;
      })
    };

    if (cleanData.id) {
      updateHour(restaurantId, cleanData.id, cleanData);
    } else {
      createHour(restaurantId, cleanData);
    }
    window.scrollTo(0, 0);
  };

  getColumns = () => {
    const {
      editTimeSlot,
      removeTimeSlot
    } = this.props;
    const columns = [
      {
        id: 'type',
        Header: 'Meal Type',
        accessor: props =>
          props.mealType === 'Other' ||
          mealTypes.findIndex(meal => meal === props.mealType) === -1 ? (
            <MealTypeInput
              value={props.mealType}
              onChange={
                e => editTimeSlot(props.id, {
                  mealType: e.target.value
                })
              }
              onClose={() => editTimeSlot(props.id, { mealType: 'Breakfast' })}
            />
          ) : (
            <div className="flex flex-wrap mr-4">
              <Dropdown id="meal-type" className="flex-grow xl:flex-1">
                {mealTypes.map(meal => (
                  <DropdownItem
                    key={meal}
                    id={meal}
                    selected={meal === props.mealType}
                    onClick={() => editTimeSlot(props.id, { mealType: meal })}
                  >
                    {meal}
                  </DropdownItem>
                ))}
              </Dropdown>
            </div>
          )
      },
      {
        id: 'days',
        Header: 'Days',
        accessor: props => (
          <div className="flex flex-wrap mr-4">
            <Dropdown id="start-days" className="flex-grow xl:flex-1 xl:mr-2">
              {days.map(day => (
                <DropdownItem
                  key={day}
                  id={day}
                  selected={day === props.daysStart}
                  onClick={() => editTimeSlot(props.id, { daysStart: day })}
                >
                  {day}
                </DropdownItem>
              ))}
            </Dropdown>
            <div className="w-full flex-grow my-2 pl-2 xl:mt-4 xl:hidden">
              thru
            </div>
            <Dropdown id="end-days" className="flex-grow xl:flex-1">
              {days.map(day => (
                <DropdownItem
                  key={day}
                  id={day}
                  selected={day === props.daysEnd}
                  onClick={() => editTimeSlot(props.id, { daysEnd: day })}
                >
                  {day}
                </DropdownItem>
              ))}
            </Dropdown>
          </div>
        )
      },
      {
        id: 'hours',
        Header: 'Hours',
        accessor: props => (
          <div className="flex flex-wrap mr-4">
            {props.hoursStart === 'Other' ||
            hoursStarts.findIndex(start => start === props.hoursStart) ===
              -1 ? (
              <HoursInput
                value={props.hoursStart}
                onChange={
                  e => editTimeSlot(props.id, {
                    hoursStart: e.target.value
                  })
                }
                onClose={() => editTimeSlot(props.id, { hoursStart: '07:00' })}
              />
            ) : (
              <Dropdown
                id="start-hour-type"
                className="flex-grow xl:flex-1 xl:mr-2"
              >
                {hoursStarts.map(start => (
                  <DropdownItem
                    key={start}
                    id={start}
                    selected={start === props.hoursStart}
                    onClick={() => editTimeSlot(props.id, { hoursStart: start })}
                  >
                    {start}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
            <div className="w-full flex-grow my-2 pl-2 xl:mt-4 xl:hidden">
              thru
            </div>
            {props.hoursEnd === 'Other' ||
            hoursEnds.findIndex(end => end === props.hoursEnd) === -1 ? (
              <HoursInput
                value={props.hoursEnd}
                onChange={
                  e => editTimeSlot(props.id, {
                    hoursEnd: e.target.value
                  })
                }
                onClose={() => editTimeSlot(props.id, { hoursEnd: '12:00' })}
              />
            ) : (
              <Dropdown id="end-hour-type" className="flex-grow xl:flex-1">
                {hoursEnds.map(end => (
                  <DropdownItem
                    key={end}
                    id={end}
                    selected={end === props.hoursEnd}
                    onClick={() => editTimeSlot(props.id, { hoursEnd: end })}
                  >
                    {end}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
          </div>
        )
      },
      {
        id: 'more',
        Header: () => <span />,
        accessor: data => (
          <More id={`${data.id}-more`}>
            <MoreMenu id={`${data.id}-moremenu`}>
              <MoreMenuItem
                id={`${data.id}-delete`}
                onClick={() => removeTimeSlot(data.id)}
              >
                Delete
              </MoreMenuItem>
            </MoreMenu>
          </More>
        ),
        className: 'flex items-center',
        width: 50
      },
    ];
    return columns;
  };

  render() {
    const {
      isLoading,
      restaurant,
      hours,
      addTimeSlot,
      moveTimeSlot,
      editHour,
      tinyMCEKey
    } = this.props;
    const columns = this.getColumns();
    const hour = (hours.length && hours[0]) || { timeSlots: [] };

    return (
      <Layout metaTitle="Hours">
        <div className="w-full max-w-3xl">
          <h1 className="mb-4">Hours</h1>
          <div className="mb-8">
            Use this page to create and manage Hours for{' '}
            {isLoading ? 'Restaurant' : restaurant.name}.
          </div>
          <div className="sm:flex sm:flex-1 justify-start mb-6 md:justify-end">
            <button
              type="button"
              className="btn btn-clear no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              onClick={
                () => addTimeSlot({
                  id: uuidV4(),
                  mealType: 'Breakfast',
                  daysStart: 'Monday',
                  daysEnd: 'Sunday',
                  hoursStart: '07:00',
                  hoursEnd: '12:00',
                  created: true
                })
              }
            >
              + Add Hours
            </button>
          </div>
          <Table
            id="hours-table"
            data={hour.timeSlots}
            columns={columns}
            moveRow={(drag, hover) => moveTimeSlot(drag, hover)}
            isDraggable
          />
          <div className="sm:flex sm:flex-1 justify-start mt-6 mb-6 md:justify-end">
            <SwitchButton
              id="published"
              name="published"
              label={hour.published ? 'Published' : 'Unpublished'}
              checked={hour.published}
              className="mt-4 mb-6 mr-6"
              onChange={evt => {
                editHour({ published: !hour.published });
              }}
            />
            <button
              type="button"
              className="btn btn-clear no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              onClick={
                () => addTimeSlot({
                  id: uuidV4(),
                  mealType: 'Breakfast',
                  daysStart: 'Monday',
                  daysEnd: 'Sunday',
                  hoursStart: '07:00',
                  hoursEnd: '12:00',
                  created: true
                })
              }
            >
              + Add Hours
            </button>
          </div>
          <FormSection
            id="title"
            title="Title & Content"
            className="flex flex-col flex-wrap"
          >
            <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
              <div className="font-light mb-3 text-base leading-normal">
                <p>
                  <i>Create a custom message about the Restaurant Hours.</i>
                </p>
                <p>
                  <i>Set to 'Published' to display it on the live site.</i>
                </p>
              </div>
            </div>
            <div className="flex-1 xl:flex-initial xl:w-2/3">
              <div className="mb-3">Content for this Event</div>
              <TinyMCE
                // NOTE we need a randomly/new generated key so tinymce does not
                // cache content from a previous use. This key is generated in
                // the hours reducer. This is also specific to the hours' usage
                // of tinymce outside of a form setting
                key={tinyMCEKey}
                id="message"
                placeholder="Hours Message Goes Here"
                meta={{}}
                input={{
                  value: hour.message || '',
                  onChange: val => {
                    editHour({ message: val });
                  }
                }}
              />
              <div className="mt-12">
                <SwitchButton
                  id="messagePublished"
                  name="messagePublished"
                  label={hour.messagePublished ? 'Published' : 'Unpublished'}
                  checked={hour.messagePublished}
                  className="mt-4 mb-6 mr-6"
                  onChange={evt => {
                    editHour({
                      messagePublished: !hour.messagePublished
                    });
                  }}
                />
              </div>
            </div>
          </FormSection>
          <div className="flex justify-center my-6">
            <button type="button" className="btn" onClick={this.saveHours}>
              Save
            </button>
          </div>
        </div>
      </Layout>
    );
  }
}

Hours.propTypes = {
  restaurant: PropTypes.object.isRequired,
  hours: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchHours: PropTypes.func.isRequired,
  addTimeSlot: PropTypes.func.isRequired,
  editTimeSlot: PropTypes.func.isRequired,
  editHour: PropTypes.func.isRequired,
  createHour: PropTypes.func.isRequired,
  updateHour: PropTypes.func.isRequired,
  moveTimeSlot: PropTypes.func.isRequired,
  removeTimeSlot: PropTypes.func.isRequired,
  tinyMCEKey: PropTypes.string.isRequired,
  match: PropTypes.object
};

Hours.defaultProps = {
  match: {},
  hours: [
    {
      published: false,
      messagePublished: false,
      timeSlots: []
    },
  ]
};

export default connect(
  (state) => ({
    restaurant: state.property.data,
    hours: state.hours.data,
    tinyMCEKey: state.hours.tinyMCEKey,
    isLoading: state.properties.isLoading || state.hours.isLoading
  }),
  {
    ...sidebarActions,
    ...modalActions,
    ...propertyActions,
    ...hourActions
  }
)(Hours);
