/**
 * urlPropertyNamespace returns the url namespace for a property given it's
 * type, ie. hotels for Hotel and restaurants for a Restaurant
 *
 * @param {Object} property (a property object)
 * @returns {String}
 * @api public
 */

const urlPropertyNamespace = property => {
  return property.type === 'hotel' ? 'hotels' : 'restaurants';
};

/**
 * expose
 */

export default urlPropertyNamespace;
