import uuidV4 from 'uuid/v4';
import { moveBySplice } from '../utils/move_by_splice';

import {
  FETCH_HOURS_REQUEST,
  FETCH_HOURS_SUCCESS,
  FETCH_HOURS_FAILURE,
  CREATE_HOUR_REQUEST,
  CREATE_HOUR_SUCCESS,
  CREATE_HOUR_FAILURE,
  EDIT_HOUR,
  UPDATE_HOUR_REQUEST,
  UPDATE_HOUR_SUCCESS,
  UPDATE_HOUR_FAILURE,
  DELETE_HOUR_REQUEST,
  DELETE_HOUR_SUCCESS, /* eslint-disable-line no-unused-vars */ // FIXME why is this not used?
  DELETE_HOUR_FAILURE,
  MOVE_TIME_SLOT,
  ADD_TIME_SLOT,
  EDIT_TIME_SLOT,
  REMOVE_TIME_SLOT
} from '../actions/hours';

const initialState = {
  data: [{ timeSlots: [] }],
  isLoading: false,
  error: ''
};

// eslint-disable-next-line default-param-last
export default function hours(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_HOURS_REQUEST:
    case CREATE_HOUR_REQUEST:
    case UPDATE_HOUR_REQUEST:
    case DELETE_HOUR_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_HOURS_SUCCESS:
      /* eslint-disable no-case-declarations */
      let data = payload.length && payload[0];
      if (data) {
        data = {
          ...data,
          timeSlots: data.timeSlots.map(v => ({ ...v, id: uuidV4() }))
        };
      }
      return {
        ...state,
        isLoading: false,
        error: '',
        data: [data],
        tinyMCEKey: new Date()
      };

    case CREATE_HOUR_SUCCESS:
    case UPDATE_HOUR_SUCCESS:
      /* eslint-disable no-param-reassign */
      payload = {
        ...payload,
        timeSlots: payload.timeSlots.map(v => ({ ...v, id: uuidV4() }))
      };
      return { ...state, data: [payload] };

    case EDIT_HOUR: {
      return { ...state, data: [{ ...state.data[0], ...payload }] };
    }

    case ADD_TIME_SLOT: {
      const { timeSlots } = state.data[0] || { timeSlots: [] };
      timeSlots.push(payload);
      return {
        ...state,
        isLoading: false,
        error: '',
        data: [{ ...state.data[0], timeSlots }]
      };
    }
    case REMOVE_TIME_SLOT: {
      const { timeSlots } = state.data[0];
      const currentIndex = timeSlots.findIndex(hour => hour.id === payload);
      // eslint-disable-next-line no-shadow
      const data =
        currentIndex > -1
          ? timeSlots
              .slice(0, currentIndex)
              .concat(timeSlots.slice(currentIndex + 1))
          : timeSlots;
      return {
        ...state,
        isLoading: false,
        error: '',
        data: [{ ...state.data[0], timeSlots: data }]
      };
    }
    case EDIT_TIME_SLOT: {
      const { timeSlots } = state.data[0];
      // eslint-disable-next-line no-shadow
      const data = timeSlots.map(hour => {
        if (hour.id === payload.id) return { ...hour, ...payload };
        return hour;
      });
      return { ...state, data: [{ ...state.data[0], timeSlots: data }] };
    }
    case MOVE_TIME_SLOT: {
      const { source, target } = payload;
      const { timeSlots } = state.data[0];
      const data = moveBySplice(source, target, timeSlots); // eslint-disable-line no-shadow

      return { ...state, data: [{ ...state.data[0], timeSlots: data }] };
    }

    case FETCH_HOURS_FAILURE:
    case CREATE_HOUR_FAILURE:
    case UPDATE_HOUR_FAILURE:
    case DELETE_HOUR_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
