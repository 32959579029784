import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_SPECIALS_REQUEST = 'FETCH_SPECIALS_REQUEST';
export const FETCH_SPECIALS_SUCCESS = 'FETCH_SPECIALS_SUCCESS';
export const FETCH_SPECIALS_FAILURE = 'FETCH_SPECIALS_FAILURE';

export const FETCH_SPECIAL_REQUEST = 'FETCH_SPECIAL_REQUEST';
export const FETCH_SPECIAL_SUCCESS = 'FETCH_SPECIAL_SUCCESS';
export const FETCH_SPECIAL_FAILURE = 'FETCH_SPECIAL_FAILURE';

export const CREATE_SPECIAL_REQUEST = 'CREATE_SPECIAL_REQUEST';
export const CREATE_SPECIAL_SUCCESS = 'CREATE_SPECIAL_SUCCESS';
export const CREATE_SPECIAL_FAILURE = 'CREATE_SPECIAL_FAILURE';

export const UPDATE_SPECIAL_REQUEST = 'UPDATE_SPECIAL_REQUEST';
export const UPDATE_SPECIAL_SUCCESS = 'UPDATE_SPECIAL_SUCCESS';
export const UPDATE_SPECIAL_FAILURE = 'UPDATE_SPECIAL_FAILURE';

export const DELETE_SPECIAL_REQUEST = 'DELETE_SPECIAL_REQUEST';
export const DELETE_SPECIAL_SUCCESS = 'DELETE_SPECIAL_SUCCESS';
export const DELETE_SPECIAL_FAILURE = 'DELETE_SPECIAL_FAILURE';

export const MOVE_SPECIAL = 'MOVE_SPECIAL';

export const CLONE_SPECIALS_REQUEST = 'CLONE_SPECIALS_REQUEST';
export const CLONE_SPECIALS_SUCCESS = 'CLONE_SPECIALS_SUCCESS';
export const CLONE_SPECIALS_FAILURE = 'CLONE_SPECIALS_FAILURE';

export const SORT_SPECIALS_REQUEST = 'SORT_SPECIALS_REQUEST';
export const SORT_SPECIALS_SUCCESS = 'SORT_SPECIALS_SUCCESS';
export const SORT_SPECIALS_FAILURE = 'SORT_SPECIALS_FAILURE';

export const fetchSpecials = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_SPECIALS_REQUEST
  });
  return fetch(path || `/properties/${propertyId}/specials${fromObj(qry, '?')}`)
    .then(response => {
      const specials = response.data.map(convertToJS);
      const meta = convertToJS(response.meta);
      dispatch({
        type: FETCH_SPECIALS_SUCCESS,
        payload: specials,
        meta
      });
      return { data: specials, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_SPECIALS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countSpecials = (propertyId, params) => dispatch => {
  return fetch(`/properties/${propertyId}/specials${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchSpecial = (propertyId, specialId) => dispatch => {
  dispatch({
    type: FETCH_SPECIAL_REQUEST
  });
  return fetch(`/properties/${propertyId}/specials/${specialId}`)
    .then(response => {
      const special = convertToJS(response.data);
      dispatch({
        type: FETCH_SPECIAL_SUCCESS,
        payload: special
      });
      return special;
    })
    .catch(e => {
      dispatch({
        type: FETCH_SPECIAL_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createSpecial = (propertyId, special) => dispatch => {
  dispatch({
    type: CREATE_SPECIAL_REQUEST
  });
  return fetch(`/properties/${propertyId}/specials`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(special))
  })
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: CREATE_SPECIAL_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: CREATE_SPECIAL_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateSpecial = (propertyId, specialId, update) => dispatch => {
  dispatch({
    type: UPDATE_SPECIAL_REQUEST
  });
  return fetch(`/properties/${propertyId}/specials/${specialId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const special = convertToJS(response.data);
      dispatch({
        type: UPDATE_SPECIAL_SUCCESS,
        payload: special
      });
      return special;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_SPECIAL_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteSpecial = (propertyId, specialId) => dispatch => {
  dispatch({
    type: DELETE_SPECIAL_REQUEST
  });
  return fetch(`/properties/${propertyId}/specials/${specialId}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch({
        type: DELETE_SPECIAL_SUCCESS,
        payload: specialId
      });
      return specialId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_SPECIAL_REQUEST,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveSpecial = (source, target) => ({
  type: MOVE_SPECIAL,
  payload: { source, target }
});

export const sortSpecials = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_SPECIALS_REQUEST
  });
  return fetch(`/properties/${propertyId}/specials/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_SPECIALS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_SPECIALS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const cloneSpecials = (propertyId, ids, toPropertyIds) => (
  dispatch
) => {
  dispatch({
    type: CLONE_SPECIALS_REQUEST
  });
  return fetch(`/properties/${propertyId}/specials/clone`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ids,
      to_property_ids: toPropertyIds
    })
  })
    .then(response => {
      // const data = convertToJS(response.data);
      dispatch({
        type: CLONE_SPECIALS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: CLONE_SPECIALS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
