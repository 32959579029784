const repeatOptions = [
  { key: 'none', value: 'Does Not Repeat' },
  { key: 'daily', value: 'Repeats Daily' },
  { key: 'weekly', value: 'Repeats Weekly' },
  { key: 'monthly', value: 'Repeats Monthly' },
  { key: 'yearly', value: 'Repeats Yearly' },
].map(item => ({
  id: item.key,
  name: item.value
}));

export default repeatOptions;
