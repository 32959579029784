import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as tiledPromoActions from '../../../actions/tiledPromos';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteTiledPromo: PropTypes.func.isRequired
};

const DeleteDiningModal = ({ data, deleteTiledPromo, ...props }) => (
  <DeleteModal
    id="delete-dining-modal"
    title="Private Dining"
    itemTitle={data.title}
    deleteItem={() => deleteTiledPromo(data.hotelId, data.id)}
    {...props}
  />
);

DeleteDiningModal.propTypes = propTypes;

export default connect(
  null,
  tiledPromoActions
)(DeleteDiningModal);
