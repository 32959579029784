import range from 'lodash/range';

export const daysData = range(61).map(num => ({
  id: num,
  name: num.toLocaleString(undefined, { minimumIntegerDigits: 2 })
}));

export const daysFromOne = range(1, 61).map(num => ({
  id: num,
  name: num.toLocaleString(undefined, { minimumIntegerDigits: 2 })
}));
