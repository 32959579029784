import React from 'react';
import SidebarNav from '../SidebarNav';

const Sidebar = () => (
  <nav
    className="w-48 mb-auto min-h-screen pt-1 fixed mainnav z-2 hidden lg:block"
    id="mainnav"
  >
    <SidebarNav />
  </nav>
);

export default Sidebar;
