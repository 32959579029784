import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import { actions as authorityActions } from 'swcms-authority';
import Layout from '../../../layout';
import { updateSidebar } from '../../../actions/sidebar';
import * as propertyActions from '../../../actions/properties';
import * as homePromoActions from '../../../actions/homePromos';
import * as specialActions from '../../../actions/specials';
import * as tiledPromoActions from '../../../actions/tiledPromos';
import * as accommodationActions from '../../../actions/accommodations';
import DashboardTile from '../../../components/DashboardTile';

/**
 * authority actions shorthand
 */

const { READ, WRITE } = authorityActions;

const propTypes = {
  fetchProperty: PropTypes.func.isRequired,
  fetchHomePromos: PropTypes.func.isRequired,
  fetchSpecials: PropTypes.func.isRequired,
  fetchTiledPromos: PropTypes.func.isRequired,
  fetchAccommodations: PropTypes.func.isRequired,
  updateHomePromo: PropTypes.func.isRequired,
  updateSpecial: PropTypes.func.isRequired,
  updateTiledPromo: PropTypes.func.isRequired,
  updateAccommodation: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  hotel: PropTypes.object.isRequired,
  authority: PropTypes.object.isRequired,
  match: PropTypes.object
};

const defaultProps = {
  match: {}
};

const Dashboard = ({
  hotel,
  match: {
    url,
    params: { hotelid }
  },
  fetchProperty,
  fetchHomePromos,
  updateHomePromo,
  fetchSpecials,
  updateSpecial,
  fetchTiledPromos,
  updateTiledPromo,
  fetchAccommodations,
  updateAccommodation,
  updateSidebar, // eslint-disable-line no-shadow
  authority
}) => {
  // NOTE the current implementation of isLoading does not take into account
  // that sub-components may also make their own individual API requests. And
  // because of that, due to state changes in the parent component, may cause
  // child components to re-render making multiple of the same calls. In order
  // to mitigate that, in this case, we are ignoring the isLoading option set
  // via redux and doing a vanilla style promise start, then, end to controll
  // the isLoading and ensure that sub-components that require making API calls
  // are only doing that once. Allowing delegation to the individual component.

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });

      setIsLoading(false);
    });
    return () => {};
  }, []);

  if (isLoading) return <></>;

  // perm is the base permissionable object required for authority can opts
  const perm = {
    permissionableId: hotel.id,
    permissionableType: 'PropertyPermission'
  };

  return (
    <Layout metaTitle="Home Promos">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full">
          <h1 className="mb-12">{hotel.name}</h1>

          {authority.can(READ, { ...perm, resource: 'home_promo' }) && (
            <DashboardTile
              id="active-promos"
              title="Home Promo"
              fetchHandler={fetchHomePromos}
              updateHandler={updateHomePromo}
              qryOpts={[
                {
                  qry: {
                    per_page: 3,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                }
              ]}
              propertyId={hotel.id}
              url={() => `${url}/promos`}
              deleteModalId="DeleteHomePromoModal"
            />
          )}
          {authority.can(READ, { ...perm, resource: 'special' }) && (
            <DashboardTile
              id="active-specials"
              title="Special"
              fetchHandler={fetchSpecials}
              updateHandler={updateSpecial}
              qryOpts={[
                {
                  qry: {
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                }
              ]}
              propertyId={hotel.id}
              url={() => `${url}/specials`}
              deleteModalId="DeleteSpecialModal"
            />
          )}

          {authority.can(READ, { ...perm, resource: 'tiled_promo' }) && (
            <DashboardTile
              id="active-tiled-promos"
              title="Tiled Promo"
              fetchHandler={fetchTiledPromos}
              updateHandler={updateTiledPromo}
              qryOpts={[
                {
                  title: 'Meetings',
                  qry: {
                    type: 'meeting',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                },
                {
                  title: 'Weddings',
                  qry: {
                    type: 'wedding',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                },
                {
                  title: 'Private Dining',
                  qry: {
                    type: 'dining',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                },
                {
                  title: 'Misc',
                  qry: {
                    type: 'misc',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                },
                {
                  title: 'Misc2',
                  qry: {
                    type: 'misc2',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                },
                {
                  title: 'Misc3',
                  qry: {
                    type: 'misc3',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                }
              ]}
              propertyId={hotel.id}
              url={qryOpt => {
                // check for undefined, initial state may not be defined
                if (!qryOpt) {
                  return '';
                }
                let ns = '';
                switch (qryOpt.qry.type) {
                  case 'meeting':
                  case 'wedding':
                    ns = `${qryOpt.qry.type}s`;
                    break;

                  default:
                    ns = qryOpt.qry.type;
                    break;
                }

                return `${url}/tiled/${ns}`;
              }}
              // FIXME there are mulitple modals for the same model type. They
              // should all use the same modal
              deleteModalId="DeleteMeetingModal"
            />
          )}

          {authority.can(READ, { ...perm, resource: 'accommodation' }) && (
            <DashboardTile
              id="active-accommodations"
              title="Accommodation"
              fetchHandler={fetchAccommodations}
              updateHandler={updateAccommodation}
              qryOpts={[
                {
                  title: 'Guest Rooms',
                  qry: {
                    type: 'guestroom',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                },
                {
                  title: 'Suites',
                  qry: {
                    type: 'suite',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                },
                {
                  title: 'Accessible',
                  qry: {
                    type: 'accessible',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                },
                {
                  title: 'Misc',
                  qry: {
                    type: 'misc',
                    per_page: 5,
                    published: true,
                    order_by: 'sort_index:ASC',
                    starts_on: `<${moment().format()}`,
                    ends_on: `>${moment().format()}`
                  }
                }
              ]}
              propertyId={hotel.id}
              url={qryOpt => {
                // check for undefined, initial state may not be defined
                if (!qryOpt) {
                  return '';
                }
                let ns = '';
                switch (qryOpt.qry.type) {
                  case 'guestroom':
                    ns = 'guest-rooms';
                    break;

                  case 'suite':
                  case 'misc':
                    ns = `${qryOpt.qry.type}s`;
                    break;

                  default:
                    ns = qryOpt.qry.type;
                    break;
                }

                return `${url}/accommodations/${ns}`;
              }}
              deleteModalId="DeleteAccommodationModal"
            />
          )}

          <div
            id="dashboard-links"
            className="w-full mb-12 border-t border-blue"
          >
            <div className="">
              <div className="bg-white p-6">
                <div className="my-4">Other Tasks</div>
                {authority.can(WRITE, { ...perm, resource: 'calendar' }) && (
                  <div className="mb-4">
                    <Link to={`${url}/calendars/events/new`}>
                      Create a Calendar Event
                    </Link>
                  </div>
                )}
                {authority.can(WRITE, { ...perm, resource: 'faq' }) && (
                  <div className="mb-4">
                    <Link to={`${url}/faqs/new`}>Create an FAQ</Link>
                  </div>
                )}
                {authority.can(WRITE, { resource: 'can_assign_alerts' }) && (
                  <div className="mb-4">
                    <Link to="alerts/new">Create an Alert</Link>
                  </div>
                )}
                {authority.isSuper() && (
                  <div className="mb-4">
                    <Link to={`${url}/settings`}>Edit Site Settings</Link>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

Dashboard.propTypes = propTypes;
Dashboard.defaultProps = defaultProps;

export default connect(
  state => {
    return {
      hotel: state.property.data,
      authority: state.auth.authority
      // isLoading: state.properties.isLoading
    };
  },
  {
    ...propertyActions,
    updateSidebar,
    ...homePromoActions,
    ...specialActions,
    ...tiledPromoActions,
    ...accommodationActions
  }
)(Dashboard);
