import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import Dropdown, { DropdownItem } from '../../components/common/Dropdown';

import Layout from '../../layout';
import { SearchBar } from '../../components/common/SearchBar/SearchBar';
import { Tabs, TabList, Tab, TabPill } from '../../components/common/Tabs';
import Table from '../../components/common/Table';
import More, { MoreMenu, MoreMenuItem } from '../../components/common/More';
import * as assetActions from '../../actions/assets';
import * as modalActions from '../../actions/modals';

import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import UploadModal from '../../components/UploadModal';
import Paginate from '../../components/Paginate';
import { inputHandlerFunc, fetchHandlerFunc } from '../../utils/fetch-helpers';

const searchTypes = [['tags', 'Tags'], ['filename', 'File Name']];

const propTypes = {
  fetchAssets: PropTypes.func.isRequired,
  resetAssets: PropTypes.func.isRequired,
  createAsset: PropTypes.func.isRequired,
  countAssets: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  assets: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  meta: PropTypes.object
};

const defaultProps = {
  meta: {}
};

const condfn = obj => {
  const { val, type } = obj;
  const cond = {};
  if (!val) {
    return cond;
  }
  switch (type) {
    // NOTE in the api this searches the "name" not the physical filename which
    // is hashed/id'd and would not be used in normal human search
    case 'filename':
      cond.name = val;
      break;

    // TODO if you want to search by category uncomment to support
    // case 'category':
    //   cond.category = val;
    //   break;

    default:
      cond.q = val;
      break;
  }
  return cond;
};

const MediaLibrary = ({
  fetchAssets,
  resetAssets,
  createAsset,
  countAssets,
  showModal,
  assets,
  meta,
  isLoading
}) => {
  const [refresh, setRefresh] = useState(0);
  const [photoCount, setPhotoCount] = useState(0);
  const [documCount, setDocumCount] = useState(0);
  const [selected, setSelected] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [searchType, setSearchType] = useState(searchTypes[0][0]);

  // refreshOnAssetAdd is the debounced setRefresh to refresh the fetchfn after
  // an asset(s) is uploaded.
  const refreshOnAssetAdd = debounce(() => setRefresh(refresh + 1), 1000);

  const tabsconf = [
    {
      qry: { filetype: 'image' },
      cb: setPhotoCount
    },
    // NOTE commented out since there is no tab right now and it will disable
    // the request till actually needed, this also breaks tab function as it
    // creates an 'index' forr video when no there is no tab throwing the index
    // off by 1
    // {
    //   qry: { filetype: 'video' }, cb: setVideoCount
    // },
    {
      // NOTE this search for "documents" assumes PDFs or anything that is a
      // filetype of application/...
      qry: { filetype: 'appli' },
      cb: setDocumCount
    },
  ];

  const fetchfn = fetchHandlerFunc(fetchAssets, countAssets, tabsconf);

  const searchHandler = inputHandlerFunc(
    obj => setSearchInput(obj.val),
    useCallback(debounce(fetchfn, 500), []),
    condfn
  );

  useEffect(() => {
    fetchfn(selected, condfn({ val: searchInput, type: searchType }));

    return () => change('asset', []);
  }, [selected, refresh, searchType]);

  const columns = [
    {
      id: 'name',
      Header: 'File Name',
      accessor: data => <Link to={`/media/${data.id}`}>{data.name}</Link>
    },
    {
      id: 'categories',
      Header: 'Categories',
      accessor: data => {
        return data.categories.map(cat => cat.name).join(', ');
      }
    },
    {
      id: 'type',
      Header: 'Type',
      width: 200,
      accessor: data => data.filetype
    },
    {
      id: 'tags',
      Header: 'Tags',
      accessor: data => {
        return data.tags.map(tag => tag.name).join(', ');
      }
    },
    {
      id: 'more',
      Header: <span />,
      width: 40,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={() =>
                showModal(
                  'DeleteAssetModal',
                  {
                    id: data.id,
                    title: data.name
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )}
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      )
    },
  ];

  return (
    <Layout metaTitle="Media Library">
      <div className="w-full max-w-3xl">
        <h1 className="mb-8">Media Library</h1>
        <div className="mb-12">
          Use this page to create and manage media used across all properties.
        </div>
        {/* <div className="flex mb-12"> */}
        <div className="flex flex-col flex-col-reverse items-start md:flex-row mb-3 sm:mb-6 md:mb-12">
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="mb-3">Search By:</div>
            <Dropdown id="search-type" className="flex-grow xl:flex-1 mb-3">
              {searchTypes.map(([key, label]) => (
                <DropdownItem
                  key={key}
                  id={key}
                  selected={searchType === key}
                  onClick={() => {
                    resetAssets();
                    setSearchType(key);

                    // if there is a searchInput value and we are switching the search
                    // type trigger an isOpen state since the dropdown will be closed
                    // due to the input onfocus event when the user goes to change the
                    // search type
                    if (searchInput) {
                      // FIXME this throws TypeError: Cannot read properties of null (reading 'current')
                      // when I type a search and then change a type
                      // searchBarRef.current.instanceRef.setIsOpen(true);
                    }
                  }}
                >
                  {label}
                </DropdownItem>
              ))}
            </Dropdown>
            <SearchBar
              id="media-library-search"
              className="flex-1 sm:mr-2 lg:mt-1"
              value={searchInput}
              placeholder="Filter Media Library..."
              onChange={({ value }) =>
                searchHandler({ val: value, type: searchType }, selected)}
              onClick={() => {}}
            />
          </div>
          <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
            <button
              type="button"
              className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              onClick={() => setIsOpen(true)}
            >
              + Add Files...
            </button>
          </div>
          <UploadModal
            id="media-library-upload"
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            onUploadSuccess={file => {
              const { filename, filesize, filetype, altName } = file.meta;
              const tags = get(file, 'meta.tags')
                ? get(file, 'meta.tags')
                    .split(',')
                    .map(tag => ({ name: tag.trim() }))
                : [];
              const categories = get(file, 'meta.categories')
                ? get(file, 'meta.categories')
                    .split(',')
                    .map(tag => ({ name: tag.trim() }))
                : [];
              const body = {
                filename,
                filesize,
                filetype,
                name: altName,
                tags,
                categories
              };
              return createAsset(body).then(refreshOnAssetAdd);
            }}
          />
        </div>
        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <div>
            <Tabs selectedIndex={selected} onSelect={i => setSelected(i)}>
              <TabList>
                <Tab id={0}>
                  <span className="mr-2">Photos</span>
                  <TabPill id="photo-assets">{photoCount}</TabPill>
                </Tab>
                {/* <Tab id={1}>
                  <span className="mr-2">Videos</span>
                  <TabPill id="video-assets">{videoCount}</TabPill>
                </Tab> */}
                <Tab id={2}>
                  <span className="mr-2">Documents</span>
                  <TabPill id="document-assets">{documCount}</TabPill>
                </Tab>
                <Paginate
                  meta={meta}
                  fetchHandler={fetchAssets}
                  className="hidden lg:block"
                />
              </TabList>
              <Table id="assets-table" data={assets} columns={columns} />
            </Tabs>
            <Paginate meta={meta} fetchHandler={fetchAssets} />
          </div>
        )}
      </div>
    </Layout>
  );
};

MediaLibrary.propTypes = propTypes;
MediaLibrary.defaultProps = defaultProps;

export default connect(
  state => ({
    assets: state.assets.data,
    meta: state.assets.meta,
    tags: state.tags.data,
    isLoading: state.assets.isLoading
  }),
  { ...assetActions, ...modalActions }
)(MediaLibrary);
