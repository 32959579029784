import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import Layout from '../../../../layout';
import * as propertyActions from '../../../../actions/properties';
import * as accommodationActions from '../../../../actions/accommodations';
import * as sidebarActions from '../../../../actions/sidebar';
import HotelAccommodationForm from '../../../../components/HotelAccommodationForm';
import FormNavigator from '../../../../components/FormNavigator';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  createAccommodation: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchAccommodations: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'promo', title: 'Promo Flag' },
  { id: 'preview', title: 'Preview + Publish' },
];

const AccessibleNew = ({
  match: {
    params: { hotelid }
  },
  history: { push },
  images,
  createAccommodation,
  hotel,
  fetchProperty,
  fetchAccommodations,
  updateSidebar
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchAccommodations(hotelid);
    return () => {};
  }, []);

  const handleSubmit = data => {
    let { doesNotEnd, endsOn } = data;
    if (doesNotEnd) {
      endsOn = new Date(2120, 0, 1);
    }
    const body = {
      ...data,
      type: 'accessible',
      startsOn: data.startsOn.toJSON(),
      endsOn: endsOn ? endsOn.toJSON() : endsOn,
      images: images.map(({ assetId, alt, videoUrl }, i) => ({
        assetId,
        alt,
        videoUrl,
        sort_index: i
      })),
      modal1Title:
        // eslint-disable-next-line no-nested-ternary
        data.modal1Title === 'None'
          ? null
          : data.modal1Title === 'Other'
          ? data.modal1TitleOther
          : data.modal1Title,
      modal2Title:
        // eslint-disable-next-line no-nested-ternary
        data.modal2Title === 'None'
          ? null
          : data.modal2Title === 'Other'
          ? data.modal2TitleOther
          : data.modal2Title,
      cta: Object.assign(
        data.cta,
        data.cta.type === 'Other' && { type: data.cta.typeInput }
      ),
      promo_flag:
        // eslint-disable-next-line no-nested-ternary
        data.promoFlag === 'Other' ? data.promoFlagOther : data.promoFlag
    };
    return createAccommodation(hotelid, body)
      .then(() => push(`/hotels/${hotelid}/accommodations/accessible`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };
  return (
    <Layout metaTitle="New Accessible">
      <div className="w-full max-w-3xl">
        <Link
          to={`/hotels/${hotelid}/accommodations/accessible`}
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Accessible
        </Link>
        <h1 className="mb-6">New Accessible</h1>
        <div className="mb-16">
          Use this page to create a new Accessible on {hotel.name}.
        </div>
        <HotelAccommodationForm
          id="new-accessible-accommodation"
          category="Accessible"
          hotel={hotel}
          initialValues={{
            doesNotEnd: true,
            modal1Title: 'None',
            modal2Title: 'None',
            cta: {
              type: 'None',
              opensInNewWindow: true,
              addDatePickerPopup: false,
              advancedPurchaseDays: 0,
              minimumNightStay: 1
            },
            promoFlag: 'None',
            published: false,
            archived: false
          }}
          onSubmit={handleSubmit}
        />
      </div>
      <FormNavigator
        id="new-accessibles-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

AccessibleNew.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    accommodations: state.accommodations.data.filter(
      accommodation => accommodation.type === 'accessible'
    ),
    images: get(state, 'images.hotel-accommodations-image.items', [])
      .filter(item => item.file)
      .map(item => ({
        assetId: item.file.id,
        alt: item.alt,
        videoUrl: item.videoUrl
      })),
    isLoading: state.property.isLoading
  }),
  { ...propertyActions, ...accommodationActions, ...sidebarActions }
)(AccessibleNew);
