import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Input from '../../common/Input';
import FormDropdown from '../../common/form/Dropdown';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const validate = values => {
  const errors = {};

  if (!values.campaignType) {
    errors.campaignType = 'Required!';
  }

  if (values.campaignType === '00') {
    errors.campaignType = 'Must choose a valid value!';
  }

  if (!values.CampaignName) {
    errors.CampaignName = 'Required!';
  }

  if (!values.mediaPlacement) {
    errors.mediaPlacement = 'Required!';
  }

  if (values.mediaPlacement === '00') {
    errors.mediaPlacement = 'Must choose a valid value!';
  }

  return errors;
};

const campaignTypeOptions = [
  { key: 'B2B', value: 'Business to Business' },
  { key: 'LOY', value: 'Loyalty Promotion' },
  { key: 'REV', value: 'Revenue Driving Promotion' },
  { key: 'OTH', value: 'Undeclared campaign type' },
  { key: 'PTS', value: 'Points Offer' },
  { key: 'PTR', value: 'Partner Promotion' },
  { key: 'GP', value: 'Group Promotion' },
].map(item => ({
  id: item.key,
  name: item.value
}));

const mediaPlacementOptions = [
  { key: 'HMM', value: 'Homepage Marketing Module' },
  { key: 'OFF', value: 'Hotel Offers' },
  { key: 'PKG', value: 'Hotel Packages' },
  { key: 'DIS', value: 'Hotel discount' },
  { key: 'MOF', value: 'Meetings & Events' },
].map(item => ({
  id: item.key,
  name: item.value
}));

const GenerateTrackingForm = ({ id, handleSubmit }) => (
  <form id={id} onSubmit={handleSubmit}>
    <div className="my-12 text-grey-darker">
      <div className="mx-3">
        <div className="mb-3">Campaign Type*</div>
        <Field
          id="campaignType"
          name="campaignType"
          data={campaignTypeOptions}
          component={FormDropdown}
          className="w-full mb-6"
        />
      </div>
      <div className="mx-3">
        <div className="mb-3">Campaign Name*</div>
        <Field
          id="CampaignName"
          name="CampaignName"
          component={Input}
          className="w-full mb-6"
          placeholder="Cyber Monday Deal"
        />
      </div>
      <div className="mx-3">
        <div className="mb-3">Media Placement*</div>
        <Field
          id="mediaPlacement"
          name="mediaPlacement"
          data={mediaPlacementOptions}
          component={FormDropdown}
          className="w-full mb-6"
        />
      </div>
      <div className="mx-3">
        <div className="mb-3">Variation</div>
        <Field
          id="variation"
          name="variation"
          component={Input}
          className="w-full mb-6"
          placeholder="xxxx"
        />
      </div>
    </div>
    <div className="flex justify-center my-6">
      <button type="submit" className="btn">
        Generate
      </button>
    </div>
  </form>
);

GenerateTrackingForm.propTypes = propTypes;

export default reduxForm({ form: 'GenerateTrackingForm', validate })(
  GenerateTrackingForm
);
