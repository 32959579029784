import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  onScroll: PropTypes.func
};

const defaultProps = {
  style: {},
  onScroll: () => {}
};

const SearchBarMenu = ({ id, children, style, onScroll }) => (
  <div
    id={id}
    className="border border-t-0 border-grey-light rounded-b-sm shadow bg-white h-96 overflow-y-scroll"
    style={style}
    onScroll={onScroll}
  >
    {children}
  </div>
);

SearchBarMenu.propTypes = propTypes;
SearchBarMenu.defaultProps = defaultProps;

export default SearchBarMenu;
