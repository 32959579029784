import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import UserIcon from '../../common/UserIcon';

class UserMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleClickOutside = () => this.setState({ isOpen: false });

  render() {
    const { isOpen } = this.state;
    const { logoutUser } = this.props;
    return (
      <div id="user-menu">
        <UserIcon
          id="user-icon"
          role="button"
          tabIndex={0}
          className="cursor-pointer outline-none relative"
          style={{ fill: '#888888', width: 32, marginTop: 4 }}
          onClick={() => this.setState({ isOpen: !isOpen })}
        />
        {isOpen && (
          <div
            id="user-menu-menu"
            style={{ maxHeight: 210 }}
            className="shadow absolute z-50 bg-white overflow-y-auto w-48 -ml-32 mt-3"
          >
            <Link
              to="/profile"
              className="!no-underline"
              onClick={this.handleClickOutside}
            >
              <div className="p-4 outline-none text-black hover:bg-blue-light">
                Profile
              </div>
            </Link>
            <div
              role="button"
              tabIndex={0}
              className="p-4 outline-none hover:bg-blue-light text-black cursor-pointer"
              onClick={logoutUser}
            >
              Log Out
            </div>
          </div>
        )}
      </div>
    );
  }
}

UserMenu.propTypes = {
  logoutUser: PropTypes.func.isRequired
};

const clickOutsideConfig = {
  handleClickOutside: instance => instance.handleClickOutside
};

export { UserMenu };

export default onClickOutside(UserMenu, clickOutsideConfig);
