import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import timezones from 'compact-timezone-list';
import * as modalActions from '../../actions/modals';
import * as imageActions from '../../actions/images';
import * as assetActions from '../../actions/assets';
import Input from '../common/Input';
import FormErrorBox from '../common/FormErrorBox';
import FormSection from '../common/FormSection';
import FormDropdown from '../common/form/Dropdown';
import SetAnAsset from '../SetAnAsset';
// prop types
const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  error: undefined
};

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required!';
  }

  if (!values.url) {
    errors.url = 'Required!';
  }

  if (!values.timezone) {
    errors.timezone = 'Required!';
  }

  return errors;
};

const timezoneList = timezones.map(timezone => ({
  id: timezone.tzCode,
  name: timezone.label
}));

const condfn = () => {
  return {
    filetype: 'image',
    category: 'cat#Restaurant Logo'
  };
};

const RestaurantForm = ({
  id,
  handleSubmit,
  change,
  submit,
  error
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="name"
        title="Restaurant Name"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Enter the Name for this Restaurant.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="font-normal mb-3 text-base">Restaurant Name*</div>
          <Field
            id="name"
            name="name"
            component={Input}
            className="w-full mb-6"
            placeholder="Enter Name for this restaurant"
            style={{ width: 320 }}
          />
        </div>
      </FormSection>
      <FormSection
        id="url"
        title="Restaurant URL"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Enter the Site URL to use for this Restaurant.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Site URL*</div>
          <div className="flex items-center">
            <div className="mr-2 mb-6 text-grey-dark text-base">https://</div>
            <div>
              <Field
                id="url"
                name="url"
                component={Input}
                className="w-full mb-3"
                style={{ width: 320 }}
                placeholder="Enter the URL for this restaurant"
              />
              <div className="text-sm font-bold">www.example.com</div>
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection
        id="logo"
        title="Set a Restaurant Logo"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Set the Logo to use for this Restaurant. This will only display
                within this CMS and not the live site.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <SetAnAsset
            change={change}
            condfn={condfn}
            canUploadAsset={() => true}
            categories={[{ name: 'Restaurant Logo' }]}
            name='restaurant-logo-image'
            id="restaurant-logo-upload"
            header='Select an image*'
            onlySingle
          />
        </div>
      </FormSection>
      <FormSection
        id="timezone"
        title="Time Zone"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Set the Time Zone for this Hotel property.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Time Zone*</div>
          <Field
            id="timezone"
            name="timezone"
            className="w-full mb-6"
            style={{ width: 320 }}
            component={FormDropdown}
            data={timezoneList}
            placeholder="Select a Time Zone"
          />
        </div>
      </FormSection>
      <div className="flex justify-center my-12">
        <button
          type="button"
          className="btn btn-clear mr-4"
          onClick={e => {
            e.preventDefault();
            change('archived', true);
            setTimeout(() => {
              submit();
            }, 0);
          }}
          disabled={!!error}
        >
          Archive this Restaurant
        </button>
        <button
          type="submit"
          className="btn"
          style={{ width: 200 }}
          disabled={!!error}
        >
          Save
        </button>
      </div>
      {error && <FormErrorBox id="restaurant-error">{error}</FormErrorBox>}
    </form>
  );
};

RestaurantForm.propTypes = propTypes;
RestaurantForm.defaultProps = defaultProps;

export default compose(
  reduxForm({ form: 'RestaurantForm', validate }),
  connect(
    state => ({}),
    { ...modalActions, ...imageActions, ...assetActions }
  )
)(RestaurantForm);
