import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const NotFound = ({ auth: { isAuthenticated } }) => {
  // FIXME 404 pages for logged in users
  if (isAuthenticated) {
    return <h1>404 Not Found</h1>;
  }

  // FIXME 404 for pages for non-logged in users
  return <h1>Oops... 404 Not Found</h1>;
};

NotFound.propTypes = {
  auth: PropTypes.object.isRequired
};

export default connect(state => ({ auth: state.auth }))(NotFound);
