import React from 'react';
import PropTypes from 'prop-types';

/**
 * viewingLabel returns the count range viewed for the current set
 *
 * @param {Object} meta deconstructed to use perPage, count, page, totalPages
 * @returns {String}
 * @api private
 */

const viewingLabel = ({ perPage, count, page, totalPages }) => {
  const i = page === 1 ? 0 : (page - 1) * perPage;
  const j = page === totalPages ? count : perPage * page;

  return `${i + 1} - ${j}`;
};

const Paginate = ({ meta, fetchHandler }) => {
  if (!meta) {
    return <></>;
  }

  const getPage = (dir, metaAttr) => {
    const { links } = metaAttr;
    if (!(dir in links)) {
      return;
    }

    const link = links[dir];
    fetchHandler(null, link);
  };

  return (
    <div className="paginate">
      <div className="paginate__controls">
        <p className="text-sm">
          <span>
            Page {meta.page} of {meta.totalPages}, Viewing {viewingLabel(meta)}{' '}
            of {meta.count}
            &nbsp;&nbsp;&nbsp;&nbsp;
          </span>
          <span>
            <button
              type="button"
              onClick={() => {
                getPage('previous', meta);
              }}
            >
              &#60; Prev
            </button>
            &nbsp; | &nbsp;
            <button
              type="button"
              onClick={() => {
                getPage('next', meta);
              }}
            >
              Next &#62;
            </button>
          </span>
        </p>
      </div>
      {/* eslint-disable-next-line */}
    </div>
  );
};

Paginate.propTypes = {
  meta: PropTypes.object.isRequired,
  fetchHandler: PropTypes.func.isRequired
};

/**
 * expose
 */

export default Paginate;
