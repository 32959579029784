import isoFetch from 'isomorphic-fetch';
import Cookies from 'js-cookie';
import merge from 'lodash/merge';
import { apiBaseUrl } from '../config';
import history from './history';

if (!apiBaseUrl) {
  throw new Error('API_BASE_URL must be defined');
}

/**
 * handleErr handles specific response errors, such as unauthorized and
 * forbidden where specific events should occur for non-generic errors
 *
 * @param {Object} json (response json)
 * @param {Number} status (http status)
 * @returns {RejectedPromise}
 * @api private
 */

/* eslint-disable consistent-return */
const handleErr = (json, status) => {
  switch (status) {
    case 401:
      Cookies.remove('token');
      history.push('/');
      history.go(); // must force the push
      break;

    default:
      break;
  }

  return Promise.reject(json);
};

const returnJSON = response => {
  const { status } = response;
  if (status === 204) return null;
  return response
    .json()
    .then(json => (response.ok ? json : handleErr(json, status)));
};

export const authHeader = () => ({
  Authorization: `bearer ${Cookies.get('token')}`
});

export default (partialUrl, options = {}, forceAuth = false) => {
  const init =
    partialUrl === '/auth' && !forceAuth
      ? options
      : merge({}, options, { headers: authHeader() });
  // strip the /api prefix that might be part of the meta links urls returned by
  // the API
  const path = partialUrl.replace('/api', '');
  return isoFetch(`${apiBaseUrl}${path}`, init).then(returnJSON);
};
