import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as modalActions from '../../actions/modals';
import Input from '../common/Input';
import TinyMCE from '../common/form/TinyMCE';
import FormDatePicker from '../common/form/DatePicker';
import SearchBar from '../common/SearchBar';
import FormDropdown from '../common/form/Dropdown';
import FormSection from '../common/FormSection';
import { daysFromOne, daysData } from '../../utils/days-options';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  eventEnds: PropTypes.bool,
  ctaOption: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  minNightStay: PropTypes.bool
};

const defaultProps = {
  eventEnds: false,
  ctaOption: '',
  minNightStay: false
};

const ctaOptions = [
  { id: 'bookNow', name: 'Book Now' },
  { id: 'offerDetails', name: 'Offer Details' },
];

const promoData = [
  {
    id: 'websiteExclusive',
    name: 'Website Exclusive'
  },
];

const EditSharedSpecialsForm = ({
  id,
  handleSubmit,
  eventEnds,
  ctaOption,
  minNightStay,
  showModal
}) => (
  <form id={id} onSubmit={handleSubmit}>
    <FormSection id="title" title="Title & Content">
      <div className="mr-12 w-1/3">
        <div className="font-bold mb-3 text-sm">Title*</div>
        <Field
          id="specials-title"
          name="title"
          className="w-full"
          placeholder="Enter a title..."
          component={Input}
        />
      </div>
      <div className="flex-1">
        <div className="font-bold mb-3 text-sm">Anchor Link Title*</div>
        <Field
          id="anchor-title"
          name="anchorTitle"
          className="w-full mb-6"
          placeholder="Enter the anchor link title..."
          component={Input}
        />
        <div className="font-bold mb-3 text-sm">Content for this Special*</div>
        <Field
          id="special-content"
          name="content"
          className="mb-6"
          placeholder="Enter the content for this special..."
          component={TinyMCE}
        />
        <div className="font-bold mb-3 text-sm flex justify-between">
          <div>Terms + Conditions</div>
          {/* FIXME how do these buttons work? Not sure how to assign a type here */}
          {/* eslint-disable-next-line react/button-has-type */}
          <button className="btn btn-link p-0">- Remove this modal</button>
        </div>
        <Field
          id="terms-conditions"
          name="terms"
          className="mb-4"
          placeholder="Enter the Terms + Conditions here..."
          component={TinyMCE}
        />
        {/* FIXME how do these buttons work? Not sure how to assign a type here */}
        {/* eslint-disable-next-line react/button-has-type */}
        <button className="btn btn-link">+ Add Package Restrictions</button>
      </div>
    </FormSection>
    <FormSection
      id="date"
      title="Date & Time"
      className="border-t"
      isHorizontal
    >
      <div className="mr-4">
        <div className="text-sm font-bold mb-3">Start Date*</div>
        <Field
          id="start-date"
          name="startDate"
          component={FormDatePicker}
          className="mb-6"
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MM/dd/yyyy h:mm aa"
          timeCaption="time"
          autoComplete="off"
        />
        <div className="flex items-center">
          <Field
            id="event-ends"
            type="checkbox"
            name="eventEnds"
            className="mr-3"
            component={Input}
          />
          <div className="text-sm font-bold">Special does not end</div>
        </div>
      </div>
      <div>
        <div
          className={classnames('text-sm font-bold mb-3', {
            'text-grey-light': eventEnds
          })}
        >
          End Date*
        </div>
        <Field
          id="end-date"
          name="endDate"
          component={FormDatePicker}
          disabled={eventEnds}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          dateFormat="MM/dd/yyyy h:mm aa"
          timeCaption="time"
          autoComplete="off"
        />
      </div>
    </FormSection>
    <FormSection id="image" title="Set an Image" className="border-t">
      <div>
        <div className="text-sm font-bold mb-3">Select an image*</div>
        <SearchBar
          id="event-image"
          placeholder="Search..."
          className="w-64 mb-3"
        />
        <button
          type="button"
          className="btn btn-clear"
          onClick={() => {
            showModal('UploadFileModal');
          }}
        >
          +Upload File
        </button>
      </div>
    </FormSection>
    <FormSection id="cta" title="CTA (Call to Action)" className="border-t">
      <div className="mr-12 w-1/3">
        <div className="text-sm font-bold mb-3">Select an Option*</div>
        <Field
          id="ctaOption"
          name="ctaOption"
          data={ctaOptions}
          component={FormDropdown}
          style={{ width: 300 }}
        />
      </div>
      <div className="flex-1">
        {ctaOption === 'bookNow' && (
          <Fragment>
            <div className="text-sm font-bold mb-3">Link URL*</div>
            <div className="flex mb-6">
              <Field
                id="linkUrl"
                name="linkUrl"
                className="flex-1 mr-2"
                placeholder="Enter a Link URL..."
                component={Input}
              />
              <button
                type="button"
                className="btn btn-clear px-6"
                onClick={e => {
                  e.preventDefault();
                  showModal('GenerateURLModal', {
                    form: 'SharedSpecialsForm',
                    field: 'linkUrl'
                  });
                }}
              >
                Generate URL
              </button>
            </div>
            <div className="flex mb-6 items-center">
              <Field
                id="newWindow"
                name="newWindow"
                type="checkbox"
                className="mr-2"
                component={Input}
              />
              <div className="text-sm font-semibold">Opens in New Window</div>
            </div>
            <div className="flex mb-6 items-center">
              <div className="flex w-1/2 items-center">
                <Field
                  id="datePicker"
                  name="datePicker"
                  type="checkbox"
                  className="mr-2"
                  component={Input}
                />
                <div className="text-sm font-semibold">
                  Add Date Picker Popup
                </div>
              </div>
              <div className="flex w-1/2 items-center">
                <Field
                  id="purchaseDays"
                  name="purchaseDays"
                  className="mr-2"
                  component={FormDropdown}
                  data={daysData}
                />
                <div className="text-sm font-semibold">
                  Advance Purchase Days
                </div>
              </div>
            </div>
            <div className="flex mb-6 items-center">
              <div className="flex w-1/2 items-center">
                <Field
                  id="minNightStay"
                  name="minNightStay"
                  type="checkbox"
                  className="mr-2"
                  component={Input}
                />
                <div className="text-sm font-semibold">
                  Add Minimum Night Stay (NEW)
                </div>
              </div>
              <div className="flex w-1/2 items-center">
                <Field
                  id="minNightDays"
                  name="minNightDays"
                  className="mr-2"
                  component={FormDropdown}
                  data={daysFromOne}
                  disabled={!minNightStay}
                />
                <div
                  className={classnames('text-sm font-semibold', {
                    'text-grey': !minNightStay
                  })}
                >
                  Min Days (NEW)
                </div>
              </div>
            </div>
          </Fragment>
        )}
        <div className="text-sm font-bold mb-3">GA Link Title</div>
        <Field
          id="trackingTag"
          name="trackingTag"
          className="w-full mb-4"
          placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
          component={Input}
        />
        <div className="flex justify-left">
          <button
            type="button"
            className="btn btn-clear px-6 mr-4"
            onClick={e => {
              e.preventDefault();
              showModal('GenerateTrackingModal', {
                form: 'EditSharedSpecialsForm',
                field: 'trackingTag'
              });
            }}
          >
            Generate Tracking
          </button>
        </div>
      </div>
    </FormSection>
    <FormSection
      id="promo"
      title="Promo Flag"
      className="border-t"
      isHorizontal
    >
      <div className="mr-4">
        <div className="text-sm font-bold mb-3">Promo Flag*</div>
        <Field
          id="promoFlag"
          name="promoFlag"
          component={FormDropdown}
          className="mb-6"
          data={promoData}
        />
      </div>
    </FormSection>
    <FormSection id="details" title="Details Page" className="border-t">
      <div className="w-1/3 mr-12">
        <div className="text-sm font-bold mb-3">Custom URL:</div>
        <Field
          id="customUrl"
          name="customUrl"
          placeholder="details-page-custom-url"
          className="w-full"
          component={Input}
        />
      </div>
      <div className="flex-1">
        <div className="text-sm font-bold mb-3">Page Content:</div>
        <Field
          id="pageContent"
          name="pageContent"
          className="mb-6"
          placeholder="Enter the content for this Detail Page..."
          component={TinyMCE}
        />
        <div className="flex mb-6 items-center">
          <Field
            id="displayTerms"
            name="displayTerms"
            type="checkbox"
            className="mr-2"
            component={Input}
          />
          <div className="text-sm font-semibold">
            Display Terms + Conditions content on page
          </div>
        </div>
        <div className="flex mb-8 items-center">
          <Field
            id="displayPackage"
            name="displayPackage"
            type="checkbox"
            className="mr-2"
            component={Input}
          />
          <div className="text-sm font-semibold">
            Display Package Restrictions content on page
          </div>
        </div>
        <div className="text-sm font-bold mb-3">Select an image*</div>
        <SearchBar
          id="details-image"
          placeholder="Search..."
          className="w-64 mb-6"
        />
        <div className="text-sm font-bold mb-3">CTA Title:</div>
        <Field
          id="detailTitle"
          name="detailTitle"
          className="mb-6"
          data={[{ id: 'learnMore', name: 'Learn More' }]}
          style={{ width: 256 }}
          component={FormDropdown}
        />
        <div className="text-sm font-bold mb-3">CTA Link URL</div>
        <Field
          id="ctaUrl"
          name="ctaUrl"
          className="mb-6"
          style={{ width: 256 }}
          component={Input}
        />
        <div className="flex mb-6 items-center">
          <Field
            id="detailsNewWindow"
            name="detailsNewWindow"
            className="mr-2"
            type="checkbox"
            component={Input}
          />
          <div className="text-sm font-semibold">Opens in New Window</div>
        </div>
        <div className="flex mb-6 items-center">
          <div className="flex w-1/2 items-center">
            <Field
              id="detailsDatePicker"
              name="detailsDatePicker"
              className="mr-2"
              type="checkbox"
              component={Input}
            />
            <div className="text-sm font-semibold">Add Date Picker Popup</div>
          </div>
          <div className="flex w-1/2 items-center">
            <Field
              id="detailsPurchaseDays"
              name="detailsPurchaseDays"
              className="mr-2"
              data={daysData}
              component={FormDropdown}
            />
            <div className="text-sm font-semibold">Advance Purchase Days</div>
          </div>
        </div>
        <div className="flex mb-6 items-center">
          <div className="flex w-1/2 items-center">
            <Field
              id="detailsMinNightStay"
              name="detailsMinNightStay"
              className="mr-2"
              type="checkbox"
              component={Input}
            />
            <div className="text-sm font-semibold">
              Add Minimum Night Stay (NEW)
            </div>
          </div>
          <div className="flex w-1/2 items-center">
            <Field
              id="detailsMinDays"
              name="detailsMinDays"
              className="mr-2"
              data={daysFromOne}
              component={FormDropdown}
            />
            <div className="text-sm font-semibold">Min Days (NEW)</div>
          </div>
        </div>
        <div className="text-base font-normal mb-3">Adobe Tracking Tag</div>
        <Field
          id="details.cta.trackingTag"
          name="details.cta.trackingTag"
          className="w-full mb-4"
          placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
          component={Input}
        />
        <div className="flex justify-left">
          <button
            type="button"
            className="btn btn-clear px-6 mr-4"
            onClick={e => {
              e.preventDefault();
              showModal('GenerateTrackingModal', {
                form: 'HotelSpecialForm',
                field: 'details.cta.trackingTag'
              });
            }}
          >
            Generate Tracking
          </button>
        </div>
      </div>
    </FormSection>
    <FormSection
      id="distribute"
      title="Distribute to Hotels (NEW)"
      className="border-t"
      isHorizontal
    >
      <div className="w-2/3">
        <div className="text-sm mb-8">
          This promo will be placed across multiple hotels. If you would like to
          make changes to a Shared Promo on a specific hotel, you can edit via
          the Promos section for that hotel.
        </div>
        <div className="text-sm font-semibold mb-3">Select Hotels*</div>
        <Field
          id="distributeHotels"
          name="distributeHotels"
          component={Input}
          className="mb-6 w-full"
          placeholder="Filter Hotels..."
        />
      </div>
    </FormSection>
    <FormSection id="preview" title="Preview + Publish" className="border-t" />
    <div className="flex justify-center my-6">
      <button type="submit" className="btn">
        Save
      </button>
    </div>
  </form>
);

EditSharedSpecialsForm.propTypes = propTypes;
EditSharedSpecialsForm.defaultProps = defaultProps;

const selector = formValueSelector('EditSharedSpecialsForm');

export default compose(
  reduxForm({ form: 'EditSharedSpecialsForm' }),
  connect(
    state => ({
      eventEnds: selector(state, 'eventEnds'),
      ctaOption: selector(state, 'ctaOption'),
      minNightStay: selector(state, 'minNightStay')
    }),
    modalActions
  )
)(EditSharedSpecialsForm);
