import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_LISTS_REQUEST = 'FETCH_LISTS_REQUEST';
export const FETCH_LISTS_SUCCESS = 'FETCH_LISTS_SUCCESS';
export const FETCH_LISTS_FAILURE = 'FETCH_LISTS_FAILURE';

export const FETCH_LIST_REQUEST = 'FETCH_LIST_REQUEST';
export const FETCH_LIST_SUCCESS = 'FETCH_LIST_SUCCESS';
export const FETCH_LIST_FAILURE = 'FETCH_LIST_FAILURE';

export const CREATE_LIST_REQUEST = 'CREATE_LIST_REQUEST';
export const CREATE_LIST_SUCCESS = 'CREATE_LIST_SUCCESS';
export const CREATE_LIST_FAILURE = 'CREATE_LIST_FAILURE';

export const UPDATE_LIST_REQUEST = 'UPDATE_LIST_REQUEST';
export const UPDATE_LIST_SUCCESS = 'UPDATE_LIST_SUCCESS';
export const UPDATE_LIST_FAILURE = 'UPDATE_LIST_FAILURE';

export const DELETE_LIST_REQUEST = 'DELETE_LIST_REQUEST';
export const DELETE_LIST_SUCCESS = 'DELETE_LIST_SUCCESS';
export const DELETE_LIST_FAILURE = 'DELETE_LIST_FAILURE';

export const MOVE_LIST = 'MOVE_LIST';

export const CLONE_LISTS_REQUEST = 'CLONE_LISTS_REQUEST';
export const CLONE_LISTS_SUCCESS = 'CLONE_LISTS_SUCCESS';
export const CLONE_LISTS_FAILURE = 'CLONE_LISTS_FAILURE';

export const SORT_LISTS_REQUEST = 'SORT_LISTS_REQUEST';
export const SORT_LISTS_SUCCESS = 'SORT_LISTS_SUCCESS';
export const SORT_LISTS_FAILURE = 'SORT_LISTS_FAILURE';

export const fetchLists = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_LISTS_REQUEST
  });
  return fetch(path || `/properties/${propertyId}/lists${fromObj(qry, '?')}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_LISTS_SUCCESS,
        payload: data,
        meta
      });
      return { data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_LISTS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countLists = (propertyId, params) => dispatch => {
  return fetch(`/properties/${propertyId}/lists${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchList = (propertyId, listId) => dispatch => {
  dispatch({
    type: FETCH_LIST_REQUEST
  });
  return fetch(`/properties/${propertyId}/lists/${listId}`)
    .then(response => {
      const List = convertToJS(response.data);
      dispatch({
        type: FETCH_LIST_SUCCESS,
        payload: List
      });
      return List;
    })
    .catch(e => {
      dispatch({
        type: FETCH_LIST_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createList = (propertyId, list) => dispatch => {
  dispatch({
    type: CREATE_LIST_REQUEST
  });
  return fetch(`/properties/${propertyId}/lists`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(list))
  })
    .then(response => {
      const List = convertToJS(response.data);
      dispatch({
        type: CREATE_LIST_SUCCESS,
        payload: List
      });
      return List;
    })
    .catch(e => {
      dispatch({
        type: CREATE_LIST_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateList = (propertyId, listId, update) => dispatch => {
  dispatch({
    type: UPDATE_LIST_REQUEST
  });
  return fetch(`/properties/${propertyId}/lists/${listId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const List = convertToJS(response.data);
      dispatch({
        type: UPDATE_LIST_SUCCESS,
        payload: List
      });
      return List;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_LIST_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteList = (propertyId, listId) => dispatch => {
  dispatch({
    type: DELETE_LIST_REQUEST
  });
  return fetch(`/properties/${propertyId}/lists/${listId}`, {
    method: 'DELETE'
  })
    .then(response => {
      dispatch({
        type: DELETE_LIST_SUCCESS,
        payload: listId
      });
      return listId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_LIST_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveList = (source, target) => ({
  type: MOVE_LIST,
  payload: { source, target }
});

export const sortLists = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_LISTS_REQUEST
  });
  return fetch(`/properties/${propertyId}/lists/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_LISTS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_LISTS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const cloneLists = (propertyId, ids, toPropertyIds) => (
  dispatch
) => {
  dispatch({
    type: CLONE_LISTS_REQUEST
  });
  return fetch(`/properties/${propertyId}/lists/clone`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ids,
      to_property_ids: toPropertyIds
    })
  })
    .then(response => {
      // const faq = convertToJS(response.data);
      dispatch({
        type: CLONE_LISTS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: CLONE_LISTS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
