import * as np from 'path';
import React from 'react';
import { Switch, Redirect, withRouter } from 'react-router-dom';
import { DualRoute, PrivateRoute, PublicRoute } from '../components/Routes';
import * as C from '../pages';
import directories from './directories';
import { urlByParams } from './pathsFor';
import * as routeTypes from './types';

/**
 * Route Component Aliases
 */

const { NotFound } = C;

/**
 * expand routeTypes
 */

const { PUBLIC, DUAL } = routeTypes;

/**
 * mapRoutes builds react-route-dom Routes from an array of defined path and
 * component states
 *
 * @param {Array} directories
 * @param {Object} parent (a parent directory)
 * @param {String} keyPrefix (prefix to key to enforce uniqueness in childs)
 * @returns {Array<Route>}
 * @api private
 */

// eslint-disable-next-line no-shadow
const mapRoutes = (directories, parent = {}, keyPrefix = '') => {
  return directories
    .map((dir, i) => {
      let {
        component,
        components,
        directories: children,
        exact,
        path,
        redirect,
        resource,
        route,
        suffix
      } = dir;

      // TODO suffixes is deprecated and should be removed
      if (suffix) {
        component = `${parent.component}${suffix}`;
      }
      // if there are none of these properties, the directory is most likely an
      // alias used soley for SidebarNav use and should not be routed
      if (!component && !components && !children && !redirect) {
        return;
      }

      /* eslint-disable prefer-const */
      let routes = [];
      let props = {
        key: `${keyPrefix}${i}`,
        path: np.join(parent.path || '', path),
        exact,
        resource: resource || parent.resource,
        component: C[component]
      };
      if (redirect) {
        delete props.component;
        props.render = ({ match: { params } }) => {
          return <Redirect to={urlByParams(params, redirect)} />;
        };
      }

      // if not DUAL or PUBLIC it's assumed to be PRIVATE
      let RouteComponent = PrivateRoute;

      if (route === DUAL) {
        /* eslint-disable  prettier/prettier */
        // dual routes require a publc and private component, which is defined
        // the route directory as the components property
        delete props.component;
        delete props.render;
        components.forEach(v => {
          props[v[0]] = C[v[1]];
        });

        RouteComponent = DualRoute;
      } else if (route === PUBLIC) {
        RouteComponent = PublicRoute;
      }

      routes.push(<RouteComponent {...props} />);

      if (children && children.length) {
        routes.push(mapRoutes(children, dir, i));
      }

      /* eslint-disable consistent-return */
      return routes.flat();
    })
    .flat()
    .filter(v => v);
};

const AppRoutes = () => (
  <Switch>
    {mapRoutes(directories)}
    <NotFound />
  </Switch>
);

export default withRouter(AppRoutes);
