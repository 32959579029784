import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_FAQS_REQUEST = 'FETCH_FAQS_REQUEST';
export const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS';
export const FETCH_FAQS_FAILURE = 'FETCH_FAQS_FAILURE';

export const FETCH_FAQ_REQUEST = 'FETCH_FAQ_REQUEST';
export const FETCH_FAQ_SUCCESS = 'FETCH_FAQ_SUCCESS';
export const FETCH_FAQ_FAILURE = 'FETCH_FAQ_FAILURE';

export const CREATE_FAQ_REQUEST = 'CREATE_FAQ_REQUEST';
export const CREATE_FAQ_SUCCESS = 'CREATE_FAQ_SUCCESS';
export const CREATE_FAQ_FAILURE = 'CREATE_FAQ_FAILURE';

export const UPDATE_FAQ_REQUEST = 'UPDATE_FAQ_REQUEST';
export const UPDATE_FAQ_SUCCESS = 'UPDATE_FAQ_SUCCESS';
export const UPDATE_FAQ_FAILURE = 'UPDATE_FAQ_FAILURE';

export const DELETE_FAQ_REQUEST = 'DELETE_FAQ_REQUEST';
export const DELETE_FAQ_SUCCESS = 'DELETE_FAQ_SUCCESS';
export const DELETE_FAQ_FAILURE = 'DELETE_FAQ_FAILURE';

export const MOVE_FAQ = 'MOVE_FAQ';

export const CLONE_FAQS_REQUEST = 'CLONE_FAQS_REQUEST';
export const CLONE_FAQS_SUCCESS = 'CLONE_FAQS_SUCCESS';
export const CLONE_FAQS_FAILURE = 'CLONE_FAQS_FAILURE';

export const SORT_FAQS_REQUEST = 'SORT_FAQS_REQUEST';
export const SORT_FAQS_SUCCESS = 'SORT_FAQS_SUCCESS';
export const SORT_FAQS_FAILURE = 'SORT_FAQS_FAILURE';

export const fetchFAQs = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_FAQS_REQUEST
  });
  return fetch(path || `/properties/${propertyId}/faqs${fromObj(qry, '?')}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_FAQS_SUCCESS,
        payload: data,
        meta
      });
      return { data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_FAQS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const fetchFAQ = (propertyId, faqId) => dispatch => {
  dispatch({
    type: FETCH_FAQ_REQUEST
  });
  return fetch(`/properties/${propertyId}/faqs/${faqId}`)
    .then(response => {
      const faq = convertToJS(response.data);
      dispatch({
        type: FETCH_FAQ_SUCCESS,
        payload: faq
      });
      return faq;
    })
    .catch(e => {
      dispatch({
        type: FETCH_FAQ_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createFAQ = (propertyId, faq) => dispatch => {
  dispatch({
    type: CREATE_FAQ_REQUEST
  });
  return fetch(`/properties/${propertyId}/faqs`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(faq))
  })
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: CREATE_FAQ_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: CREATE_FAQ_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateFAQ = (propertyId, faqId, update) => dispatch => {
  dispatch({
    type: UPDATE_FAQ_REQUEST
  });
  return fetch(`/properties/${propertyId}/faqs/${faqId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const faq = convertToJS(response.data);
      dispatch({
        type: UPDATE_FAQ_SUCCESS,
        payload: faq
      });
      return faq;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_FAQ_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteFAQ = (propertyId, faqId) => dispatch => {
  dispatch({
    type: DELETE_FAQ_REQUEST
  });
  return fetch(`/properties/${propertyId}/faqs/${faqId}`, { method: 'DELETE' })
    .then(response => {
      dispatch({
        type: DELETE_FAQ_SUCCESS,
        payload: faqId
      });
      return faqId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_FAQ_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveFAQ = (source, target) => ({
  type: MOVE_FAQ,
  payload: { source, target }
});

export const sortFaqs = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_FAQS_REQUEST
  });
  return fetch(`/properties/${propertyId}/faqs/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_FAQS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_FAQS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const cloneFaqs = (propertyId, ids, toPropertyId, inCategoryId) => (
  dispatch
) => {
  dispatch({
    type: CLONE_FAQS_REQUEST
  });
  return fetch(`/properties/${propertyId}/faqs/clone`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ids,
      to_property_id: toPropertyId,
      in_category_id: inCategoryId
    })
  })
    .then(response => {
      // const faq = convertToJS(response.data);
      dispatch({
        type: CLONE_FAQS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: CLONE_FAQS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
