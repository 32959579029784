import {
  ASSIGN_ALERT_SUCCESS,
  ASSIGN_ALERT_REQUEST,
  ASSIGN_ALERT_FAILURE,
  UNASSIGN_ALERT_SUCCESS,
  UNASSIGN_ALERT_REQUEST,
  UNASSIGN_ALERT_FAILURE,
  FETCH_ASSIGNED_ALERTS_SUCCESS,
  FETCH_ASSIGNED_ALERTS_REQUEST,
  FETCH_ASSIGNED_ALERTS_FAILURE
} from '../actions/assignedAlerts';

const initialState = {
  data: [],
  isLoading: true,
  error: ''
};

export default function assignedAlerts(
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case ASSIGN_ALERT_REQUEST:
    case UNASSIGN_ALERT_REQUEST:
    case FETCH_ASSIGNED_ALERTS_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case ASSIGN_ALERT_SUCCESS: {
      const currentIndex = state.data.findIndex(
        r => r.property.id === payload.property.id
      );
      const data =
        currentIndex > -1
          ? state.data
              .slice(0, currentIndex)
              .concat(payload)
              .concat(state.data.slice(currentIndex + 1))
          : state.data.concat(payload);
      return { ...state, isLoading: false, error: '', data };
    }
    case UNASSIGN_ALERT_SUCCESS: {
      const currentIndex = state.data.findIndex(r => r.property.id === payload);
      const data =
        currentIndex > -1
          ? state.data
              .slice(0, currentIndex)
              .concat(state.data.slice(currentIndex + 1))
          : state.data;
      return { ...state, isLoading: false, error: '', data };
    }
    case FETCH_ASSIGNED_ALERTS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };
    case ASSIGN_ALERT_FAILURE:
    case UNASSIGN_ALERT_FAILURE:
    case FETCH_ASSIGNED_ALERTS_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
