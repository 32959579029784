import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import Layout from '../../../layout';
import FormNavigator from '../../../components/FormNavigator';
import HotelCalendarForm from '../../../components/HotelCalendarForm';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as calendarActions from '../../../actions/calendars';
import * as calendarEventActions from '../../../actions/calendarEvents';
import * as imageActions from '../../../actions/images';
import ctaTitleOptions from '../../../utils/cta-title-options';
import dateTz from '../../../utils/date-tz';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  calendars: PropTypes.array.isRequired,
  calendarEvent: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  images: PropTypes.array.isRequired,
  detailsImage: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  updateCalendarEvent: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchCalendars: PropTypes.func.isRequired,
  fetchCalendarEvent: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'details', title: 'Internal Detail Page' },
  { id: 'preview', title: 'Preview + Publish' },
];

const Edit = ({
  hotel,
  calendars,
  calendarEvent,
  isLoading,
  images,
  detailsImage,
  match: {
    params: { hotelid, id }
  },
  history: { push },
  updateCalendarEvent,
  fetchProperty,
  fetchCalendars,
  fetchCalendarEvent,
  updateSidebar,
  setCurrentImage
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchCalendars(hotelid);
    fetchCalendarEvent(hotelid, id);
    return () => {};
  }, []);

  useEffect(() => {
    get(calendarEvent, 'images', []).forEach(image => {
      setCurrentImage('hotel-calendar-image', image);
    });

    const currentDetailsImage = get(calendarEvent, 'details.image');
    if (currentDetailsImage) {
      setCurrentImage('calendar-details-image', currentDetailsImage);
    }
    return () => {};
  }, [calendarEvent]);

  const handleSubmit = data => {
    const detailType = get(data, 'details.cta.type');
    const detailTitle =
      detailType === 'Other' ? data.details.cta.titleInput : detailType;
    const body = {
      ...data,
      start: dateTz(data.start, hotel.timezone),
      end: data.end ? dateTz(data.end, hotel.timezone) : null,
      repeats: data.repeatOption === 'none' ? null : data.repeatOption,
      repeats_on: data.repeatsOn,
      images,
      details: {
        ...data.details,
        image: detailsImage.file
          ? { assetId: detailsImage.file.id, alt: detailsImage.alt }
          : null,
        cta: { ...data.details.cta, type: detailTitle }
      }
    };
    return updateCalendarEvent(hotelid, id, body)
      .then(() => {
        push(`/hotels/${hotelid}/calendars`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  const start = dateTz(get(calendarEvent, 'start', ''), hotel.timezone, true);
  const end = dateTz(get(calendarEvent, 'end', ''), hotel.timezone, true);

  return (
    <Layout metaTitle="Edit Calendar Event">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <h1 className="mb-6">Edit Calendar Event</h1>
          <div className="mb-6">
            Use this page to manage {calendarEvent.title} Calendar Event for{' '}
            {hotel.name}.
          </div>
          <Link
            to={`/hotels/${hotelid}/calendars`}
            className="inline-block mb-12 text-black"
          >
            &lt; Back to Calendars
          </Link>
          <HotelCalendarForm
            id="new-calendar"
            calendars={calendars}
            hotel={hotel}
            initialValues={{
              ...calendarEvent,
              calendarId: get(calendarEvent, 'calendar.id'),
              repeatOption:
                calendarEvent.repeats === null ? 'none' : calendarEvent.repeats,
              details: calendarEvent.details && {
                ...calendarEvent.details,
                cta: calendarEvent.details.cta && {
                  ...calendarEvent.details.cta,
                  type:
                    ctaTitleOptions.findIndex(
                      title =>
                        title.id === get(calendarEvent, 'details.cta.type')
                    ) === -1
                      ? 'Other'
                      : get(calendarEvent, 'details.cta.type'),
                  titleInput:
                    ctaTitleOptions.findIndex(
                      title =>
                        title.id === get(calendarEvent, 'details.cta.type')
                    ) === -1
                      ? get(calendarEvent, 'details.cta.type')
                      : undefined
                }
              },
              start,
              end
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
      )}
      <FormNavigator
        id="edit-calendars-navigator"
        sections={sections}
        className=""
      />
    </Layout>
  );
};

Edit.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    calendars: state.calendars.data,
    calendarEvent: state.calendarEvent.data,
    images: get(state, 'images.hotel-calendar-image.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt })),
    detailsImage: get(state, 'images.calendar-details-image.items.0', {}),
    isLoading:
      state.property.isLoading ||
      state.calendars.isLoading ||
      state.calendarEvent.isLoading
  }),
  {
    ...propertyActions,
    ...sidebarActions,
    ...calendarActions,
    ...calendarEventActions,
    ...imageActions
  }
)(Edit);
