import { SIDEBAR_UPDATE } from '../actions/sidebar';

const initialState = {
  titles: {}
};

// eslint-disable-next-line default-param-last
export default function sidebar(state = initialState, { type, payload }) {
  switch (type) {
    case SIDEBAR_UPDATE:
      return {
        ...state,
        ...payload
      };
    default:
      return state;
  }
}
