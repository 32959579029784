import { moveBySplice } from '../utils/move_by_splice';

import {
  FETCH_PRESSES_REQUEST,
  FETCH_PRESSES_SUCCESS,
  FETCH_PRESSES_FAILURE,
  CREATE_PRESS_REQUEST,
  CREATE_PRESS_SUCCESS,
  CREATE_PRESS_FAILURE,
  UPDATE_PRESS_REQUEST,
  UPDATE_PRESS_SUCCESS,
  UPDATE_PRESS_FAILURE,
  DELETE_PRESS_REQUEST,
  DELETE_PRESS_SUCCESS,
  DELETE_PRESS_FAILURE,
  FETCH_PRESS_REQUEST,
  FETCH_PRESS_SUCCESS,
  FETCH_PRESS_FAILURE,
  MOVE_PRESS,
  SORT_PRESSES_REQUEST,
  SORT_PRESSES_SUCCESS,
  SORT_PRESSES_FAILURE
} from '../actions/presses';

export function presses(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_PRESSES_REQUEST:
    case SORT_PRESSES_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_PRESSES_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case MOVE_PRESS: {
      const { source, target } = payload;
      const data = moveBySplice(source, target, state.data);

      return { ...state, data };
    }

    case SORT_PRESSES_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_PRESSES_FAILURE:
    case SORT_PRESSES_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function press(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_PRESS_REQUEST:
    case CREATE_PRESS_REQUEST:
    case UPDATE_PRESS_REQUEST:
    case DELETE_PRESS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_PRESS_SUCCESS:
    case CREATE_PRESS_SUCCESS:
    case UPDATE_PRESS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_PRESS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_PRESS_FAILURE:
    case CREATE_PRESS_FAILURE:
    case UPDATE_PRESS_FAILURE:
    case DELETE_PRESS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
