import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import * as modalActions from '../../actions/modals';
import * as imageActions from '../../actions/images';
import * as assetActions from '../../actions/assets';
import Input from '../common/Input';
import TinyMCE from '../common/form/TinyMCE';
import FormDatePicker from '../common/form/DatePicker';
import FormDropdown from '../common/form/Dropdown';
import FormSection from '../common/FormSection';
import Switch from '../common/form/Switch';
import FormErrorBox from '../common/FormErrorBox';
import promoFlags from '../../utils/promo-flag-options';
import { daysData, daysFromOne } from '../../utils/days-options';
import SetAnAsset from '../SetAnAsset';

const propTypes = {
  id: PropTypes.string.isRequired,
  hotel: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  hideTitle: PropTypes.bool,
  paragraph: PropTypes.string,
  doesNotEnd: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  datePicker: PropTypes.bool,
  promoFlag: PropTypes.string,
  promoFlagOther: PropTypes.string,
  published: PropTypes.bool,
  archived: PropTypes.bool,
  firstImage: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  profilePermissions: PropTypes.array.isRequired
};

const defaultProps = {
  title: '',
  hideTitle: false,
  paragraph: '',
  doesNotEnd: false,
  datePicker: false,
  promoFlag: '',
  promoFlagOther: '',
  published: false,
  archived: false,
  error: undefined
};

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Required!';
  }

  if (!values.startsOn) {
    errors.startsOn = 'Required!';
  }

  if (!values.doesNotEnd && !values.endsOn) {
    errors.endsOn = 'End date is required if event ends';
  }

  if (values.promoFlag === 'Other') {
    if (!values.promoFlagOther) {
      errors.promoFlagOther =
        'Promo Flag title is required if Other is selected';
    }
  }

  // NOTE home-promos alts are not required but leaving here for reference of
  // future use

  // if (values['home-promos-image']) {
  //   // NOTE must set the errors attribute or create it, other will error when
  //   // applying error messages below
  //   errors['home-promos-image'] = errors['home-promos-image'] || {};

  //   const image = values['home-promos-image'];
  //   if (!image.asset_id) {
  //     errors['home-promos-image'].asset_id = 'Image is required';
  //   }
  //   if (!image.alt) {
  //     errors['home-promos-image'].alt = 'Image Alt Tag is required';
  //   }
  // }

  return errors;
};

const condfn = () => {
  return {
    filetype: 'image',
    category: 'cat#Home Promos'
  };
};

const HotelHomePromoForm = ({
  id,
  hotel,
  handleSubmit,
  showModal,
  title,
  hideTitle,
  paragraph,
  doesNotEnd,
  datePicker,
  promoFlag,
  promoFlagOther,
  published,
  archived,
  firstImage,
  change,
  error,
  profilePermissions
}) => {
  const paragraphHtml = paragraph || '';

  const canUploadAsset = () => {
    return (
      profilePermissions.findIndex(perm => {
        return (
          perm.resource === '*' &&
          perm.permissionableId === null &&
          perm.permissionableType === null &&
          perm.r &&
          perm.w &&
          perm.x
        );
      }) > -1
    );
  };

  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="title"
        title="Title & Content"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Enter the title and content to use for this Home Promo.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div
            className={classnames('mb-3', {
              'text-grey': hideTitle
            })}
          >
            Title*
          </div>
          <Field
            id="promos-title"
            name="title"
            className="w-full mb-4"
            placeholder="Enter a title..."
            component={Input}
          />
          <div className="flex items-center mb-10">
            <Field
              id="hideTitle"
              name="hideTitle"
              component={Input}
              className="mr-2"
              type="checkbox"
            />
            <div className="text-base font-normal">
              Hide Title On Home Promo
            </div>
          </div>
          <div className="mb-3">Paragraph</div>
          <Field
            id="promos-paragraph"
            name="paragraph"
            className="mb-6"
            placeholder="Enter a short description for this home promotion..."
            component={TinyMCE}
          />
        </div>
      </FormSection>

      <FormSection
        id="date"
        title="Date & Time"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter the Start Date for this Home Promo. To set an End Date,
                uncheck 'Promotion does not end'.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Start Date*</div>
          <Field
            id="starts-on"
            name="startsOn"
            component={FormDatePicker}
            className="mb-6"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MM/dd/yyyy h:mm aa"
            timeCaption="time"
            autoComplete="off"
          />
          <div className="flex items-center mb-6">
            <Field
              id="does-not-end"
              type="checkbox"
              name="doesNotEnd"
              className="mr-3"
              component={Input}
              onChange={(_, v) => {
                if (v) {
                  change('endsOn', new Date(2120, 0, 1));
                } else {
                  change('endsOn', null);
                }
              }}
            />
            <div className="">Promotion does not end</div>
          </div>
          {!doesNotEnd && (
            <div>
              <div
                className={classnames('mb-3', {
                  'text-grey-light': doesNotEnd
                })}
              >
                End Date*
              </div>
              <Field
                id="ends-on"
                name="endsOn"
                component={FormDatePicker}
                disabled={doesNotEnd}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy h:mm aa"
                timeCaption="time"
                autoComplete="off"
              />
            </div>
          )}
        </div>
      </FormSection>
      <FormSection
        id="image"
        title="Set an Image"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Select an image to use for this Home Promo. If you don't see the
                image you are looking for,&nbsp;
                <a
                  href="http://tickets.still-water.com/tickets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  create a ticket here.
                </a>
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <SetAnAsset 
            change={change}
            condfn={condfn}
            canUploadAsset={canUploadAsset}
            categories={[{ name: 'Home Promos' }]}
            name='home-promos-image'
            id='hotel-home-promo-upload'
            disableAlt
          />
        </div>
      </FormSection>
      <FormSection
        id="cta"
        title="CTA (Call to Action)"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>Select a CTA option to use for this Home Promo.</i>
            </p>
            <p>
              <i>
                If you'd like to add an Adobe Tracking tag to the link for this
                Home Promo, enter it in the Adobe Tracking Tag field.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Link URL*</div>
          <div className="flex flex-col mb-6">
            <Field
              id="cta.linkUrl"
              name="cta.linkUrl"
              className="flex-1 mr-2 mb-3"
              placeholder="Enter a Link URL..."
              component={Input}
            />
            <div className="flex justify-left">
              <button
                type="button"
                className="btn btn-clear px-6 mr-4"
                onClick={e => {
                  e.preventDefault();
                  showModal('GenerateURLModal', {
                    form: 'HotelHomePromoForm',
                    field: 'cta.linkUrl',
                    hotelId: hotel.id
                  });
                }}
              >
                Generate URL
              </button>
              <button
                type="button"
                className="btn btn-clear px-6"
                onClick={e => {
                  e.preventDefault();
                  showModal('GenerateEmailURLModal', {
                    form: 'HotelHomePromoForm',
                    field: 'cta.linkUrl',
                    hotelId: hotel.id
                  });
                }}
              >
                Generate Email URL
              </button>
            </div>
          </div>
          <div className="flex mb-6 items-center">
            <Field
              id="cta.opensInNewWindow"
              name="cta.opensInNewWindow"
              type="checkbox"
              className="mr-2 mb-10"
              component={Input}
            />
            <div className="mb-10">Opens in New Window</div>
          </div>
          <div className="flex mb-6 items-center">
            <Field
              id="cta.addDatePickerPopup"
              name="cta.addDatePickerPopup"
              type="checkbox"
              className="mr-2"
              component={Input}
            />
            <div className="">Add Date Picker Popup</div>
          </div>
          <div className="flex mb-6 items-center">
            <Field
              id="cta.advancedPurchaseDays"
              name="cta.advancedPurchaseDays"
              className="mr-2"
              component={FormDropdown}
              data={daysData}
              disabled={!datePicker}
            />
            <div
              className={classnames('', {
                'text-grey': !datePicker
              })}
            >
              Advance Purchase Days
            </div>
          </div>
          <div className="flex mb-12 items-center">
            <Field
              id="cta.minimumNightStay"
              name="cta.minimumNightStay"
              className="mr-2"
              component={FormDropdown}
              data={daysFromOne}
              disabled={!datePicker}
            />
            <div
              className={classnames('', {
                'text-grey': !datePicker
              })}
            >
              Minimum Night Stay
            </div>
          </div>
          <div className="mb-3">Adobe Tracking Tag</div>
          <Field
            id="cta.trackingTag"
            name="cta.trackingTag"
            className="w-full mb-4"
            placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
            component={Input}
          />
          <div className="flex justify-left">
            <button
              type="button"
              className="btn btn-clear px-6 mr-4"
              onClick={e => {
                e.preventDefault();
                showModal('GenerateTrackingModal', {
                  form: 'HotelHomePromoForm',
                  field: 'cta.trackingTag'
                });
              }}
            >
              Generate Tracking
            </button>
          </div>
        </div>
      </FormSection>
      <FormSection
        id="promo"
        title="Promo Flag"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Set a promo flag for this Home Promo from the dropdown. To use a
                custom promo flag, select 'Other' from the dropdown and enter
                the text to display.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Promo Flag:</div>
          <Field
            id="promoFlag"
            name="promoFlag"
            data={promoFlags}
            className="mb-6"
            component={FormDropdown}
          />
          {promoFlag === 'Other' && (
            <Field
              id="promoFlagOther"
              name="promoFlagOther"
              className="mb-6"
              component={Input}
              placeholder="Enter title for Promo Flag"
            />
          )}
        </div>
      </FormSection>
      <FormSection
        id="preview"
        title="Preview & Publish"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex w-full flex-col flex-wrap xl:flex-row">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  Here is a preview of your Home Promo. When you're ready,
                  toggle the publish status to display it on the live site and
                  hit 'Save'.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="text-base font-normal mb-3">Preview:</div>
            {firstImage.file && (
              <div
                className="flex flex-col-reverse preview-homepromo"
                style={{
                  width: 500,
                  height: 400,
                  backgroundImage: `url(${get(firstImage, 'file.url')})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover'
                }}
              >
                {promoFlag === 'None' ? (
                  ''
                ) : (
                  <div className="ribbon-callout">
                    {promoFlag === 'Other' ? promoFlagOther : promoFlag}
                  </div>
                )}
                <div className="p-6 text-white preview-homepromo-text">
                  {!hideTitle && <h3>{title}</h3>}
                  <div dangerouslySetInnerHTML={{ __html: paragraphHtml }} />
                </div>
                <div className="bg_gradient_feature" />
              </div>
            )}
            <div className="mt-12">
              <div className="text-base font-normal mb-3">Publish Status:</div>
              <Field
                id="published"
                name="published"
                component={Switch}
                label={published ? 'Published' : 'Unpublished'}
                className="mb-12"
                disabled={archived}
              />
              <div className="text-base font-normal mb-3">
                Archive this Home Promo?
              </div>
              <Field
                id="archived"
                name="archived"
                component={Switch}
                label={archived ? 'Archived' : 'Unarchived'}
                className="mb-6"
                onChange={(e, value) => {
                  e.preventDefault();
                  if (value && published) {
                    change('published', false);
                  }
                  change('archived', value);
                }}
              />
            </div>
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && (
        <FormErrorBox id="hotel-home-promo-error">{error}</FormErrorBox>
      )}
    </form>
  );
};

HotelHomePromoForm.propTypes = propTypes;
HotelHomePromoForm.defaultProps = defaultProps;

const selector = formValueSelector('HotelHomePromoForm');

export default compose(
  reduxForm({ form: 'HotelHomePromoForm', validate }),
  connect(
    state => ({
      title: selector(state, 'title'),
      hideTitle: selector(state, 'hideTitle'),
      paragraph: selector(state, 'paragraph'),
      doesNotEnd: selector(state, 'doesNotEnd'),
      datePicker: selector(state, 'cta.addDatePickerPopup'),
      promoFlag: selector(state, 'promoFlag'),
      promoFlagOther: selector(state, 'promoFlagOther'),
      published: selector(state, 'published'),
      archived: selector(state, 'archived'),
      firstImage: get(state, 'images.home-promos-image.items.0', {}),
      profilePermissions: state.auth.permissions
    }),
    { ...modalActions, ...imageActions, ...assetActions }
  )
)(HotelHomePromoForm);
