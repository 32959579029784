const promoFlags = [
  'None',
  'Accessible Features',
  'Balcony',
  'Best for Family',
  'Best View',
  'Family-Friendly Room',
  'Newly Renovated',
  'Ocean View',
  'Ocean Front',
  'Premium View',
  'Resort View',
  'Spa Bathtub',
  'Terrace',
  'Other',
].map(opt => ({ id: opt, name: opt }));

export default promoFlags;
