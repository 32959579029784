import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeleteModal from '../DeleteModal';
import * as userActions from '../../../actions/users';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteUser: PropTypes.func.isRequired
};

const DeleteUserModal = ({ data, deleteUser, ...props }) => (
  <DeleteModal
    id="delete-user-modal"
    title="User"
    itemTitle={data.title}
    deleteItem={() => deleteUser(data.id)}
    {...props}
  />
);

DeleteUserModal.propTypes = propTypes;

export default connect(
  null,
  userActions
)(DeleteUserModal);
