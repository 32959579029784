import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  style: PropTypes.object,
  fill: PropTypes.string,
  active: PropTypes.bool
};

const defaultProps = {
  onClick: () => {},
  style: {},
  fill: '#b8c2cc',
  active: false
};

const EyeIcon = ({ id, fill, style, onClick, active }) => (
  <svg
    className="cursor-pointer"
    version="1.1"
    id={id}
    x="0px"
    y="0px"
    viewBox="0 0 20 20"
    style={{ ...style, enableBackground: 'new 0 0 185.344 185.344' }}
    xmlSpace="preserve"
    onClick={onClick}
  >
    <path
      style={active ? { fill: '#3490dc' } : { fill }}
      d="M10 12a2 2 0 100-4 2 2 0 000 4z"
    />
    <path
      style={active ? { fill: '#3490dc' } : { fill }}
      fillRule="evenodd"
      d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z"
      clipRule="evenodd"
    />
  </svg>
);

EyeIcon.propTypes = propTypes;
EyeIcon.defaultProps = defaultProps;

export default EyeIcon;
