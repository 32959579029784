import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeleteModal from '../DeleteModal';
import * as calendarActions from '../../../actions/calendars';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteCalendar: PropTypes.func.isRequired
};

const DeleteCalendarModal = ({ data, deleteCalendar, ...props }) => (
  <DeleteModal
    id="delete-calendar"
    title="Calendar"
    itemTitle={data.title}
    deleteItem={() => deleteCalendar(data.propertyId, data.id)}
    {...props}
  />
);

DeleteCalendarModal.propTypes = propTypes;

export default connect(
  null,
  calendarActions
)(DeleteCalendarModal);
