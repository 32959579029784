// import firebase from 'firebase';

export const FETCH_NOTIFICATIONS_REQUEST = 'FETCH_NOTIFICATIONS_REQUEST';
export const FETCH_NOTIFICATIONS_SUCCESS = 'FETCH_NOTIFICATIONS_SUCCESS';
export const FETCH_NOTIFICATIONS_FAILURE = 'FETCH_NOTIFICATIONS_FAILURE';

export const fetchNotifications = () => dispatch => {
  dispatch({
    type: FETCH_NOTIFICATIONS_REQUEST
  });
  // FIXME firebase is not active, need to remove all related
  return; // eslint-disable-line
  // return firebase
  //   .database()
  //   .ref('notifications')
  //   .once('value')
  //   .then(snapshot => {
  //     const notifications = snapshot.val();
  //     dispatch({
  //       type: FETCH_NOTIFICATIONS_SUCCESS,
  //       payload: notifications
  //     });
  //     return notifications;
  //   })
  //   .catch(e => {
  //     dispatch({
  //       type: FETCH_NOTIFICATIONS_FAILURE,
  //       payload: e.message
  //     });
  //     return Promise.reject(e);
  //   });
};
