import merge from 'lodash/merge';
import { MODAL_SHOW, MODAL_HIDE, MODAL_UPDATE_STYLES } from '../actions/modals';

const initialState = {
  open: false,
  name: '',
  contentLabel: '',
  data: {},
  styles: {}
};

// eslint-disable-next-line default-param-last
export default function modals(state = initialState, { type, payload }) {
  switch (type) {
    case MODAL_SHOW:
      return { ...state, open: true, ...payload };
    case MODAL_HIDE:
      return initialState;
    case MODAL_UPDATE_STYLES:
      return merge({}, state, { styles: payload });
    default:
      return state;
  }
}
