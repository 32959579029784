import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import { createCanvas } from 'canvas';
import ChevronDownIcon from '../ChevronDownIcon';

function getTextWidth(text) {
  // re-use canvas object for better performance
  const canvas =
    getTextWidth.canvas || (getTextWidth.canvas = createCanvas(200, 100));
  const context = canvas.getContext('2d');
  context.font = '16px Open Sans, sans-serif';
  const metrics = context.measureText(text);
  return metrics.width;
}

class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  handleClickOutside = () => this.setIsOpen(false);

  setIsOpen = isOpen => this.setState({ isOpen });

  render() {
    const {
      id,
      children,
      className,
      style,
      placeholder,
      disabled
      // FIXME meta: { touched, error }
    } = this.props;
    // FIXME below is a quick fix to resolve `TypeError: Cannot read property 'touched' of undefined` issue that pops up in hours.
    // Ref, https://app.asana.com/0/1119928407758199/1197402555760973/f
    // NOTE, not familiar enough with the Frontend side to know exactly why dropdowns work for others but fails on hours
    const { meta } = this.props;
    const { touched, error } = meta || {};
    const { isOpen } = this.state;
    const styleWidth = style.width;
    const selectedItem = React.Children.toArray(children).find(
      child => child.props.selected
    );
    const textWidth = Math.max(
      ...React.Children.map(children, child =>
        getTextWidth(child.props.children)
      )
    );
    const width = styleWidth || textWidth + 58;
    return (
      <div id={id} style={{ width }} className={className}>
        <div
          id={`${id}-button`}
          role="button"
          tabIndex={0}
          style={{ height: 50 }}
          className={classnames(
            'flex border border-grey rounded-sm outline-none',
            {
              'rounded-b-none': isOpen
            },
            {
              'Input--error': touched && error
            },
            { 'cursor-not-allowed': disabled, 'text-grey': disabled }
          )}
          onClick={() => !disabled && this.setIsOpen(!isOpen)}
        >
          <div
            id={`${id}-text`}
            className="px-2 py-4 flex-1 font-light overflow-hidden whitespace-no-wrap"
            // The line below was causing a layout issue on the Restaurant Hours table, so removed for now
            // e.g. Hours and Days have different textWidths, so dropdowns were breaking to a new line inconsistently
            // style={{ width: textWidth + 24 }}
          >
            {selectedItem ? (
              selectedItem.props.children
            ) : (
              <span className="text-grey-dark">{placeholder}</span>
            )}
          </div>
          <div className="border-l border-grey w-8 px-2 pt-1 text-2xl text-grey">
            <ChevronDownIcon id="dropdown-chevron" style={{ marginTop: 10 }} />
          </div>
        </div>
        {isOpen && (
          <div
            id={`${id}-menu`}
            style={{ width: '100%', maxHeight: 210 }}
            className="shadow z-50 bg-white h-64 overflow-y-scroll"
          >
            {React.Children.map(children, child =>
              React.cloneElement(child, {
                onClick: () => {
                  child.props.onClick();
                  this.setIsOpen(false);
                }
              })
            )}
          </div>
        )}
        {touched && error && <div className="text-red mt-2">{error}</div>}
      </div>
    );
  }
}

Dropdown.propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object
};

Dropdown.defaultProps = {
  style: {},
  placeholder: '',
  className: '',
  disabled: false,
  meta: {}
};

const clickOutsideConfig = {
  handleClickOutside: instance => instance.handleClickOutside
};

export { Dropdown };

export default onClickOutside(Dropdown, clickOutsideConfig);
