// 
import moment from 'moment-timezone';

/**
 * dateTz adjusts the dates for timezone
 *
 * @param {Date} date
 * @param {String} tz (timezone, eg. America/New_York)
 * @param {Boolean} local (force local conversion so time is always the same regardless of zone and not adjusted to the timezone)
 * @returns {String|Date}
 * @api private
 */

const dateTz = (date, tz, local) => {
  if (!date || !tz) {
    return '';
  }

  if (local) {
    // offset of the date (note, this date is converted into the user's system
    // time). Use the provided date vs "now" to ensure DST is calculated into
    // the offset (Z)
    const offset = moment(date).format('Z');

    // convert the date to the timezone, this will give us the actual "time",
    // eg. 5:00 PM. Then, without conversion, attach the user's timezone offset
    // to keep the same time without the conversion
    // FIXME can this conversion be simplified?...
    const d = moment(
      moment.tz(date, 'UTC').format('YYYY-MM-DDTHH:mm:ss') + offset
    );

    return d.toDate();
  }

  // convert the date to UTC without changing the "time"
  return `${moment(date).format('YYYY-MM-DDTHH:mm:ss')}.00Z`;
};

/**
 * expose
 */

export default dateTz;
