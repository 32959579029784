import fetch from '../utils/fetch';
import { divideTags, combineTags } from '../utils/tags';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';
import history from '../utils/history';

export const FETCH_ASSETS_REQUEST = 'FETCH_ASSETS_REQUEST';
export const FETCH_ASSETS_SUCCESS = 'FETCH_ASSETS_SUCCESS';
export const FETCH_ASSETS_FAILURE = 'FETCH_ASSETS_FAILURE';

export const FETCH_ASSET_REQUEST = 'FETCH_ASSET_REQUEST';
export const FETCH_ASSET_SUCCESS = 'FETCH_ASSET_SUCCESS';
export const FETCH_ASSET_FAILURE = 'FETCH_ASSET_FAILURE';

export const CREATE_ASSET_REQUEST = 'CREATE_ASSET_REQUEST';
export const CREATE_ASSET_SUCCESS = 'CREATE_ASSET_SUCCESS';
export const CREATE_ASSET_FAILURE = 'CREATE_ASSET_FAILURE';

export const UPDATE_ASSET_REQUEST = 'UPDATE_ASSET_REQUEST';
export const UPDATE_ASSET_SUCCESS = 'UPDATE_ASSET_SUCCESS';
export const UPDATE_ASSET_FAILURE = 'UPDATE_ASSET_FAILURE';

export const DELETE_ASSET_REQUEST = 'DELETE_ASSET_REQUEST';
export const DELETE_ASSET_SUCCESS = 'DELETE_ASSET_SUCCESS';
export const DELETE_ASSET_FAILURE = 'DELETE_ASSET_FAILURE';

export const resetAssets = () => dispatch => {
  dispatch({
    type: FETCH_ASSETS_SUCCESS,
    payload: [],
    meta: {}
  });
};

export const fetchAssets = (qry, path, append) => dispatch => {
  dispatch({
    type: FETCH_ASSETS_REQUEST
  });
  return fetch(path || `/assets?${fromObj(qry)}`)
    .then(response => {
      const assets = response.data.map(convertToJS).map(divideTags);
      const meta = convertToJS(response.meta);
      dispatch({
        type: FETCH_ASSETS_SUCCESS,
        append,
        payload: assets,
        meta
      });
      return { assets, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_ASSETS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countAssets = params => dispatch => {
  return fetch(`/assets${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchAsset = assetId => dispatch => {
  dispatch({
    type: FETCH_ASSET_REQUEST
  });
  return fetch(`/assets/${assetId}`)
    .then(response => {
      const asset = divideTags(convertToJS(response.data));
      dispatch({
        type: FETCH_ASSET_SUCCESS,
        payload: asset
      });
      return asset;
    })
    .catch(e => {
      dispatch({
        type: FETCH_ASSET_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createAsset = body => dispatch => {
  dispatch({
    type: CREATE_ASSET_REQUEST
  });
  return fetch('/assets', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(combineTags(body)))
  })
    .then(response => {
      const asset = divideTags(convertToJS(response.data));
      dispatch({
        type: CREATE_ASSET_SUCCESS,
        payload: asset
      });
      return asset;
    })
    .catch(e => {
      dispatch({
        type: CREATE_ASSET_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateAsset = (assetId, body) => dispatch => {
  dispatch({
    type: UPDATE_ASSET_REQUEST
  });
  return fetch(`/assets/${assetId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(combineTags(body)))
  })
    .then(response => {
      const asset = divideTags(convertToJS(response.data));
      dispatch({
        type: UPDATE_ASSET_SUCCESS,
        payload: asset
      });
      return asset;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_ASSET_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteAsset = (assetId, redirectUrl) => dispatch => {
  dispatch({
    type: DELETE_ASSET_REQUEST
  });
  return fetch(`/assets/${assetId}`, { method: 'DELETE' })
    .then(() => {
      dispatch({
        type: DELETE_ASSET_SUCCESS,
        payload: assetId
      });
      if (redirectUrl) {
        history.push(redirectUrl);
      }
      return assetId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_ASSET_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
