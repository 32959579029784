
import * as np from 'path';

/**
 * hasLineage checks ordered intersections and returns true of the paths have
 * the same, ordered namespacing to qualify as a breadcrumb
 *
 * @param {String} a (current path)
 * @param {String} b (breadcrumbing paths)
 * @returns {Boolean}
 * @api private
 */

const hasLineage = (a, b) => {
  const as = a.split('/');
  const bs = b.split('/');
  if (bs.length > as.length) {
    return false;
  }

  let ok = false;

  let i = 0;
  const j = as.length;
  for (; i < j; i++) {
    if (i > bs.length - 1) {
      break;
    }
    if (as[i] === bs[i]) {
      ok = true;
    } else {
      return false;
    }
  }

  return ok;
};

/**
 * zIndexfn checks to ensure `a` is a number, because a zIndex of 0 should not
 * fail an truthy/falsy test because it's assumed as false in javascript
 *
 * @param {Number} a (current zIndex)
 * @param {Number} b (parent zIndex)
 * @returns {Number}
 */

const zIndexfn = (a, b) => {
  if (typeof a === 'number' && !Number.isNaN(a)) {
    return a;
  }

  return b;
};

/**
 * isRel checks rels against a given path to find a match. This also supports
 * regex in the rel array
 *
 * @param {Array} rels
 * @param {String} path
 * @returns {Boolean}
 * @api private
 */

const isRel = (rels, path) => {
  if (!rels || !path) {
    return false;
  }

  let i = 0;
  const j = rels.length;
  for (; i < j; i++) {
    const v = rels[i];
    if (v instanceof RegExp && v.test(path)) {
      return true;
    } if (v === path) {
      return true;
    }
  }

  return false;
};

/**
 * pathsFor returns related paths for a given path
 *
 * @param {String} path (this is a router formatted path eg. /path/:id/more/path)
 * @returns {Array} (mutiple returned [ navs, breadcrumbs ])
 * @api public
 */

const pathsFor = (path, filterfn = v => v) => {
  return (directories, parent = {}) => {
    let lineage = [];

    // FIXME would this be better to be done as a forEach?
    let dirs = directories.map(dir => {
      const {
        directories: children,
        exact,
        hidden,
        html,
        activeRoot,
        path: dirPath,
        rel,
        resource,
        title,
        zIndex
      } = dir;
      // force rel into an array, so it's always an array when used within
      // pathsFor
      const rels = [rel].flat();

      let paths = [];

      const fullpath = np.join(parent.path || '', dirPath);

      const v = {
        exact,
        html,
        path: fullpath,
        resource: resource || parent.resource,
        title,
        zIndex: zIndexfn(zIndex, parent.zIndex),
        activeRoot: activeRoot || (children && !!children.length && fullpath)
      };

      if (isRel(rels, path) && !hidden) {
        paths.push(v);
      }
      if (hasLineage(path, fullpath)) {
        // make sure there are no duplicate paths
        if (!lineage.filter(item => item.path === fullpath).length) {
          lineage.push(v);
        }
      }

      // find additional paths through "sub" directories
      if (children && children.length) {
        // FIXME if there is continued traversing through the tree, the path in
        // v (passed parent) will need to have path joined
        const [p, b] = pathsFor(path)(children, dir);
        paths.push(p);
        if (b) {
          lineage.push(b.flat());
        }
      }

      return paths.flat();
    });

    return [
      dirs
        .flat()
        .filter(v => filterfn(v))
        .sort((a, b) => {
          // sort on zIndex works very much like that of html/css the higher the
          // number the higher update the pile it goes, so basically an DESC sort
          if (a.zIndex < b.zIndex) {
            return 1;
          }
          if (a.zIndex > b.zIndex) {
            return -1;
          }
          return 0;
        }),
      lineage
        .flat()
        .filter(v => v)
        .sort((a, b) => {
          // sort on zIndex works very much like that of html/css the higher the
          // number the higher update the pile it goes, so basically an DESC sort
          if (a.zIndex < b.zIndex) {
            return 1;
          }
          if (a.zIndex > b.zIndex) {
            return -1;
          }
          return 0;
        }),
    ];
  };
};

/**
 * urlByParams interpolates urls given params and a string format
 *
 * @param {Object} params (params parsed through matchPath)
 * @param {String} path
 * @returns {String}
 * @api public
 */

const urlByParams = (params, path) => {
  if (!params) {
    return path;
  }
  return Object.keys(params).reduce(
    (url, key) => url.replace(`:${key}`, params[key]),
    path
  );
};

/**
 * expose
 */

export default pathsFor;
export { urlByParams };
