import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  onClick: PropTypes.func,
  divStyle: PropTypes.object,
  svgStyle: PropTypes.object
};

const defaultProps = {
  className: 'h-6 w-6',
  textClassName: 'h-6 w-6',
  onClick: () => {},
  divStyle: {},
  svgStyle: {}
};

const ExitButton = ({
  id,
  className,
  textClassName,
  divStyle,
  svgStyle,
  ...otherProps
}) => (
  <div
    className={classnames('flex items-center cursor-pointer', className)}
    style={divStyle}
  >
    <svg
      id={id}
      className={classnames('fill-current', textClassName, {
        'text-grey-darker hover:text-black': !textClassName
      })}
      style={svgStyle}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      {...otherProps}
    >
      <path d="M405 136.798L375.202 107 256 226.202 136.798 107 107 136.798 226.202 256 107 375.202 136.798 405 256 285.798 375.202 405 405 375.202 285.798 256z" />
    </svg>
  </div>
);

ExitButton.propTypes = propTypes;
ExitButton.defaultProps = defaultProps;

export default ExitButton;
