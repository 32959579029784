// 
import React from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import 'react-tabs/style/react-tabs.css';
import capitalize from 'lodash/capitalize';

import { Tab, Tabs, TabList, TabPill } from '../common/Tabs';
import Table from '../common/Table';
import More, { MoreMenu, MoreMenuItem } from '../common/More';
import * as propertyActions from '../../actions/properties';
import * as modalActions from '../../actions/modals';
import Paginate from '../Paginate';

const propTypes = {
  properties: PropTypes.array.isRequired,
  activeCount: PropTypes.object.isRequired,
  archivedCount: PropTypes.number.isRequired,
  meta: PropTypes.object.isRequired,
  fetchProperties: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  location: PropTypes.object,
  history: PropTypes.object,
  profilePermissions: PropTypes.array.isRequired
};

const defaultProps = {
  location: {},
  history: {}
};

const tabs = ['active', 'archived'];

const DashboardTable = ({
  properties,
  activeCount,
  archivedCount,
  location: { search, pathname },
  history: { push },
  fetchProperties,
  meta,
  updateProperty,
  showModal,
  profilePermissions
}) => {
  const query = qs.parse(search.slice(1));
  const selected = query.list === 'archived' ? 1 : 0;
  const activeTab = tabs[selected];
  // FIXME
  const canAddProperty =
    profilePermissions.findIndex(p => {
      return (
        !p.permissionable_id && !p.permissionable_type && p.resource === '*'
      );
    }) > -1;
  const columns = [
    {
      id: 'name',
      Header: 'Property Name',
      accessor: data => (
        <Link to={`/${data.type}s/${data.id}`}>{data.name}</Link>
      )
    },
    {
      id: 'type',
      Header: 'Property Type',
      width: 200,
      accessor: data => (
        <div className="text-sm leading-tight">{data.type.toUpperCase()}</div>
      )
    },
    {
      id: 'more',
      Header: <span />,
      width: 40,
      accessor: data =>
        canAddProperty ? (
          <More id={`${data.id}-more`}>
            <MoreMenu id={`${data.id}-moremenu`}>
              <MoreMenuItem
                id={`${data.id}-settings`}
                onClick={() => {
                  push(`/${data.type}s/${data.id}/settings`);
                }}
              >
                Settings
              </MoreMenuItem>
              <MoreMenuItem
                id={`${data.id}-${activeTab}`}
                onClick={() =>
                  updateProperty(data.id, { archived: !data.archived })}
              >
                {activeTab === 'archived' ? 'Unarchive' : 'Archive'}
              </MoreMenuItem>
              <MoreMenuItem
                id={`${data.id}-delete`}
                onClick={() =>
                  showModal(`Delete${capitalize(data.type)}Modal`, {
                    id: data.id,
                    title: data.name
                  })}
              >
                Delete
              </MoreMenuItem>
            </MoreMenu>
          </More>
        ) : null
    },
  ];

  return (
    <Tabs
      selectedIndex={selected}
      onSelect={index => {
        const newQuery = { ...query, list: tabs[index] };
        push(`${pathname}?${qs.stringify(newQuery)}`);
      }}
    >
      <TabList>
        <Tab>
          <span className="mr-2">Active</span>
          <TabPill id="active">{activeCount}</TabPill>
        </Tab>
        <Tab>
          <span className="mr-2">Archived</span>
          <TabPill id="archived">{archivedCount}</TabPill>
        </Tab>
        <Paginate meta={meta} fetchHandler={fetchProperties} />
      </TabList>

      <Table id="dashboard-table" data={properties} columns={columns} />
    </Tabs>
  );
};

DashboardTable.propTypes = propTypes;
DashboardTable.defaultProps = defaultProps;

export default compose(
  withRouter,
  connect(
    state => ({
      properties: state.properties.data,
      meta: state.properties.meta,
      profilePermissions: state.auth.permissions
    }),
    { ...propertyActions, ...modalActions }
  )
)(DashboardTable);
