/**
 * onDrop handles the onDrop for storable lists
 *
 * @param {Object} evt (onDrop event object)
 * @param {Array} data (data array)
 * @param {Function} sortHandler (sort handler)
 * @param {Object} opts (Additional objects to pass to the sort handler)
 * @api public
 */

const onDrop = (evt, data, sortHandler, opts = {}) => {
  const { id } = evt;
  const i = data.findIndex(v => v.id === id);
  const n = i === data.length - 1 ? i - 1 : i + 1;

  const to = data[n];
  if (!to) {
    return;
  }

  sortHandler(id, to.id, opts);
};

/**
 * expose
 */

export { onDrop };
