import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object
};

const defaultProps = {
  style: {}
};

const MoreMenu = ({ id, children, style }) => (
  <div className="relative md:static">
    <div
      id={id}
      className="border border-t-0 border-grey-light absolute z-50 rounded-b-sm shadow bg-white w-32 md:w-48 -ml-24 lg:-ml-48 mt-1 p-3"
      style={style}
    >
      {children}
      <div className="arrow-up" />
    </div>
  </div>
);

MoreMenu.propTypes = propTypes;
MoreMenu.defaultProps = defaultProps;

export default MoreMenu;
