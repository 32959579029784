import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import * as propertyActions from '../../../actions/properties';
import * as listActions from '../../../actions/lists';
import * as sidebarActions from '../../../actions/sidebar';
import HotelListForm from '../../../components/HotelListForm';
import Layout from '../../../layout';
import FormNavigator from '../../../components/FormNavigator';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  list: PropTypes.object.isRequired,
  updateList: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchList: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'preview', title: 'Preview + Publish' },
];

const ListsEdit = ({
  match: {
    params: { hotelid, id }
  },
  history: { push },
  updateList,
  hotel,
  list,
  isLoading,
  fetchProperty,
  fetchList,
  updateSidebar
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchList(hotelid, id);
    return () => {};
  }, []);

  const handleSubmit = data => {
    const body = {
      ...data,
      startsOn: data.startsOn.toJSON(),
      endsOn: data.endsOn ? data.endsOn.toJSON() : data.endsOn
    };
    return updateList(hotelid, id, body)
      .then(() => {
        push(`/hotels/${hotelid}/lists`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout type="Specials" metaTitle="Edit List">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to={`/hotels/${hotel.id}/lists`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Lists
          </Link>
          <h1 className="mb-6">{list.title}</h1>
          <div className="mb-16">
            Use this page to edit {list.title} List for {hotel.name}.
          </div>

          <HotelListForm
            id="edit-lists"
            hotel={hotel}
            initialValues={{
              ...list,
              startsOn: list.startsOn ? new Date(list.startsOn) : list.startsOn,
              endsOn: list.endsOn ? new Date(list.endsOn) : list.endsOn
              // eslint-disable-next-line no-nested-ternary
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
      )}
      <FormNavigator
        id="edit-lists-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

ListsEdit.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    list: state.list.data,
    isLoading: state.property.isLoading || state.list.isLoading
  }),
  {
    ...propertyActions,
    ...listActions,
    ...sidebarActions
  }
)(ListsEdit);
