import React, { useEffect, useState } from 'react';
import { Field } from 'redux-form';
import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import uuidV4 from 'uuid/v4';
import Input from '../common/Input';
import * as imageActions from '../../actions/images';
import placeholder from '../../assets/placeholder.png';

const propTypes = {
  id: PropTypes.string.isRequired,
  images: PropTypes.object,
  setImageEnv: PropTypes.func.isRequired,
  setActiveImage: PropTypes.func.isRequired,
  updateImagesSort: PropTypes.func.isRequired,
  addImage: PropTypes.func.isRequired,
  updateImage: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onlySingle: PropTypes.bool,
  disableAlt: PropTypes.bool
};

const defaultProps = {
  images: [],
  onlySingle: false,
  disableAlt: false
};

const ImageTab = SortableElement(
  ({ image, sortIndex, active, stateId, setActiveImage, removeImage, disableAlt }) => {
    return (
      <li
        style={{ display: 'inline-block' }}
        role="presentation"
        key={`image-${sortIndex}`}
        className={classnames(
          'bg-grey-lighter px-4 py-3 text-sm border border-transparent cursor-pointer flex items-center',
          { 'bg-grey-lightest': active !== image.id, 'pr-2': sortIndex > 0 }
        )}
        onClick={() => {
          setActiveImage(stateId, image.id);
        }}
      >
        <span>Image {sortIndex + 1}</span>
        {image.file && (
          <span
            role="presentation"
            className="hover:underline ml-3"
            onClick={evt => {
              evt.stopPropagation();
              removeImage(stateId, image.id);
            }}
          >
            &times;
          </span>
        )}
      </li>
    );
  }
);

const ImagesTabList = SortableContainer(
  ({ images, active, stateId, setActiveImage, removeImage }) => {
    return (
      <ul className="p-0">
        {images.map((image, i) => (
          <ImageTab
            key={image.id || uuidV4()}
            index={i}
            image={image}
            sortIndex={i}
            active={active}
            stateId={stateId}
            setActiveImage={setActiveImage}
            removeImage={removeImage}
          />
        ))}
      </ul>
    );
  }
);

const ImageSlide = ({
  id,
  images,
  updateImagesSort,
  setActiveImage,
  setImageEnv,
  addImage,
  updateImage,
  removeImage,
  onlySingle,
  change,
  disableAlt
}) => {
  const [idx, setIdx] = useState(-1);

  const { active, items } = images;

  const currentImage = items.find(item => item.id === active);
  const url = get(currentImage, ['file', 'url']);
  const filename = get(currentImage, 'file.name', 'image-filename.jpg');
  const alt = get(currentImage, 'file.alt');
  const videoUrl = get(currentImage, 'videoUrl');

  const [addVideo, setAddVideo] = useState((videoUrl || '').length > 0);

  useEffect(() => {
    // set the initial state
    setImageEnv(id, { onlySingle });

    // NOTE setting setImageEnv in the componentWillMount section
    // can cause race conditions, this is the case of this issue
    // https://app.asana.com/0/1119928407758199/1187570128549065/f
    //
    // Any reseting of the ImageSlide components will now be done
    // in the willUnmount hook
    return () => {
      setImageEnv(id, {});
    };
  }, [id]);

  // separate useEffect so setImageEnv doesn't run multiple times
  useEffect(() => {
    // without this the alt value does not get set to the field
    if (onlySingle && currentImage && currentImage.file) {
      /* eslint-disable-next-line no-shadow */
      const { id: assetId, alt, videoUrl } = currentImage.file;
      change(id, { asset_id: assetId, alt, video_url: videoUrl });
    }

    // handle for multiple images
    if (!onlySingle && items && items.length) {
      if (items[0].file) {
        // we will need the current index to set any updated values to the correct
        // item in the set of images
        const n = items.findIndex(v => v.id === active);
        setIdx(n);
        
        // set the whole image array set
        // NOTE v.file? is for adding a new image via tab
        /* eslint-disable-next-line no-shadow */
        const images = items.map(v => ({ asset_id: v.file?.id, alt: v.alt, video_url: v.videoUrl }));
        change(id, images);
      } else {
        // if you have reached this, most likely you have removed all images, we
        // now need to clear the alt tag of the last image removed
        change(id, [{ alt: '' }]);
      }
    }
  }, [active, alt]);
  // NOTE we need to trigger this on note again or it does not get run on a returning visit.


  const onSortEnd = ({ oldIndex, newIndex }) => {
    updateImagesSort(id, oldIndex, newIndex);
  };

  return (
    <div id={id}>
      <div className="flex">
        <ImagesTabList
          images={items.sort(
            (a, b) => (a.file || {}).sortIndex - (b.file || {}).sortIndex
          )}
          axis="x"
          onSortEnd={onSortEnd}
          active={active}
          distance={1}
          setActiveImage={(stateId, activeImageid) => {
            setAddVideo(false);
            setActiveImage(stateId, activeImageid);
          }}
          removeImage={(stateId, imageId) => {
            removeImage(stateId, imageId);
          }}
          stateId={id}
        />
        {!onlySingle && (
          <div
            role="presentation"
            className="border border-grey-lighter px-4 py-3 text-sm underline cursor-pointer"
            onClick={() => {
              addImage(id, { alt: '', videoUrl: '' });
            }}
          >
            + Add a Slide
          </div>
        )}
      </div>
      <div className="max-w-sm p-4 border border-solid border-grey-light">
        <img
          src={url || placeholder}
          alt="Placeholder while awaiting real one"
          style={{ width: 'auto' }}
        />
      </div>
      <div className="my-3">{filename}</div>
      <div className="mt-10 mb-3 font-normal text-base">Image Alt Tag*</div>
      <Field
        id="imgAlt"
        name={`${onlySingle ? id : `${id}.${idx}`}.alt`}
        component={Input}
        onChange={e => {
          change(`${onlySingle ? id : `${id}.${idx}`}.alt`, e.target.value);
          updateImage(id, currentImage.id, { alt: e.target.value })
        }}
        disabled={disableAlt}
      />
      <div className="flex mt-4 mb-6 items-center">
        <Input
          id="addVideo"
          name="addVideo"
          type="checkbox"
          className="mr-2"
          input={{
            checked: addVideo || (videoUrl || '').length > 0,
            onChange: e => {
              const isChecked = e.currentTarget.checked;
              if (!isChecked) {
                change(`${onlySingle ? id : `${id}.${idx}`}.video_url`, '');
                updateImage(id, currentImage.id, { videoUrl: '' });
              }
              setAddVideo(isChecked);
            }
          }}
        />
        <div className="leading-normal">
          Open Video in a Lightbox
          <br />
          <i>
            (A maximum of 1 static image slide is allowed when a video url is
            set. Please delete any extra image slides.)
          </i>
        </div>
      </div>
      {(addVideo || (videoUrl || '').length > 0) && (
        <Field
          id="addVideoUrl"
          name={`${onlySingle ? id : `${id}.${idx}`}.video_url`}
          component={Input}
          onChange={e => {
            change(`${onlySingle ? id : `${id}.${idx}`}.video_url`, e.target.value);
            updateImage(id, currentImage.id, { alt: e.target.value })
          }}
        />
      )}
    </div>
  );
};

ImageSlide.propTypes = propTypes;
ImageSlide.defaultProps = defaultProps;

export default connect(
  (state, props) => ({
    images: get(state.images, props.id, { active: '', items: [] })
  }),
  imageActions
)(ImageSlide);
