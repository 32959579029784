import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { submit, SubmissionError } from 'redux-form';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import { authfn } from 'swcms-authority';

import * as userActions from '../../actions/users';
import * as permissionActions from '../../actions/permissions';
import * as sessionActions from '../../actions/sessions';

import EditUserForm from '../../components/EditUserForm';
import EditUserTable from '../../components/EditUserTable';
import Layout from '../../layout';
import FormSection from '../../components/common/FormSection';

const propTypes = { /* eslint-disable-line no-unused-vars */ // FIXME
  profile: PropTypes.object.isRequired,
  authority: PropTypes.object.isRequired,
  permissions: PropTypes.func.isRequired,
  fetchUser: PropTypes.func.isRequired,
  fetchPermissions: PropTypes.func.isRequired,
  resetPermissions: PropTypes.func.isRequired,
  addPermission: PropTypes.func.isRequired,
  updatePermissions: PropTypes.func.isRequired,
  deleteSession: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  isLoading: PropTypes.bool
};

const defaultProps = { /* eslint-disable-line no-unused-vars */ // FIXME
  match: {},
  history: {},
  user: {},
  isLoading: false
};

const Edit = ({
  profile,
  authority,
  permissions,
  fetchUser,
  fetchPermissions,
  resetPermissions,
  addPermission,
  updatePermissions,
  deleteSession,
  updateUser,
  submit, // eslint-disable-line no-shadow
  match: {
    params: { id }
  },
  history: { push },
  user,
  isLoading
}) => {
  const [isDeletingSession, setIsDeletingSession] = useState(false);
  const [sessionDeleted, setSessionDelete] = useState(false);
  const [isSuper, setIsSuper] = useState(false);

  /* eslint-disable consistent-return */
  useEffect(() => {
    if (id === profile.id) {
      return push('/profile');
    }
    fetchUser(id);
    fetchPermissions(id).then(data => setIsSuper(authfn(data).isSuper()));
  }, [id]);

  const handleSubmit = data => {
    const update = pick(data, ['email', 'firstName', 'lastName', 'password']);
    return updateUser(id, {
      ...update,
      generatePassword: data.generatePassword
    })
      .then(user => updatePermissions(user.id, permissions)) // eslint-disable-line no-shadow
      .then(() => push('/users'))
      .catch(e => new SubmissionError({ _error: e.message }));
  };

  const handleDeleteSession = () => {
    setIsDeletingSession(true);
    deleteSession(user.id)
      .then(() => setSessionDelete(true))
      .catch(err => {
        // FIXME handle error
        console.error('[error]', err); // eslint-disable-line
      })
      .finally(() => setIsDeletingSession(false));
  };

  const handleConvertSuperUser = val => {
    return evt => {
      evt.preventDefault();

      resetPermissions();
      if (val) {
        addPermission({ resource: '*', r: true, w: true, x: true });
      }

      setIsSuper(val);
    };
  };

  const userDetails = omit(user, 'permissions');
  return (
    <Layout metaTitle="Edit User">
      <div className="w-full max-w-3xl">
        <Link
          to="/users"
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Users
        </Link>
        <h1 className="mb-6">{`${user.firstName} ${user.lastName}`}</h1>
        <div className="mb-16">
          Use this page to manage Users and permissions.
        </div>
        <FormSection id="details" title="User Details">
          {isLoading ? (
            <div>Loading ...</div>
          ) : (
            <EditUserForm
              initialValues={{
                ...userDetails,
                generatePasswordText: 'Reset Password',
                editMode: true
              }}
              isSuper={isSuper}
              onSubmit={handleSubmit}
            />
          )}
        </FormSection>
        <FormSection
          id="permissions"
          title="Assigned Permissions"
          className="border-t mb-12"
        >
          {authority.isSuper() && !isSuper && (
            <div className="w-full center mb-8">
              <a
                href="#"
                className="btn hover:bg-red no-underline border-0"
                onClick={handleConvertSuperUser(true)}
              >
                Convert this user to a Super User
              </a>
            </div>
          )}
          <div className="w-full">
            {!isSuper && (
              <EditUserTable user={user} permissions={permissions} />
            )}
            {authority.isSuper() && isSuper && (
              <div className="w-full text-center">
                <strong>The current user is a Super User</strong>
                <a
                  href="#"
                  className="btn hover:bg-red no-underline border-0 mt-4"
                  onClick={handleConvertSuperUser(false)}
                >
                  Convert this user to a Regular User
                </a>
              </div>
            )}
          </div>
        </FormSection>
        {/* NOTE, you can't delete your own session from here, logout instead */}
        {profile.id !== user.id && authority.canDelete('user') && (
          <FormSection
            id="session"
            title="User's Session"
            className="border-t mb-12"
          >
            {isDeletingSession && (
              <div className="flex justify-center">
                Clearing {user.firstName} {user.lastName}'s current sessions...
              </div>
            )}
            {sessionDeleted && (
              <div className="flex justify-center">
                {user.firstName} {user.lastName}'s current sessions has been
                deleted
              </div>
            )}
            {!isDeletingSession && !sessionDeleted && (
              <>
                <button
                  className="btn"
                  type="submit"
                  onClick={() => handleDeleteSession()}
                >
                  Destroy User's Current Sessions
                </button>
                <div className="w-full" style={{ marginTop: '16px' }}>
                  <hint>
                    This will log the user out and require them to login again.
                  </hint>
                </div>
              </>
            )}
          </FormSection>
        )}
        <div className="flex justify-center">
          <button
            className="btn"
            type="submit"
            onClick={() => submit('EditUserForm')}
          >
            Save
          </button>
        </div>
      </div>
    </Layout>
  );
};

Edit.propTypes = propTypes;
Edit.defaultProps = defaultProps;

export default connect(
  (state) => ({
    profile: state.auth.profile,
    authority: state.auth.authority,
    user: state.user.data,
    permissions: state.permissions.data,
    isLoading: state.user.isLoading || state.permissions.isLoading
  }),
  { ...userActions, ...permissionActions, ...sessionActions, submit }
)(Edit);
