import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as modalActions from '../../actions/modals';
import Input from '../common/Input';
import TinyMCE from '../common/form/TinyMCE';
import FormDropdown from '../common/form/Dropdown';
import FormSection from '../common/FormSection';
import Switch from '../common/form/Switch';
import FormErrorBox from '../common/FormErrorBox';
import { daysData, daysFromOne } from '../../utils/days-options';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  modalDatePicker: PropTypes.bool,
  headerBarDatePicker: PropTypes.bool,
  published: PropTypes.bool,
  archived: PropTypes.bool,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  displayAs: PropTypes.string,
  pageDisplayOn: PropTypes.array
};

const defaultProps = {
  displayAs: 'header_bar',
  pageDisplayOn: [],
  modalDatePicker: false,
  headerBarDatePicker: false,
  published: false,
  archived: false,
  error: undefined
};

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required!';
  }

  if (['modal_window', 'header_bar', 'both'].indexOf(values.displayAs) === -1) {
    errors.displayAs = 'Required!';
  }

  return errors;
};

const displayAsList = [
  {
    id: 'modal_window',
    name: 'Modal Window'
  },
  {
    id: 'header_bar',
    name: 'Header Bar'
  },
  {
    id: 'both',
    name: 'Both'
  },
];

const displayModalAsList = [
  {
    id: 'modal_window',
    name: 'Modal Window'
  },
  {
    id: 'info_box',
    name: 'Info Box'
  },
];

const HotelAlertForm = ({
  id,
  handleSubmit,
  showModal,
  modalDatePicker,
  headerBarDatePicker,
  published,
  archived,
  displayAs,
  pageDisplayOn,
  change,
  error
}) => {
  const setPageDisplayOn = (v, rem) => {
    const i = pageDisplayOn.indexOf(v);
    if (!rem && i !== -1) {
      pageDisplayOn.splice(i, 1);
    } else {
      pageDisplayOn.push(v);
    }
    change('modal.pageDisplayOn', [...new Set(pageDisplayOn)]);
  };
  const checkPageDisplayOn = v => {
    return pageDisplayOn && pageDisplayOn.indexOf(v) > -1;
  };
  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="name"
        title="Set a Name & Display Type"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter a name for this Alert, then select how to Display the
                Alert from the dropdown.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Alert Name*</div>
          <Field
            id="name"
            name="name"
            className="w-full mb-6"
            placeholder="Enter a name for this alert"
            component={Input}
          />
          <div className="mb-3">Display As...</div>
          {/* FIXME remove blank option */}
          <Field
            id="displayAs"
            name="displayAs"
            className="w-full mb-6"
            style={{ width: 300 }}
            component={FormDropdown}
            data={displayAsList}
            placeholder="Select a Display As type"
          />
        </div>
      </FormSection>
      {['header_bar', 'both'].indexOf(displayAs) !== -1 && (
        <FormSection
          id="header_bar"
          title="Header Bar Content"
          className="flex flex-col flex-wrap"
          isHorizontal
        >
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  Enter the content and link details for this Header Bar Alert.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="mb-3">Header Bar Text*</div>
            <Field
              id="headerBar.text"
              name="headerBar.text"
              className="mb-12"
              placeholder="Enter a short description for this alert..."
              component={TinyMCE}
            />
            <div className="mb-3">Header Bar Link Text</div>
            <Field
              id="headerBar.linkText"
              name="headerBar.linkText"
              className="w-full mb-6"
              placeholder="Enter the link text"
              component={Input}
            />
            <div className="mb-3">Header Bar Link URL</div>
            <Field
              id="headerBar.modalUrl"
              name="headerBar.linkUrl"
              className="w-full"
              placeholder="Link URL goes here"
              component={Input}
            />
            <div className="text-xs font-light mt-3 mb-6">
              *Please include
              <strong> https://</strong> when entering the URL
            </div>
            <div className="flex mb-12 items-center">
              <Field
                id="headerBar.opensInNewWindow"
                name="headerBar.opensInNewWindow"
                type="checkbox"
                className="mr-2"
                component={Input}
              />
              <div className="">Opens in New Window</div>
            </div>
            <div className="flex mb-6 items-center">
              <Field
                id="headerBar.addDatePickerPopup"
                name="headerBar.addDatePickerPopup"
                type="checkbox"
                className="mr-2"
                component={Input}
              />
              <div className="">Add Date Picker Popup</div>
            </div>
            <div className="flex mb-6 items-center">
              <Field
                id="headerBar.advancedPurchaseDays"
                name="headerBar.advancedPurchaseDays"
                className="mr-2"
                component={FormDropdown}
                data={daysData}
                disabled={!headerBarDatePicker}
              />
              <div
                className={classnames('', {
                  'text-grey': !headerBarDatePicker
                })}
              >
                Advance Purchase Days
              </div>
            </div>
            <div className="flex mb-12 items-center">
              <Field
                id="headerBar.minimumNightStay"
                name="headerBar.minimumNightStay"
                className="mr-2"
                component={FormDropdown}
                data={daysFromOne}
                disabled={!headerBarDatePicker}
              />
              <div
                className={classnames('', {
                  'text-grey': !headerBarDatePicker
                })}
              >
                Minimum Night Stay
              </div>
            </div>
            <div className="mb-3 mt-3">Header Bar Adobe Tracking Tag</div>
            <Field
              id="alerts-modal-ga_link_title"
              name="headerBar.trackingTag"
              className="w-full"
              placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
              component={Input}
            />
            <div className="flex justify-left">
              <button
                type="button"
                className="btn btn-clear px-6 mr-4"
                onClick={e => {
                  e.preventDefault();
                  showModal('GenerateTrackingModal', {
                    form: 'HotelAlertForm',
                    field: 'headerBar.trackingTag'
                  });
                }}
              >
                Generate Tracking
              </button>
            </div>
          </div>
        </FormSection>
      )}
      {['modal_window', 'both'].indexOf(displayAs) !== -1 && (
        <FormSection
          id="modal"
          title="Modal Window Title & Content"
          className="flex flex-col flex-wrap"
        >
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p className="mb-3">
                <i>
                  Set this Alert to Display As a pop-up Modal Window or an
                  on-page Info Box.
                </i>
              </p>
              <p className="mb-3">
                <i>Then enter the Title and Content for this Alert.</i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="mb-3">Display As:</div>
            <Field
              id="modal.displayModalAs"
              name="modal.displayModalAs"
              className="w-full mb-6"
              style={{ width: 300 }}
              component={FormDropdown}
              data={displayModalAsList}
              placeholder="Select a Display As type"
            />
            <div className="mb-12">
              <div className="mb-4">Display On:</div>
              <div className="mb-3">
                <label className="flex">
                  <Input
                    className="mr-2"
                    id="modalpageDisplayOnhomepage"
                    type="checkbox"
                    input={{
                      checked: checkPageDisplayOn('homepage'),
                      onChange: (_, val) => {
                        setPageDisplayOn('homepage', val);
                      }
                    }}
                  />
                  Homepage
                </label>
              </div>
              <div className="mb-2">
                <label className="flex">
                  <Input
                    className="mr-2"
                    id="modalpageDisplayOnamenities"
                    type="checkbox"
                    input={{
                      checked: checkPageDisplayOn('amenities'),
                      onChange: (_, val) => {
                        setPageDisplayOn('amenities', val);
                      }
                    }}
                  />
                  Amenities
                </label>
              </div>
              <div className="mb-2">
                <label className="flex">
                  <Input
                    className="mr-2"
                    id="modalpageDisplayOncovid19"
                    type="checkbox"
                    input={{
                      checked: checkPageDisplayOn('covid19'),
                      onChange: (_, val) => {
                        setPageDisplayOn('covid19', val);
                      }
                    }}
                  />
                  Covid 19
                </label>
              </div>
              <div className="mb-2">
                <label className="flex">
                  <Input
                    className="mr-2"
                    id="modalpageDisplayOneatdrink"
                    type="checkbox"
                    input={{
                      checked: checkPageDisplayOn('eatdrink'),
                      onChange: (_, val) => {
                        setPageDisplayOn('eatdrink', val);
                      }
                    }}
                  />
                  Eat & Drink
                </label>
              </div>
            </div>
            <div className="mb-3">Alert Modal Title*</div>
            <Field
              id="modal.title"
              name="modal.title"
              className="w-full mb-6"
              placeholder="Enter a title..."
              component={Input}
            />
            <div className="mb-3">Alert Modal Text*</div>
            <Field
              id="modal.text"
              name="modal.text"
              className="mb-12"
              placeholder="Enter a short description for this alert..."
              component={TinyMCE}
            />
            <div className="mb-3">Alert Modal Link Text</div>
            <Field
              id="modal.linkText"
              name="modal.linkText"
              className="w-full mb-6"
              placeholder="Enter the link text"
              component={Input}
            />
            <div className="mb-3">Alert Modal Link URL</div>
            <Field
              id="modal.modalUrl"
              name="modal.linkUrl"
              className="w-full"
              placeholder="Link URL goes here"
              component={Input}
            />
            <div className="text-xs font-light mt-3 mb-4">
              *Please include
              <strong> https://</strong> when entering the URL
            </div>
            <div className="flex mb-12 items-center">
              <Field
                id="modal.opensInNewWindow"
                name="modal.opensInNewWindow"
                type="checkbox"
                className="mr-2"
                component={Input}
              />
              <div className="">Opens in New Window</div>
            </div>
            <div className="flex mb-6 items-center">
              <Field
                id="modal.addDatePickerPopup"
                name="modal.addDatePickerPopup"
                type="checkbox"
                className="mr-2"
                component={Input}
              />
              <div className="">Add Date Picker Popup</div>
            </div>
            <div className="flex mb-6 items-center">
              <Field
                id="modal.advancedPurchaseDays"
                name="modal.advancedPurchaseDays"
                className="mr-2"
                component={FormDropdown}
                data={daysData}
                disabled={!modalDatePicker}
              />
              <div
                className={classnames('', {
                  'text-grey': !modalDatePicker
                })}
              >
                Advance Purchase Days
              </div>
            </div>
            <div className="flex mb-12 items-center">
              <Field
                id="modal.minimumNightStay"
                name="modal.minimumNightStay"
                className="mr-2"
                component={FormDropdown}
                data={daysFromOne}
                disabled={!modalDatePicker}
              />
              <div
                className={classnames('', {
                  'text-grey': !modalDatePicker
                })}
              >
                Minimum Night Stay
              </div>
            </div>
            <div className="mb-3 mt-3">Alert Modal Adobe Tracking Tag</div>
            <Field
              id="alerts-modal-ga_link_title"
              name="modal.trackingTag"
              className="w-full"
              placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
              component={Input}
            />
            <div className="flex justify-left">
              <button
                type="button"
                className="btn btn-clear px-6 mr-4"
                onClick={e => {
                  e.preventDefault();
                  showModal('GenerateTrackingModal', {
                    form: 'HotelAlertForm',
                    field: 'modal.trackingTag'
                  });
                }}
              >
                Generate Tracking
              </button>
            </div>
          </div>
        </FormSection>
      )}
      <FormSection
        id="save"
        title="Save & Assign"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                When you're ready, toggle the publish status to display this
                Alert on the live site and hit 'Save'.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="w-1/3 mr-12">
            <div className="text-base font-normal mb-3">
              Archive this Alert?
            </div>
            <Field
              id="archived"
              name="archived"
              component={Switch}
              label={archived ? 'Archived' : 'Unarchived'}
              className="mb-6"
              onChange={(e, value) => {
                e.preventDefault();
                if (value && published) {
                  change('published', false);
                }
                change('archived', value);
              }}
            />
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && <FormErrorBox id="hotel-alert-error">{error}</FormErrorBox>}
    </form>
  );
};

HotelAlertForm.propTypes = propTypes;
HotelAlertForm.defaultProps = defaultProps;

const selector = formValueSelector('HotelAlertForm');

export default compose(
  reduxForm({ form: 'HotelAlertForm', validate }),
  connect(
    state => ({
      displayAs: selector(state, 'displayAs'),
      pageDisplayOn: selector(state, 'modal.pageDisplayOn') || [], // FIXME why does default state not handle this?
      modalDatePicker: selector(state, 'modal.addDatePickerPopup'),
      headerBarDatePicker: selector(state, 'headerBar.addDatePickerPopup'),
      published: selector(state, 'published'),
      archived: selector(state, 'archived')
    }),
    { ...modalActions }
  )
)(HotelAlertForm);
