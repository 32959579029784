import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Input from '../common/Input';
import Textarea from '../common/Textarea';
import FormSwitch from '../common/form/Switch';
import FormSection from '../common/FormSection';
import FormErrorBox from '../common/FormErrorBox';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  published: PropTypes.bool,
  archived: PropTypes.bool,
  change: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  published: false,
  archived: false,
  error: undefined
};

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Required!';
  }

  return errors;
};

const RestaurantMenuForm = ({
  id,
  handleSubmit,
  published,
  archived,
  change,
  error
}) => {
  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="title"
        title="Title & Content"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>Enter a title for this Menu.</i>
            </p>
            <p>
              <i>
                Then paste the entire script code into the Code Script area.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Title*</div>
          <Field
            id="menu-title"
            name="title"
            className="w-full mb-6"
            placeholder="Enter a title..."
            component={Input}
          />
          <div className="mb-3">
            Code Script, ex. &#60;script id&#61;&#8216;xxxx&#8217;
            type&#61;&#8216;xxxxx&#8217;&#62;&#8230;&#60;&#47;script&#62;
          </div>
          <Field
            id="script"
            name="script"
            className=""
            placeholder="Paste the code script here..."
            rows="12"
            component={Textarea}
          />
        </div>
      </FormSection>
      <FormSection
        id="publish"
        title="Publish"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex w-full flex-col flex-wrap xl:flex-row">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  When you're ready, toggle the publish status to display this
                  Menu on the live site and hit 'Save'.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="text-base font-normal mb-3">Publish Status:</div>
            <Field
              id="published"
              name="published"
              component={FormSwitch}
              label={published ? 'Published' : 'Unpublished'}
              className="mb-12"
              disabled={archived}
            />
            <div className="text-base font-normal mb-3">Archive this Menu?</div>
            <Field
              id="archived"
              name="archived"
              component={FormSwitch}
              label={archived ? 'Archived' : 'Unarchived'}
              className="mb-12"
              onChange={(e, value) => {
                e.preventDefault();
                if (value && published) {
                  change('published', false);
                }
                change('archived', value);
              }}
            />
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && <FormErrorBox id="restaurant-menu-error">{error}</FormErrorBox>}
    </form>
  );
};

RestaurantMenuForm.propTypes = propTypes;
RestaurantMenuForm.defaultProps = defaultProps;

const selector = formValueSelector('RestaurantMenuForm');

export default compose(
  reduxForm({ form: 'RestaurantMenuForm', validate }),
  connect(state => ({
    title: selector(state, 'title'),
    script: selector(state, 'script'),
    published: selector(state, 'published'),
    archived: selector(state, 'archived')
  }))
)(RestaurantMenuForm);
