import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import omit from 'lodash/omit';
import * as sidebarActions from '../../../actions/sidebar';
import * as propertyActions from '../../../actions/properties';
import * as calendarEventActions from '../../../actions/calendarEvents';
import Layout from '../../../layout';
import SearchBar from '../../../components/common/SearchBar';
import Input from '../../../components/common/Input';
import {
  Tabs,
  Tab,
  TabList,
  TabPill,
  TabPanel
} from '../../../components/common/Tabs';
import CheckboxList from '../../../components/common/List/CheckboxList';
import { List, ListItem } from '../../../components/common/List';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  hotels: PropTypes.array.isRequired,
  calendarEvents: PropTypes.array.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchCalendarEvents: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  createCalendarEvent: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const Clone = ({
  hotel,
  hotels,
  calendarEvents,
  fetchProperty,
  fetchCalendarEvents,
  updateSidebar,
  createCalendarEvent,
  match: {
    params: { hotelid }
  },
  history
}) => {
  const [searchEvents, setSearchEvents] = useState('');
  const [searchHotels, setSearchHotels] = useState('');
  const [checked, setChecked] = useState({});
  const [eventsChecked, setEventsChecked] = useState({});
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      // FIXME this then... seems to be an anti-pattern here. fetchProperty
      // should fetch, then the state comes in as `hotel` and that change state
      // should trigger another useEffect that holds updateSidebar. This would
      // also fix the no-shadow issues that manifest from this.

      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchCalendarEvents(hotelid);
    return () => {};
  }, []);

  const onSubmit = () => {
    // checked hotels
    const checkedHotels = Object.entries(checked).reduce(
      (total, [key, value]) => (value ? total.concat(key) : total),
      []
    );
    // for each hotel, create each home calendar events selected
    Promise.all(
      checkedHotels.flatMap(checkedId => {
        const cloneEvents = Object.keys(eventsChecked)
          .map(eventId => calendarEvents.find(event => event.id === eventId))
          .map(event => {
            const images = Array.isArray(event.images)
              ? event.images.map(item => ({
                  assetId: item.id,
                  alt: item.alt
                }))
              : event.images;
            return Object.assign({}, omit(event, ['id']), {
              propertyId: checkedId,
              images,
              sortIndex: null
            });
          });
        return cloneEvents.map(event => createCalendarEvent(checkedId, event));
      })
    ).then(() => history.push(`/hotels/${hotelid}/calendars`));
  };

  const filteredEvents = matchSorter(calendarEvents, searchEvents, {
    keys: ['title']
  });
  const currentEvents = filteredEvents.filter(event => !event.archived);
  const archivedEvents = filteredEvents.filter(event => event.archived);
  const filteredHotels = matchSorter(hotels, searchHotels, { keys: ['name'] });

  return (
    <Layout metaTitle="Clone a Calendar Event">
      <div className="w-full max-w-3xl">
        <Link
          to={`/hotels/${hotelid}/calendars`}
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Calendars
        </Link>
        <h1 className="mb-6">Clone a Calendar Event</h1>
        <div className="mb-16">
          Use this page to Clone Calendar Events on {hotel.name}.
        </div>
        <div className="flex flex-wrap justify-between mb-16">
          <div style={{ width: 440 }}>
            <SearchBar
              id="filter-events"
              className="mb-4"
              placeholder="Filter Calendar Events..."
              value={searchEvents}
              onChange={({ value }) => setSearchEvents(value)}
            />
            <Tabs>
              <TabList>
                <Tab>
                  <span className="mr-2">Current</span>
                  <TabPill id="current-pill">{currentEvents.length}</TabPill>
                </Tab>
                <Tab>
                  <span className="mr-2">Archived</span>
                  <TabPill id="archived-pill">{archivedEvents.length}</TabPill>
                </Tab>
              </TabList>

              <TabPanel>
                <List id="current-list">
                  {currentEvents.map(event => (
                    <ListItem id={event.id} key={event.id}>
                      <Input
                        id={event.id}
                        type="checkbox"
                        className="mr-4"
                        input={{
                          checked: !!eventsChecked[event.id],
                          onChange: () =>
                            setEventsChecked({
                              ...eventsChecked,
                              [event.id]: !eventsChecked[event.id]
                            })
                        }}
                      />
                      <Link
                        to={`/hotels/${hotelid}/calendars/events/${
                          event.id
                        }/edit`}
                      >
                        {event.title}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
              <TabPanel>
                <List id="archived-list">
                  {archivedEvents.map(event => (
                    <ListItem id={event.id} key={event.id}>
                      <Input
                        id={event.id}
                        type="checkbox"
                        className="mr-4"
                        input={{
                          checked: !!eventsChecked[event.id],
                          onChange: () =>
                            setEventsChecked({
                              ...eventsChecked,
                              [event.id]: !eventsChecked[event.id]
                            })
                        }}
                      />
                      <Link
                        to={`/hotels/${hotelid}/calendars/events/${
                          event.id
                        }/edit`}
                      >
                        {event.title}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
            </Tabs>
          </div>
          <div style={{ width: 440 }}>
            <SearchBar
              id="filter-hotels"
              className="mb-16"
              placeholder="Filter Hotels..."
              value={searchHotels}
              onChange={({ value }) => setSearchHotels(value)}
            />
            <CheckboxList
              id="checkbox-hotels"
              title="Hotels"
              data={filteredHotels.map(hotelItem => ({
                id: hotelItem.id,
                name: hotelItem.name,
                displayName: item => (
                  <Link to={`/hotels/${item.id}`} className="mr-4">
                    {item.name}
                  </Link>
                ),
                checked: checked[hotelItem.id],
                onChange: () => {
                  setChecked({
                    ...checked,
                    [hotelItem.id]: !checked[hotelItem.id]
                  });
                },
                disabled: hotelItem.id === hotelid
              }))}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button type="button" className="btn" onClick={onSubmit}>
            Clone
          </button>
        </div>
      </div>
    </Layout>
  );
};

Clone.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    // FIXME this should not have a filter here, this should be a direct API
    // query for type
    hotels: state.properties.data.filter(property => property.type === 'hotel'),
    calendarEvents: state.calendarEvents.data,
    isLoading: state.property.isLoading || state.calendarEvents.isLoading
  }),
  {
    ...propertyActions,
    ...sidebarActions,
    ...calendarEventActions
  }
)(Clone);
