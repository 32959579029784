import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import ImageSlide from '../ImageSlide';
import UploadModal from '../UploadModal';
import AssetSearchBar from '../AssetSearchBar';
import * as imageActions from '../../actions/images';
import * as assetActions from '../../actions/assets';

const propTypes = {
  setCurrentImage: PropTypes.func.isRequired,
  createAsset: PropTypes.func.isRequired,

  condfn: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  canUploadAsset: PropTypes.func,

  categories: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  header: PropTypes.string,
  onlySingle: PropTypes.bool,
  disableAlt: PropTypes.bool
};

const defaultProps = {
  canUploadAsset: () => false,
  header: 'Select an image*',
  onlySingle: true,
  disableAlt: false
};

const SetAnAsset = ({
  // comes from actions
  setCurrentImage,
  createAsset,

  // user provider props
  condfn,
  change,
  canUploadAsset = () => false,
  categories,
  name,
  id,
  header = 'Select an image*',
  onlySingle,
  disableAlt
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="mb-3">{header}</div>
      <AssetSearchBar
        condfn={condfn}
        clickMenuItemHandler={image => {
          setCurrentImage(name, image);
          change(`${name}.asset_id`, image.id);
        }}
      />
      {canUploadAsset() && (
        <>
          <button
            type="button"
            className="btn btn-clear mb-12"
            onClick={e => {
              e.preventDefault();
              setIsOpen(true);
            }}
          >
            + Upload File
          </button>
          <UploadModal
            id={id}
            isOpen={isOpen}
            onRequestClose={() => setIsOpen(false)}
            onUploadSuccess={file => {
              const { filename, filesize, filetype, altName } = file.meta;
              const tags = get(file, 'meta.tags')
                ? get(file, 'meta.tags')
                    .split(',')
                    .map(tag => ({ name: tag.trim() }))
                : [];
              const body = {
                filename,
                filesize,
                filetype,
                name: altName,
                tags,
                alt: get(file, 'meta.alt'),
                categories
              };
              return createAsset(body).then(asset => {
                setCurrentImage(name, asset);
                change(`${name}.asset_id`, asset.id);
              });
            }}
          />
        </>
      )}
      <div className="flex-1">
        <ImageSlide id={name} onlySingle={onlySingle} change={change} disableAlt={disableAlt} />
      </div>
    </>
  );
};

SetAnAsset.propTypes = propTypes;
SetAnAsset.defaultProps = defaultProps;

/**
 * expose
 */

export default connect(
  (state, props) => ({}),
  { ...imageActions, ...assetActions }
)(SetAnAsset);