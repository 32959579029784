import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_MENUS_REQUEST = 'FETCH_MENUS_REQUEST';
export const FETCH_MENUS_SUCCESS = 'FETCH_MENUS_SUCCESS';
export const FETCH_MENUS_FAILURE = 'FETCH_MENUS_FAILURE';

export const FETCH_MENU_REQUEST = 'FETCH_MENU_REQUEST';
export const FETCH_MENU_SUCCESS = 'FETCH_MENU_SUCCESS';
export const FETCH_MENU_FAILURE = 'FETCH_MENU_FAILURE';

export const CREATE_MENU_REQUEST = 'CREATE_MENU_REQUEST';
export const CREATE_MENU_SUCCESS = 'CREATE_MENU_SUCCESS';
export const CREATE_MENU_FAILURE = 'CREATE_MENU_FAILURE';

export const UPDATE_MENU_REQUEST = 'UPDATE_MENU_REQUEST';
export const UPDATE_MENU_SUCCESS = 'UPDATE_MENU_SUCCESS';
export const UPDATE_MENU_FAILURE = 'UPDATE_MENU_FAILURE';

export const DELETE_MENU_REQUEST = 'DELETE_MENU_REQUEST';
export const DELETE_MENU_SUCCESS = 'DELETE_MENU_SUCCESS';
export const DELETE_MENU_FAILURE = 'DELETE_MENU_FAILURE';

export const MOVE_MENU = 'MOVE_MENU';

export const SORT_MENUS_REQUEST = 'SORT_MENUS_REQUEST';
export const SORT_MENUS_SUCCESS = 'SORT_MENUS_SUCCESS';
export const SORT_MENUS_FAILURE = 'SORT_MENUS_FAILURE';

export const fetchMenus = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_MENUS_REQUEST
  });
  return fetch(path || `/properties/${propertyId}/menus${fromObj(qry, '?')}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_MENUS_SUCCESS,
        payload: data,
        meta
      });
      return { data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_MENUS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countMenus = (propertyId, params) => dispatch => {
  return fetch(`/properties/${propertyId}/menus${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchMenu = (propertyId, menuId) => dispatch => {
  dispatch({
    type: FETCH_MENU_REQUEST
  });
  return fetch(`/properties/${propertyId}/menus/${menuId}`)
    .then(response => {
      const menu = convertToJS(response.data);
      dispatch({
        type: FETCH_MENU_SUCCESS,
        payload: menu
      });
      return menu;
    })
    .catch(e => {
      dispatch({
        type: FETCH_MENU_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createMenu = (propertyId, body) => dispatch => {
  dispatch({
    type: CREATE_MENU_REQUEST
  });
  return fetch(`/properties/${propertyId}/menus`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(body))
  })
    .then(response => {
      const menu = convertToJS(response.data);
      dispatch({
        type: CREATE_MENU_SUCCESS,
        payload: menu
      });
      return menu;
    })
    .catch(e => {
      dispatch({
        type: CREATE_MENU_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateMenu = (propertyId, menuId, update) => dispatch => {
  dispatch({
    type: UPDATE_MENU_REQUEST
  });
  return fetch(`/properties/${propertyId}/menus/${menuId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const menu = convertToJS(response.data);
      dispatch({
        type: UPDATE_MENU_SUCCESS,
        payload: menu
      });
      return menu;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_MENU_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteMenu = (propertyId, menuId) => dispatch => {
  dispatch({
    type: DELETE_MENU_REQUEST
  });
  return fetch(`/properties/${propertyId}/menus/${menuId}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch({
        type: DELETE_MENU_SUCCESS,
        payload: menuId
      });
      return menuId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_MENU_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveMenu = (source, target) => ({
  type: MOVE_MENU,
  payload: { source, target }
});

export const sortMenus = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_MENUS_REQUEST
  });
  return fetch(`/properties/${propertyId}/menus/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_MENUS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_MENUS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
