import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import values from 'lodash/values';
import Layout from '../../layout';
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPill
} from '../../components/common/Tabs';
import Table from '../../components/common/Table';
import ExitButton from '../../components/common/ExitButton';
import * as notificationActions from '../../actions/notifications';

const columns = [
  {
    id: 'date',
    Header: 'Date',
    accessor: data => moment(data.date).format('MM/DD/YYYY'),
    width: 140
  },
  {
    id: 'title',
    Header: 'Title',
    accessor: data => data.title
  },
  {
    id: 'ex',
    Header: () => <span />,
    accessor: () => (
      <ExitButton
        id="notification-exit"
        className="h-4 w-4"
        textClassName="w-4"
      />
    ),
    width: 40
  },
];

const propTypes = {
  notifications: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchNotifications: PropTypes.func.isRequired
};

const Notifications = ({ notifications, isLoading, fetchNotifications }) => {
  useEffect(() => {
    fetchNotifications();
    return () => {};
  }, []);

  return (
    <Layout metaTitle="All Notifications">
      <div className="w-full max-w-3xl">
        <h1 className="mb-24">All Notifications</h1>

        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <Tabs>
            <TabList>
              <Tab>
                <span className="mr-3">Active</span>
                <TabPill id="active-notifications">
                  {notifications.length}
                </TabPill>
              </Tab>
            </TabList>

            <TabPanel>
              <Table
                id="notifications-table"
                columns={columns}
                data={notifications}
              />
            </TabPanel>
          </Tabs>
        )}
      </div>
    </Layout>
  );
};

Notifications.propTypes = propTypes;

export default connect(
  state => ({
    notifications: values(state.notifications.data),
    isLoading: state.notifications.isLoading
  }),
  notificationActions
)(Notifications);
