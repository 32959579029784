import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import moment from 'moment';
import Layout from '../../../../layout';
import { SearchBar } from '../../../../components/common/SearchBar/SearchBar';
import {
  Tabs,
  TabList,
  Tab,
  TabPill
} from '../../../../components/common/Tabs';
import Table from '../../../../components/common/Table';
import More, {
  MoreMenu,
  MoreMenuItem
} from '../../../../components/common/More';
import EditableLink from '../../../../components/common/EditableLink';
import * as sidebarActions from '../../../../actions/sidebar';
import * as modalActions from '../../../../actions/modals';
import * as propertyActions from '../../../../actions/properties';
import * as tiledPromoActions from '../../../../actions/tiledPromos';
import Paginate from '../../../../components/Paginate';
import {
  inputHandlerFunc,
  fetchHandlerFunc
} from '../../../../utils/fetch-helpers';
import { onDrop } from '../../../../utils/sort-helpers';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  weddings: PropTypes.array.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchTiledPromos: PropTypes.func.isRequired,
  countTiledPromos: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  moveTiledPromo: PropTypes.func.isRequired,
  sortTiledPromos: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  updateTiledPromo: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  authority: PropTypes.object.isRequired,
  meta: PropTypes.object
};

const defaultProps = {
  meta: {}
};

const TiledPromoType = 'wedding';

const condfn = val => {
  const cond = { type: TiledPromoType, order_by: 'sort_index:ASC' };
  if (val) {
    cond.title = `i%%${val}`;
  }

  return cond;
};

const Weddings = ({
  match: {
    url,
    params: { hotelid }
  },
  history: { push },
  hotel,
  weddings,
  meta,
  fetchProperty,
  fetchTiledPromos,
  countTiledPromos,
  updateSidebar,
  moveTiledPromo,
  sortTiledPromos,
  updateProperty,
  updateTiledPromo,
  showModal,
  authority
}) => {
  const [refresh, setRefresh] = useState(0);
  const [activeCount, setActivCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [selected, setSelected] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const tabsconf = [
    {
      qry: { archived: false },
      cb: setActivCount
    },
    {
      qry: { archived: true },
      cb: setArchivedCount
    },
  ];

  const fetchfn = fetchHandlerFunc(
    fetchTiledPromos,
    countTiledPromos,
    tabsconf,
    hotelid
  );

  const searchHandler = inputHandlerFunc(
    setSearchInput,
    useCallback(debounce(fetchfn, 500), []),
    condfn
  );

  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    return () => {};
  }, []);

  useEffect(() => {
    fetchfn(selected, condfn(searchInput));
    return () => {};
  }, [selected, refresh]);

  const columns = [
    {
      id: 'title',
      Header: 'Title',
      accessor: data => <Link to={`${url}/${data.id}/edit`}>{data.title}</Link>
    },
    {
      id: 'live',
      Header: '',
      accessor: data =>
        (data.doesNotEnd || moment(data.endsOn) > moment()) &&
        moment(data.startsOn) < moment() &&
        data.published ? (
          <span className="bg-teal text-white px-5 py-1 text-xs rounded-full">
            LIVE
          </span>
        ) : (
          ''
        ),
      width: 120
    },
    {
      id: 'startsOn',
      Header: 'Starts On',
      accessor: data => moment(data.startsOn).format('MM-DD-YYYY'),
      width: 120
    },
    {
      id: 'endsOn',
      Header: 'Ends On',
      accessor: data =>
        data.endsOn && !data.doesNotEnd
          ? moment(data.endsOn).format('MM-DD-YYYY')
          : '--',
      width: 120
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: data => (
        <span className="capitalize">
          {data.published ? 'Published' : 'Unpublished'}
        </span>
      ),
      width: 120
    },
    {
      id: 'more',
      Header: () => <span />,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-archive`}
              onClick={() =>
                updateTiledPromo(
                  hotelid,
                  data.id,
                  Object.assign(
                    {
                      id: data.id,
                      archived: !data.archived
                    },
                    !data.archived && { published: false }
                  )
                ).then(setRefresh(refresh + 1))}
            >
              {data.archived ? 'Unarchive' : 'Archive'}
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-clone`}
              onClick={() => push(`${url}/clone?selected=${data.id}`)}
            >
              Clone
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={() =>
                showModal(
                  'DeleteWeddingModal',
                  {
                    hotelId: hotelid,
                    id: data.id,
                    title: data.title
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )}
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      ),
      width: 40
    },
  ];

  const weddingsURL = get(hotel, 'previewUrls.weddingsUrl');

  return (
    <Layout metaTitle="Weddings Promos">
      <div className="w-full max-w-3xl">
        <h1 className="mb-6">Weddings Promos</h1>
        <div className="mb-6">
          Use this page to create and manage Weddings Promos on {hotel.name}.
        </div>
        <div className="mb-12 text-sm flex items-center">
          View Live Promos Weddings Page:
          <EditableLink
            id="hotel-weddings-link"
            className="ml-3"
            link={weddingsURL}
            target="_blank"
            onSubmit={data =>
              updateProperty(hotelid, {
                previewUrls: { ...hotel.previewUrls, weddingsUrl: data.link }
              })}
            isEditable={authority.isSuper()}
          />
        </div>
        <div className="flex flex-col flex-col-reverse md:flex-row mb-6 md:mb-12">
          <SearchBar
            id="weddings-search"
            className="flex-1 mr-2 lg:mt-1"
            placeholder="Search Weddings Promos..."
            value={searchInput}
            onChange={({ value }) => searchHandler(value, selected)}
            onClick={() => {}}
            style={{ width: 320 }}
          />
          <div className="flex flex-1 justify-start mb-6 md:mb-0 md:justify-end">
            <Link
              to={`${url}/clone`}
              className="btn btn-clear no-underline mr-3"
            >
              Clone Promo
            </Link>
            <Link to={`${url}/new`} className="btn no-underline">
              + New Promo
            </Link>
          </div>
        </div>

        <Tabs selectedIndex={selected} onSelect={i => setSelected(i)}>
          <TabList>
            <Tab>
              <span className="mr-2">Active</span>
              <TabPill id="active-pill">{activeCount}</TabPill>
            </Tab>
            <Tab>
              <span className="mr-2">Archived</span>
              <TabPill id="archived-pill">{archivedCount}</TabPill>
            </Tab>
            <Paginate
              meta={meta}
              fetchHandler={(_, link) => fetchTiledPromos(null, null, link)}
            />
          </TabList>

          <Table
            id="tiled-promos"
            data={weddings}
            columns={columns}
            moveRow={(drag, hover) => moveTiledPromo(drag, hover)}
            onDrop={(evt, moved) => {
              if (moved) {
                onDrop(evt, weddings, sortTiledPromos.bind(null, hotelid), {
                  type: TiledPromoType,
                  archived: selected ? true : false // eslint-disable-line no-unneeded-ternary
                });
              }
            }}
            isDraggable
          />
        </Tabs>
        <Paginate
          meta={meta}
          fetchHandler={(_, link) => fetchTiledPromos(null, null, link)}
        />
      </div>
    </Layout>
  );
};

Weddings.propTypes = propTypes;
Weddings.defaultProps = defaultProps;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    weddings: state.tiledPromos.data,
    meta: state.tiledPromos.meta,
    authority: state.auth.authority,
    isLoading: state.property.isLoading || state.tiledPromos.isLoading
  }),
  {
    ...sidebarActions,
    ...modalActions,
    ...propertyActions,
    ...tiledPromoActions
  }
)(Weddings);
