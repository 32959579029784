import { matchPath } from 'react-router-dom';
import { NAV_UPDATE } from '../actions/nav';
import { urlByParams } from '../routes/pathsFor';

/**
 * makeLink parses a directory into a valid link structure for the SidebarNav
 *
 * @param {Object} dir (a directory object from pathsFor, most likely)
 * @param {Object} url (a react router match object)
 * @returns {Object}
 * @api private
 */

const makeLink = (dir, { params = {}, url }) => {
  const { path } = dir;

  /* eslint-disable prefer-const */
  let link = {
    ...dir,
    url: urlByParams(params, path)
  };

  // we need to make another matchPath here against the dir's path and original
  // matched url for proper active states for each individual link
  const _match = matchPath(url, { path: link.path });
  if (_match && _match.isExact) {
    link.active = true;
  } else if (link.activeRoot) {
    let reg;
    if (link.activeRoot instanceof RegExp) {
      reg = link.activeRoot;
    } else {
      reg = new RegExp(`^${urlByParams(params, link.activeRoot)}/.+`);
    }
    link.active = reg.test(url);
  }

  return link;
};

const initialState = {
  data: [],
  breadcrumbs: [],
  isLoading: true
};

export default function sidebar(
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload, match }
) {
  switch (type) {
    case NAV_UPDATE:
      /* eslint-disable no-case-declarations */
      let [navs, breadcrumbs] = payload;
      navs = navs.map(v => makeLink(v, match));
      breadcrumbs = breadcrumbs.map(v => {
        return { ...v, url: urlByParams(match.params, v.path) };
      });
      return { ...state, isLoading: false, data: navs, breadcrumbs };
    default:
      return state;
  }
}
