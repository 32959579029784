import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';

export const FETCH_CALENDARS_REQUEST = 'FETCH_CALENDARS_REQUEST';
export const FETCH_CALENDARS_SUCCESS = 'FETCH_CALENDARS_SUCCESS';
export const FETCH_CALENDARS_FAILURE = 'FETCH_CALENDARS_FAILURE';

export const CREATE_CALENDAR_REQUEST = 'CREATE_CALENDAR_REQUEST';
export const CREATE_CALENDAR_SUCCESS = 'CREATE_CALENDAR_SUCCESS';
export const CREATE_CALENDAR_FAILURE = 'CREATE_CALENDAR_FAILURE';

export const UPDATE_CALENDAR_REQUEST = 'UPDATE_CALENDAR_REQUEST';
export const UPDATE_CALENDAR_SUCCESS = 'UPDATE_CALENDAR_SUCCESS';
export const UPDATE_CALENDAR_FAILURE = 'UPDATE_CALENDAR_FAILURE';

export const DELETE_CALENDAR_REQUEST = 'DELETE_CALENDAR_REQUEST';
export const DELETE_CALENDAR_SUCCESS = 'DELETE_CALENDAR_SUCCESS';
export const DELETE_CALENDAR_FAILURE = 'DELETE_CALENDAR_FAILURE';

export const fetchCalendars = propertyId => dispatch => {
  dispatch({
    type: FETCH_CALENDARS_REQUEST
  });
  return fetch(`/properties/${propertyId}/calendars`)
    .then(response => {
      const calendars = response.data.map(convertToJS);
      dispatch({
        type: FETCH_CALENDARS_SUCCESS,
        payload: calendars
      });
      return calendars;
    })
    .catch(e => {
      dispatch({
        type: FETCH_CALENDARS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createCalendar = (propertyId, body) => dispatch => {
  dispatch({
    type: CREATE_CALENDAR_REQUEST
  });
  return fetch(`/properties/${propertyId}/calendars`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(body))
  })
    .then(response => {
      const calendar = convertToJS(response.data);
      dispatch({
        type: CREATE_CALENDAR_SUCCESS,
        payload: calendar
      });
      return calendar;
    })
    .catch(e => {
      dispatch({
        type: CREATE_CALENDAR_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateCalendar = (propertyId, calendarId, update) => dispatch => {
  dispatch({
    type: UPDATE_CALENDAR_REQUEST
  });
  return fetch(`/properties/${propertyId}/calendars/${calendarId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const Calendar = convertToJS(response.data);
      dispatch({
        type: UPDATE_CALENDAR_SUCCESS,
        payload: Calendar
      });
      return Calendar;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_CALENDAR_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteCalendar = (propertyId, calendarId) => dispatch => {
  dispatch({
    type: DELETE_CALENDAR_REQUEST
  });
  return fetch(`/properties/${propertyId}/calendars/${calendarId}`, {
    method: 'DELETE'
  })
    .then(response => {
      dispatch({
        type: DELETE_CALENDAR_SUCCESS,
        payload: calendarId
      });
      return calendarId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_CALENDAR_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
