import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Editor } from '@tinymce/tinymce-react';

class TinyMCE extends Component {
  constructor(props) {
    super(props);
    this.state = { value: '' };
  }

  componentDidMount() {
    const {
      input: { value }
    } = this.props;
    this.setState({
      value
    });
  }

  static getDerivedStateFromProps(props, state) {
    const {
      input: { value }
    } = props;
    const stateValue = state.value.toString('html');

    if (value && value !== stateValue) {
      return {
        value
      };
    }

    return null;
  }

  handleChange = (value) => {
    const {
      input: { onChange }
    } = this.props;
    onChange(value);
  };

  render() {
    const {
      id,
      placeholder,
      className,
      meta: { touched, error },
      input: { value }
    } = this.props;
    return (
      <div id={id} className={className}>
        <Editor
          tinymceScriptSrc='/static/js/tinymce/tinymce.min.js'
          licenseKey='gpl'
          className={classnames({ 'border-red': touched && error })}
          value={value}
          init={{
            height: 500,
            menubar: false,
            placeholder,
            plugins: ['searchreplace', 'lists', 'link', 'code', 'wordcount'],
            toolbar:
              'undo redo | bold italic underline | bullist numlist | removeformat | link unlink | code  ',
            branding: false
          }}
          onEditorChange={this.handleChange}
        />
        {touched && error && <div className="mt-2 text-red">{error}</div>}
      </div>
    );
  }
}

TinyMCE.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string
  }).isRequired,
  meta: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string
};

TinyMCE.defaultProps = {
  placeholder: '',
  className: ''
};

export default TinyMCE;
