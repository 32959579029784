import { moveBySplice } from '../utils/move_by_splice';
import {
  FETCH_ACCOMMODATIONS_REQUEST,
  FETCH_ACCOMMODATIONS_SUCCESS,
  FETCH_ACCOMMODATIONS_FAILURE,
  FETCH_ACCOMMODATION_REQUEST,
  FETCH_ACCOMMODATION_SUCCESS,
  FETCH_ACCOMMODATION_FAILURE,
  CREATE_ACCOMMODATION_REQUEST,
  CREATE_ACCOMMODATION_SUCCESS,
  CREATE_ACCOMMODATION_FAILURE,
  UPDATE_ACCOMMODATION_REQUEST,
  UPDATE_ACCOMMODATION_SUCCESS,
  UPDATE_ACCOMMODATION_FAILURE,
  DELETE_ACCOMMODATION_REQUEST,
  DELETE_ACCOMMODATION_SUCCESS,
  DELETE_ACCOMMODATION_FAILURE,
  MOVE_ACCOMMODATION,
  CLONE_ACCOMMODATIONS_REQUEST,
  CLONE_ACCOMMODATIONS_SUCCESS,
  CLONE_ACCOMMODATIONS_FAILURE,
  SORT_ACCOMMODATIONS_REQUEST,
  SORT_ACCOMMODATIONS_SUCCESS,
  SORT_ACCOMMODATIONS_FAILURE
} from '../actions/accommodations';

export function accommodations(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_ACCOMMODATIONS_REQUEST:
    case CLONE_ACCOMMODATIONS_REQUEST:
    case SORT_ACCOMMODATIONS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_ACCOMMODATIONS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case MOVE_ACCOMMODATION: {
      const { source, target } = payload;
      const data = moveBySplice(source, target, state.data);

      return { ...state, data };
    }

    case CLONE_ACCOMMODATIONS_SUCCESS:
    case SORT_ACCOMMODATIONS_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_ACCOMMODATIONS_FAILURE:
    case CLONE_ACCOMMODATIONS_FAILURE:
    case SORT_ACCOMMODATIONS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function accommodation(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_ACCOMMODATION_REQUEST:
    case CREATE_ACCOMMODATION_REQUEST:
    case UPDATE_ACCOMMODATION_REQUEST:
    case DELETE_ACCOMMODATION_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_ACCOMMODATION_SUCCESS:
    case CREATE_ACCOMMODATION_SUCCESS:
    case UPDATE_ACCOMMODATION_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_ACCOMMODATION_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_ACCOMMODATION_FAILURE:
    case CREATE_ACCOMMODATION_FAILURE:
    case UPDATE_ACCOMMODATION_FAILURE:
    case DELETE_ACCOMMODATION_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
