import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import values from 'lodash/values';
import moment from 'moment';
import * as sharedActions from '../../actions/shared';
import SearchBar from '../../components/common/SearchBar';
import More from '../../components/common/More';

import Layout from '../../layout';
import Table from '../../components/common/Table';
import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
  TabPill
} from '../../components/common/Tabs';

const propTypes = {
  shared: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchShared: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const tabs = ['current', 'archived'];
const columns = [
  {
    id: 'title',
    Header: 'Title',
    accessor: props => props.title
  },
  {
    id: 'shared',
    Header: 'Shared Across...',
    accessor: () => <span />
  },
  {
    id: 'details',
    Header: 'Details Page',
    accessor: props => (props.details ? 'Yes' : '--'),
    width: 100
  },
  {
    id: 'startsOn',
    Header: 'Starts On',
    accessor: props => moment(props.startDate).format('MM-DD-YYYY'),
    width: 100
  },
  {
    id: 'endsOn',
    Header: 'Ends On',
    accessor: props =>
      props.endDate ? moment(props.endDate).format('MM-DD-YYYY') : '--',
    width: 100
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <span className="capitalize">{status}</span>
  },
  {
    id: 'more',
    Header: () => <span />,
    accessor: () => <More id="more" />,
    width: 40
  },
];

const SharedSpecials = ({
  fetchShared,
  shared,
  isLoading,
  location: { search },
  history,
  match: { url }
}) => {
  useEffect(() => {
    fetchShared();
    return () => {};
  }, []);
  const query = qs.parse(search.slice(1));
  const selected = query.list === 'archived' ? 1 : 0;
  const specials = values(shared.specials);
  const currentSpecials = specials.filter(special => !special.archived);
  const archivedSpecials = specials.filter(special => special.archived);
  const [searchInput, setSearchInput] = useState('');
  return (
    <Layout metaTitle="Shared Specials">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <h1 className="mb-8">Shared Specials</h1>
          <div className="mb-12">
            Use this page to create, manage, and distribute Specials across
            multiple hotels.
          </div>
          <div className="flex justify-between mb-8">
            <SearchBar
              id="shared-specials-search"
              placeholder="Filter Shared Specials..."
              value={searchInput}
              onChange={({ value }) => setSearchInput(value)}
            />
            <div className="flex flex-1 justify-end">
              {/* FIXME what type of button is this? */}
              {/* eslint-disable-next-line react/button-has-type */}
              <button className="btn btn-clear mr-2">
                Clone a Shared Special
              </button>
              <Link
                to="/hotels/shared/specials/new"
                className="btn inline-block no-underline"
              >
                + New Shared Special
              </Link>
            </div>
          </div>

          <Tabs
            selectedIndex={selected}
            onSelect={index => {
              const newQuery = { ...query, list: tabs[index] };
              history.push(`${url}?${qs.stringify(newQuery)}`);
            }}
          >
            <TabList>
              <Tab>
                <span className="mr-2">Current</span>
                <TabPill id="current">{currentSpecials.length}</TabPill>
              </Tab>
              <Tab>
                <span className="mr-2">Archived</span>
                <TabPill id="archived">{archivedSpecials.length}</TabPill>
              </Tab>
            </TabList>

            <TabPanel>
              <Table
                id="shared-specials-table"
                columns={columns}
                data={currentSpecials}
              />
            </TabPanel>
            <TabPanel>
              <Table
                id="shared-specials-archived"
                columns={columns}
                data={archivedSpecials}
              />
            </TabPanel>
          </Tabs>
        </div>
      )}
    </Layout>
  );
};

SharedSpecials.propTypes = propTypes;

export default connect(
  state => ({
    shared: state.shared.data,
    isLoading: state.shared.isLoading
  }),
  sharedActions
)(SharedSpecials);
