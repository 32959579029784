import React from 'react';

const Footer = () => (
  <footer className="py-12 flex justify-center bg-black text-grey-darker">
    <div className="font-thin">Powered by</div>{' '}
    <span className="ml-1 uppercase text-grey font-bold">suiteh</span>
  </footer>
);

export default Footer;
