import React from 'react';
import PropTypes from 'prop-types';

/**
 * displayOnlyTime takes the date and displays the time only
 *
 * @param {Date} date (a date object)
 * @returns {String}
 * @api private
 */

const displayOnlyTime = date => {
  // API dates will most likely be in a JSON string format, so we must convert
  // FIXME would a string type check work better?
  const __date = new Date(date);
  let hrs = __date.getHours();
  const min = __date.getMinutes();
  let amPm = 'AM';
  if (hrs >= 12) {
    amPm = 'PM';
    hrs -= 12;
  }
  if (hrs === 0) {
    hrs = 12;
  }
  return `${hrs}:${`0${min}`.slice(-2)} ${amPm}`;
};

/**
 * Label is the date/time + title component
 *
 * @param {String} title
 * @param {Date} start
 * @param {Date} end
 * @param {Boolean} allDay
 * @returns {React}
 * @api private
 */

// eslint-disable-next-line react/prop-types
const Label = ({ title, start, allDay }) => {
  return (
    <div className="event-label text-sm mb-2">
      <div className="event-label__time">
        {!allDay && displayOnlyTime(start)}
        {allDay && 'All Day'}
      </div>
      <span className="event__title">{title}</span>
    </div>
  );
};

/**
 * CalendarEvent Render
 *
 * @param {Object} props
 * @returns {React}
 * @api public
 */
// FIXME remove the viewPublished parameters, it's no longer used. That state is
// controlled directly by the API calls
function CalendarEvent({ event, viewableCalendars, viewPublished }) {
  try {
    if (viewableCalendars.indexOf(event.calendar.id) < 0) {
      return '';
    }
    switch (viewPublished) {
      case 0:
        if (event.published) {
          return '';
        }
        break;
      case 1:
        if (!event.published) {
          return '';
        }
        break;
      default:
        break;
    }
  } catch (err) {
    // FIXME handle error
    console.log(err); // eslint-disable-line
  }

  return (
    <span className={`events__item event ${event.published ? 'event--published' : 'event--unpublished'}`}>
      <Label title={event.title} start={event.start} allDay={event.allDay} />
    </span>
  );
}

CalendarEvent.propTypes = {
  event: PropTypes.object.isRequired,
  viewableCalendars: PropTypes.array,
  viewPublished: PropTypes.number
};

CalendarEvent.defaultProps = {
  viewableCalendars: [],
  viewPublished: 0
};

/**
 * Expose
 */

export default CalendarEvent;
