import omit from 'lodash/omit';

export const divideTags = asset =>
  Object.assign({}, asset, {
    categories: (asset.tags || [])
      .filter(tag => tag.name && tag.name.indexOf('cat#') > -1)
      .map(tag => {
        // Ref, https://app.asana.com/0/1119928407758199/1193283961851332/f
        const mat = tag.name.match(/cat#(.+)/);
        if (!mat) {
          return tag;
        }
        return Object.assign({}, tag, { name: mat[1] });
      }),
    tags: (asset.tags || []).filter(
      tag => tag.name && tag.name.indexOf('cat#') === -1
    )
  });

export const combineTags = asset =>
  omit(
    Object.assign({}, asset, {
      tags: asset.tags.concat(
        asset.categories.map(cat =>
          cat.name.startsWith('cat#')
            ? cat
            : Object.assign({}, cat, { name: `cat#${cat.name}` })
        )
      )
    }),
    'categories'
  );
