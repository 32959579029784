import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const defaultProps = {
  children: null
};

const TabPill = ({ id, children }) => (
  <div id={id} className="react-tabs__tab-pill">
    {children}
  </div>
);

TabPill.propTypes = propTypes;
TabPill.defaultProps = defaultProps;

export default TabPill;
