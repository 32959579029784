import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';
import * as propertyActions from '../../../actions/properties';
import * as specialActions from '../../../actions/specials';
import * as modalActions from '../../../actions/modals';

import Layout from '../../../layout';
import { updateSidebar } from '../../../actions/sidebar';
import { SearchBar } from '../../../components/common/SearchBar/SearchBar';
import {
  Tab,
  Tabs,
  TabList,
  TabPill
} from '../../../components/common/Tabs';
import Table from '../../../components/common/Table';
import More, { MoreMenu, MoreMenuItem } from '../../../components/common/More';
import EditableLink from '../../../components/common/EditableLink';
import Paginate from '../../../components/Paginate';
import {
  inputHandlerFunc,
  fetchHandlerFunc
} from '../../../utils/fetch-helpers';
import { onDrop } from '../../../utils/sort-helpers';

const propTypes = {
  fetchProperty: PropTypes.func.isRequired,
  fetchSpecials: PropTypes.func.isRequired,
  countSpecials: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  moveSpecial: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  sortSpecials: PropTypes.func.isRequired,
  updateSpecial: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hotel: PropTypes.object.isRequired,
  specials: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  authority: PropTypes.object.isRequired,
  meta: PropTypes.object
};

const defaultProps = {
  match: {},
  history: {},
  meta: {}
};

const condfn = val => {
  const cond = { order_by: 'sort_index:ASC', per_page: 100 };
  if (val) {
    cond.title = `i%%${val}`;
  }

  return cond;
};

const Specials = ({
  hotel,
  specials,
  meta,
  isLoading,
  match: {
    url,
    params: { hotelid }
  },
  history: { push },
  fetchProperty,
  fetchSpecials,
  countSpecials,
  updateSidebar, // eslint-disable-line no-shadow
  moveSpecial,
  updateProperty,
  sortSpecials,
  updateSpecial,
  showModal,
  authority
}) => {
  const [refresh, setRefresh] = useState(0);
  const [activeCount, setActivCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [selected, setSelected] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const tabsconf = [
    {
      qry: { archived: false },
      cb: setActivCount
    },
    {
      qry: { archived: true },
      cb: setArchivedCount
    },
  ];

  const fetchfn = fetchHandlerFunc(
    fetchSpecials,
    countSpecials,
    tabsconf,
    hotelid
  );

  const searchHandler = inputHandlerFunc(
    setSearchInput,
    useCallback(debounce(fetchfn, 500), []),
    condfn
  );

  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    return () => {};
  }, []);

  useEffect(() => {
    fetchfn(selected, condfn(searchInput));
    return () => {};
  }, [selected, refresh]);

  const columns = [
    {
      id: 'title',
      Header: 'Title',
      accessor: data => <Link to={`${url}/${data.id}/edit`}>{data.title}</Link>
    },
    {
      id: 'live',
      Header: '',
      accessor: data =>
        (data.doesNotEnd || moment(data.endsOn) > moment()) &&
        moment(data.startsOn) < moment() &&
        data.published ? (
          <span className="bg-teal text-white px-5 py-1 text-xs rounded-full">
            LIVE
          </span>
        ) : (
          ''
        ),
      width: 120
    },
    {
      id: 'detailsPage',
      Header: 'Details Page',
      accessor: data => (get(data, 'details.content') ? 'Yes' : '--'),
      width: 120
    },
    {
      id: 'startsOn',
      Header: 'Starts On',
      accessor: data => moment(data.startsOn).format('MM-DD-YYYY'),
      width: 120
    },
    {
      id: 'endsOn',
      Header: 'Ends On',
      accessor: data =>
        data.endsOn && !data.doesNotEnd
          ? moment(data.endsOn).format('MM-DD-YYYY')
          : '--',
      width: 120
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: data => (
        <span className="capitalize">
          {data.published ? 'Published' : 'Unpublished'}
        </span>
      ),
      width: 120
    },
    {
      id: 'more',
      Header: () => <span />,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-archive`}
              onClick={() =>
                updateSpecial(
                  hotelid,
                  data.id,
                  Object.assign(
                    {
                      id: data.id,
                      archived: !data.archived
                    },
                    !data.archived && { published: false }
                  )
                ).then(setRefresh(refresh + 1))}
            >
              {data.archived ? 'Unarchive' : 'Archive'}
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-clone`}
              onClick={() => push(`${url}/clone?selected=${data.id}`)}
            >
              Clone
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={() =>
                showModal(
                  'DeleteSpecialModal',
                  {
                    hotelId: hotelid,
                    id: data.id,
                    title: data.title
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )}
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      ),
      width: 40
    },
  ];

  const specialsURL = get(hotel, 'previewUrls.specialsUrl');

  return (
    <Layout metaTitle="Specials">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <h1 className="mb-6">Specials</h1>
          <div className="mb-6">
            Use this page to create and manage Specials on {hotel.name}.
          </div>
          <div className="mb-12 text-sm flex items-center">
            View Live Specials Page:
            <EditableLink
              id="hotel-special-link"
              className="ml-3"
              link={specialsURL}
              target="_blank"
              onSubmit={data =>
                updateProperty(hotelid, {
                  previewUrls: { ...hotel.previewUrls, specialsUrl: data.link }
                })}
              isEditable={authority.isSuper()}
            />
          </div>

          <div className="flex flex-col flex-col-reverse md:flex-row mb-3 sm:mb-6 md:mb-12">
            <SearchBar
              id="specials-list-search"
              className="flex-1 sm:mr-2 lg:mt-1"
              value={searchInput}
              placeholder="Filter Specials..."
              onChange={({ value }) => searchHandler(value, selected)}
              onClick={() => {}}
            />
            <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
              <Link
                to={`${url}/clone`}
                className="btn btn-clear no-underline mb-3 sm:mb-0 sm:mr-3"
              >
                Clone Special
              </Link>
              <Link to={`${url}/new`} className="btn no-underline mb-6 sm:mb-0">
                + New Special
              </Link>
            </div>
          </div>

          <Tabs selectedIndex={selected} onSelect={i => setSelected(i)}>
            <TabList>
              <Tab>
                <span className="mr-2">Active</span>
                <TabPill id="active-pill">{activeCount}</TabPill>
              </Tab>
              <Tab>
                <span className="mr-2">Archived</span>
                <TabPill id="archived-pill">{archivedCount}</TabPill>
              </Tab>
              <Paginate
                meta={meta}
                fetchHandler={(_, link) => fetchSpecials(null, null, link)}
              />
            </TabList>

            <Table
              id="specials"
              columns={columns}
              data={specials}
              moveRow={(drag, hover) => moveSpecial(drag, hover)}
              onDrop={(evt, moved) => {
                if (moved) {
                  onDrop(evt, specials, sortSpecials.bind(null, hotelid), {
                    archived: selected ? true : false // eslint-disable-line no-unneeded-ternary
                  });
                }
              }}
              isDraggable
            />
          </Tabs>
          <Paginate
            meta={meta}
            fetchHandler={(_, link) => fetchSpecials(null, null, link)}
          />
        </div>
      )}
    </Layout>
  );
};

Specials.propTypes = propTypes;
Specials.defaultProps = defaultProps;

export default connect(
  state => ({
    hotel: state.property.data,
    specials: state.specials.data,
    meta: state.specials.meta,
    authority: state.auth.authority,
    isLoading: state.property.isLoading || state.specials.isLoading
  }),
  {
    ...propertyActions,
    ...modalActions,
    ...specialActions,
    updateSidebar
  }
)(Specials);
