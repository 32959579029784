/**
 * moveBySplice moves the src to trg in a given array returning a new array
 *
 * @param {Object} src
 * @param {Object} trg
 * @param {Array} stateData
 * @returns {Array}
 * @api public
 */

const moveBySplice = (src, trg, stateData) => {
  const data = [...stateData];

  const spliced = data.splice(src.index, 1);
  data.splice(trg.index, 0, spliced[0]);

  return data;
};

export { moveBySplice };
