import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import classnames from 'classnames';
import get from 'lodash/get';
import FormDropdown from '../common/form/Dropdown';
import Input from '../common/Input';
import FormDatePicker from '../common/form/DatePicker';
import TinyMCE from '../common/form/TinyMCE';
import * as modalActions from '../../actions/modals';
import * as imageActions from '../../actions/images';
import * as assetActions from '../../actions/assets';
import FormSection from '../common/FormSection';
import Switch from '../common/form/Switch';
import FormErrorBox from '../common/FormErrorBox';
import repeatOptions from '../../utils/repeat-options';
import ctaTitleOptions from '../../utils/cta-title-options';
import { daysFromOne, daysData } from '../../utils/days-options';
import { capitalize } from '../../utils/string';
import urlNs from '../../utils/url-property-namespace';
import SetAnAsset from '../SetAnAsset';

const propTypes = {
  id: PropTypes.string.isRequired,
  hotel: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  firstImage: PropTypes.object.isRequired,
  calendars: PropTypes.array.isRequired,
  title: PropTypes.string,
  repeatsOn: PropTypes.array,
  eventEndsOption: PropTypes.string,
  repeatsEndsOn: PropTypes.string,
  repeatsTimes: PropTypes.string,
  eventCreation: PropTypes.string,
  detailsDatePicker: PropTypes.bool,
  detailTitle: PropTypes.string,
  published: PropTypes.bool,
  error: PropTypes.string,
  calendarEventId: PropTypes.number.isRequired,
  profilePermissions: PropTypes.array.isRequired,
  change: PropTypes.func.isRequired
};

const defaultProps = {
  title: '',
  repeatsOn: [],
  eventEndsOption: '',
  repeatsEndsOn: '',
  repeatsTimes: '',
  eventCreation: '',
  detailsDatePicker: false,
  detailTitle: '',
  published: false,
  error: undefined
};

const validate = values => {
  const errors = { cta: {}, details: {} };

  if (!values.title) {
    errors.title = 'Required!';
  }

  if (values.customTime && values.customTime.length > 30) {
    errors.customTime = 'Character count exceeded 30!';
  }

  if (!values.start) {
    errors.start = 'Required!';
  }

  if (!values.end) {
    errors.end = 'Required!';
  }

  if (get(values, 'cta.type') !== 'No Detail Page') {
    if (!get(values, 'cta.linkText')) {
      errors.cta.linkText = 'Required!';
    }
  }

  if (
    get(values, 'cta.type') === 'External Site' &&
    !get(values, 'cta.linkUrl')
  ) {
    errors.cta.linkUrl = 'Required!';
  }

  if (values['hotel-calendar-image']) {
    errors['hotel-calendar-image'] = errors['hotel-calendar-image'] || {};

    const image = values['hotel-calendar-image'];
    if (!image.asset_id) {
      errors['hotel-calendar-image'].asset_id = 'Image is required';
    }
    if (!image.alt) {
      errors['hotel-calendar-image'].alt = 'Image Alt Tag is required';
    }
  }

  return errors;
};

const eventCreationData = [
  'No Detail Page',
  'Internal Detail Page',
  'External Site'
].map(item => ({ id: item, name: item }));

const condfn = () => {
  return {
    filetype: 'image',
    category: 'cat#Calendars'
  };
};

const HotelCalendarForm = ({
  // FIXME id conflicts with what should be the calendar event id. But is the
  // css dom ID #new-calendar
  id,
  hotel,
  handleSubmit,
  showModal,
  firstImage,
  calendars,
  calendarEventId,
  title,
  repeatsOn,
  eventEndsOption,
  repeatsEndsOn,
  repeatsTimes,
  detailsDatePicker,
  detailTitle,
  eventCreation,
  published,
  error,
  profilePermissions,
  change
}) => {
  const canUploadAsset = () => {
    return (
      profilePermissions.findIndex(perm => {
        return (
          perm.resource === '*' &&
          perm.permissionableId === null &&
          perm.permissionableType === null &&
          perm.r &&
          perm.w &&
          perm.x
        );
      }) > -1
    );
  };

  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="title"
        title="Title & Content"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Select which Calendar from the dropdown to place this Event,
                then enter a title for it.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Select a Calendar*</div>
          <Field
            id="calendarId"
            name="calendarId"
            style={{ width: 300 }}
            className="mb-6"
            component={FormDropdown}
            data={calendars}
          />
          <div className="mb-3">Event Title*</div>
          <Field
            id="event-title"
            name="title"
            className="w-full mb-6"
            placeholder="Enter a title for this Event..."
            component={Input}
          />
          <div className="mb-3">Short Description* (Less than 50 words)</div>
          <Field
            id="event-short-description"
            name="shortDescription"
            className="w-full mb-6"
            placeholder="Enter a short description for this Event..."
            component={TinyMCE}
          />
        </div>
      </FormSection>
      <FormSection
        id="date"
        title="Date and Time"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>Set a Start Date and End Date for this Event.</i>
            </p>
            <p className="mb-3">
              <i>
                If the Event Repeats, select the repeat frequency from the
                dropdown. Then follow the prompts to set when the repeat
                frequency will end.
              </i>
            </p>
            <p className="mb-3">
              <i>
                For multi-day events, please select "Repeats Daily" + "After ...
                occurrences" for optimal display on website.
              </i>
            </p>
            <p className="mb-3">
              <i>
                For Events that end past Midnight (12:00 AM), please set the
                TIME of your END DATE to be 11:45 PM on the same day that your
                event starts. THEN please use the CUSTOM TIME field to enter
                your event's time span in the following format: 8:00 PM to 2:30
                AM.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="flex flex-col sm:flex-row">
            <div className="mr-3">
              <div className="mb-3">Start Date*</div>
              <Field
                id="start-date"
                name="start"
                component={FormDatePicker}
                className="mb-6"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy h:mm aa"
                timeCaption="time"
                autoComplete="off"
              />
            </div>
            <div className="mr-3">
              <div className="mb-3">End Date*</div>
              <Field
                id="end-date"
                name="end"
                component={FormDatePicker}
                className="mb-6"
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy h:mm aa"
                timeCaption="time"
                autoComplete="off"
              />
            </div>
          </div>
          <div className="flex items-center mb-12">
            <Field
              id="all-day"
              type="checkbox"
              name="allDay"
              className="mr-3"
              component={Input}
            />
            <div className="">All Day Event</div>
          </div>

          <div className="mb-3">
            Custom Time (For Display Only - Max 30 characters. Ex. 10:00 PM to
            2:00 AM)
          </div>
          <Field
            id="event-custom-time"
            name="customTime"
            className="w-full mb-6"
            placeholder="Enter a Custom Time only if it needs to be different from the default display..."
            component={Input}
          />
          <div className="mb-4">
            <div className="my-3">Event Repeats...</div>
            <Field
              id="repeatOption"
              name="repeatOption"
              className=""
              onChange={val => {
                showModal('CalendarEventRepeatModal', {
                  form: 'HotelCalendarForm',
                  field: 'repeatOption',
                  hotelId: hotel.id,
                  repeatOption: val,
                  repeatsOn,
                  eventEndsOption,
                  repeatsEndsOn,
                  repeatsTimes
                });
              }}
              component={FormDropdown}
              data={repeatOptions}
            />
          </div>
          {repeatsOn && !!repeatsOn.length && (
            <div className="flex items-center mb-4">
              <div className="mr-3">Repeats on: </div>
              <div className="font-bold">
                {repeatsOn.map(v => capitalize(v)).join(', ')}
              </div>
            </div>
          )}

          {eventEndsOption === 'On Date ...' && (
            <div className="flex items-center mb-4">
              <div className="mr-3">Repeats ends on: </div>
              <div className="font-bold">
                {JSON.stringify(repeatsEndsOn).substr(1, 10)}
              </div>
            </div>
          )}

          {eventEndsOption === 'After ... occurances' && (
            <div className="flex items-center mb-4">
              <div className="mr-3">Repeats ends after: </div>
              <div className="font-bold mr-3">{repeatsTimes} </div>
              <div> occurances</div>
            </div>
          )}
          <div className="flex items-center mb-4">
            <div className="font-bold">
              {eventEndsOption === 'Repeats Forever' && 'Repeats Forever'}
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection
        id="image"
        title="Set an Image"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Select an image to use for this Event. If you don't see the
                image you are looking for,&nbsp;
                <a
                  href="http://tickets.still-water.com/tickets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  create a ticket here.
                </a>
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <SetAnAsset
            change={change}
            condfn={condfn}
            canUploadAsset={canUploadAsset}
            categories={[{ name: 'Calendars' }]}
            name="hotel-calendar-image"
            id="hotel-calendar-upload"
          />
        </div>
      </FormSection>
      <FormSection
        id="cta"
        title="CTA (Call to Action)"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>Select the landing page for this Event's CTA.</i>
            </p>
            <p className="mb-3">
              <i>
                For an Internal Detail Page, you'll need to enter additional
                details like page content, page image, and CTA link in the next
                form section.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Event Creation Type*</div>
          <Field
            id="cta.type"
            name="cta.type"
            data={eventCreationData}
            component={FormDropdown}
            className="mb-6"
            style={{ width: 300 }}
          />
          {eventCreation !== 'No Detail Page' && (
            <Fragment>
              <div className="mb-3">Calendar CTA Link Text*</div>
              <Field
                id="cta.linkText"
                name="cta.linkText"
                className="w-full mb-6"
                placeholder="e.g. Buy Tickets, Purchase Now, etc."
                component={Input}
              />
            </Fragment>
          )}
          {eventCreation === 'External Site' && (
            <Fragment>
              <div className="mb-3">Calendar CTA Link URL*</div>
              <Field
                id="cta.linkUrl"
                name="cta.linkUrl"
                className="w-full mb-3"
                placeholder="http://www.example.com"
                component={Input}
              />
              <div className="text-xs font-light mb-3">
                Please include <span className="font-bold">http://</span> for
                the URL (CTA Link will automatically open in a new window)
              </div>
            </Fragment>
          )}
          {eventCreation !== 'No Detail Page' && (
            <Fragment>
              <div className="mb-3">Calendar CTA Adobe Tracking Tag</div>
              <Field
                id="cta.trackingTag"
                name="cta.trackingTag"
                className="w-full mb-3"
                placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
                component={Input}
              />
              <div className="flex justify-left">
                <button
                  type="button"
                  className="btn btn-clear px-6 mr-4"
                  onClick={e => {
                    e.preventDefault();
                    showModal('GenerateTrackingModal', {
                      form: 'HotelCalendarForm',
                      field: 'cta.trackingTag'
                    });
                  }}
                >
                  Generate Tracking
                </button>
              </div>
            </Fragment>
          )}
        </div>
      </FormSection>
      {eventCreation === 'Internal Detail Page' && (
        <FormSection
          id="details"
          title="Internal Detail Page"
          className="border-t flex flex-col flex-wrap"
        >
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>Enter the page content for this Details page.</i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="flex-1">
              <div className="mb-3">Page Content:</div>
              <Field
                id="details.content"
                name="details.content"
                className="mb-6"
                placeholder="Enter the content for this Detail Page..."
                component={TinyMCE}
              />
            </div>
          </div>
          <div className="flex w-full flex-col flex-wrap xl:flex-row mt-5 pt-5 border-t border-dashed border-grey-light">
            <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
              <div className="font-light mb-3 text-base leading-normal">
                <h2 className="text-xl font-normal mb-3">Details Page Image</h2>
                <p>
                  <i>
                    Select an image to use for this Details Page. If you don't
                    see the image you are looking for,&nbsp;
                    <a
                      href="http://tickets.still-water.com/tickets"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      create a ticket here.
                    </a>
                  </i>
                </p>
              </div>
            </div>
            <div className="flex-1 xl:flex-initial xl:w-2/3">
              <SetAnAsset
                change={change}
                condfn={condfn}
                canUploadAsset={canUploadAsset}
                categories={[{ name: 'Calendars' }]}
                name="calendar-details-image"
                id="calendar-details-upload"
                header="Select an image for this Details Page"
                onlySingle
              />
            </div>
          </div>
          <div className="flex w-full flex-col flex-wrap xl:flex-row mt-5 pt-5 border-t border-dashed border-grey-light">
            <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
              <div className="font-light mb-3 text-base leading-normal">
                <h2 className="text-xl font-normal mb-3">Details Page CTA</h2>
                <p>
                  <i>
                    Select a CTA to use for this Details Page. To use a custom
                    CTA, select 'Other' from the dropdown and enter the text to
                    use.
                  </i>
                </p>
              </div>
            </div>
            <div className="flex-1 xl:flex-initial xl:w-2/3">
              <div className="mb-3">CTA Link Text</div>
              <Field
                id="details.cta.type"
                name="details.cta.type"
                className="mb-6"
                data={ctaTitleOptions}
                style={{ width: 256 }}
                component={FormDropdown}
              />
              {detailTitle === 'Other' && (
                <Field
                  id="details.cta.titleInput"
                  name="details.cta.titleInput"
                  className="mb-6 -mt-3"
                  component={Input}
                  placeholder="Enter custom title for CTA"
                />
              )}
              {detailTitle !== 'None' && (
                <Fragment>
                  <div
                    className={classnames('text-base font-normal mb-3', {
                      'text-grey': detailTitle === 'None'
                    })}
                  >
                    CTA Link URL
                  </div>
                  <div className="flex flex-col mb-6">
                    <Field
                      id="details.cta.linkUrl"
                      name="details.cta.linkUrl"
                      className="flex-1 mr-2 mb-3"
                      component={Input}
                      disabled={detailTitle === 'None'}
                    />
                    <div className="flex justify-left">
                      {(detailTitle === 'Check Availability' ||
                        detailTitle === 'Other') && (
                        <button
                          type="button"
                          className="btn btn-clear px-6 mr-4"
                          onClick={e => {
                            e.preventDefault();
                            showModal('GenerateURLModal', {
                              form: 'HotelCalendarForm',
                              field: 'details.cta.linkUrl',
                              hotelId: hotel.id
                            });
                          }}
                        >
                          Generate URL
                        </button>
                      )}
                      {(detailTitle === 'Inquire Now' ||
                        detailTitle === 'Other') && (
                        <button
                          type="button"
                          className="btn btn-clear px-6"
                          onClick={e => {
                            e.preventDefault();
                            showModal('GenerateEmailURLModal', {
                              form: 'HotelCalendarForm',
                              field: 'details.cta.linkUrl',
                              hotelId: hotel.id
                            });
                          }}
                        >
                          Generate Email URL
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="flex mb-6 items-center">
                    <Field
                      id="details.cta.opensInNewWindow"
                      name="details.cta.opensInNewWindow"
                      className="mr-2"
                      type="checkbox"
                      component={Input}
                    />
                    <div className="text-base font-normal">
                      Opens in a New Window
                    </div>
                  </div>
                  {detailTitle !== 'Learn More' &&
                    detailTitle !== 'Inquire Now' && (
                      <Fragment>
                        <div className="flex mb-6 items-center">
                          <Field
                            id="details.cta.addDatePickerPopup"
                            name="details.cta.addDatePickerPopup"
                            type="checkbox"
                            className="mr-2"
                            component={Input}
                          />
                          <div className="text-base font-normal">
                            Add Date Picker Popup
                          </div>
                        </div>
                        <div className="flex mb-6 items-center">
                          <Field
                            id="details.cta.advancedPurchaseDays"
                            name="details.cta.advancedPurchaseDays"
                            className="mr-2"
                            component={FormDropdown}
                            data={daysData}
                            disabled={!detailsDatePicker}
                          />
                          <div
                            className={classnames('text-base font-normal', {
                              'text-grey': !detailsDatePicker
                            })}
                          >
                            Advance Purchase Days
                          </div>
                        </div>
                        <div className="flex mb-12 items-center">
                          <Field
                            id="details.cta.minimumNightStay"
                            name="details.cta.minimumNightStay"
                            className="mr-2"
                            component={FormDropdown}
                            data={daysFromOne}
                            disabled={!detailsDatePicker}
                          />
                          <div
                            className={classnames('text-base font-normal', {
                              'text-grey': !detailsDatePicker
                            })}
                          >
                            Minimum Night Stay
                          </div>
                        </div>
                      </Fragment>
                    )}
                  <div className="text-base font-normal mb-3">
                    Adobe Tracking Tag
                  </div>
                  <Field
                    id="details.cta.trackingTag"
                    name="details.cta.trackingTag"
                    className="w-full mb-4"
                    placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
                    component={Input}
                  />
                  <div className="flex justify-left">
                    <button
                      type="button"
                      className="btn btn-clear px-6 mr-4"
                      onClick={e => {
                        e.preventDefault();
                        showModal('GenerateTrackingModal', {
                          form: 'HotelCalendarForm',
                          field: 'details.cta.trackingTag'
                        });
                      }}
                    >
                      Generate Tracking
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </FormSection>
      )}
      <FormSection
        id="preview"
        title="Preview & Publish"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex w-full flex-col flex-wrap xl:flex-row">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  Here is a preview of your Event. When you're ready, toggle the
                  publish status to display it on the live site and hit 'Save'.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="mb-6">Preview:</div>
            <div className="flex flex-col">
              <div className="flex-1 mb-6">
                <div className="mb-3">Grid view</div>
                <div
                  className="bg-grey-light border border-grey"
                  style={{ width: 200 }}
                >
                  {firstImage.file && (
                    <img
                      alt={firstImage.alt}
                      src={get(firstImage, 'file.url')}
                      className="w-full"
                    />
                  )}
                  <div className="m-6">
                    <div className="font-semibold mb-2 text-xl">{title}</div>
                  </div>
                </div>
              </div>
              <div className="flex-1 mb-6">
                <div className="mb-2">List View</div>
                <div
                  className="bg-grey-light border border-grey flex"
                  style={{ width: 600 }}
                >
                  {firstImage.file && (
                    <img
                      alt={firstImage.alt}
                      src={get(firstImage, 'file.url')}
                      style={{ maxWidth: 200 }}
                    />
                  )}
                  <div className="m-6">
                    <div className="font-semibold mb-2 text-xl">{title}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6">
              <div className="text-base font-normal mb-3">Publish Status:</div>
              <Field
                id="published"
                name="published"
                component={Switch}
                label={published ? 'Published' : 'Unpublished'}
                className="mb-6"
              />
            </div>
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button
          type="submit"
          className="btn btn-clear mr-3"
          onClick={e => {
            e.preventDefault();
            showModal('DeleteClanedarEventModal', {
              hotelId: hotel.id,
              id: calendarEventId,
              title,
              redirectUrl: `/${urlNs(hotel)}/${hotel.id}/calendars`
            });
          }}
        >
          Delete
        </button>
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && <FormErrorBox id="hotel-calendar-error">{error}</FormErrorBox>}
    </form>
  );
};

HotelCalendarForm.propTypes = propTypes;
HotelCalendarForm.defaultProps = defaultProps;

const selector = formValueSelector('HotelCalendarForm');

export default compose(
  connect(
    state => ({
      calendarEventId: selector(state, 'id'),
      title: selector(state, 'title'),
      eventCreation: selector(state, 'cta.type'),
      repeatsOn: selector(state, 'repeatsOn'),
      eventEndsOption: selector(state, 'eventEndsOption'),
      repeatsEndsOn: selector(state, 'repeatsEndsOn'),
      repeatsTimes: selector(state, 'repeatsTimes'),
      detailsDatePicker: selector(state, 'details.cta.addDatePickerPopup'),
      detailTitle: selector(state, 'details.cta.type'),
      published: selector(state, 'published'),
      firstImage: get(state, 'images.hotel-calendar-image.items.0', {}),
      profilePermissions: state.auth.permissions
    }),
    { ...modalActions, ...imageActions, ...assetActions }
  ),
  reduxForm({ form: 'HotelCalendarForm', validate })
)(HotelCalendarForm);
