import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import uuidV4 from 'uuid/v4';
import { connect } from 'react-redux';
import Layout from '../../layout';
import * as sharedActions from '../../actions/shared';
import SharedSpecialsForm from '../../components/SharedSpecialsForm';

const propTypes = {
  history: PropTypes.object.isRequired,
  addSharedSpecial: PropTypes.func.isRequired
};

const SpecialsNew = ({ history: { push }, addSharedSpecial }) => {
  const handleSubmit = data => {
    const id = uuidV4();
    return addSharedSpecial({ id, ...data }).then(() =>
      push('/hotels/shared/specials')
    );
  };

  return (
    <Layout metaTitle="New Shared Specials">
      <div className="w-full max-w-3xl">
        <h1 className="mb-6">New Shared Specials</h1>
        <div className="mb-10">
          Use this page to create, manage, and distribute Shared Specials across
          multiple properties.
        </div>
        <Link to="/hotels/shared/specials" className="inline-block mb-10">
          &lt; Back to Shared Specials
        </Link>
        <SharedSpecialsForm
          id="new-shared-specials"
          initialValues={{
            eventEnds: true,
            ctaOption: 'bookNow',
            newWindow: true,
            datePicker: true,
            purchaseDays: '3',
            minNightStay: true,
            minNightDays: '3',
            promoFlag: 'websiteExclusive',
            detailsDatePicker: true,
            detailsPurchaseDays: '3',
            detailsMinNightStay: true,
            detailsMinDays: '3',
            detailTitle: 'learnMore'
          }}
          onSubmit={handleSubmit}
        />
      </div>
    </Layout>
  );
};

SpecialsNew.propTypes = propTypes;

export default connect(
  null,
  sharedActions
)(SpecialsNew);
