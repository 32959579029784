import React from 'react';
import PropTypes from 'prop-types';
import { HashLink } from 'react-router-hash-link';
import { withRouter } from 'react-router-dom';
import classnames from 'classnames';

const propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  match: PropTypes.object,
  location: PropTypes.object,
  sections: PropTypes.array
};

const defaultProps = {
  className: '',
  match: {},
  location: {},
  sections: []
};

const FormNavigator = ({
  id,
  match: { url },
  className,
  location: { hash },
  sections
}) => (
  <div id={id} className={classnames(className)}>
    <div className="w-48 formnavigator" style={{ minHeight: '16rem' }}>
      <div className="fixed bg-grey-lighter p-6 w-48">
        <div className="uppercase my-4 text-grey tracking-wide">Sections</div>
        {sections.map(section => (
          <div key={section.id} className="mb-4">
            <HashLink
              to={`${url}#${section.id}-anchor`}
              className={classnames(
                'no-underline text-grey-dark hover:text-black text-sm',
                {
                  'text-black': hash === `#${section.id}`
                }
              )}
              disabled={hash === `#${section.id}`}
            >
              {section.title}
            </HashLink>
          </div>
        ))}
      </div>
    </div>
  </div>
);

FormNavigator.propTypes = propTypes;
FormNavigator.defaultProps = defaultProps;

export default withRouter(FormNavigator);
