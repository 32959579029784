import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import Layout from '../../../layout';
import * as sidebarActions from '../../../actions/sidebar';
import * as propertyActions from '../../../actions/properties';
import * as pressActions from '../../../actions/presses';
import RestaurantPressForm from '../../../components/RestaurantPressForm/RestaurantPressForm';
import FormNavigator from '../../../components/FormNavigator';

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'date', title: 'Publication Date' },
  { id: 'upload', title: 'Upload a PDF' },
  { id: 'preview', title: 'Preview + Publish' },
];

class New extends Component {
  componentDidMount() {
    const {
      updateSidebar,
      fetchProperty,
      match: {
        params: { restaurantId }
      }
    } = this.props;
    fetchProperty(restaurantId).then(restaurant => {
      updateSidebar({ titles: { Restaurant: restaurant.name } });
    });
  }

  handleSubmit = data => {
    const {
      createPress,
      match: {
        params: { restaurantId }
      },
      history: { push }
    } = this.props;

    /* eslint-disable no-param-reassign */
    let { pdf } = data;
    pdf = pdf && pdf.id ? { assetId: pdf.id } : null;

    const press = {
      ...data,
      pdf,
      publishDate: data.publishDate.toJSON() // FIXME do you need to toJSON?
    };

    return createPress(restaurantId, press)
      .then(() => push(`/restaurants/${restaurantId}/press`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  render() {
    const {
      restaurant,
      match: {
        params: { restaurantId }
      }
    } = this.props;
    return (
      <Layout metaTitle="Press">
        <div className="w-full max-w-3xl">
          <Link
            to={`/restaurants/${restaurantId}/press`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Press
          </Link>
          <h1 className="mb-6">New Press</h1>
          <div className="mb-16">
            Use this page to create Press for {restaurant.name}.
          </div>
          <RestaurantPressForm
            id="new-press-form"
            restaurant={restaurant}
            initialValues={{
              doesNotEnd: true,
              archived: false
            }}
            onSubmit={this.handleSubmit}
          />
        </div>
        <FormNavigator
          id="new-press-navigator"
          sections={sections}
          className="hidden xl:block mt-32 pt-16"
        />
      </Layout>
    );
  }
}

New.propTypes = {
  updateSidebar: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  createPress: PropTypes.func.isRequired,
  restaurant: PropTypes.object,
  match: PropTypes.object,
  history: PropTypes.object
};

New.defaultProps = {
  restaurant: {},
  match: {},
  history: {}
};

export default connect(
  (state) => ({
    restaurant: state.property.data,
    isLoading: state.property.isLoading
  }),
  {
    ...sidebarActions,
    ...propertyActions,
    ...pressActions
  }
)(New);
