import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isPromise from '../../utils/is-promise';
import * as modalActions from '../../actions/modals';
import Modal from '../common/Modal';
import * as allModals from '../Modals';

const propTypes = {
  modals: PropTypes.object.isRequired,
  hideModal: PropTypes.func.isRequired
};

const ModalPortal = ({
  modals: { name, contentLabel, styles, data, onCloseHandler = () => {} },
  hideModal
}) => {
  const CurrentModal = allModals[name];
  return (
    <Modal
      isOpen={!!name}
      contentLabel={contentLabel}
      onRequestClose={() => {
        hideModal().then(() => {
          window.scrollTo(0, Math.abs(document.body.style.top.slice(0, -2)));
        });
      }}
      styles={styles}
    >
      {name ? (
        <CurrentModal
          // FIXME there needs to be an onCancel vs onSubmit (though this can
          // pose a problem if there is more than a Cancel vs Submit button om
          // modals). But, this will allow cancelling vs submitting to have
          // different functionality as there could be specific things you want
          // to happen when someone submits vs cancels, as in the case of the
          // DashboardTiles
          onClose={() => {
            const onClose = onCloseHandler();
            if (isPromise(onClose)) {
              return onClose.then(() => hideModal());
            }
            return hideModal();
          }}
          data={data}
        />
      ) : (
        <div />
      )}
    </Modal>
  );
};

ModalPortal.propTypes = propTypes;

export default connect(
  state => ({ modals: state.modals }),
  modalActions
)(ModalPortal);
