import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_HOME_PROMOS_REQUEST = 'FETCH_HOME_PROMOS_REQUEST';
export const FETCH_HOME_PROMOS_SUCCESS = 'FETCH_HOME_PROMOS_SUCCESS';
export const FETCH_HOME_PROMOS_FAILURE = 'FETCH_HOME_PROMOS_FAILURE';

export const FETCH_HOME_PROMO_REQUEST = 'FETCH_HOME_PROMO_REQUEST';
export const FETCH_HOME_PROMO_SUCCESS = 'FETCH_HOME_PROMO_SUCCESS';
export const FETCH_HOME_PROMO_FAILURE = 'FETCH_HOME_PROMO_FAILURE';

export const CREATE_HOME_PROMO_REQUEST = 'CREATE_HOME_PROMO_REQUEST';
export const CREATE_HOME_PROMO_SUCCESS = 'CREATE_HOME_PROMO_SUCCESS';
export const CREATE_HOME_PROMO_FAILURE = 'CREATE_HOME_PROMO_FAILURE';

export const UPDATE_HOME_PROMO_REQUEST = 'UPDATE_HOME_PROMO_REQUEST';
export const UPDATE_HOME_PROMO_SUCCESS = 'UPDATE_HOME_PROMO_SUCCESS';
export const UPDATE_HOME_PROMO_FAILURE = 'UPDATE_HOME_PROMO_FAILURE';

export const DELETE_HOME_PROMO_REQUEST = 'DELETE_HOME_PROMO_REQUEST';
export const DELETE_HOME_PROMO_SUCCESS = 'DELETE_HOME_PROMO_SUCCESS';
export const DELETE_HOME_PROMO_FAILURE = 'DELETE_HOME_PROMO_FAILURE';

export const MOVE_HOME_PROMO = 'MOVE_HOME_PROMO';

export const CLONE_HOME_PROMOS_REQUEST = 'CLONE_HOME_PROMOS_REQUEST';
export const CLONE_HOME_PROMOS_SUCCESS = 'CLONE_HOME_PROMOS_SUCCESS';
export const CLONE_HOME_PROMOS_FAILURE = 'CLONE_HOME_PROMOS_FAILURE';

export const SORT_HOME_PROMOS_REQUEST = 'SORT_HOME_PROMOS_REQUEST';
export const SORT_HOME_PROMOS_SUCCESS = 'SORT_HOME_PROMOS_SUCCESS';
export const SORT_HOME_PROMOS_FAILURE = 'SORT_HOME_PROMOS_FAILURE';

export const fetchHomePromos = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_HOME_PROMOS_REQUEST
  });
  return fetch(
    path || `/properties/${propertyId}/home-promos${fromObj(qry, '?')}`
  )
    .then(response => {
      const homePromos = response.data.map(convertToJS);
      const meta = convertToJS(response.meta);
      dispatch({
        type: FETCH_HOME_PROMOS_SUCCESS,
        payload: homePromos,
        meta
      });
      return { data: homePromos, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_HOME_PROMOS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countHomePromos = (propertyId, params) => dispatch => {
  return fetch(`/properties/${propertyId}/home-promos${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchHomePromo = (propertyId, promoId) => dispatch => {
  dispatch({
    type: FETCH_HOME_PROMO_REQUEST
  });
  return fetch(`/properties/${propertyId}/home-promos/${promoId}`)
    .then(response => {
      const homePromo = convertToJS(response.data);
      dispatch({
        type: FETCH_HOME_PROMO_SUCCESS,
        payload: homePromo
      });
      return homePromo;
    })
    .catch(e => {
      dispatch({
        type: FETCH_HOME_PROMO_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createHomePromo = (propertyId, promo) => dispatch => {
  dispatch({
    type: CREATE_HOME_PROMO_REQUEST
  });
  return fetch(`/properties/${propertyId}/home-promos`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(promo))
  })
    .then(response => {
      const homePromo = convertToJS(response.data);
      dispatch({
        type: CREATE_HOME_PROMO_SUCCESS,
        payload: homePromo
      });
      return homePromo;
    })
    .catch(e => {
      dispatch({
        type: CREATE_HOME_PROMO_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateHomePromo = (propertyId, promoId, update) => dispatch => {
  dispatch({
    type: UPDATE_HOME_PROMO_REQUEST
  });
  return fetch(`/properties/${propertyId}/home-promos/${promoId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const homePromo = convertToJS(response.data);
      dispatch({
        type: UPDATE_HOME_PROMO_SUCCESS,
        payload: homePromo
      });
      return homePromo;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_HOME_PROMO_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteHomePromo = (propertyId, promoId) => dispatch => {
  dispatch({
    type: DELETE_HOME_PROMO_REQUEST
  });
  return fetch(`/properties/${propertyId}/home-promos/${promoId}`, {
    method: 'DELETE'
  })
    .then(response => {
      dispatch({
        type: DELETE_HOME_PROMO_SUCCESS,
        payload: promoId
      });
      return promoId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_HOME_PROMO_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveHomePromo = (source, target) => ({
  type: MOVE_HOME_PROMO,
  payload: { source, target }
});

export const sortHomePromos = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_HOME_PROMOS_REQUEST
  });
  return fetch(`/properties/${propertyId}/home-promos/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_HOME_PROMOS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_HOME_PROMOS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const cloneHomePromos = (propertyId, ids, toPropertyIds) => (
  dispatch
) => {
  dispatch({
    type: CLONE_HOME_PROMOS_REQUEST
  });
  return fetch(`/properties/${propertyId}/home-promos/clone`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ids,
      to_property_ids: toPropertyIds
    })
  })
    .then(response => {
      // const faq = convertToJS(response.data);
      dispatch({
        type: CLONE_HOME_PROMOS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: CLONE_HOME_PROMOS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
