import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const HoursInput = ({ value, onChange, onClose }) => {
  const inputEl = useRef(null);
  useEffect(() => {
    if (value === 'Other') {
      inputEl.current.select();
    }
    return () => {};
  }, []);

  return (
    <div className="w-full xl:flex-1 xl:mr-2">
      <div className="flex">
        <input
          id="hour-type"
          type="text"
          className="Input-restaurant-hours pr-8 pl-2 w-full xl:flex-1"
          ref={inputEl}
          value={value}
          onChange={onChange}
        />
        <div
          role="button"
          tabIndex={0}
          className="bg-grey h-6 w-6 items-center px-2 pt-1 -ml-8 mr-2 my-auto rounded-full text-white z-10"
          onClick={onClose}
        >
          &times;
        </div>
      </div>
    </div>
  );
};

HoursInput.propTypes = propTypes;

export default HoursInput;
