import * as routeTypes from './types';

/**
 * expand routeTypes
 */

const { PRIVATE, PUBLIC, DUAL } = routeTypes;

/**
 * short hand for object interpolation
 */

const exact = true;

/**
 * directories describes the routing/navigation tree for the current site and
 * their 'rel'ationships. This is the path "directory"
 *
 * Schema definition can be found in the ./directories.docs.yaml file.
 */

const directories = [
  {
    title: 'Dashboard',
    path: '/',
    exact,
    route: DUAL,
    // NOTE components is plural for dual routes
    components: [
      [PUBLIC, 'Home'],
      [PRIVATE, 'Dashboard']
    ],
    rel: [
      '/',
      '/hotels',
      '/hotels/properties',
      /^\/hotels\/regions(\/.+)?/,
      '/restaurants',
      /^\/users|media(\/.+)?/,
      '/settings',
      '/profile',
      '/notifications',
      '/tokens'
    ]
  },
  {
    title: 'Sign In',
    path: '/',
    route: PUBLIC,
    rel: ['/forgot-password', '/reset-password/:resetToken']
  },
  {
    title: 'Forgot my Password',
    path: '/forgot-password',
    route: PUBLIC,
    component: 'ForgotPassword',
    rel: '/',
    // FIXME is 'hidden' the right word here? Does it explain what it does
    // properly?
    hidden: true
  },
  {
    title: 'Reset my Password',
    path: '/reset-password/:resetToken',
    route: PUBLIC,
    component: 'ResetPassword',
    rel: '/',
    hidden: true
  },
  {
    title: 'Users',
    path: '/users',
    exact,
    component: 'Users',
    resource: 'user',
    directories: [
      { title: 'New', path: '/new', suffix: 'New' },
      { title: 'Edit', path: '/:id/edit', suffix: 'Edit' }
    ],
    rel: ['/', /^\/users(\/.+)?/]
  },
  {
    title: 'Regions',
    path: '/hotels/regions',
    exact,
    component: 'Regions',
    directories: [
      { title: 'New', path: '/new', suffix: 'New' },
      { title: 'Edit', path: '/:id', suffix: 'Edit' }
    ],
    rel: ['/hotels', '/hotels/properties', /^\/hotels\/regions(\/.+)?/],
    zIndex: 90
  },
  {
    // FIXME rework the pathing here, /alerts is more of a dashbaord and
    // /listings is the actual index page. How can we get this to be more CRUD
    // in regards to naming scheme
    title: 'Alerts',
    path: '/hotels/alerts',
    exact,
    component: 'AlertsAssignments',
    resource: 'alert',
    directories: [
      { title: 'New', path: '/new', exact, component: 'AlertsNew' },
      { title: 'Edit', path: '/:id/edit', exact, component: 'AlertsEdit' },
      {
        title: 'Alert Listings',
        path: '/listings',
        exact,
        component: 'Alerts',
        rel: /^\/hotels\/alerts(\/.+)?/,
        activeRoot: '/hotels/alerts'
      }
    ],
    rel: ['/hotels', '/hotels/properties', /^\/hotels\/regions(\/.+)?/],
    html: {
      displayRightChevron: true
    },
    zIndex: 80
  },
  {
    title: 'Hotels',
    path: '/hotels',
    exact,
    redirect: '/hotels/properties', // FIXME why does this even redirect?
    resource: 'property',
    directories: [
      { title: 'Properties', path: '/properties', component: 'Properties' },
      {
        title: 'New Hotel',
        path: '/new',
        component: 'NewHotel',
        rel: '/hotels/new'
      },
      {
        // NOTE title is a function so we can dynamically change thie back
        // button to be the name of the property
        title: property => property.name,
        path: '/:hotelid',
        exact,
        component: 'HotelDashboard',
        rel: /^\/hotels\/:hotelid(\/.+)?/
      }
    ],
    rel: [
      '/',
      '/hotels',
      '/hotels/properties',
      '/hotels/new',
      '/hotels/:hotelid',
      /^\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/,
      /^\/hotels\/regions|alerts(\/.+)?/
    ],
    html: {
      displayRightChevron: '/'
    },
    zIndex: 100,
    activeRoot: /^\/hotels\/\b(?!regions\b)/
  },
  {
    // NOTE this is purely cosmetic, as when in /hotels/alerts, the navigation
    // is listed as 'Alert Assignments', while in the parent it needs to display
    // as 'Alerts'
    title: 'Alert Assignments',
    path: '/hotels/alerts',
    resource: 'alert',
    rel: /^\/hotels\/alerts(\/.+)?/,
    zIndex: 90
  },
  {
    title: 'Home Promos',
    path: '/hotels/:hotelid/promos',
    exact,
    component: 'HomePromos',
    resource: 'home_promo',
    directories: [
      { title: 'New', path: '/new', suffix: 'New' },
      { title: 'Edit', path: '/:id/edit', suffix: 'Edit' },
      { title: 'Clone', path: '/clone', suffix: 'Clone' }
    ],
    rel: [
      '/hotels/:hotelid',
      /\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/
    ]
  },
  {
    title: 'Specials',
    path: '/hotels/:hotelid/specials',
    exact,
    component: 'Specials',
    resource: 'special',
    directories: [
      { title: 'New', path: '/new', component: 'SpecialsNew' },
      { title: 'Edit', path: '/:id/edit', component: 'SpecialsEdit' },
      { title: 'Clone', path: '/clone', component: 'SpecialsClone' }
    ],
    rel: [
      '/hotels/:hotelid',
      /\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/
    ]
  },
  {
    title: 'Tiled Promos',
    path: '/hotels/:hotelid/tiled',
    exact,
    redirect: '/hotels/:hotelid/tiled/meetings',
    resource: 'tiled_promo',
    directories: [
      { title: 'Clone', path: '/:type/clone', component: 'MeetingsClone' }
    ],
    rel: [
      '/hotels/:hotelid',
      /\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/
    ],
    html: {
      displayRightChevron: true
    }
  },
  ...[
    // path, Component, Title
    ['meetings', 'Meetings', 'Meetings'],
    ['weddings', 'Weddings', 'Weddings'],
    ['dining', 'PrivateDining', 'Private Dining'],
    ['misc', 'Misc', 'Misc'],
    ['misc2', 'Misc2', 'Misc2'],
    ['misc3', 'Misc3', 'Misc3']
  ].map(v => {
    return {
      title: v[2],
      path: `/hotels/:hotelid/tiled/${v[0]}`,
      exact,
      component: v[1],
      resource: 'tiled_promo',
      directories: [
        { title: 'New', path: '/new', suffix: 'New' },
        { title: 'Edit', path: '/:id/edit', suffix: 'Edit' }
      ],
      rel: /^\/hotels\/:hotelid\/tiled(\/.+)?/
    };
  }),
  {
    title: 'Accommodations',
    path: '/hotels/:hotelid/accommodations',
    exact,
    redirect: '/hotels/:hotelid/accommodations/guest-rooms',
    resource: 'accommodation',
    directories: [
      { title: 'Clone', path: '/:type/clone', component: 'GuestRoomClone' }
    ],
    rel: [
      '/hotels/:hotelid',
      /\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/
    ],
    html: {
      displayRightChevron: true
    }
  },
  ...[
    ['guest-rooms', 'GuestRoom', 'Guest Rooms'],
    ['suites', 'Suite', 'Suites'],
    ['accessible', 'Accessible', 'Accessible'],
    ['miscs', 'MiscAccom', 'Misc']
  ].map(v => {
    return {
      title: v[2],
      path: `/hotels/:hotelid/accommodations/${v[0]}`,
      exact,
      component: v[1],
      resource: 'accommodation',
      directories: [
        { title: 'New', path: '/new', suffix: 'New' },
        { title: 'Edit', path: '/:id/edit', suffix: 'Edit' }
      ],
      rel: /^\/hotels\/:hotelid\/accommodations(\/.+)?/
    };
  }),
  {
    title: 'Calendars',
    path: '/hotels/:hotelid/calendars',
    exact,
    component: 'HotelCalendars',
    resource: 'calendar',
    directories: [
      { title: 'New', path: '/events/new', suffix: 'New' },
      { title: 'Edit', path: '/events/:id/edit', suffix: 'Edit' },
      { title: 'Clone', path: '/events/clone', suffix: 'Clone' }
    ],
    rel: [
      '/hotels/:hotelid',
      /\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/
    ]
  },
  {
    title: 'Lists',
    path: '/hotels/:hotelid/lists',
    exact,
    component: 'Lists',
    resource: 'list',
    directories: [
      { title: 'New', path: '/new', suffix: 'New' },
      { title: 'Edit', path: '/:id/edit', suffix: 'Edit' },
      { title: 'Clone', path: '/clone', suffix: 'Clone' }
    ],
    rel: [
      '/hotels/:hotelid',
      /\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/
    ]
  },
  {
    title: 'FAQ Categories',
    path: '/hotels/:hotelid/faqs/categories',
    exact,
    component: 'FAQsCategories',
    resource: 'faq_category',
    directories: [
      { title: 'New', path: '/new', suffix: 'New' },
      { title: 'Edit', path: '/:id/edit', suffix: 'Edit' }
    ],
    rel: [
      '/hotels/:hotelid',
      /\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/
    ],
    hidden: true,
    zIndex: 80
  },
  {
    title: 'FAQs',
    path: '/hotels/:hotelid/faqs',
    exact,
    component: 'FAQs',
    resource: 'faq',
    directories: [
      { title: 'New', path: '/new', suffix: 'New' },
      { title: 'Edit', path: '/:id/edit', suffix: 'Edit' },
      { title: 'Clone', path: '/clone', suffix: 'Clone' }
    ],
    rel: [
      '/hotels/:hotelid',
      /\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/
    ],
    zIndex: 90
  },
  {
    title: 'Settings',
    path: '/hotels/:hotelid/settings',
    component: 'HotelSettings',
    rel: [
      '/hotels/:hotelid',
      /\/hotels\/:hotelid\/\b(?!tiled|accommodations\b).+/
    ]
  },
  {
    title: 'Restaurants',
    path: '/restaurants',
    exact,
    component: 'Restaurants',
    resource: 'property',
    directories: [
      {
        title: 'New Restaurant',
        path: '/new',
        suffix: 'New',
        rel: '/restaurants/new'
      },
      {
        title: property => property.name,
        path: '/:restaurantId',
        exact,
        component: 'RestaurantDashboard',
        rel: /^\/restaurants\/:restaurantId(\/.+)?/
      }
      // FIXME this works but is not in the proper order. Need a way to set sort order
      // { path: '/:restaurantId/settings', component: 'RestaurantSettings', rel: /^\/restaurants\/:restaurantId(\/.+)?/ }
    ],
    rel: [
      '/',
      '/restaurants',
      '/restaurants/new',
      /^\/restaurants\/:restaurantId(\/.+)?/
    ]
  },
  {
    title: 'Hours',
    path: '/restaurants/:restaurantId/hours',
    component: 'Hours',
    resource: 'hour',
    rel: /^\/restaurants\/:restaurantId(\/.+)?/
  },
  {
    title: 'Menus',
    path: '/restaurants/:restaurantId/menu',
    exact,
    component: 'RestaurantMenu',
    resource: 'menu',
    directories: [
      { title: 'New', path: '/new', suffix: 'New' },
      { title: 'Edit', path: '/:id/edit', suffix: 'Edit' }
    ],
    rel: /^\/restaurants\/:restaurantId(\/.+)?/
  },
  {
    title: 'Events',
    path: '/restaurants/:restaurantId/events',
    exact,
    component: 'Events',
    resource: 'event',
    directories: [
      { title: 'New', path: '/new', component: 'NewEvent' },
      { title: 'Edit', path: '/:id/edit', component: 'EditEvent' }
    ],
    rel: /^\/restaurants\/:restaurantId(\/.+)?/
  },
  {
    title: 'Calendars',
    path: '/restaurants/:restaurantId/calendars',
    exact,
    component: 'RestaurantCalendars',
    resource: 'calendar',
    directories: [
      { title: 'New', path: '/events/new', suffix: 'New' },
      { title: 'Edit', path: '/events/:id/edit', suffix: 'Edit' }
      // FIXME is this actually a useable route?
      // { title: 'Clone', path: '/events/clone', suffix: 'Clone' }
    ],
    rel: /^\/restaurants\/:restaurantId(\/.+)?/
  },
  {
    title: 'Press',
    path: '/restaurants/:restaurantId/press',
    exact,
    component: 'RestaurantPress',
    resource: 'press',
    directories: [
      { title: 'New', path: '/new', suffix: 'New' },
      { title: 'Edit', path: '/:id/edit', suffix: 'Edit' }
    ],
    rel: [
      // '/restaurants/:restaurantId',
      /^\/restaurants\/:restaurantId(\/.+)?/
    ]
  },
  {
    title: 'Settings',
    path: '/restaurants/:restaurantId/settings',
    component: 'RestaurantSettings',
    resource: 'property',
    rel: /^\/restaurants\/:restaurantId(\/.+)?/
  },
  {
    title: 'Media Library',
    path: '/media',
    exact,
    component: 'MediaLibrary',
    resource: 'asset',
    directories: [
      // { title: 'New', path: '/new', suffix: 'New' },
      { title: 'Edit', path: '/:id', component: 'EditMedia' }
    ],
    rel: ['/', /^\/media(\/.+)?/]
  },
  {
    title: 'Settings',
    path: '/settings',
    component: 'Settings',
    rel: ['/', '/settings']
  },
  {
    title: 'Profile',
    path: '/profile',
    component: 'Profile',
    resource: 'profile',
    rel: '/profile'
  },
  {
    title: 'Notifications',
    path: '/notifications',
    component: 'Notifications',
    rel: '/notifications'
  },
  {
    title: 'API Tokens',
    path: '/tokens',
    exact,
    component: 'Tokens',
    directories: [{ title: 'New', path: '/new', component: 'TokensNew' }],
    rel: ['/', /^\/tokens(\/.+)?/]
  }
];

/**
 * expose
 */

export default directories;
