import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import Layout from '../../layout';
import ForgotPasswordForm from '../../components/ForgotPasswordForm';
import * as authActions from '../../actions/auth';

const propTypes = {
  history: PropTypes.object.isRequired,
  forgotPassword: PropTypes.func.isRequired
};

const Profile = ({ history, forgotPassword }) => {
  const handleSubmit = data => {
    const { email } = data;
    if (!email) {
      return null;
    }
    return forgotPassword(email)
      .then(() => {
        history.push('/');
      })
      .catch(() => {
        throw new SubmissionError({
          _error: 'Email was incorrect or not found.'
        });
      });
  };
  const handleCancel = e => {
    e.preventDefault();
    history.push('/');
  };
  return (
    <Layout metaTitle="Forgot Password">
      <div className="w-full text-grey-darker max-w-3xl">
        <h1 className="mb-6 text-4xl">Forgot Password</h1>
        <div className="mb-16">Use this page to reset your password.</div>

        <ForgotPasswordForm
          id="forgot-password"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
    </Layout>
  );
};

Profile.propTypes = propTypes;

export default connect(
  state => ({
    profile: state.auth.profile,
    isLoading: state.auth.isLoading
  }),
  authActions
)(Profile);
