import { moveBySplice } from '../utils/move_by_splice';
import {
  FETCH_LISTS_REQUEST,
  FETCH_LISTS_SUCCESS,
  FETCH_LISTS_FAILURE,
  CREATE_LIST_REQUEST,
  CREATE_LIST_SUCCESS,
  CREATE_LIST_FAILURE,
  FETCH_LIST_REQUEST,
  FETCH_LIST_SUCCESS,
  FETCH_LIST_FAILURE,
  UPDATE_LIST_REQUEST,
  UPDATE_LIST_SUCCESS,
  UPDATE_LIST_FAILURE,
  DELETE_LIST_REQUEST,
  DELETE_LIST_SUCCESS,
  DELETE_LIST_FAILURE,
  MOVE_LIST,
  CLONE_LISTS_REQUEST,
  CLONE_LISTS_SUCCESS,
  CLONE_LISTS_FAILURE,
  SORT_LISTS_REQUEST,
  SORT_LISTS_SUCCESS,
  SORT_LISTS_FAILURE
} from '../actions/lists';

export function lists(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_LISTS_REQUEST:
    case CLONE_LISTS_REQUEST:
    case SORT_LISTS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_LISTS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case MOVE_LIST: {
      const { source, target } = payload;
      const data = moveBySplice(source, target, state.data);

      return { ...state, data };
    }

    case CLONE_LISTS_SUCCESS:
    case SORT_LISTS_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_LISTS_FAILURE:
    case CLONE_LISTS_FAILURE:
    case SORT_LISTS_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}

export function list(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case CREATE_LIST_REQUEST:
    case FETCH_LIST_REQUEST:
    case UPDATE_LIST_REQUEST:
    case DELETE_LIST_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_LIST_SUCCESS:
    case CREATE_LIST_SUCCESS:
    case UPDATE_LIST_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_LIST_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_LIST_FAILURE:
    case CREATE_LIST_FAILURE:
    case UPDATE_LIST_FAILURE:
    case DELETE_LIST_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
