import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import * as modalActions from '../../actions/modals';
import Input from '../common/Input';
import TinyMCE from '../common/form/TinyMCE';
import FormDatePicker from '../common/form/DatePicker';
import FormDropdown from '../common/form/Dropdown';
import FormSwitch from '../common/form/Switch';
import FormSection from '../common/FormSection';
import FormErrorBox from '../common/FormErrorBox';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  doesNotEnd: PropTypes.bool,
  categories: PropTypes.array.isRequired,
  question: PropTypes.string,
  answer: PropTypes.string,
  published: PropTypes.bool,
  archived: PropTypes.bool,
  change: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  doesNotEnd: false,
  question: '',
  answer: '',
  published: false,
  archived: false,
  error: undefined
};

const validate = values => {
  const errors = {};
  if (!values.category) {
    errors.category = 'Required!';
  }

  if (!values.question) {
    errors.question = 'Required!';
  }

  if (!values.answer) {
    errors.answer = 'Required!';
  }

  if (!values.startsOn) {
    errors.startsOn = 'Required!';
  }

  if (!values.doesNotEnd && !values.endsOn) {
    errors.endsOn = 'End date is required if event ends';
  }

  return errors;
};

const HotelFAQForm = ({
  id,
  handleSubmit,
  doesNotEnd,
  categories,
  question,
  answer,
  published,
  archived,
  change,
  error
}) => {
  const answerString = answer;
  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="title"
        title="Question & Answer"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Select a Category for this FAQ, then enter the question and
                answer.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Select an FAQ Category*</div>
          <Field
            id="faq-category"
            name="categoryId"
            style={{ width: 300 }}
            className="mb-6"
            component={FormDropdown}
            data={categories}
          />
          <div className="mb-3">Question*</div>
          <Field
            id="faq-question"
            name="question"
            className="w-full mb-6"
            placeholder="Enter a question..."
            component={Input}
          />
          <div className="mb-3">Answer*</div>
          <Field
            id="faq-answer"
            name="answer"
            className="mb-6"
            placeholder="Enter a short answer for this FAQ question..."
            component={TinyMCE}
          />
        </div>
      </FormSection>
      <FormSection
        id="date"
        title="Date & Time"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter the Start Date for this FAQ. To set an End Date, uncheck
                'FAQ does not end'.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Start Date*</div>
          <Field
            id="starts-on"
            name="startsOn"
            component={FormDatePicker}
            className="mb-6"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MM/dd/yyyy h:mm aa"
            timeCaption="time"
            autoComplete="off"
          />
          <div className="flex items-center">
            <Field
              id="event-ends"
              type="checkbox"
              name="doesNotEnd"
              className="mr-3"
              component={Input}
              onChange={(_, v) => {
                if (v) {
                  change('endsOn', new Date(2120, 0, 1));
                } else {
                  change('endsOn', null);
                }
              }}
            />
            <div className="text-base font-normal">FAQ does not end</div>
          </div>
          {!doesNotEnd && (
            <div>
              <div
                className={classnames('text-base font-normal mb-3 pt-6', {
                  'text-grey-light': doesNotEnd
                })}
              >
                End Date*
              </div>
              <Field
                id="ends-on"
                name="endsOn"
                component={FormDatePicker}
                disabled={doesNotEnd}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy h:mm aa"
                timeCaption="time"
                autoComplete="off"
              />
            </div>
          )}
        </div>
      </FormSection>
      <FormSection
        id="preview"
        title="Preview & Publish"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex w-full flex-col flex-wrap xl:flex-row">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  Here is a preview of your FAQ. When you're ready, toggle the
                  publish status to display it on the live site and hit 'Save'.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="mb-3">Preview:</div>
            <div className="py-4 faq-question-wrapper">
              <h4 className="mb-2">{question}</h4>
              <p>
                <div dangerouslySetInnerHTML={{ __html: answerString }} />
              </p>
            </div>
            <div className="mt-12">
              <div className="text-base font-normal mb-3">Publish Status:</div>
              <Field
                id="published"
                name="published"
                component={FormSwitch}
                label={published ? 'Published' : 'Unpublished'}
                className="mb-12"
                disabled={archived}
              />
              <div className="text-base font-normal mb-3">
                Archive this FAQ?
              </div>
              <Field
                id="archived"
                name="archived"
                component={FormSwitch}
                label={archived ? 'Archived' : 'Unarchived'}
                className="mb-6"
                onChange={(e, value) => {
                  e.preventDefault();
                  if (value && published) {
                    change('published', false);
                  }
                  change('archived', value);
                }}
              />
            </div>
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && <FormErrorBox id="hotel-faq-error">{error}</FormErrorBox>}
    </form>
  );
};

HotelFAQForm.propTypes = propTypes;
HotelFAQForm.defaultProps = defaultProps;

const selector = formValueSelector('HotelFAQForm');

export default compose(
  reduxForm({ form: 'HotelFAQForm', validate }),
  connect(
    state => ({
      doesNotEnd: selector(state, 'doesNotEnd'),
      question: selector(state, 'question'),
      answer: selector(state, 'answer'),
      published: selector(state, 'published'),
      archived: selector(state, 'archived')
    }),
    modalActions
  )
)(HotelFAQForm);
