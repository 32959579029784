import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../../layout';
import Table from '../../../components/common/Table';
import FormDropdown from '../../../components/common/form/Dropdown';
import Switch from '../../../components/common/form/Switch';
import {
  Tabs,
  TabPill,
  TabList,
  Tab,
  TabPanel
} from '../../../components/common/Tabs';
import * as propertyActions from '../../../actions/properties';
import * as alertsActions from '../../../actions/alerts';
import * as globalAlertActions from '../../../actions/globalAlert';
import * as assignedAlertsActions from '../../../actions/assignedAlerts';
import Paginate from '../../../components/Paginate';
import { fetchHandlerFunc } from '../../../utils/fetch-helpers';

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  alerts: PropTypes.array.isRequired,
  assignedAlerts: PropTypes.array.isRequired,
  properties: PropTypes.array.isRequired,
  fetchProperties: PropTypes.func.isRequired,
  fetchAlerts: PropTypes.func.isRequired,
  fetchGlobalAlert: PropTypes.func.isRequired,
  createGlobalAlert: PropTypes.func.isRequired,
  deleteGlobalAlert: PropTypes.func.isRequired,
  fetchAssignedAlerts: PropTypes.func.isRequired,
  assignAlert: PropTypes.func.isRequired,
  unassignAlert: PropTypes.func.isRequired,
  authority: PropTypes.object.isRequired,
  globalAlert: PropTypes.object,
  meta: PropTypes.object
};

const defaultProps = {
  globalAlert: {},
  meta: {}
};

/**
 * prependAlert adds a No ALert default option
 * @param {Array} alerts
 * @return {Array}
 * @api private
 */

const prependAlerts = alerts => {
  if (alerts.length >= 1 && alerts[0].id !== '') {
    alerts.unshift({ id: '', name: 'No Alert' });
  }

  return alerts;
};

const validates = values => { /* eslint-disable-line no-unused-vars */
  const errors = {};

  return errors;
};

const condfn = val => {
  const cond = { order_by: 'name:ASC', type: 'hotel' };
  if (val) {
    cond.name = `i%%${val}`;
  }

  return cond;
};

const Regions = ({
  alerts,
  assignedAlerts,
  globalAlert,
  properties,
  meta,
  isLoading,
  fetchProperties,
  fetchAlerts,
  fetchGlobalAlert,
  createGlobalAlert,
  deleteGlobalAlert,
  fetchAssignedAlerts,
  assignAlert,
  unassignAlert,
  authority
}) => {
  const fetchPropertiesfn = fetchHandlerFunc(fetchProperties);

  useEffect(() => {
    fetchPropertiesfn(null, condfn());
    // FIXME per_page needs to return to a normal number with pagination so
    // this will scale, bumping to 200 for now
    fetchAlerts({ archived: false, per_page: 200, order_by: 'name:ASC' });
    fetchGlobalAlert();
    fetchAssignedAlerts();
    return () => {};
  }, []);

  const columns = [
    {
      id: 'name',
      Header: 'Property Name',
      accessor: ({ id, name }) => {
        const assigned = assignedAlerts.filter(
          v => (v.property || {}).id === id
        )[0];
        return (
          <div>
            {name}
            <br />
            {assigned && assigned.alert && (
              <Link to={`/hotels/alerts/${assigned.alert.id}/edit`}>
                Edit Alert
              </Link>
            )}
            {!assigned && 'No Alert'}
          </div>
        );
      },
      width: 220
    },
    {
      id: 'override',
      Header: 'Override',
      accessor: ({ id }) => (
        <Field
          id={`hotel-${id}-override`}
          name={`hotel-${id}-override`}
          component={Switch}
          label=""
          className="mb-3"
          checked={
            (assignedAlerts.filter(v => (v.property || {}).id === id)[0] || {})
              .override
          }
          onChange={(evt, value) => {
            const alert = assignedAlerts.filter(
              v => (v.property || {}).id === id
            )[0];
            if (alert) {
              assignAlert(id, { override: value });
            }
          }}
        />
      ),
      width: 120
    },
    {
      id: 'alert-listing',
      Header: 'Alert Listing',
      accessor: ({ id }) => (
        <Field
          id={`property-${id}-alert`}
          name={`property-${id}-alert`}
          className="w-full mb-6 lg:w-3/4"
          style={{ width: 'auto' }}
          component={FormDropdown}
          data={alerts}
          input={{
            value: (
              assignedAlerts.filter(
                v => (v.property || {}).id === id && v.alert
              )[0] || {
                alert: {}
              }
            ).alert.id,
            onChange: value => {
              if (value) {
                assignAlert(id, { alertId: value });
              } else {
                unassignAlert(id);
              }
            }
          }}
          placeholder="No Alert"
        />
      )
    },
  ];

  return (
    <Layout metaTitle="Alert Assignments">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <h1 className="mb-8">Alert Assignments</h1>
          <div className="section global-alert">
            <h2 className="font-normal mb-4">
              {/* <i className="icon icon-carret-up">&nbsp;</i> */}
              Global Alert
            </h2>
            <p className="mb-4 leading-normal">
              To set a Global Alert, select an Alert Listing from the dropdown.
              <em>
                *Please note: that a Global Alert will override a Property
                Alert.
              </em>
            </p>
            <div className="box box--selection sm:w-full lg:w-3/4">
              <Field
                id="globalAlertId"
                name="globalAlertId"
                className="w-full mb-4 bg-white"
                style={{ width: 'auto' }}
                component={FormDropdown}
                data={alerts}
                input={{
                  value: globalAlert.id,
                  onChange: value => {
                    if (value && value !== '') {
                      createGlobalAlert(value);
                    } else {
                      deleteGlobalAlert();
                    }
                  }
                }}
                placeholder="No Alert"
              />
            </div>
          </div>
          <div className="section property-alert">
            <h2 className="font-normal mb-4">
              {/* <i className="icon icon-carret-up">&nbsp;</i> */}
              Property Alert
            </h2>
            {/* display: block */}
            <p className="mb-4 leading-normal">
              To assign an alert to a specific property, select an alert listing
              from the dropdown.
            </p>
            <p className="mb-4 leading-normal">
              If you need to override a Global Alert, toggle on "Override" so
              that the Property Alert takes priority.
            </p>
          </div>
          <div className="sm:flex sm:flex-1 justify-start mb-6 md:justify-end">
            {authority.isSuper() && (
              <Link
                to="/hotels/alerts/new"
                className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              >
                + New Alert
              </Link>
            )}
          </div>
          {isLoading ? (
            <div>Loading ...</div>
          ) : (
            <>
              <Tabs>
                <TabList className="alerts_tabs">
                  <Tab>
                    <span className="mr-2">Active</span>
                    <TabPill id="current">{assignedAlerts.length}</TabPill>
                  </Tab>
                </TabList>

                <TabPanel>
                  <Table
                    id="assigned-alerts-table"
                    class="assigned-alerts-table-container"
                    data={properties}
                    columns={columns}
                  />
                </TabPanel>
              </Tabs>
              <Paginate meta={meta} fetchHandler={fetchProperties} />
            </>
          )}
        </div>
      )}
    </Layout>
  );
};

Regions.propTypes = propTypes;
Regions.defaultProps = defaultProps;

// const selector = formValueSelector('Region'); // FIXME why is this here and/or unused?

export default compose(
  reduxForm({ form: 'Region', validates }),
  connect(
    state => ({
      assignedAlerts: state.assignedAlerts.data,
      alerts: prependAlerts(state.alerts.data),
      globalAlert: state.globalAlert.data,
      properties: state.properties.data,
      meta: state.properties.meta,
      authority: state.auth.authority,
      isLoading:
        state.alerts.isLoading ||
        state.globalAlert.isLoading ||
        state.properties.isLoading ||
        state.assignedAlerts.isLoading
    }),
    {
      ...alertsActions,
      ...globalAlertActions,
      ...propertyActions,
      ...assignedAlertsActions
    }
  )
)(Regions);
