import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../../../css/components/textarea.css';

const propTypes = {
  id: PropTypes.string.isRequired,
  textarea: PropTypes.object.isRequired,
  input: PropTypes.object.isRequired, // FIXME is this actually an object?
  name: PropTypes.string,
  rows: PropTypes.string,
  textareaRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  autocomplete: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.object
};

const defaultProps = {
  name: '',
  rows: '5',
  textareaRef: null,
  className: '',
  style: {},
  placeholder: undefined,
  autocomplete: undefined,
  disabled: false,
  meta: {}
};

class Textarea extends Component {
  render() {
    const {
      id,
      name,
      rows,
      textareaRef,
      className,
      style,
      placeholder,
      autocomplete,
      input,
      disabled,
      meta: { touched, error }
    } = this.props;
    const classname = classnames('Textarea', {
      'Textarea--error': touched && error
    });
    return (
      <div className={className}>
        <textarea
          id={id}
          name={name}
          rows={rows}
          ref={textareaRef}
          className={classname}
          style={style}
          placeholder={placeholder}
          autoComplete={autocomplete}
          {...input}
          disabled={disabled}
        />
        {touched && error && <div className="text-red mt-2">{error}</div>}
      </div>
    );
  }
}

Textarea.propTypes = propTypes;
Textarea.defaultProps = defaultProps;

export default Textarea;
