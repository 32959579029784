import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_ACCOMMODATIONS_REQUEST = 'FETCH_ACCOMMODATIONS_REQUEST';
export const FETCH_ACCOMMODATIONS_SUCCESS = 'FETCH_ACCOMMODATIONS_SUCCESS';
export const FETCH_ACCOMMODATIONS_FAILURE = 'FETCH_ACCOMMODATIONS_FAILURE';

export const FETCH_ACCOMMODATION_REQUEST = 'FETCH_ACCOMMODATION_REQUEST';
export const FETCH_ACCOMMODATION_SUCCESS = 'FETCH_ACCOMMODATION_SUCCESS';
export const FETCH_ACCOMMODATION_FAILURE = 'FETCH_ACCOMMODATION_FAILURE';

export const CREATE_ACCOMMODATION_REQUEST = 'CREATE_ACCOMMODATION_REQUEST';
export const CREATE_ACCOMMODATION_SUCCESS = 'CREATE_ACCOMMODATION_SUCCESS';
export const CREATE_ACCOMMODATION_FAILURE = 'CREATE_ACCOMMODATION_FAILURE';

export const UPDATE_ACCOMMODATION_REQUEST = 'UPDATE_ACCOMMODATION_REQUEST';
export const UPDATE_ACCOMMODATION_SUCCESS = 'UPDATE_ACCOMMODATION_SUCCESS';
export const UPDATE_ACCOMMODATION_FAILURE = 'UPDATE_ACCOMMODATION_FAILURE';

export const DELETE_ACCOMMODATION_REQUEST = 'DELETE_ACCOMMODATION_REQUEST';
export const DELETE_ACCOMMODATION_SUCCESS = 'DELETE_ACCOMMODATION_SUCCESS';
export const DELETE_ACCOMMODATION_FAILURE = 'DELETE_ACCOMMODATION_FAILURE';

export const MOVE_ACCOMMODATION = 'MOVE_ACCOMMODATION';

export const CLONE_ACCOMMODATIONS_REQUEST = 'CLONE_ACCOMMODATIONS_REQUEST';
export const CLONE_ACCOMMODATIONS_SUCCESS = 'CLONE_ACCOMMODATIONS_SUCCESS';
export const CLONE_ACCOMMODATIONS_FAILURE = 'CLONE_ACCOMMODATIONS_FAILURE';

export const SORT_ACCOMMODATIONS_REQUEST = 'SORT_ACCOMMODATIONS_REQUEST';
export const SORT_ACCOMMODATIONS_SUCCESS = 'SORT_ACCOMMODATIONS_SUCCESS';
export const SORT_ACCOMMODATIONS_FAILURE = 'SORT_ACCOMMODATIONS_FAILURE';

export const fetchAccommodations = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_ACCOMMODATIONS_REQUEST
  });
  return fetch(
    path || `/properties/${propertyId}/accommodations${fromObj(qry, '?')}`
  )
    .then(response => {
      const accommodations = response.data.map(convertToJS);
      const meta = convertToJS(response.meta);
      dispatch({
        type: FETCH_ACCOMMODATIONS_SUCCESS,
        payload: accommodations,
        meta
      });
      return { data: accommodations, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_ACCOMMODATIONS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countAccommodations = (propertyId, params) => dispatch => {
  return fetch(
    `/properties/${propertyId}/accommodations${fromObj(params, '?')}`
  )
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchAccommodation = (propertyId, accommodationId) => dispatch => {
  dispatch({
    type: FETCH_ACCOMMODATION_REQUEST
  });
  return fetch(`/properties/${propertyId}/accommodations/${accommodationId}`)
    .then(response => {
      const accommodation = convertToJS(response.data);
      dispatch({
        type: FETCH_ACCOMMODATION_SUCCESS,
        payload: accommodation
      });
      return accommodation;
    })
    .catch(e => {
      dispatch({
        type: FETCH_ACCOMMODATION_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createAccommodation = (propertyId, body) => dispatch => {
  dispatch({
    type: CREATE_ACCOMMODATION_REQUEST
  });
  return fetch(`/properties/${propertyId}/accommodations`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(body))
  })
    .then(response => {
      const accommodation = convertToJS(response.data);
      dispatch({
        type: CREATE_ACCOMMODATION_SUCCESS,
        payload: accommodation
      });
      return accommodation;
    })
    .catch(e => {
      dispatch({
        type: CREATE_ACCOMMODATION_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateAccommodation = (
  propertyId,
  accommodationId,
  update
) => dispatch => {
  dispatch({
    type: UPDATE_ACCOMMODATION_REQUEST
  });
  return fetch(`/properties/${propertyId}/accommodations/${accommodationId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const accommodation = convertToJS(response.data);
      dispatch({
        type: UPDATE_ACCOMMODATION_SUCCESS,
        payload: accommodation
      });
      return accommodation;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_ACCOMMODATION_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteAccommodation = (
  propertyId,
  accommodationId
) => dispatch => {
  dispatch({
    type: DELETE_ACCOMMODATION_REQUEST
  });
  return fetch(`/properties/${propertyId}/accommodations/${accommodationId}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch({
        type: DELETE_ACCOMMODATION_SUCCESS,
        payload: accommodationId
      });
      return accommodationId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_ACCOMMODATION_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveAccommodation = (source, target) => ({
  type: MOVE_ACCOMMODATION,
  payload: { source, target }
});

export const sortAccommodations = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_ACCOMMODATIONS_REQUEST
  });
  return fetch(`/properties/${propertyId}/accommodations/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_ACCOMMODATIONS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_ACCOMMODATIONS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const cloneAccommodations = (propertyId, ids, toPropertyIds) => (
  dispatch
) => {
  dispatch({
    type: CLONE_ACCOMMODATIONS_REQUEST
  });
  return fetch(`/properties/${propertyId}/accommodations/clone`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ids,
      to_property_ids: toPropertyIds
    })
  })
    .then(response => {
      // const faq = convertToJS(response.data);
      dispatch({
        type: CLONE_ACCOMMODATIONS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: CLONE_ACCOMMODATIONS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
