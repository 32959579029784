import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import Layout from '../../../layout';
import * as sidebarActions from '../../../actions/sidebar';
import * as propertyActions from '../../../actions/properties';
import * as menuActions from '../../../actions/menus';
import RestaurantMenuForm from '../../../components/RestaurantMenuForm/RestaurantMenuForm';
import FormNavigator from '../../../components/FormNavigator';

const sections = [
  { id: 'title', title: 'Title & Script' },
  { id: 'Publish', title: 'Publish' },
];

class Edit extends Component {
  componentDidMount() {
    const {
      updateSidebar,
      fetchProperty,
      fetchMenu,
      match: {
        params: { restaurantId, id }
      }
    } = this.props;
    fetchProperty(restaurantId).then(restaurant => {
      updateSidebar({ titles: { Restaurant: restaurant.name } });
    });
    fetchMenu(restaurantId, id);
  }

  handleSubmit = data => {
    const {
      updateMenu,
      match: {
        params: { restaurantId, id }
      },
      history: { push }
    } = this.props;
    const menu = { ...data };
    return updateMenu(restaurantId, id, menu)
      .then(() => push(`/restaurants/${restaurantId}/menu`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  render() {
    const {
      restaurant,
      menu,
      isLoading,
      match: {
        params: { restaurantId }
      }
    } = this.props;
    return (
      <Layout metaTitle="Menu">
        <div className="w-full max-w-3xl">
          <Link
            to={`/restaurants/${restaurantId}/menu`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Menu
          </Link>
          <h1 className="mb-4">Edit Menu</h1>
          <div className="mb-16">
            Use this page to manage {menu.title} Menu Item for{' '}
            {isLoading ? 'Restaurant' : restaurant.name}.
          </div>
          <RestaurantMenuForm
            id="edit-menu-form"
            restaurant={restaurant}
            onSubmit={this.handleSubmit}
            initialValues={{
              ...menu
            }}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
        <FormNavigator
          id="new-menu-navigator"
          sections={sections}
          className="hidden xl:block mt-32 pt-16"
        />
      </Layout>
    );
  }
}

Edit.propTypes = {
  updateSidebar: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateMenu: PropTypes.func.isRequired,
  fetchMenu: PropTypes.func.isRequired,
  restaurant: PropTypes.object,
  menu: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object
};

Edit.defaultProps = {
  restaurant: {},
  menu: {},
  match: {},
  history: {}
};

export default connect(
  state => ({
    restaurant: state.property.data,
    menu: state.menu.data,
    isLoading: state.property.isLoading || state.menu.isLoading
  }),
  { ...sidebarActions, ...propertyActions, ...menuActions }
)(Edit);
