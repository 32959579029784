import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

const defaultProps = {
  className: undefined,
  style: null
};

const HouseIcon = ({ id, className, style, ...otherProps }) => (
  <svg
    id={id}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    viewBox="0 0 512 512"
    {...otherProps}
  >
    <path d="M208 448V320h96v128h97.6V256H464L256 64 48 256h62.4v192z" />
  </svg>
);

HouseIcon.propTypes = propTypes;
HouseIcon.defaultProps = defaultProps;

export default HouseIcon;
