import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Input from '../common/Input';
import FormErrorBox from '../common/FormErrorBox';

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Calendar name is required!';
  }
  return errors;
};

class AddCalendarForm extends Component {
  constructor(props) {
    super(props);
    this.textInput = null;
  }

  componentDidMount() {
    if (this.textInput) {
      this.textInput.focus();
    }
  }

  setInputRef = el => {
    this.textInput = el;
  };

  render() {
    const { id, handleSubmit, onClose, error } = this.props;
    return (
      <form id={id} onSubmit={handleSubmit}>
        <Field
          id="rest-calendar-input"
          name="name"
          placeholder="New Calendar"
          className="mb-3"
          inputRef={this.setInputRef}
          component={Input}
        />
        <div>
          <button
            type="submit"
            className="btn w-14 mr-3 mb-1 px-4 min-w-full"
            disabled={!!error}
          >
            Save
          </button>
          <button
            type="button"
            className="btn btn-clear px-4 min-w-full"
            onClick={e => {
              e.preventDefault();
              onClose();
            }}
          >
            Cancel
          </button>
        </div>
        {error && <FormErrorBox id="add-calendar-error">{error}</FormErrorBox>}
      </form>
    );
  }
}

AddCalendarForm.propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string
};

AddCalendarForm.defaultProps = {
  error: undefined
};

export default reduxForm({ form: 'AddCalendarForm', validate })(
  AddCalendarForm
);
