import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../../../layout';
import * as propertyActions from '../../../../actions/properties';
import * as sidebarActions from '../../../../actions/sidebar';
import * as categoriesActions from '../../../../actions/categories';
import * as modalActions from '../../../../actions/modals';
import { List, ListItem } from '../../../../components/common/List';
import More, {
  MoreMenu,
  MoreMenuItem
} from '../../../../components/common/More';
import { onDrop } from '../../../../utils/sort-helpers';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  categories: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  moveCategory: PropTypes.func.isRequired,
  sortCategories: PropTypes.func.isRequired
};

const Categories = ({
  hotel,
  categories,
  match: {
    url,
    params: { hotelid }
  },
  fetchProperty,
  updateSidebar,
  fetchCategories,
  showModal,
  moveCategory,
  sortCategories
}) => {
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    return () => {};
  }, []);

  useEffect(() => {
    fetchCategories(hotelid, undefined, { order_by: 'sort_index:ASC' });
    return () => {};
  }, [refresh]);

  return (
    <Layout metaTitle="FAQ Categories">
      <div className="w-full max-w-3xl">
        <Link
          to={`/hotels/${hotelid}/faqs`}
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to FAQs
        </Link>
        <h1 className="mb-6">FAQ Categories</h1>
        <div className="mb-16">
          Use this page to create and manage FAQ Categories for {hotel.name}.
        </div>
        <div className="flex justify-end mb-12">
          <Link to={`${url}/new`} className="btn inline-block no-underline">
            + New FAQ Category
          </Link>
        </div>
        <List id="faq-categories-list">
          {categories.map((cat, catIdx) => (
            <ListItem
              id={cat.id}
              key={cat.id}
              className="justify-between"
              isDraggable
              dataSortIndex={cat.sortIndex}
              index={catIdx}
              moveRow={(drag, hover) => moveCategory(drag, hover)}
              onDrop={(evt, moved) => {
                if (moved) {
                  onDrop(evt, categories, sortCategories.bind(null, hotelid));
                }
              }}
            >
              <Link to={`${url}/${cat.id}/edit`}>{cat.name}</Link>
              <More id={`${cat.id}-more`}>
                <MoreMenu id={`${cat.id}-moremenu`}>
                  <MoreMenuItem
                    id={`${cat.id}-delete`}
                    onClick={() =>
                      showModal(
                        'DeleteFAQCategoryModal',
                        {
                          id: cat.id,
                          propertyId: hotelid,
                          title: cat.name
                        },
                        () => {
                          setRefresh(refresh + 1);
                        }
                      )}
                  >
                    Delete
                  </MoreMenuItem>
                </MoreMenu>
              </More>
            </ListItem>
          ))}
        </List>
      </div>
    </Layout>
  );
};

Categories.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    categories: state.categories.data,
    isLoading: state.property.isLoading || state.categories.isLoading
  }),
  {
    ...propertyActions,
    ...sidebarActions,
    ...categoriesActions,
    ...modalActions
  }
)(Categories);
