import { actions as authorityActions } from 'swcms-authority';
import { PUBLIC } from '../../routes/types';

/**
 * authority actions shorthand
 */

const { READ } = authorityActions;

/**
 * canViewFunc returns a function used to comapre a link (directory) and
 * property (if given) against the authority function to see if a user is able
 * to access that link
 *
 * @param {Object} authority (an authorityFunc return)
 * @returns {Function}
 * @api public
 */

const canViewFunc = authority => {
  return (property, link) => {
    const { resource, path, route } = link;
    if (path === '/') {
      return true;
    }
    if (route === PUBLIC) {
      return true;
    }
    if (authority.isSuper()) {
      return true;
    }

    switch (resource) {
      case 'asset':
      case 'user':
      case 'alert':
        return authority.can(READ, { resource, r: true });

      case 'property':
        /* eslint-disable no-case-declarations */
        let type;
        /* eslint-disable no-bitwise */
        if (~['/restaurants', '/restaurants/:restaurantId'].indexOf(path)) {
          type = 'restaurant';
        }
        if (
          ~[
            '/hotels',
            '/hotels/properties',
            '/hotels/:hotelid',
            '/hotels/alerts',
          ].indexOf(path)
        ) {
          type = 'hotel';
        }
        return type && authority.can(READ, { resource, property: { type } });

      default:
        return authority.can(READ, {
          resource,
          permissionableId: property.id,
          permissionableType: 'PropertyPermission'
        });
    }
  };
};

/**
 * expose
 */

export { canViewFunc };
