import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Layout from '../../../layout';
import * as sidebarActions from '../../../actions/sidebar';
import * as modalActions from '../../../actions/modals';
import * as propertyActions from '../../../actions/properties';
import * as eventActions from '../../../actions/events';
import {
  Tabs,
  TabList,
  Tab,
  TabPill
} from '../../../components/common/Tabs';
import Table from '../../../components/common/Table';
import More, { MoreMenu, MoreMenuItem } from '../../../components/common/More';
import Paginate from '../../../components/Paginate';
import { fetchHandlerFunc } from '../../../utils/fetch-helpers';
import { onDrop } from '../../../utils/sort-helpers';

const propTypes = {
  restaurant: PropTypes.object.isRequired,
  events: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  moveEvent: PropTypes.func.isRequired,
  sortEvents: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  fetchEvents: PropTypes.func.isRequired,
  countEvents: PropTypes.func.isRequired,
  match: PropTypes.object,
  meta: PropTypes.object
};

const defaultProps = {
  match: {},
  meta: {}
};

const condfn = val => {
  const cond = { order_by: 'sort_index:ASC' };
  if (val) {
    cond.title = `i%%${val}`;
  }

  return cond;
};

const Events = ({
  updateSidebar,
  fetchProperty,
  fetchEvents,
  countEvents,
  match: {
    url,
    params: { restaurantId }
  },
  updateEvent,
  showModal,
  isLoading,
  restaurant,
  events,
  meta,
  moveEvent,
  sortEvents
}) => {
  const [refresh, setRefresh] = useState(0);
  const [activeCount, setActivCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [selected, setSelected] = useState(0);

  const tabsconf = [
    {
      qry: { archived: false },
      cb: setActivCount
    },
    {
      qry: { archived: true },
      cb: setArchivedCount
    },
  ];

  const fetchfn = fetchHandlerFunc(
    fetchEvents,
    countEvents,
    tabsconf,
    restaurantId
  );

  useEffect(() => {
    fetchProperty(restaurantId).then(item => {
      updateSidebar({ titles: { Restaurant: item.name } });
    });

    return () => {};
  }, []);

  useEffect(() => {
    fetchfn(selected, condfn());

    return () => {};
  }, [selected, refresh]);

  const columns = [
    {
      id: 'title',
      Header: 'Title',
      accessor: ({ id, title }) => <Link to={`${url}/${id}/edit`}>{title}</Link>
    },
    {
      id: 'live',
      Header: '',
      accessor: data =>
        (data.doesNotEnd || moment(data.endsOn) > moment()) &&
        moment(data.startsOn) < moment() &&
        data.published ? (
          <span className="bg-teal text-white px-5 py-1 text-xs rounded-full">
            LIVE
          </span>
        ) : (
          ''
        ),
      width: 120
    },
    {
      id: 'startsOn',
      Header: 'Starts On',
      // eslint-disable-next-line react/prop-types
      accessor: props => moment(props.startsOn).format('YYYY-MM-DD'),
      width: 120
    },
    {
      id: 'endsOn',
      Header: 'Ends On',
      accessor: data =>
        data.endsOn && !data.doesNotEnd
          ? moment(data.endsOn).format('MM-DD-YYYY')
          : '--',
      width: 120
    },
    {
      id: 'displayOnHomepage',
      Header: () => (
        <div className="whitespace-normal break-word">Displays on Home</div>
      ),
      accessor: data => (data.displayOnHomepage ? 'Yes' : 'No'),
      width: 120
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: data => (
        <span className="capitalize">
          {data.published ? 'Published' : 'Unpublished'}
        </span>
      ),
      width: 120
    },
    {
      id: 'more',
      Header: () => <span />,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-archive`}
              onClick={() =>
                updateEvent(restaurantId, data.id, {
                  archived: !data.archived
                }).then(setRefresh(refresh + 1))}
            >
              {data.archived ? 'Unarchive' : 'Archive'}
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={() =>
                showModal(
                  'DeleteEventModal',
                  {
                    restaurantId,
                    id: data.id,
                    title: data.title
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )}
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      ),
      width: 40
    },
  ];

  return (
    <Layout metaTitle="Events">
      <div className="w-full max-w-3xl">
        <h1 className="mb-4">Events</h1>
        <div className="mb-8">
          Use this page to create and manage Events for{' '}
          {isLoading ? 'Restaurant' : restaurant.name}.
        </div>
        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <div>
            <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
              <Link
                to={`${url}/new`}
                className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              >
                + New Event
              </Link>
            </div>
            <Tabs selectedIndex={selected} onSelect={i => setSelected(i)}>
              <TabList>
                <Tab>
                  <span className="mr-2">Active</span>
                  <TabPill id="active-pill">{activeCount}</TabPill>
                </Tab>
                <Tab>
                  <span className="mr-2">Archived</span>
                  <TabPill id="archived-pill">{archivedCount}</TabPill>
                </Tab>
                <Paginate
                  meta={meta}
                  fetchHandler={(_, link) => fetchEvents(null, null, link)}
                />
              </TabList>
              <Table
                id="events-table"
                data={events}
                columns={columns}
                moveRow={(drag, hover) => moveEvent(drag, hover)}
                onDrop={(evt, moved) => {
                  if (moved) {
                    onDrop(evt, events, sortEvents.bind(null, restaurantId), {
                      archived: selected ? true : false // eslint-disable-line no-unneeded-ternary
                    });
                  }
                }}
                isDraggable
              />
            </Tabs>
            <Paginate
              meta={meta}
              fetchHandler={(_, link) => fetchEvents(null, null, link)}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

Events.propTypes = propTypes;
Events.defaultProps = defaultProps;

export default connect(
  (state) => ({
    restaurant: state.property.data,
    events: state.events.data,
    meta: state.events.meta,
    isLoading: state.property.isLoading || state.events.isLoading
  }),
  {
    ...sidebarActions,
    ...modalActions,
    ...propertyActions,
    ...eventActions
  }
)(Events);
