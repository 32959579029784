import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import Input from '../common/Input';
import FormSection from '../common/FormSection';
import FormErrorBox from '../common/FormErrorBox';
import * as permissionActions from '../../actions/permissions';

const propTypes = {
  generatePasswordText: PropTypes.string,
  generatePassword: PropTypes.bool,
  canAssignAlerts: PropTypes.bool.isRequired,
  canManageUsers: PropTypes.bool.isRequired,
  canManageAssets: PropTypes.bool.isRequired,
  addPermission: PropTypes.func.isRequired,
  removePermission: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string,
  isSuper: PropTypes.bool
};

const defaultProps = {
  generatePasswordText: '',
  generatePassword: false,
  error: undefined,
  isSuper: false
};

const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required!';
  }

  if (!values.lastName) {
    errors.lastName = 'Required!';
  }

  if (!values.email) {
    errors.email = 'Required!';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Email must be in email format';
  }

  // don't validate password unless there is a password or confirmPassword
  if (
    (!values.editMode || values.password || values.confirmPassword) &&
    !values.generatePassword
  ) {
    if (!values.password) {
      errors.password = 'Required!';
    } else if (values.password.length < 8) {
      errors.password = 'Password must be at least 8 characters';
    }

    if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Password must match confirmation';
    }
  }

  return errors;
};

const EditUserForm = ({
  isSuper,
  generatePasswordText,
  generatePassword,
  canAssignAlerts,
  canManageUsers,
  canManageAssets,
  addPermission,
  removePermission,
  handleSubmit,
  error
}) => {
  const addCanManageUsersPermission = () => {
    addPermission('user');
    addPermission('permission');
  };
  const removeCanManageUsersPermission = () => {
    removePermission('user');
    removePermission('permission');
  };
  const handleUpdatePermission = resource => e => {
    const { checked } = e.target;
    if (checked) {
      if (resource === 'user') {
        addCanManageUsersPermission();
      } else {
        addPermission({ resource });
      }
    } else if (resource === 'user') {
      removeCanManageUsersPermission();
    } else {
      removePermission({ resource });
    }
  };
  return (
    <form className="" onSubmit={handleSubmit}>
      <FormSection
        id="username"
        title="User Name"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Enter the First Name and Last Name for this user account.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <label htmlFor="firstName">
            <div className="font-normal mb-3 text-base">First Name*</div>
            <Field
              id="firstName"
              placeholder="First Name"
              className="w-full mb-6"
              name="firstName"
              component={Input}
            />
          </label>
          <label htmlFor="lastName">
            <div className="font-normal mb-3 text-base">Last Name*</div>
            <Field
              id="lastName"
              placeholder="Last Name"
              className="w-full mb-6"
              name="lastName"
              component={Input}
            />
          </label>
        </div>
      </FormSection>
      <FormSection
        id="usercredentials"
        title="User Credentials"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Enter the Email and Password for this user account. Email
                address will be used as the login.
              </i>
            </p>
            <br />
            <p>
              <i>Then select the user's CMS permissions, if any.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <label htmlFor="email">
            <div className="font-normal text-base mb-2">
              Email* (will also be your login)
            </div>
            <Field
              id="email"
              type="email"
              className="w-full mb-6"
              placeholder="email@kimptonhotels.com"
              name="email"
              component={Input}
            />
          </label>
          <label htmlFor="generatePassword" className="flex items-center mb-2">
            <Field
              id="generatePassword"
              type="checkbox"
              name="generatePassword"
              component={Input}
            />
            <div className="font-normal text-base ml-2">
              {generatePasswordText}
              <br />
              <small>
                <em>
                  A system generated password will be sent to user via email.
                </em>
              </small>
            </div>
          </label>
          {!generatePassword && (
            <div>
              <label htmlFor="password">
                <div className="font-normal text-base mb-2 mt-6">
                  New Password:
                </div>
                <Field
                  id="password"
                  type="password"
                  className="w-full mb-2"
                  placeholder="**********"
                  name="password"
                  component={Input}
                  autocomplete="new-password"
                  disabled={generatePassword}
                />
              </label>
              <label htmlFor="confirmPassword">
                <div className="font-normal text-base mb-2">
                  Confirm New Password:
                </div>
                <Field
                  id="confirmPassword"
                  type="password"
                  className="w-full mb-6"
                  placeholder="**********"
                  name="confirmPassword"
                  component={Input}
                  disabled={generatePassword}
                />
              </label>
            </div>
          )}
          {!isSuper && (
            <>
              <div className="w-full mb-6">
                <label htmlFor="assignAlerts" className="flex items-center">
                  <Input
                    type="checkbox"
                    id="canAssignAlerts"
                    input={{
                      checked: canAssignAlerts,
                      onChange: handleUpdatePermission('can_assign_alerts')
                    }}
                  />
                  <div className="font-normal text-base ml-2">
                    Can Assign Alerts
                  </div>
                </label>
              </div>
              <div className="w-full mb-6">
                <label htmlFor="canManageUsers" className="flex items-center">
                  <Input
                    type="checkbox"
                    id="canManageUsers"
                    input={{
                      checked: canManageUsers,
                      onChange: handleUpdatePermission('user')
                    }}
                  />
                  <div className="font-normal text-base ml-2">
                    Can Manage Users
                  </div>
                </label>
              </div>
              <div className="w-full mb-6">
                <label htmlFor="canManageAssets" className="flex items-center">
                  <Input
                    type="checkbox"
                    id="canManageAssets"
                    input={{
                      checked: canManageAssets,
                      onChange: handleUpdatePermission('asset')
                    }}
                  />
                  <div className="font-normal text-base ml-2">
                    Can Manage Assets
                  </div>
                </label>
              </div>
            </>
          )}
        </div>
        {error && <FormErrorBox id="user-form-error">{error}</FormErrorBox>}
      </FormSection>
    </form>
  );
};

EditUserForm.propTypes = propTypes;
EditUserForm.defaultProps = defaultProps;

const selector = formValueSelector('EditUserForm');

export default compose(
  reduxForm({
    form: 'EditUserForm',
    validate
  }),
  connect(
    state => ({
      editMode: selector(state, 'editMode'),
      generatePasswordText: selector(state, 'generatePasswordText'),
      generatePassword: selector(state, 'generatePassword'),
      canAssignAlerts:
        state.permissions.data.findIndex(
          permission => permission.resource === 'can_assign_alerts'
        ) > -1,
      canManageUsers:
        state.permissions.data.findIndex(
          permission => permission.resource === 'user'
        ) > -1 &&
        state.permissions.data.findIndex(
          permission => permission.resource === 'permission'
        ) > -1,
      canManageAssets:
        state.permissions.data.findIndex(
          permission => permission.resource === 'asset'
        ) > -1
    }),
    permissionActions
  )
)(EditUserForm);
