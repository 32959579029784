import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Input from '../common/Input';
import FormErrorBox from '../common/FormErrorBox';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  error: undefined
};

const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required!';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Email must be in email format';
  }
  return errors;
};

const ForgotPasswordForm = ({ id, error, handleSubmit, onCancel }) => (
  <form id={id} className="my-6" onSubmit={handleSubmit}>
    <div className="md:flex md:flex-wrap md:bg-white md:p-10 md:rounded-sm md:shadow-md md:border-t-2 md:border-blue-lighter md:mb-10">
      <div className="my-4 md:p-3 md:w-1/3">
        <label htmlFor="email">
          <div className="my-2">Account Email*</div>
          <Field
            id="email"
            type="email"
            className="w-full max-w-xs"
            placeholder="email@kimptonhotels.com"
            name="email"
            component={Input}
          />
        </label>
      </div>
    </div>
    <div className="md:p-3,my-4 flex flex-wrap md:flex-row-reverse w-full">
      <button
        className="btn sm:mr-4 md:mr-0 md:ml-4 mt-4 w-full max-w-xs"
        type="submit"
      >
        Submit
      </button>
      <button
        type="button"
        className="btn btn-clear forgot-pw-cancel mt-4 w-full max-w-xs"
        onClick={onCancel}
      >
        Cancel
      </button>
    </div>
    {error && <FormErrorBox id="user-form-error">{error}</FormErrorBox>}
  </form>
);

ForgotPasswordForm.propTypes = propTypes;
ForgotPasswordForm.defaultProps = defaultProps;

export default reduxForm({ form: 'ForgotPasswordForm', validate })(
  ForgotPasswordForm
);
