import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_HOURS_REQUEST = 'FETCH_HOURS_REQUEST';
export const FETCH_HOURS_SUCCESS = 'FETCH_HOURS_SUCCESS';
export const FETCH_HOURS_FAILURE = 'FETCH_HOURS_FAILURE';

export const CREATE_HOUR_REQUEST = 'CREATE_HOUR_REQUEST';
export const CREATE_HOUR_SUCCESS = 'CREATE_HOUR_SUCCESS';
export const CREATE_HOUR_FAILURE = 'CREATE_HOUR_FAILURE';

export const UPDATE_HOUR_REQUEST = 'UPDATE_HOUR_REQUEST';
export const UPDATE_HOUR_SUCCESS = 'UPDATE_HOUR_SUCCESS';
export const UPDATE_HOUR_FAILURE = 'UPDATE_HOUR_FAILURE';

export const DELETE_HOUR_REQUEST = 'DELETE_HOUR_REQUEST';
export const DELETE_HOUR_SUCCESS = 'DELETE_HOUR_SUCCESS';
export const DELETE_HOUR_FAILURE = 'DELETE_HOUR_FAILURE';

export const EDIT_HOUR = 'EDIT_HOUR';

export const ADD_TIME_SLOT = 'ADD_TIME_SLOT';
export const EDIT_TIME_SLOT = 'EDIT_TIME_SLOT';
export const REMOVE_TIME_SLOT = 'REMOVE_TIME_SLOT';
export const MOVE_TIME_SLOT = 'MOVE_TIME_SLOT';

export const fetchHours = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_HOURS_REQUEST
  });
  return fetch(path || `/properties/${propertyId}/hours${fromObj(qry, '?')}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_HOURS_SUCCESS,
        payload: data,
        meta
      });
      return { data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_HOURS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createHour = (propertyId, body) => dispatch => {
  dispatch({
    type: CREATE_HOUR_REQUEST
  });
  return fetch(`/properties/${propertyId}/hours`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(body))
  })
    .then(response => {
      const hour = convertToJS(response.data);
      dispatch({
        type: CREATE_HOUR_SUCCESS,
        payload: hour
      });
      return hour;
    })
    .catch(e => {
      dispatch({
        type: CREATE_HOUR_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateHour = (propertyId, hourId, update) => dispatch => {
  dispatch({
    type: UPDATE_HOUR_REQUEST
  });
  return fetch(`/properties/${propertyId}/hours/${hourId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const hour = convertToJS(response.data);
      dispatch({
        type: UPDATE_HOUR_SUCCESS,
        payload: hour
      });
      return hour;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_HOUR_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

// NOTE hours, in their current implementation have no real "delete" state
// export const deleteHour = (propertyId, hourId) => dispatch => {
//   dispatch({
//     type: DELETE_HOUR_REQUEST
//   });
//   return fetch(`/properties/${propertyId}/hours/${hourId}`, {
//     method: 'DELETE'
//   })
//     .then(() => {
//       // dispatch(removeTimeSlot(hourId));
//       return hourId;
//     })
//     .catch(e => {
//       dispatch({
//         type: DELETE_HOUR_FAILURE,
//         payload: e.message
//       });
//       return Promise.reject(e);
//     });
// };

export const editHour = update => ({
  type: EDIT_HOUR,
  payload: { ...update, updated: true }
});

export const addTimeSlot = hour => ({
  type: ADD_TIME_SLOT,
  payload: hour
});

export const editTimeSlot = (hourId, update) => ({
  type: EDIT_TIME_SLOT,
  payload: { id: hourId, ...update, updated: true }
});

export const removeTimeSlot = hourId => ({
  type: REMOVE_TIME_SLOT,
  payload: hourId
});

export const moveTimeSlot = (source, target) => ({
  type: MOVE_TIME_SLOT,
  payload: { source, target }
});
