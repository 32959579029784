import React, { useCallback, useState } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { compose } from 'redux';
import * as propertyActions from '../../actions/gotoProperties';
import SearchBar, {
  SearchBarMenu,
  SearchBarMenuItem
} from '../common/SearchBar';
import NotificationIcon from '../common/NotificationIcon';
import * as authActions from '../../actions/auth';
import * as assetActions from '../../actions/assets';
import UserMenu from './UserMenu';
import NavMenu from './NavMenu';

import '../../css/components/header.css';

const propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  gotoProperties: PropTypes.array.isRequired,
  fetchGotoProperties: PropTypes.func.isRequired,
  clearGotoProperties: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object
};

const defaultProps = {
  history: {}
};

const Header = ({
  location: { pathname },
  isAuthenticated,
  gotoProperties,
  history,
  logoutUser,
  fetchGotoProperties,
  clearGotoProperties
}) => {
  const [searchInput, setSearchInput] = useState('');

  const fetchfn = useCallback(
    debounce(val => {
      if (!val) {
        clearGotoProperties();
        return;
      }
      const cond = {
        name: `i%%${val}`,
        order_by: 'name:ASC'
      };
      fetchGotoProperties(cond);
    }, 500),
    []
  );

  const inputHandler = stateHandler => {
    return val => {
      stateHandler(val);
      fetchfn(val);
    };
  };

  const searchHandler = inputHandler(setSearchInput);

  return (
    <header className="bg-white text-grey-darker xs:pl-3 xs:pr-3 lg:p-2 flex border-t-2 border-blue shadow">
      {isAuthenticated && <NavMenu logoutUser={logoutUser} />}
      <Link
        to="/"
        className="inline-block text-grey-darker no-underline hover:underline"
      >
        <h2 className="logo-kimpton pt-2">Kimpton Hotels</h2>
      </Link>
      {isAuthenticated ? (
        <ul className="flex-1 list-reset flex items-center justify-end">
          <li className="mx-2 border-0">
            <SearchBar
              id="header-search"
              value={searchInput}
              placeholder="Go to a Property..."
              onChange={({ value }) => searchHandler(value)}
              onClick={() => {}}
            >
              <SearchBarMenu id="header-search-menu">
                {gotoProperties.map(property => (
                  <SearchBarMenuItem
                    key={property.id}
                    id={property.id}
                    onClick={() => {
                      setSearchInput('');
                      history.push(`/${property.type}s/${property.id}`);
                    }}
                  >
                    {property.name}
                  </SearchBarMenuItem>
                ))}
              </SearchBarMenu>
            </SearchBar>
          </li>
          <li className="mx-4 hidden lg:block">
            <Link to="/notifications">
              <NotificationIcon
                id="header-notification"
                style={{ fill: '#888888', width: 32, marginTop: 4 }}
              />
            </Link>
          </li>
          <li className="mx-2 hidden lg:block">
            <UserMenu logoutUser={logoutUser} />
          </li>
        </ul>
      ) : (
        <>
          {pathname !== '/' && (
            <ul className="nav-list-item flex-1 list-reset flex items-center justify-end text-grey-darker">
              <Link to="/">Sign In</Link>
            </ul>
          )}
        </>
      )}
    </header>
  );
};

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default compose(
  withRouter,
  connect(
    state => ({
      isAuthenticated: state.auth.isAuthenticated,
      gotoProperties: state.gotoProperties.data
    }),
    {
      ...propertyActions,
      ...authActions,
      ...assetActions
    }
  )
)(Header);
