import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import * as categoriesActions from '../../../actions/categories';
import ExitButton from '../../common/ExitButton';
import NewFaqCategoryForm from './NewFaqCategoryForm';
import isPromise from '../../../utils/is-promise';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  hotel: PropTypes.object.isRequired,
  createCategory: PropTypes.func.isRequired,
  onClosePostHandler: PropTypes.func.isRequired
};

const NewFaqCategoryModal = ({
  onClose,
  hotel,
  createCategory,
  onClosePostHandler
}) => {
  return (
    <div id="new-faq-category-modal">
      <div className="flex justify-center border-b py-2 px-4 border-grey bg-grey-lighter">
        <h2 className="font-light">Clone to a New FAQ Category...</h2>
        <ExitButton
          id="generate-url-exit"
          onClick={onClose}
          className="absolute"
          divStyle={{ right: '.5rem' }}
          svgStyle={{ fill: 'black' }}
        />
      </div>
      <div className="p-6">
        <NewFaqCategoryForm
          id="new-faq-category-modal-form"
          initialValues={{ name: '' }}
          onSubmit={data => {
            const created = createCategory(hotel.id, data);
            if (isPromise(created)) {
              created.then(res => {
                onClosePostHandler(res);
                onClose();
              });
            } else {
              onClose();
            }
          }}
          enableReinitialize
          keepDirtyOnReinitialize
          onClose={onClose}
        />
      </div>
    </div>
  );
};

NewFaqCategoryModal.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    onClosePostHandler: props.data.onClosePostHandler
  }),
  { change, ...categoriesActions }
)(NewFaqCategoryModal);
