import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash/get';
import moment from 'moment';

import Layout from '../../../layout';
import { updateSidebar } from '../../../actions/sidebar';
import { SearchBar } from '../../../components/common/SearchBar/SearchBar';
import {
  Tab,
  Tabs,
  TabList,
  TabPill
} from '../../../components/common/Tabs';
import Table from '../../../components/common/Table';
import HomePromosPreview from '../../../components/HomePromosPreview';
import More, { MoreMenu, MoreMenuItem } from '../../../components/common/More';
import EditableLink from '../../../components/common/EditableLink';
import * as modalActions from '../../../actions/modals';
import * as propertyActions from '../../../actions/properties';
import * as homePromoActions from '../../../actions/homePromos';
import Paginate from '../../../components/Paginate';
import {
  inputHandlerFunc,
  fetchHandlerFunc
} from '../../../utils/fetch-helpers';
import { onDrop } from '../../../utils/sort-helpers';

const propTypes = {
  fetchProperty: PropTypes.func.isRequired,
  fetchHomePromos: PropTypes.func.isRequired,
  countHomePromos: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  moveHomePromo: PropTypes.func.isRequired,
  sortHomePromos: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  updateHomePromo: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hotel: PropTypes.object.isRequired,
  homePromos: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object,
  authority: PropTypes.object.isRequired
};

const defaultProps = {
  match: {},
  history: {}
};

const condfn = val => {
  const cond = { order_by: 'sort_index:ASC', per_page: 100 };
  if (val) {
    cond.title = `i%%${val}`;
  }

  return cond;
};

const HomePromos = ({
  hotel,
  homePromos,
  meta,
  isLoading,
  match: {
    url,
    params: { hotelid }
  },
  history: { push },
  fetchProperty,
  fetchHomePromos,
  countHomePromos,
  updateSidebar, // eslint-disable-line no-shadow
  moveHomePromo,
  sortHomePromos,
  updateProperty,
  updateHomePromo,
  showModal,
  authority
}) => {
  const [refresh, setRefresh] = useState(0);
  const [activeCount, setActivCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [selected, setSelected] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const tabsconf = [
    {
      qry: { archived: false },
      cb: setActivCount
    },
    {
      qry: { archived: true },
      cb: setArchivedCount
    },
  ];

  const fetchfn = fetchHandlerFunc(
    fetchHomePromos,
    countHomePromos,
    tabsconf,
    hotelid
  );

  const searchHandler = inputHandlerFunc(
    setSearchInput,
    useCallback(debounce(fetchfn, 500), []),
    condfn
  );

  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    return () => {};
  }, []);

  useEffect(() => {
    fetchfn(selected, condfn(searchInput));
    return () => {};
  }, [selected, refresh]);

  if (isLoading) return <></>;

  let promoCount = 3;
  let live = '';

  const columns = [
    {
      id: 'title',
      Header: 'Title',
      accessor: data => <Link to={`${url}/${data.id}/edit`}>{data.title}</Link>
    },
    {
      id: 'live',
      Header: '',
      accessor: data => {
        live =
          (data.doesNotEnd || moment(data.endsOn) > moment()) &&
          moment(data.startsOn) < moment() &&
          data.published &&
          promoCount > 0
            ? ((promoCount -= 1),
              (
                <span className="bg-teal text-white px-5 py-1 text-xs rounded-full">
                  LIVE
                </span>
              ))
            : '';
        return live;
      },
      width: 120
    },
    {
      id: 'startsOn',
      Header: 'Starts On',
      accessor: data => moment(data.startsOn).format('MM-DD-YYYY'),
      width: 120
    },
    {
      id: 'endsOn',
      Header: 'Ends On',
      accessor: data =>
        data.endsOn && !data.doesNotEnd
          ? moment(data.endsOn).format('MM-DD-YYYY')
          : '--',
      width: 120
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: data => (
        <span className="capitalize">
          {data.published ? 'Published' : 'Unpublished'}
        </span>
      ),
      width: 120
    },
    {
      id: 'more',
      Header: () => <span />,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-archive`}
              onClick={() =>
                updateHomePromo(
                  hotelid,
                  data.id,
                  Object.assign(
                    {
                      id: data.id,
                      archived: !data.archived
                    },
                    !data.archived && { published: false }
                  )
                ).then(setRefresh(refresh + 1))}
            >
              {data.archived ? 'Unarchive' : 'Archive'}
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-clone`}
              onClick={() => push(`${url}/clone?selected=${data.id}`)}
            >
              Clone
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={() =>
                showModal(
                  'DeleteHomePromoModal',
                  {
                    hotelId: hotelid,
                    id: data.id,
                    title: data.title
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )}
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      ),
      width: 40
    },
  ];

  const homePromosURL = get(hotel, 'previewUrls.homePromosUrl');

  return (
    <Layout metaTitle="Home Promos">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <h1 className="mb-6">Home Promos</h1>
          <div className="mb-6">
            Use this page to create and manage Home Promos on {hotel.name}.
          </div>
          <div className="mb-12 text-sm flex items-center">
            View Live Home Promos Page:
            <EditableLink
              id="hotel-promos-link"
              className="ml-3"
              link={homePromosURL}
              target="_blank"
              onSubmit={data =>
                updateProperty(hotelid, {
                  previewUrls: {
                    ...hotel.previewUrls,
                    homePromosUrl: data.link
                  }
                })}
              isEditable={authority.isSuper()}
            />
          </div>

          <div className="mb-0">
            <HomePromosPreview id="active-promos-preview" data={homePromos} />
          </div>

          <h2 className="font-light mb-6">All Home Promos</h2>

          <div className="flex flex-col flex-col-reverse md:flex-row mb-6 md:mb-12">
            <SearchBar
              id="promos-list-search"
              className="flex-1 mr-2 lg:mt-1"
              placeholder="Filter Home Promos..."
              onChange={({ value }) => searchHandler(value, selected)}
              onClick={() => {}}
            />
            <div className="flex flex-1 justify-start mb-6 md:mb-0 md:justify-end">
              <Link
                to={`${url}/clone`}
                className="btn btn-clear no-underline mr-3"
              >
                Clone Home Promo
              </Link>
              <Link to={`${url}/new`} className="btn no-underline">
                + New Home Promo
              </Link>
            </div>
          </div>
          <Tabs selectedIndex={selected} onSelect={i => setSelected(i)}>
            <TabList>
              <Tab>
                <span className="mr-2">Active</span>
                <TabPill id="active-pill">{activeCount}</TabPill>
              </Tab>
              <Tab>
                <span className="mr-2">Archived</span>
                <TabPill id="archived-pill">{archivedCount}</TabPill>
              </Tab>
              <Paginate
                meta={meta}
                fetchHandler={(_, link) => fetchHomePromos(null, null, link)}
              />
            </TabList>

            <Table
              id="promos"
              columns={columns}
              data={homePromos}
              moveRow={(drag, hover) => {
                moveHomePromo(drag, hover);
              }}
              onDrop={(evt, moved) => {
                if (moved) {
                  onDrop(evt, homePromos, sortHomePromos.bind(null, hotelid), {
                    archived: selected ? true : false // eslint-disable-line no-unneeded-ternary
                  });
                }
              }}
              isDraggable
            />
          </Tabs>
          <Paginate
            meta={meta}
            fetchHandler={(_, link) => fetchHomePromos(null, null, link)}
          />
        </div>
      )}
    </Layout>
  );
};

HomePromos.propTypes = propTypes;
HomePromos.defaultProps = defaultProps;

export default connect(
  (state) => ({
    hotel: state.property.data,
    homePromos: state.homePromos.data,
    meta: state.homePromos.meta,
    authority: state.auth.authority,
    isLoading: state.property.isLoading || state.homePromos.isLoading,
    dndDir: state.dndDir
  }),
  {
    ...modalActions,
    ...propertyActions,
    updateSidebar,
    ...homePromoActions
  }
)(HomePromos);
