import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import * as alertActions from '../../../actions/alerts';
import HotelAlertForm from '../../../components/HotelAlertForm';
import Table from '../../../components/common/Table';
import Layout from '../../../layout';
import FormNavigator from '../../../components/FormNavigator';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  alert: PropTypes.object.isRequired,
  updateAlert: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchAlert: PropTypes.func.isRequired
};

const sections = [
  { id: 'name', title: 'Name & Display Type' },
  { id: 'title', title: 'Title & Content' },
  { id: 'save', title: 'Save & Assign' },
];

const HomePromosEdit = ({
  match: {
    params: { id }
  },
  history: { push },
  updateAlert,
  alert,
  isLoading,
  fetchAlert
}) => {
  useEffect(() => {
    fetchAlert(id);
    return () => {};
  }, []);

  const handleSubmit = data => {
    const body = {
      ...data
    };
    return updateAlert(id, body)
      .then(() => {
        push(`/hotels/alerts/listings`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout type="Alerts" metaTitle="Edit Alerts">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to="/hotels/alerts/listings"
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Alert Listing
          </Link>
          <h1 className="mb-6">{alert.name}</h1>
          <div className="mb-16">Use this page to edit Alert {alert.name}</div>

          <HotelAlertForm
            id="edit-alert"
            initialValues={{ ...alert }}
            onSubmit={handleSubmit}
            enableReinitialize
            keepDirtyOnReinitialize
          />
          <hr />
          <h3>Properties Associated to {alert.name}</h3>
          <small>
            <em>
              These are only directly linked properties. Global Alerts may not
              display any associated properties though active.
            </em>
          </small>
          <br />
          <Table
            id="alert-properties"
            columns={[
              {
                id: 'name',
                Header: 'Name',
                accessor: data => (
                  <Link
                    to={`/${data.type === 'hotel' ? 'hotels' : 'restaurants'}/${
                      data.id
                    }`}
                  >
                    {`${data.name}`}
                  </Link>
                ),
                className: 'text-left'
              },
            ]}
            data={alert.properties || []}
          />
        </div>
      )}
      <FormNavigator
        id="edit-promos-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

HomePromosEdit.propTypes = propTypes;

export default connect(
  (state, props) => ({
    alert: state.alert.data,
    isLoading: state.alert.isLoading
  }),
  { ...alertActions }
)(HomePromosEdit);
