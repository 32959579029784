import React from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Layout from '../../../layout';
import * as propertyActions from '../../../actions/properties';
import * as regionsActions from '../../../actions/regions';
import EditRegionForm from '../../../components/EditRegionForm';

const propTypes = {
  updateProperty: PropTypes.func.isRequired,
  addRegion: PropTypes.func.isRequired,
  history: PropTypes.object
};

const defaultProps = {
  history: {}
};

const RegionsNew = ({ updateProperty, addRegion, history: { push } }) => {
  const handleSubmit = data => {
    const { name, hotels } = data;
    const body = { name };
    return addRegion(body)
      .then(region => {
        const updateHotels = Object.keys(hotels || {})
          .filter(hotelId => hotels[hotelId])
          .map(hotelId => updateProperty(hotelId, { region_id: region.id }));
        return Promise.all(updateHotels);
      })
      .then(() => push('/hotels/regions'));
  };

  return (
    <Layout metaTitle="New">
      <div className="w-full max-w-3xl">
        <Link
          to="/hotels/regions"
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Regions
        </Link>
        <h1 className="mb-6">New Region</h1>
        <div className="mb-16">
          Use this page to manage hotels for a region.
        </div>
        <EditRegionForm id="regions-new" onSubmit={handleSubmit} isNew />
      </div>
    </Layout>
  );
};

RegionsNew.propTypes = propTypes;
RegionsNew.defaultProps = defaultProps;

export default compose(
  withRouter,
  connect(
    null,
    { ...propertyActions, ...regionsActions }
  )
)(RegionsNew);
