import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as tiledPromoActions from '../../../actions/tiledPromos';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteTiledPromo: PropTypes.func.isRequired
};

const DeleteMisc3Modal = ({ data, deleteTiledPromo, ...props }) => (
  <DeleteModal
    id="delete-misc-modal"
    title="Misc3"
    itemTitle={data.title}
    deleteItem={() => deleteTiledPromo(data.hotelId, data.id)}
    {...props}
  />
);

DeleteMisc3Modal.propTypes = propTypes;

export default connect(null, tiledPromoActions)(DeleteMisc3Modal);
