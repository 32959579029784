import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import pick from 'lodash/pick';
import { SubmissionError } from 'redux-form';
import * as authActions from '../../actions/auth';
import Layout from '../../layout';
import ProfileForm from '../../components/ProfileForm';

const propTypes = {
  profile: PropTypes.object.isRequired,
  logoutUser: PropTypes.func.isRequired,
  updateProfile: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const Profile = ({ profile, logoutUser, history, updateProfile }) => {
  const handleSubmit = data => {
    const update = pick(data, ['email', 'firstName', 'lastName', 'password']);
    return updateProfile(update)
      .then(() => history.push('/'))
      .catch(e => new SubmissionError({ _error: e.message }));
  };
  const handleLogout = e => {
    e.preventDefault();
    logoutUser();
  };
  return (
    <Layout metaTitle="Profile Settings">
      <div className="w-full max-w-3xl">
        <h1 className="mb-6">Profile Settings</h1>
        <div className="mb-16">
          Use this page to manage your Profile settings.
        </div>

        <ProfileForm
          id="profile-settings"
          initialValues={profile}
          onSubmit={handleSubmit}
          onLogout={handleLogout}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
    </Layout>
  );
};

Profile.propTypes = propTypes;

export default connect(
  state => ({
    profile: state.auth.profile,
    isLoading: state.auth.isLoading
  }),
  authActions
)(Profile);
