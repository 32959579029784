import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_REGIONS_REQUEST = 'FETCH_REGIONS_REQUEST';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILURE = 'FETCH_REGIONS_FAILURE';

export const CREATE_REGIONS_REQUEST = 'CREATE_REGIONS_REQUEST';
export const CREATE_REGIONS_SUCCESS = 'CREATE_REGIONS_SUCCESS';
export const CREATE_REGIONS_FAILURE = 'CREATE_REGIONS_FAILURE';

export const DELETE_REGIONS_REQUEST = 'DELETE_REGIONS_REQUEST';
export const DELETE_REGIONS_SUCCESS = 'DELETE_REGIONS_SUCCESS';
export const DELETE_REGIONS_FAILURE = 'DELETE_REGIONS_FAILURE';

export const fetchRegions = (qry, path) => dispatch => {
  dispatch({
    type: FETCH_REGIONS_REQUEST
  });
  return fetch(path || `/regions?${fromObj(qry)}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_REGIONS_SUCCESS,
        payload: data,
        meta
      });
      return { data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_REGIONS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const addRegion = body => dispatch => {
  dispatch({
    type: CREATE_REGIONS_REQUEST
  });
  return fetch('/regions', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(body))
  })
    .then(response => {
      const region = convertToJS(response.data);
      dispatch({
        type: CREATE_REGIONS_SUCCESS,
        payload: region
      });
      return region;
    })
    .catch(e => {
      dispatch({
        type: CREATE_REGIONS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteRegion = regionId => dispatch => {
  dispatch({
    type: DELETE_REGIONS_REQUEST
  });
  return fetch(`/regions/${regionId}`, { method: 'DELETE' })
    .then(response => {
      dispatch({
        type: DELETE_REGIONS_SUCCESS,
        payload: regionId
      });
      return regionId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_REGIONS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
