import merge from 'lodash/merge';
import arrayMove from 'array-move';
import {
  INITIALIZE_IMAGE,
  ADD_IMAGE,
  REMOVE_IMAGE,
  SET_ACTIVE_IMAGE,
  SET_CURRENT_IMAGE,
  UPDATE_IMAGE,
  UPDATE_IMAGES_SORT
} from '../actions/images';

const initialState = {};

const initialEnvState = {
  active: 1,
  counter: 1,
  items: [{ id: 1, name: 'Image 1', alt: '', videoUrl: '' }],
  onlySingle: true
};

// eslint-disable-next-line default-param-last
export default function images(state = initialState, { type, payload, meta }) {
  switch (type) {
    case INITIALIZE_IMAGE: {
      const { env } = meta;
      // FIXME why do we need this? This is caching states, per https://app.asana.com/0/1119928407758199/1164533765362385/f
      // const currentEnv = state[env];
      // if (currentEnv) {
      //   return state;
      // }
      return { ...state, [env]: { ...initialEnvState, ...payload } };
    }
    case ADD_IMAGE: {
      const { env } = meta;
      // FIXME what happened here this returns undefined in the
      // accommodations/new form, how did this work previously?
      const { items, counter } = state[env] || { items: [], counter: 0 };
      const newCounter = counter + 1;
      const currentEnv = {
        ...state[env],
        items: items.concat({
          id: newCounter,
          // FIXME this counter will not scale on dynamic sorting as this name
          // is fixed. Not even sure if this being used anymore.
          name: `Image ${newCounter}`,
          ...payload
        }),
        counter: newCounter,
        active: newCounter
      };
      return { ...state, [env]: currentEnv };
    }
    case REMOVE_IMAGE: {
      const { env } = meta;
      const { items, active } = state[env];
      const newItems = items.filter(image => image.id !== payload);
      const currentEnv =
        newItems.length >= 1
          ? {
              ...state[env],
              items: newItems,
              active: active === payload ? newItems[0].id : active,
              counter: newItems.length
            }
          : initialEnvState;
      return { ...state, [env]: currentEnv };
    }
    case SET_ACTIVE_IMAGE: {
      const { env } = meta;
      const currentEnv = { ...state[env], active: payload };
      return { ...state, [env]: currentEnv };
    }
    case SET_CURRENT_IMAGE: {
      const { env } = meta;
      const { active, items, counter, onlySingle } =
        state[env] || initialEnvState;
      const currentItem = items.find(item => item.id === active);
      const newCounter = counter + 1;
      const currentItems =
        currentItem.file && !onlySingle
          ? items.concat({
              id: newCounter,
              name: `Image ${newCounter}`,
              alt: payload ? payload.alt : '',
              videoUrl: payload ? payload.videoUrl : '',
              file: payload
            })
          : items.map(item =>
              item.id === active
                ? {
                    ...item,
                    file: payload,
                    alt: payload.alt || item.alt,
                    videoUrl: payload.videoUrl || item.videoUrl
                  }
                : item
            );
      const currentEnv = {
        ...state[env],
        items: currentItems,
        counter: currentItem.file && !onlySingle ? newCounter : counter,
        active,
        onlySingle
      };
      return { ...state, [env]: currentEnv };
    }
    case UPDATE_IMAGE: {
      const { env } = meta;
      const { items } = state[env];
      const currentItems = items.map(item =>
        item.id === payload.id ? { ...item, ...payload } : item
      );
      return merge({}, state, { [env]: { items: currentItems } });
    }
    case UPDATE_IMAGES_SORT: {
      const { env } = meta;
      const { items } = state[env];
      const { oldIndex, newIndex } = payload;
      const sorted = arrayMove(items, oldIndex, newIndex).map((item, i) => {
        if (item.file && 'sortIndex' in item.file) {
          /* eslint-disable no-param-reassign */
          item.file.sortIndex = i;
        }

        return item;
      });
      return {
        ...state,
        [env]: {
          items: sorted,
          active: sorted[newIndex].id,
          counter: sorted.length
        }
      };
    }
    default:
      return state;
  }
}
