import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

import Layout from '../../../layout';
import { updateSidebar } from '../../../actions/sidebar';
import { SearchBar } from '../../../components/common/SearchBar/SearchBar';
import {
  Tab,
  Tabs,
  TabList,
  TabPill
} from '../../../components/common/Tabs';
import Table from '../../../components/common/Table';
import More, { MoreMenu, MoreMenuItem } from '../../../components/common/More';
import * as modalActions from '../../../actions/modals';
import * as propertyActions from '../../../actions/properties';
import * as listActions from '../../../actions/lists';
import Paginate from '../../../components/Paginate';
import {
  inputHandlerFunc,
  fetchHandlerFunc
} from '../../../utils/fetch-helpers';
import { onDrop } from '../../../utils/sort-helpers';

const propTypes = {
  fetchProperty: PropTypes.func.isRequired,
  fetchLists: PropTypes.func.isRequired,
  countLists: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  moveList: PropTypes.func.isRequired,
  sortLists: PropTypes.func.isRequired,
  updateList: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  hotel: PropTypes.object.isRequired,
  lists: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object
};

const defaultProps = {
  match: {},
  history: {}
};

const condfn = val => {
  const cond = { order_by: 'sort_index:ASC', per_page: 100 };
  if (val) {
    cond.title = `i%%${val}`;
  }

  return cond;
};

const Lists = ({
  hotel,
  lists,
  meta,
  isLoading,
  match: {
    url,
    params: { hotelid }
  },
  history: { push },
  fetchProperty,
  fetchLists,
  countLists,
  updateSidebar, // eslint-disable-line no-shadow
  moveList,
  sortLists,
  updateList,
  showModal
}) => {
  const [refresh, setRefresh] = useState(0);
  const [activeCount, setActivCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [selected, setSelected] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const tabsconf = [
    {
      qry: { archived: false },
      cb: setActivCount
    },
    {
      qry: { archived: true },
      cb: setArchivedCount
    },
  ];

  const fetchfn = fetchHandlerFunc(fetchLists, countLists, tabsconf, hotelid);

  const searchHandler = inputHandlerFunc(
    setSearchInput,
    useCallback(debounce(fetchfn, 500), []),
    condfn
  );

  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    return () => {};
  }, []);

  useEffect(() => {
    fetchfn(selected, condfn(searchInput));
    return () => {};
  }, [selected, refresh]);
  let live = '';

  const columns = [
    {
      id: 'title',
      Header: 'Title',
      accessor: data => <Link to={`${url}/${data.id}/edit`}>{data.title}</Link>
    },
    {
      id: 'live',
      Header: '',
      accessor: data => {
        live =
          (data.doesNotEnd || moment(data.endsOn) > moment()) &&
          moment(data.startsOn) < moment() &&
          data.published ? (
            <span className="bg-teal text-white px-5 py-1 text-xs rounded-full">
              LIVE
            </span>
          ) : (
            ''
          );
        return live;
      },
      width: 120
    },
    {
      id: 'page',
      Header: 'Page',
      accessor: data => (
        <span className="capitalize list-page-display-on">
          {data.pageDisplayedOn ? data.pageDisplayedOn.join('\n') : ''}
        </span>
      ),
      width: 240
    },
    {
      id: 'status',
      Header: 'Status',
      accessor: data => (
        <span className="capitalize">
          {data.published ? 'Published' : 'Unpublished'}
        </span>
      ),
      width: 120
    },
    {
      id: 'more',
      Header: () => <span />,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-archive`}
              onClick={() =>
                updateList(
                  hotelid,
                  data.id,
                  Object.assign(
                    {
                      id: data.id,
                      archived: !data.archived
                    },
                    !data.archived && { published: false }
                  )
                ).then(setRefresh(refresh + 1))}
            >
              {data.archived ? 'Unarchive' : 'Archive'}
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-clone`}
              onClick={() => push(`${url}/clone?selected=${data.id}`)}
            >
              Clone
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={() =>
                showModal(
                  'DeleteListModal',
                  {
                    hotelId: hotelid,
                    id: data.id,
                    title: data.title
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )}
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      ),
      width: 40
    },
  ];

  return (
    <Layout metaTitle="Lists">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <h1 className="mb-6">Lists</h1>
          <div className="mb-12">
            Use this page to create and manage Lists on {hotel.name}.
          </div>

          <div className="flex flex-col flex-col-reverse md:flex-row mb-6 md:mb-12">
            <SearchBar
              id="lists-list-search"
              className="flex-1 mr-2 lg:mt-1"
              placeholder="Filter Lists..."
              onChange={({ value }) => searchHandler(value, selected)}
              onClick={() => {}}
            />
            <div className="flex flex-1 justify-start mb-6 md:mb-0 md:justify-end">
              <Link
                to={`${url}/clone`}
                className="btn btn-clear no-underline mr-3"
              >
                Clone List
              </Link>
              <Link to={`${url}/new`} className="btn no-underline">
                + New List
              </Link>
            </div>
          </div>
          <Tabs selectedIndex={selected} onSelect={i => setSelected(i)}>
            <TabList>
              <Tab>
                <span className="mr-2">Active</span>
                <TabPill id="active-pill">{activeCount}</TabPill>
              </Tab>
              <Tab>
                <span className="mr-2">Archived</span>
                <TabPill id="archived-pill">{archivedCount}</TabPill>
              </Tab>
              <Paginate
                meta={meta}
                fetchHandler={(_, link) => fetchLists(null, null, link)}
              />
            </TabList>

            <Table
              id="lists"
              columns={columns}
              data={lists}
              moveRow={(drag, hover) => {
                moveList(drag, hover);
              }}
              onDrop={(evt, moved) => {
                if (moved) {
                  onDrop(evt, lists, sortLists.bind(null, hotelid), {
                    archived: selected ? true : false // eslint-disable-line no-unneeded-ternary
                  });
                }
              }}
              isDraggable
            />
          </Tabs>
          <Paginate
            meta={meta}
            fetchHandler={(_, link) => fetchLists(null, null, link)}
          />
        </div>
      )}
    </Layout>
  );
};

Lists.propTypes = propTypes;
Lists.defaultProps = defaultProps;

export default connect(
  (state) => ({
    hotel: state.property.data,
    lists: state.lists.data,
    meta: state.lists.meta,
    authority: state.auth.authority,
    isLoading: state.property.isLoading || state.lists.isLoading,
    dndDir: state.dndDir
  }),
  {
    ...modalActions,
    ...propertyActions,
    updateSidebar,
    ...listActions
  }
)(Lists);
