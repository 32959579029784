import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MoreContext from './MoreContext';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

const defaultProps = {
  onClick: () => {}
};

const MoreMenuItem = ({ id, children, onClick }) => {
  const { onClose } = useContext(MoreContext);
  return (
    <div
      role="button"
      tabIndex={0}
      id={id}
      onClick={() => {
        onClick();
        onClose();
      }}
      className="p-3 hover:bg-blue-light cursor-pointer outline-none text-black"
    >
      {children}
    </div>
  );
};

MoreMenuItem.propTypes = propTypes;
MoreMenuItem.defaultProps = defaultProps;

export default MoreMenuItem;
