import { moveBySplice } from '../utils/move_by_splice';
import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  SORT_CATEGORIES_REQUEST,
  SORT_CATEGORIES_SUCCESS,
  SORT_CATEGORIES_FAILURE,
  CREATE_CATEGORY_REQUEST,
  CREATE_CATEGORY_SUCCESS,
  CREATE_CATEGORY_FAILURE,
  UPDATE_CATEGORY_REQUEST,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILURE,
  DELETE_CATEGORY_REQUEST,
  DELETE_CATEGORY_SUCCESS,
  FETCH_CATEGORY_REQUEST,
  FETCH_CATEGORY_SUCCESS,
  FETCH_CATEGORY_FAILURE,
  MOVE_CATEGORY
} from '../actions/categories';

export function categories(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_CATEGORIES_REQUEST:
    case SORT_CATEGORIES_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_CATEGORIES_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case MOVE_CATEGORY: {
      const { source, target } = payload;
      const data = moveBySplice(source, target, state.data);

      return { ...state, data };
    }

    case SORT_CATEGORIES_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_CATEGORIES_FAILURE:
    case SORT_CATEGORIES_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function category(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_CATEGORY_REQUEST:
    case CREATE_CATEGORY_REQUEST:
    case UPDATE_CATEGORY_REQUEST:
    case DELETE_CATEGORY_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_CATEGORY_SUCCESS:
    case CREATE_CATEGORY_SUCCESS:
    case UPDATE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_CATEGORY_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_CATEGORY_FAILURE:
    case CREATE_CATEGORY_FAILURE:
    case UPDATE_CATEGORY_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
