import moment from 'moment-timezone';
import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import history from '../utils/history';

export const FETCH_CALENDAR_EVENTS_REQUEST = 'FETCH_CALENDAR_EVENTS_REQUEST';
export const FETCH_CALENDAR_EVENTS_SUCCESS = 'FETCH_CALENDAR_EVENTS_SUCCESS';
export const FETCH_CALENDAR_EVENTS_FAILURE = 'FETCH_CALENDAR_EVENTS_FAILURE';

export const FETCH_CALENDAR_EVENT_REQUEST = 'FETCH_CALENDAR_EVENT_REQUEST';
export const FETCH_CALENDAR_EVENT_SUCCESS = 'FETCH_CALENDAR_EVENT_SUCCESS';
export const FETCH_CALENDAR_EVENT_FAILURE = 'FETCH_CALENDAR_EVENT_FAILURE';

export const CREATE_CALENDAR_EVENT_REQUEST = 'CREATE_CALENDAR_EVENT_REQUEST';
export const CREATE_CALENDAR_EVENT_SUCCESS = 'CREATE_CALENDAR_EVENT_SUCCESS';
export const CREATE_CALENDAR_EVENT_FAILURE = 'CREATE_CALENDAR_EVENT_FAILURE';

export const UPDATE_CALENDAR_EVENT_REQUEST = 'UPDATE_CALENDAR_EVENT_REQUEST';
export const UPDATE_CALENDAR_EVENT_SUCCESS = 'UPDATE_CALENDAR_EVENT_SUCCESS';
export const UPDATE_CALENDAR_EVENT_FAILURE = 'UPDATE_CALENDAR_EVENT_FAILURE';

export const DELETE_CALENDAR_EVENT_REQUEST = 'DELETE_CALENDAR_EVENT_REQUEST';
export const DELETE_CALENDAR_EVENT_SUCCESS = 'DELETE_CALENDAR_EVENT_SUCCESS';
export const DELETE_CALENDAR_EVENT_FAILURE = 'DELETE_CALENDAR_EVENT_FAILURE';

export const fetchCalendarEvents = (propertyId, a, b, published) => dispatch => {
  dispatch({
    type: FETCH_CALENDAR_EVENTS_REQUEST
  });
  let qry = '';
  if (a && b) {
    // strip off the timezones and don't force conversion
    const _a = moment(a).format('YYYY-MM-DDTHH:mm:ss');
    const _b = moment(b).format('YYYY-MM-DDTHH:mm:ss');

    qry = `start=~${_a}..${_b}`;
  }
  if (published === 1 || published === 0) {
    qry+= `&published=${published ? 'true' : 'false'}`
  }
  return fetch(`/properties/${propertyId}/calendar-events?${qry}`)
    .then(response => {
      const calendarEvents = response.data.map(convertToJS);
      dispatch({
        type: FETCH_CALENDAR_EVENTS_SUCCESS,
        payload: calendarEvents
      });
      return calendarEvents;
    })
    .catch(e => {
      dispatch({
        type: FETCH_CALENDAR_EVENTS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const fetchCalendarEvent = (propertyId, eventId) => dispatch => {
  dispatch({
    type: FETCH_CALENDAR_EVENT_REQUEST
  });
  return fetch(`/properties/${propertyId}/calendar-events/${eventId}`)
    .then(response => {
      const calendarEvent = convertToJS(response.data);
      dispatch({
        type: FETCH_CALENDAR_EVENT_SUCCESS,
        payload: calendarEvent
      });
      return calendarEvent;
    })
    .catch(e => {
      dispatch({
        type: FETCH_CALENDAR_EVENT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createCalendarEvent = (propertyId, body) => dispatch => {
  dispatch({
    type: CREATE_CALENDAR_EVENT_REQUEST
  });
  return fetch(`/properties/${propertyId}/calendar-events`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(body))
  })
    .then(response => {
      const calendarEvent = convertToJS(response.data);
      dispatch({
        type: CREATE_CALENDAR_EVENT_SUCCESS,
        payload: calendarEvent
      });
      return calendarEvent;
    })
    .catch(e => {
      dispatch({
        type: CREATE_CALENDAR_EVENT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateCalendarEvent = (
  propertyId,
  eventId,
  update
) => dispatch => {
  dispatch({
    type: UPDATE_CALENDAR_EVENT_REQUEST
  });
  return fetch(`/properties/${propertyId}/calendar-events/${eventId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const event = convertToJS(response.data);
      dispatch({
        type: UPDATE_CALENDAR_EVENT_SUCCESS,
        payload: event
      });
      return event;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_CALENDAR_EVENT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteCalendarEvent = (
  propertyId,
  eventId,
  redirectUrl
) => dispatch => {
  dispatch({
    type: DELETE_CALENDAR_EVENT_REQUEST
  });
  return fetch(`/properties/${propertyId}/calendar-events/${eventId}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch({
        type: DELETE_CALENDAR_EVENT_SUCCESS,
        payload: eventId
      });
      if (redirectUrl) {
        history.push(redirectUrl);
      }
      return eventId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_CALENDAR_EVENT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
