import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_TILED_PROMOS_REQUEST = 'FETCH_TILED_PROMOS_REQUEST';
export const FETCH_TILED_PROMOS_SUCCESS = 'FETCH_TILED_PROMOS_SUCCESS';
export const FETCH_TILED_PROMOS_FAILURE = 'FETCH_TILED_PROMOS_FAILURE';

export const FETCH_TILED_PROMO_REQUEST = 'FETCH_TILED_PROMO_REQUEST';
export const FETCH_TILED_PROMO_SUCCESS = 'FETCH_TILED_PROMO_SUCCESS';
export const FETCH_TILED_PROMO_FAILURE = 'FETCH_TILED_PROMO_FAILURE';

export const CREATE_TILED_PROMO_REQUEST = 'CREATE_TILED_PROMO_REQUEST';
export const CREATE_TILED_PROMO_SUCCESS = 'CREATE_TILED_PROMO_SUCCESS';
export const CREATE_TILED_PROMO_FAILURE = 'CREATE_TILED_PROMO_FAILURE';

export const UPDATE_TILED_PROMO_REQUEST = 'UPDATE_TILED_PROMO_REQUEST';
export const UPDATE_TILED_PROMO_SUCCESS = 'UPDATE_TILED_PROMO_SUCCESS';
export const UPDATE_TILED_PROMO_FAILURE = 'UPDATE_TILED_PROMO_FAILURE';

export const DELETE_TILED_PROMO_REQUEST = 'DELETE_TILED_PROMO_REQUEST';
export const DELETE_TILED_PROMO_SUCCESS = 'DELETE_TILED_PROMO_SUCCESS';
export const DELETE_TILED_PROMO_FAILURE = 'DELETE_TILED_PROMO_FAILURE';

export const MOVE_TILED_PROMO = 'MOVE_TILED_PROMO';

export const CLONE_TILED_PROMOS_REQUEST = 'CLONE_TILED_PROMOS_REQUEST';
export const CLONE_TILED_PROMOS_SUCCESS = 'CLONE_TILED_PROMOS_SUCCESS';
export const CLONE_TILED_PROMOS_FAILURE = 'CLONE_TILED_PROMOS_FAILURE';

export const SORT_TILED_PROMOS_REQUEST = 'SORT_TILED_PROMOS_REQUEST';
export const SORT_TILED_PROMOS_SUCCESS = 'SORT_TILED_PROMOS_SUCCESS';
export const SORT_TILED_PROMOS_FAILURE = 'SORT_TILED_PROMOS_FAILURE';

export const fetchTiledPromos = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_TILED_PROMOS_REQUEST
  });
  return fetch(
    path || `/properties/${propertyId}/tiled-promos${fromObj(qry, '?')}`
  )
    .then(response => {
      const promos = response.data.map(convertToJS);
      const meta = convertToJS(response.meta);
      dispatch({
        type: FETCH_TILED_PROMOS_SUCCESS,
        payload: promos,
        meta
      });
      return { data: promos, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_TILED_PROMOS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countTiledPromos = (propertyId, params) => dispatch => {
  return fetch(`/properties/${propertyId}/tiled-promos${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchTiledPromo = (propertyId, promoId) => dispatch => {
  dispatch({
    type: FETCH_TILED_PROMO_REQUEST
  });
  return fetch(`/properties/${propertyId}/tiled-promos/${promoId}`)
    .then(response => {
      const promo = convertToJS(response.data);
      dispatch({
        type: FETCH_TILED_PROMO_SUCCESS,
        payload: promo
      });
      return promo;
    })
    .catch(e => {
      dispatch({
        type: FETCH_TILED_PROMO_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createTiledPromo = (propertyId, body) => dispatch => {
  dispatch({
    type: CREATE_TILED_PROMO_REQUEST
  });
  return fetch(`/properties/${propertyId}/tiled-promos`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(body))
  })
    .then(response => {
      const promo = convertToJS(response.data);
      dispatch({
        type: CREATE_TILED_PROMO_SUCCESS,
        payload: promo
      });
      return promo;
    })
    .catch(e => {
      dispatch({
        type: CREATE_TILED_PROMO_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateTiledPromo = (propertyId, promoId, update) => dispatch => {
  dispatch({
    type: UPDATE_TILED_PROMO_REQUEST
  });
  return fetch(`/properties/${propertyId}/tiled-promos/${promoId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const promo = convertToJS(response.data);
      dispatch({
        type: UPDATE_TILED_PROMO_SUCCESS,
        payload: promo
      });
      return promo;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_TILED_PROMO_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteTiledPromo = (propertyId, promoId) => dispatch => {
  dispatch({
    type: DELETE_TILED_PROMO_REQUEST
  });
  return fetch(`/properties/${propertyId}/tiled-promos/${promoId}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch({
        type: DELETE_TILED_PROMO_SUCCESS,
        payload: promoId
      });
      return promoId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_TILED_PROMO_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveTiledPromo = (source, target) => ({
  type: MOVE_TILED_PROMO,
  payload: { source, target }
});

export const sortTiledPromos = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_TILED_PROMOS_REQUEST
  });
  return fetch(`/properties/${propertyId}/tiled-promos/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_TILED_PROMOS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_TILED_PROMOS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const cloneTiledPromos = (propertyId, ids, toPropertyIds) => (
  dispatch
) => {
  dispatch({
    type: CLONE_TILED_PROMOS_REQUEST
  });
  return fetch(`/properties/${propertyId}/tiled-promos/clone`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      ids,
      to_property_ids: toPropertyIds
    })
  })
    .then(response => {
      // const faq = convertToJS(response.data);
      dispatch({
        type: CLONE_TILED_PROMOS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: CLONE_TILED_PROMOS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
