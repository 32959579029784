import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import Uppy from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import uuidV4 from 'uuid/v4';
import DashboardModal from '@uppy/react/lib/DashboardModal';
import fetch from '../../utils/fetch';

const propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func.isRequired,
  onUploadSuccess: PropTypes.func
};

const defaultProps = {
  isOpen: false,
  onUploadSuccess: () => {}
};

// NOTE this seems to need to be outside the component
// https://uppy.io/docs/react/#use
const initUppy = (onRequestClose, onUploadSuccess) => {
  const id = uuidV4(); // unified id for both uppy instance and the AwsS3 plugin

  const uppy = Uppy({
    debug: true,
    autoProceed: false,
    id,
    restrictions: {
      maxFileSize: 3000000
    },
    onBeforeFileAdded: (currentFile) => {
      const modifiedFile = {
        ...currentFile,
        meta: {
          ...currentFile.meta,
          altName:
            currentFile.name
              .substring(
                0,
                currentFile.name.indexOf(currentFile.name.slice(-4))
              )
              .replace(/[^a-zA-Z0-9]/g, '-') + currentFile.name.slice(-4)
          // replace any special characters in basename
        }
      };
      return modifiedFile;
    }
  });
  
  uppy.use(AwsS3, {
      id,
      getUploadParameters(file) {
        const query = qs.stringify({
          filename: file.name,
          filetype: file.type,
          filesize: file.size,
          name: file.name
        });
        return fetch(`/assets/sign?${query}`)
          .then(response => {
            // Return an object in the correct shape.
            onRequestClose();
            return {
              method: 'PUT',
              url: response.data.signed_url,
              headers: { 'Access-Control-Allow-Origin': '*' },
              fields: response.data
            };
          })
          .catch(e => {
            // FIXME needs to actually be handled
            console.error(e); // eslint-disable-line
          });
      }
    })
    .on('upload-success', onUploadSuccess);

  return uppy;
};

const UploadModal = ({
  id,
  isOpen,
  onRequestClose,
  onUploadSuccess,
  ...otherProps
}) => {
  // create new instances of uppy
  const [uppy] = useState(() => initUppy(onRequestClose, onUploadSuccess));

  useEffect(() => {
    return () => {
      uppy.close(); // destroy the created uppy when unloading
    };
  }, []);

  return (
    <DashboardModal
      id={id}
      uppy={uppy}
      closeModalOnClickOutside
      open={isOpen}
      onRequestClose={onRequestClose}
      {...otherProps}
      metaFields={[
        // FIXME confirm that id: 'name' (name) is read-only, ref. https://app.asana.com/0/1119928407758199/1161252201150699/f
        { id: 'altName', name: 'Name', placeholder: 'file name' },
        {
          id: 'categories',
          name:
            'Categories: Home Promos, Specials, Specials Detail, Tiled Promos, Accommodations, Calendars, Hotel Logo',
          placeholder:
            'categories separated by comma (e.g. "Home Promos, Specials, Specials Detail, Tiled Promos, Calendars, Hotel Logo")'
        },
        // { id: 'description', name: 'Description', placeholder: 'description' },
        // { id: 'alt', name: 'Alt Tag', placeholder: 'alt tag' },
        {
          id: 'tags',
          name: 'Tags',
          placeholder:
            'tags separated by comma (e.g. "Christmas, Birthdays, Thanksgiving")'
        },
      ]}
    />
  );
};

UploadModal.propTypes = propTypes;
UploadModal.defaultProps = defaultProps;

export default UploadModal;
