import fetch from '../utils/fetch';
import { convertToJS } from '../utils/convert';

export const FETCH_TAGS_REQUEST = 'FETCH_TAGS_REQUEST';
export const FETCH_TAGS_SUCCESS = 'FETCH_TAGS_SUCCESS';
export const FETCH_TAGS_FAILURE = 'FETCH_TAGS_FAILURE';

export const fetchTags = () => dispatch => {
  dispatch({
    type: FETCH_TAGS_REQUEST
  });
  return fetch('/tags')
    .then(response => {
      const tags = response.data.map(convertToJS);
      dispatch({
        type: FETCH_TAGS_SUCCESS,
        payload: tags
      });
      return tags;
    })
    .catch(e => {
      dispatch({
        type: FETCH_TAGS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
