const ctaTitleOptions = [
  'Book Now',
  'Inquire Now',
  'Learn More',
  'Take A Tour',
  'None',
  'Other',
].map(opt => ({ id: opt, name: opt }));

export default ctaTitleOptions;
