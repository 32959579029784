import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import ChevronDownIcon from '../../common/ChevronDownIcon';

const propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  active: PropTypes.bool,
  isBackLink: PropTypes.bool,
  displayRightChevron: PropTypes.bool,
  onClick: PropTypes.func
};

const defaultProps = {
  active: false,
  isBackLink: false,
  displayRightChevron: false,
  onClick: () => {}
};

// eslint-disable-next-line react/prop-types
const ChevronIcon = ({ deg = '90deg', name = 'back' }) => {
  return (
    <div data-chevron-icon={name} style={{ transform: `rotate(${deg})` }}>
      <ChevronDownIcon id="sidebar-item" style={{ width: 12 }} fill="#606f7b" />
    </div>
  );
};

// eslint-disable-next-line react/prop-types
const Container = ({ url, isBackLink, displayRightChevron, onClick, children }) => {
  const className = classnames(
    'px-8 py-3 text-sm text-grey-darker hover:text-black no-underline flex',
    { 'pl-0': isBackLink },
    { 'pr-3': displayRightChevron }
  );
  return (
    <Link to={url} className={className} onClick={onClick}>
      {children}
    </Link>
  );
};

const SidebarItem = ({
  url,
  children,
  active,
  isBackLink,
  displayRightChevron,
  onClick
}) => {
  return (
    <Container
      url={url}
      isBackLink={isBackLink}
      displayRightChevron={displayRightChevron}
      onClick={onClick}
    >
      {isBackLink && <ChevronIcon />}
      <div
        className={classnames(
          'flex-1',
          { 'pl-4': isBackLink },
          { 'font-normal text-blue': active && !isBackLink }
        )}
      >
        {children}
      </div>
      {displayRightChevron && <ChevronIcon deg="270deg" name="right" />}
    </Container>
  );
};

SidebarItem.propTypes = propTypes;
SidebarItem.defaultProps = defaultProps;

export default SidebarItem;
