import fetch from '../utils/fetch';
import { convertToJS } from '../utils/convert';

export const CREATE_GLOBAL_ALERT_REQUEST = 'CREATE_GLOBAL_ALERT_REQUEST';
export const CREATE_GLOBAL_ALERT_SUCCESS = 'CREATE_GLOBAL_ALERT_SUCCESS';
export const CREATE_GLOBAL_ALERT_FAILURE = 'CREATE_GLOBAL_ALERT_FAILURE';

export const FETCH_GLOBAL_ALERT_REQUEST = 'FETCH_GLOBAL_ALERT_REQUEST';
export const FETCH_GLOBAL_ALERT_SUCCESS = 'FETCH_GLOBAL_ALERT_SUCCESS';
export const FETCH_GLOBAL_ALERT_FAILURE = 'FETCH_GLOBAL_ALERT_FAILURE';

export const DELETE_GLOBAL_ALERT_REQUEST = 'DELETE_GLOBAL_ALERT_REQUEST';
export const DELETE_GLOBAL_ALERT_SUCCESS = 'DELETE_GLOBAL_ALERT_SUCCESS';
export const DELETE_GLOBAL_ALERT_FAILURE = 'DELETE_GLOBAL_ALERT_FAILURE';

export const createGlobalAlert = alertId => dispatch => {
  dispatch({
    type: CREATE_GLOBAL_ALERT_REQUEST
  });
  return fetch(`/alerts/${alertId}/global`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(response => {
      const alert = convertToJS(response.data);
      dispatch({
        type: CREATE_GLOBAL_ALERT_SUCCESS,
        payload: alert
      });
      return alert;
    })
    .catch(e => {
      dispatch({
        type: CREATE_GLOBAL_ALERT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const fetchGlobalAlert = () => dispatch => {
  dispatch({
    type: FETCH_GLOBAL_ALERT_REQUEST
  });
  return fetch(`/alerts/global`)
    .then(response => {
      const alert = response.data ? convertToJS(response.data) : {};
      dispatch({
        type: FETCH_GLOBAL_ALERT_SUCCESS,
        payload: alert
      });
      return alert;
    })
    .catch(e => {
      dispatch({
        type: FETCH_GLOBAL_ALERT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteGlobalAlert = () => dispatch => {
  dispatch({
    type: DELETE_GLOBAL_ALERT_REQUEST
  });
  return fetch('/alerts/global', {
    method: 'DELETE'
  })
    .then(response => {
      dispatch({
        type: DELETE_GLOBAL_ALERT_SUCCESS,
        payload: {}
      });
    })
    .catch(e => {
      dispatch({
        type: DELETE_GLOBAL_ALERT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
