
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '../common/Dropdown';
import SearchBar, {
  SearchBarMenu,
  SearchBarMenuItem
} from '../common/SearchBar';
import * as assetActions from '../../actions/assets';
import { inputHandlerFunc, fetchHandlerFunc } from '../../utils/fetch-helpers';

const searchTypes = [
  ['tags', 'Tags'],
  ['filename', 'File Name'],
];

const propTypes = {
  name: PropTypes.string,
  fetchAssets: PropTypes.func.isRequired,
  resetAssets: PropTypes.func.isRequired,
  condfn: PropTypes.func,
  clickMenuItemHandler: PropTypes.func,
  data: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const defaultProps = {
  // name is the appended value to dom IDs. These are not required but would be
  // applicable if using multiple search bars in a single page
  name: '',

  // condfn is a prop passed function that returns an object with any where
  // conditions to be used to search the given resource. The one argument that
  // will be passed to condfn is the searchInput value is available
  condfn: val => ({}),              /* eslint-disable-line no-unused-vars */

  // clickMenuItemHandler is the onClick handler for selecting a menu item from
  // the searched assets list.
  clickMenuItemHandler: img => {}   /* eslint-disable-line no-unused-vars */
};

const AssetSearchBar = ({
  name,
  fetchAssets,
  resetAssets,
  data,
  meta,
  isLoading,
  condfn,
  clickMenuItemHandler
}) => {
  const [searchBarRef, setSearchBarRef] = useState(null);
  const [isActive, setIsActive] = useState(0);
  const [searchType, setSearchType] = useState(searchTypes[0][0]);
  const [searchInput, setSearchInput] = useState('');

  // fetchAssetd is a debounced fetchAssets to use for scrolling to paginate for
  // additional results
  const fetchAssetsd = debounce(fetchAssets, 500);

  // searchHandler is the pagination helper to throttle keypressed search
  const searchHandler = inputHandlerFunc(
    setSearchInput,
    useCallback(debounce(fetchHandlerFunc(fetchAssets), 500), []),
    val => {
      let cond = condfn();
      switch (searchType) {
        case 'tags':
          cond.q = val;
          break;
        case 'filename':
          // NOTE we are searching the "name" field, "filename" is a generated
          // UUID style name and is not meant to be directly searched. Name is
          // the "human" named version for the file. It would also be the
          // original name of the file in the user's directory at upload
          // The use of "filename" (File Name) is just for cosmetic purposes
          cond.name = val;
          break;
          
        default:
          break;
      }
      return cond;
    }
  );

  useEffect(() => {
    setSearchBarRef(React.createRef());
  }, []);

  useEffect(() => {
    // we don't want to run the search on the initial load, so make it "active"
    // when a search input is input
    if (searchInput) setIsActive(1);

    // NOTE isActive forces there to be 2 characters to be typed since the
    // setIsActive call right above is async
    if (isActive && searchInput) searchHandler(searchInput);

    return () => {};
  }, [searchInput, searchType]);

  return (
    <>
      <div className="mb-3">Search By:</div>
      <Dropdown id="search-type" className="flex-grow xl:flex-1 mb-3">
        {searchTypes.map(([key, label]) => (
          <DropdownItem
            key={key}
            id={key}
            selected={searchType === key}
            onClick={() => {
              resetAssets();
              setSearchType(key);

              // if there is a searchInput value and we are switching the search
              // type trigger an isOpen state since the dropdown will be closed
              // due to the input onfocus event when the user goes to change the
              // search type
              if (searchInput) {
                searchBarRef.current.instanceRef.setIsOpen(true);
              }
            }}
          >
            {label}
          </DropdownItem>
        ))}
      </Dropdown>
      <SearchBar
        ref={searchBarRef}
        id={`asset-search-bar-${name}`}
        placeholder="Enter Text..."
        className="mb-3"
        value={searchInput}
        onChange={({ value }) => setSearchInput(value)}
        onClick={() => {
          // if a user unfocuses off the input it closes the dropdown. This will
          // reopen the dropdown if there is a search value as well as results
          // to display
          //
          // NOTE removing this onClick prop will effectively do this as the
          // SearchBar has a default click handler which opens the dropdown when
          // clicked. However, it opens a dropdown even prior to typing. This
          // will only display a dropdown if there is an actual search going on.
          if (searchInput && data.length) {
            searchBarRef.current.instanceRef.setIsOpen(true);
          }
        }}
      >
        <SearchBarMenu
          id={`asset-search-bar-${name}__image-menu`}
          onScroll={evt => {
            if (isLoading) {
              return;
            }

            const { scrollHeight, scrollTop, clientHeight } = evt.target;
            const atBottom = scrollHeight - scrollTop === clientHeight;

            // FIXME this needs to be fixed, it's a bit clunky and not as smooth as I'd like
            if (!isLoading && atBottom && meta && meta.links.next) {
              /* eslint-disable no-param-reassign */
              // change the height offset or it sticks as the bottom and
              // becomes an infinite loop till all pages are called.
              const offset = clientHeight - 8;
              evt.target.scrollTop = offset;

              fetchAssetsd(null, meta.links.next, true);
            }
          }}
        >
          {data.map(image => (
            <SearchBarMenuItem
              key={image.id}
              id={image.id}
              className="flex items-center text-sm text-grey-darkest"
              autocomplete="off"
              onClick={e => {
                e.preventDefault();
                clickMenuItemHandler(image);

                setSearchInput('');
                resetAssets();
              }}
            >
              <img alt={image.alt} src={image.url} className="w-48 mr-3" />
              <div>
                <span>{image.name}</span>
                <br />
                <span>Tags: </span>
                {image.tags.map((tag, i) => (
                  <span key={tag.id} className="font-light">
                    {i > 0 && ', '}
                    {tag.name}
                  </span>
                ))}
              </div>
            </SearchBarMenuItem>
          ))}
        </SearchBarMenu>
      </SearchBar>
    </>
  );
};

AssetSearchBar.propTypes = propTypes;
AssetSearchBar.defaultProps = defaultProps;

export default compose(
  connect(
    (state, props) => ({
      ...state.assets,
      ...props
    }),
    { ...assetActions }
  )
)(AssetSearchBar);
