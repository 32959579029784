import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import Layout from '../../../../layout';
import * as propertyActions from '../../../../actions/properties';
import * as sidebarActions from '../../../../actions/sidebar';
import * as categoriesActions from '../../../../actions/categories';
import HotelFAQCategoryForm from '../../../../components/HotelFAQCategoryForm';
import FormNavigator from '../../../../components/FormNavigator';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  category: PropTypes.object.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  fetchCategory: PropTypes.func.isRequired,
  updateCategory: PropTypes.func.isRequired
};

const sections = [
  {
    id: 'name',
    title: 'FAQ Category Name'
  },
];

const Edit = ({
  match: {
    params: { hotelid, id }
  },
  history: { push },
  hotel,
  category,
  fetchProperty,
  updateSidebar,
  fetchCategory,
  updateCategory
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchCategory(hotelid, id);
    return () => {};
  }, []);

  const handleSubmit = data => {
    updateCategory(hotelid, id, data)
      .then(() => push(`/hotels/${hotelid}/faqs/categories`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout metaTitle="Edit FAQ Category">
      <div className="w-full max-w-3xl">
        <h1 className="mb-6">Edit FAQ Category</h1>
        <div className="mb-6">
          Use this page to edit {category.name} FAQ Category for {hotel.name}.
        </div>
        <Link
          to={`/hotels/${hotelid}/faqs/categories`}
          className="mb-12 text-black inline-block"
        >
          &lt; Back to FAQ Category
        </Link>

        <HotelFAQCategoryForm
          id="edit-faq-category"
          onSubmit={handleSubmit}
          initialValues={category}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
      <FormNavigator id="faq-category-navigator" sections={sections} />
    </Layout>
  );
};

Edit.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    category: state.category.data,
    isLoading: state.property.isLoading || state.category.isLoading
  }),
  { ...propertyActions, ...sidebarActions, ...categoriesActions }
)(Edit);
