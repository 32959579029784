import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Input from '../../common/Input';
import repeatOptions from '../../../utils/repeat-options';
import FormDropdown from '../../common/form/Dropdown';
import FormDatePicker from '../../common/form/DatePicker';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  repeatOption: PropTypes.string,
  eventEndsOption: PropTypes.string
};

const defaultProps = {
  repeatOption: '',
  eventEndsOption: ''
};
const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required!';
  }
  if (
    values.repeatOption === 'weekly' &&
    values.sunday !== true &&
    values.monday !== true &&
    values.tuesday !== true &&
    values.wednesday !== true &&
    values.thursday !== true &&
    values.friday !== true &&
    values.saturday !== true
  ) {
    errors.repeatOption = 'Please choose a day to repeat on.';
    // values.subject = '';
  }

  return errors;
};

const eventEndsOptions = [
  'Repeats Forever',
  'On Date ...',
  'After ... occurances',
].map(item => ({
  id: item,
  name: item
}));

const CalendarEventRepeatForm = ({
  handleSubmit,
  repeatOption,
  eventEndsOption
}) => (
  <form onSubmit={handleSubmit}>
    <div className="my-4 justify-center text-grey-darker">
      <div className="flex mx-3">
        <div className="w-1/2">
          <div className="mb-3">Repeat frequency</div>
          <div className="mb-6">
            <Field
              id="repeatOption"
              name="repeatOption"
              className=""
              component={FormDropdown}
              data={repeatOptions}
            />
          </div>

          {repeatOption === 'weekly' && (
            <Fragment>
              <div className="mb-1">Repeats on</div>
              <div className="mb-3 flex">
                <div className="flex items-center">
                  <Field
                    id="sunday"
                    name="sunday"
                    type="checkbox"
                    className="my-4 mr-1"
                    component={Input}
                  />
                  <div className="mr-3">Su</div>
                </div>
                <div className="flex items-center">
                  <Field
                    id="monday"
                    name="monday"
                    type="checkbox"
                    className="my-4 mr-1"
                    component={Input}
                  />
                  <div className="mr-3">M</div>
                </div>
                <div className="flex items-center">
                  <Field
                    id="tuesday"
                    name="tuesday"
                    type="checkbox"
                    className="my-4 mr-1"
                    component={Input}
                  />
                  <div className="mr-3">T</div>
                </div>
                <div className="flex items-center">
                  <Field
                    id="wednesday"
                    name="wednesday"
                    type="checkbox"
                    className="my-4 mr-1"
                    component={Input}
                  />
                  <div className="mr-3">W</div>
                </div>
                <div className="flex items-center">
                  <Field
                    id="thursday"
                    name="thursday"
                    type="checkbox"
                    className="my-4 mr-1"
                    component={Input}
                  />
                  <div className="mr-3">TH</div>
                </div>
                <div className="flex items-center">
                  <Field
                    id="friday"
                    name="friday"
                    type="checkbox"
                    className="my-4 mr-1"
                    component={Input}
                  />
                  <div className="mr-3">F</div>
                </div>
                <div className="flex items-center">
                  <Field
                    id="saturday"
                    name="saturday"
                    type="checkbox"
                    className="my-4 mr-1"
                    component={Input}
                  />
                  <div className="mr-3">Sa</div>
                </div>
              </div>
            </Fragment>
          )}
        </div>
        {repeatOption !== 'none' && (
          <div className="w-1/2">
            <div className="mb-3">Event ends</div>
            <div className="mb-6 flex">
              <Field
                id="eventEndsOption"
                name="eventEndsOption"
                className=""
                component={FormDropdown}
                data={eventEndsOptions}
              />
            </div>
            {eventEndsOption === 'After ... occurances' && (
              <div className="mb-3 flex">
                <div className="flex w-full items-center">
                  <div className="mr-3">After </div>
                  <Field
                    id="repeatsTimes"
                    name="repeatsTimes"
                    component={Input}
                    className="w-12 mr-3"
                    placeholder="0"
                  />
                  <div className="mr-3">occurances </div>
                </div>
              </div>
            )}
            {eventEndsOption === 'On Date ...' && (
              <div className="mb-3 flex">
                <div className="flex w-full items-center">
                  <div className="mr-3">On Date </div>

                  <Field
                    id="repeatsEndsOn"
                    name="repeatsEndsOn"
                    component={FormDatePicker}
                    className="w-12"
                    dateFormat="MM/dd/yyyy"
                    placeholder="mm/dd/yyyy"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
    <div className="flex justify-center my-6">
      <button type="submit" className="btn">
        Save
      </button>
    </div>
  </form>
);

CalendarEventRepeatForm.propTypes = propTypes;
CalendarEventRepeatForm.defaultProps = defaultProps;
const selector = formValueSelector('CalendarEventRepeatForm');

export default compose(
  reduxForm({ form: 'CalendarEventRepeatForm', validate }),
  connect(state => ({
    repeatOption: selector(state, 'repeatOption'),
    eventEndsOption: selector(state, 'eventEndsOption')
  }))
)(CalendarEventRepeatForm);
