export const MODAL_SHOW = 'MODAL_SHOW';
export const MODAL_HIDE = 'MODAL_HIDE';
export const MODAL_UPDATE_STYLES = 'MODAL_UPDATE_STYLES';

export const showModal = (name, data, onCloseHandler = () => {}) => {
  if (typeof window !== 'undefined') {
    document.body.style.top = `-${window.pageYOffset}px`;
  }
  return {
    type: MODAL_SHOW,
    payload: { name, data, onCloseHandler }
  };
};

export const hideModal = () => dispatch => {
  dispatch({
    type: MODAL_HIDE
  });
  return Promise.resolve();
};

export const updateStyles = payload => ({
  type: MODAL_UPDATE_STYLES,
  payload
});
