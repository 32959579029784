import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Input from '../../common/Input';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const defaultProps = {};
const validate = values => {
  const errors = {};
  if (!values.title) {
    errors.title = 'Required!';
  }

  return errors;
};

const EditCalendarForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="my-4 justify-center text-grey-darker">
      <div className="flex mx-3">
        <div className="w-1/2">
          <div className="mb-3">Calendar Name</div>
          <div className="flex w-full items-center">
            <Field
              id="calendarName"
              name="calendarName"
              component={Input}
              className="mr-3"
            />
          </div>
        </div>
        <div className="w-1/2">
          <div className="mb-3">Calendar ID</div>
          <div className="flex w-full items-center">
            <Field
              id="calendarId"
              name="calendarId"
              component={Input}
              className="mr-3"
              disabled="true"
            />
          </div>
        </div>
      </div>
    </div>
    <div className="flex justify-center my-6">
      <button type="submit" className="btn">
        Save
      </button>
    </div>
  </form>
);

EditCalendarForm.propTypes = propTypes;
EditCalendarForm.defaultProps = defaultProps;

// const selector = formValueSelector('EditCalendarForm');

export default compose(
  reduxForm({ form: 'EditCalendarForm', validate }),
  connect(state => ({}))
)(EditCalendarForm);
