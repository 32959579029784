import { moveBySplice } from '../utils/move_by_splice';
import {
  FETCH_EVENTS_REQUEST,
  FETCH_EVENTS_FAILURE,
  FETCH_EVENTS_SUCCESS,
  FETCH_EVENT_REQUEST,
  FETCH_EVENT_SUCCESS,
  FETCH_EVENT_FAILURE,
  CREATE_EVENT_REQUEST,
  CREATE_EVENT_SUCCESS,
  CREATE_EVENT_FAILURE,
  UPDATE_EVENT_REQUEST,
  UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_FAILURE,
  DELETE_EVENT_REQUEST,
  DELETE_EVENT_FAILURE,
  DELETE_EVENT_SUCCESS,
  MOVE_EVENT,
  SORT_EVENTS_REQUEST,
  SORT_EVENTS_SUCCESS,
  SORT_EVENTS_FAILURE
} from '../actions/events';

export function events(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_EVENTS_REQUEST:
    case SORT_EVENTS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_EVENTS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case MOVE_EVENT: {
      const { source, target } = payload;
      const data = moveBySplice(source, target, state.data);

      return { ...state, data };
    }

    case SORT_EVENTS_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_EVENTS_FAILURE:
    case SORT_EVENTS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function event(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_EVENT_REQUEST:
    case CREATE_EVENT_REQUEST:
    case UPDATE_EVENT_REQUEST:
    case DELETE_EVENT_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_EVENT_SUCCESS:
    case CREATE_EVENT_SUCCESS:
    case UPDATE_EVENT_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_EVENT_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_EVENT_FAILURE:
    case CREATE_EVENT_FAILURE:
    case UPDATE_EVENT_FAILURE:
    case DELETE_EVENT_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
