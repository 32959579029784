import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import Input from '../common/Input';
import * as modalActions from '../../actions/modals';
import { isImage, isDocument } from '../../utils/assets';
import categoriesData from '../../utils/categories';

const propTypes = {
  id: PropTypes.string.isRequired,
  assetId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  filetype: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired
};

const validate = data => {
  const errors = {};
  if (!data.name) {
    errors.name = 'Required!';
  }
  const categories = data.categories ? data.categories.split(', ') : [];
  const allCategoriesExist = categories.every(
    cat => categoriesData.indexOf(cat) > -1
  );

  if (!allCategoriesExist) {
    errors.categories = 'One or more categories do not exist';
  }
  return errors;
};

const MediaAssetForm = ({
  id,
  assetId,
  name,
  alt,
  url,
  filetype,
  handleSubmit,
  showModal
}) => (
  <form id={id} onSubmit={handleSubmit}>
    {isImage({ filetype }) && (
      <div className="flex items-center justify-center">
        <img alt={alt} src={url} className="md:max-w-xs pb-6" />
      </div>
    )}
    {isDocument({ filetype }) && (
      <div>
        View document: <a href={url}>{name}</a>
      </div>
    )}
    <div className="flex flex-wrap my-4 bg-white py-6 md:p-10 rounded-sm shadow-md border-t-2 border-blue-lighter mb-10">
      <div className="p-4 w-full md:w-1/2">
        <div className="mb-3">File Name</div>
        <Field
          id="name"
          name="name"
          component={Input}
          className="w-full"
          placeholder="File Name"
          disabled
        />
      </div>
      <div className="p-4 w-full md:w-1/2">
        <div className="mb-3">File Type</div>
        <Field
          id="filetype"
          name="filetype"
          component={Input}
          className="w-full"
          placeholder="File Type"
          disabled
        />
      </div>
      <div className="p-4 w-full md:w-1/2">
        <div className="mb-3">
          Categories:
          <br />
          (Home Promos, Specials, Specials Detail, Tiled Promos, Accommodations,
          Calendars, Hotel Logo)
        </div>
        <Field
          id="categories"
          name="categories"
          component={Input}
          className="w-full"
          placeholder='categories separated by comma (e.g. "Tiled Promos, Specials, Specials Detail, Tiled Promos, Accommodations, Calendars, Hotel Logo")'
        />
      </div>
      <div className="p-4 w-full md:w-1/2">
        <div className="mb-3">
          <br />
          Tags
        </div>
        <Field
          id="tags"
          name="tags"
          component={Input}
          className="w-full"
          placeholder='tags separated by comma (e.g. "Christmas, Birthdays, Thanksgiving")'
        />
      </div>
    </div>
    <div className="flex flex-wrap overflow-hidden sm:-mx-2">
      <div className="w-full sm:w-1/2 overflow-hidden my-1 sm:px-2">
        <button
          type="button"
          className="btn btn-clear w-full md:w-1/3 sm:float-right"
          onClick={e => {
            e.preventDefault();
            showModal('DeleteAssetModal', {
              id: assetId,
              title: name,
              redirectUrl: `/media`
            });
          }}
        >
          Delete
        </button>
      </div>
      <div className="w-full sm:w-1/2 overflow-hidden my-1 sm:px-2">
        <button type="submit" className="btn w-full md:w-1/3 sm:float-left">
          Save
        </button>
      </div>
    </div>
  </form>
);

MediaAssetForm.propTypes = propTypes;

const selector = formValueSelector('MediaAssetForm');

export default compose(
  reduxForm({ form: 'MediaAssetForm', validate }),
  connect(
    state => ({
      assetId: selector(state, 'id'),
      name: selector(state, 'name'),
      alt: selector(state, 'alt'),
      url: selector(state, 'url'),
      filetype: selector(state, 'filetype')
    }),
    modalActions
  )
)(MediaAssetForm);
