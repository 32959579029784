import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import ExitButton from '../../common/ExitButton';
import CalendarEventRepeatForm from './CalendarEventRepeatForm';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired
};

const CalendarEventRepeatModal = ({
  onClose,
  data: {
    form,
    field,
    repeatOption,
    eventEndsOption,
    repeatsOn,
    repeatsTimes,
    repeatsEndsOn
  },
  // FIXME this is an import, does this need to go through redux?
  change // eslint-disable-line no-shadow
}) => {
  const initialValues = {
    repeatOption,
    repeatsTimes,
    eventEndsOption: eventEndsOption || 'Repeats Forever',
    repeatsEndsOn: repeatsEndsOn ? new Date(repeatsEndsOn) : null
  };
  (repeatsOn || []).forEach(v => {
    initialValues[v] = true;
  });
  return (
    <div id="calendar-event-repeat-modal">
      <div className="flex justify-center border-b py-2 px-4 border-grey bg-grey-lighter">
        <h2 className="font-light">Repeat for Calendar Event</h2>
        <ExitButton
          id="calendar-event-repeat-exit"
          onClick={onClose}
          className="absolute"
          divStyle={{ right: '.5rem' }}
          svgStyle={{ fill: 'black' }}
        />
      </div>
      <div className="p-6">
        <CalendarEventRepeatForm
          initialValues={initialValues}
          id="generate-modal-form"
          onSubmit={data => {
            // FIXME this onsubmit (and others actually) should not be inline
            const repeatsOnDays = [];
            // eslint-disable-next-line no-shadow
            let repeatsEndsOn = null;
            // eslint-disable-next-line no-shadow
            let { repeatsTimes, eventEndsOption } = data;

            if (data.repeatsEndsOn != null) {
              repeatsEndsOn = data.repeatsEndsOn.toJSON();
            }
            if (data.repeatOption === 'none') {
              eventEndsOption = '';
              repeatsEndsOn = null;
              repeatsTimes = null;
            }
            if (data.repeatOption === 'weekly') {
              if (data.sunday === true) {
                repeatsOnDays.push('sunday');
              }
              if (data.monday === true) {
                repeatsOnDays.push('monday');
              }
              if (data.tuesday === true) {
                repeatsOnDays.push('tuesday');
              }
              if (data.wednesday === true) {
                repeatsOnDays.push('wednesday');
              }
              if (data.thursday === true) {
                repeatsOnDays.push('thursday');
              }
              if (data.friday === true) {
                repeatsOnDays.push('friday');
              }
              if (data.saturday === true) {
                repeatsOnDays.push('saturday');
              }
            }
            if (data.eventEndsOption === 'On Date ...') {
              repeatsTimes = null;
            }
            if (data.eventEndsOption === 'After ... occurances') {
              repeatsEndsOn = null;
            }
            if (data.eventEndsOption === 'Repeats Forever') {
              repeatsTimes = null;
              repeatsEndsOn = null;
            }

            change(form, field, `${data.repeatOption}`);
            change(form, 'eventEndsOption', eventEndsOption);
            change(form, 'repeatsOn', repeatsOnDays);
            change(form, 'repeatsTimes', repeatsTimes);
            change(form, 'repeatsEndsOn', repeatsEndsOn);
            onClose();
          }}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
    </div>
  );
};

CalendarEventRepeatModal.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data
  }),
  {
    change
  }
)(CalendarEventRepeatModal);
