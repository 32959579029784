import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

const defaultProps = {
  className: undefined,
  style: null
};

const PencilIcon = ({ id, className, style, ...otherProps }) => (
  <svg
    id={id}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    viewBox="0 0 512 512"
    {...otherProps}
  >
    <path d="M64 368v80h80l235.727-235.729-79.999-79.998L64 368zm377.602-217.602c8.531-8.531 8.531-21.334 0-29.865l-50.135-50.135c-8.531-8.531-21.334-8.531-29.865 0l-39.468 39.469 79.999 79.998 39.469-39.467z" />
  </svg>
);

PencilIcon.propTypes = propTypes;
PencilIcon.defaultProps = defaultProps;

export default PencilIcon;
