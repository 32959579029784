import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import Layout from '../../../../layout';
import modalTitles from '../../../../utils/modal-title-options';
import ctaTitleOptions from '../../../../utils/cta-title-options-tiled-promos';
import * as propertyActions from '../../../../actions/properties';
import * as tiledPromoActions from '../../../../actions/tiledPromos';
import * as sidebarActions from '../../../../actions/sidebar';
import * as imageActions from '../../../../actions/images';
import HotelMeetingForm from '../../../../components/HotelMeetingForm';
import FormNavigator from '../../../../components/FormNavigator';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  misc3: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  updateTiledPromo: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchTiledPromo: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'upload', title: 'Upload a PDF' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'preview', title: 'Preview + Publish' }
];

const Misc3Edit = ({
  hotel,
  misc3,
  images,
  isLoading,
  match: {
    params: { hotelid, id }
  },
  history: { push },
  updateTiledPromo,
  fetchProperty,
  fetchTiledPromo,
  updateSidebar,
  setCurrentImage
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchTiledPromo(hotelid, id);
    return () => {};
  }, []);

  useEffect(() => {
    get(misc3, 'images', []).forEach(image => {
      setCurrentImage('hotel-promos-image', image);
    });
    return () => {};
  }, [misc3]);

  const handleSubmit = data => {
    /* eslint-disable no-param-reassign */
    let { pdf } = data;
    pdf = pdf && pdf.id ? { assetId: pdf.id } : null;

    const update = {
      ...data,
      pdf,
      startsOn: data.startsOn.toJSON(),
      endsOn: data.endsOn ? data.endsOn.toJSON() : data.endsOn,
      images,
      modal1Title:
        // eslint-disable-next-line no-nested-ternary
        data.modal1Title === 'None'
          ? null
          : data.modal1Title === 'Other'
          ? data.modal1TitleOther
          : data.modal1Title,
      modal2Title:
        // eslint-disable-next-line no-nested-ternary
        data.modal2Title === 'None'
          ? null
          : data.modal2Title === 'Other'
          ? data.modal2TitleOther
          : data.modal2Title,
      cta: Object.assign(
        data.cta,
        data.cta.type === 'Other' && { type: data.cta.typeInput }
      )
    };
    return updateTiledPromo(hotelid, id, update)
      .then(() => push(`/hotels/${hotelid}/tiled/misc3`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };
  return (
    <Layout metaTitle="Edit Misc3 Promo">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to={`/hotels/${hotelid}/tiled/misc3`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Misc3 Promos
          </Link>
          <h1 className="mb-6">Edit Misc3 Promo</h1>
          <div className="mb-16">
            Use this page to edit {misc3.title} Misc3 Promo on {hotel.name}.
          </div>
          <HotelMeetingForm
            id="edit-miscs-form"
            category="Misc3"
            hotel={hotel}
            initialValues={{
              ...misc3,
              // eslint-disable-next-line no-nested-ternary
              modal1Title: !misc3.modal1Title
                ? 'None'
                : modalTitles.findIndex(
                    title => title.id === misc3.modal1Title
                  ) === -1
                ? 'Other'
                : misc3.modal1Title,
              modal1TitleOther:
                modalTitles.findIndex(
                  title => title.id === misc3.modal1Title
                ) === -1
                  ? misc3.modal1Title
                  : undefined,
              // eslint-disable-next-line no-nested-ternary
              modal2Title: !misc3.modal2Title
                ? 'None'
                : modalTitles.findIndex(
                    title => title.id === misc3.modal2Title
                  ) === -1
                ? 'Other'
                : misc3.modal2Title,
              modal2TitleOther:
                modalTitles.findIndex(
                  title => title.id === misc3.modal2Title
                ) === -1
                  ? misc3.modal2Title
                  : undefined,
              showmodal2: !!misc3.modal2Title,
              cta: misc3.cta && {
                ...misc3.cta,
                type:
                  ctaTitleOptions.findIndex(
                    title => title.id === get(misc3, 'cta.type')
                  ) === -1
                    ? 'Other'
                    : get(misc3, 'cta.type'),
                typeInput:
                  ctaTitleOptions.findIndex(
                    title => title.id === get(misc3, 'cta.type')
                  ) === -1
                    ? get(misc3, 'cta.type')
                    : undefined
              },
              startsOn: misc3.startsOn
                ? new Date(misc3.startsOn)
                : misc3.startsOn,
              endsOn: misc3.endsOn ? new Date(misc3.endsOn) : misc3.endsOn
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
      )}
      <FormNavigator
        id="edit-miscs-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

Misc3Edit.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    misc3: state.tiledPromo.data,
    images: get(state, 'images.hotel-promos-image.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt })),
    isLoading: state.property.isLoading || state.tiledPromo.isLoading
  }),
  {
    ...propertyActions,
    ...sidebarActions,
    ...tiledPromoActions,
    ...imageActions
  }
)(Misc3Edit);
