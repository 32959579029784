import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeleteModal from '../DeleteModal';
import * as specialActions from '../../../actions/specials';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteSpecial: PropTypes.func.isRequired
};

const DeleteSpecialModal = ({ data, deleteSpecial, ...props }) => (
  <DeleteModal
    id="delete-special-modal"
    title="Special"
    itemTitle={data.title}
    deleteItem={() => deleteSpecial(data.hotelId, data.id)}
    {...props}
  />
);

DeleteSpecialModal.propTypes = propTypes;

export default connect(
  null,
  specialActions
)(DeleteSpecialModal);
