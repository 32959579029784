import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import Layout from '../../layout';
import ResetPasswordForm from '../../components/ResetPasswordForm';
import * as resetPasswordActions from '../../actions/resetPassword';

const propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  resetPassword: PropTypes.object.isRequired,
  fetchResetPassword: PropTypes.func.isRequired,
  updateResetPassword: PropTypes.func.isRequired
};

const Profile = ({
  history,
  match: {
    params: { resetToken }
  },
  resetPassword,
  fetchResetPassword,
  updateResetPassword
}) => {
  useEffect(() => {
    fetchResetPassword(resetToken).catch(err => {
      history.push('/forgot-password');
    });
    return () => {};
  }, []);
  const handleSubmit = data => {
    const { password } = data;
    return updateResetPassword(resetPassword.resetToken, { password })
      .then(() => {
        history.push('/');
      })
      .catch(err => {
        throw new SubmissionError({
          _error: 'There was an error resetting your password.'
        });
      });
  };
  const handleCancel = e => {
    e.preventDefault();
    history.push('/');
  };
  return (
    <Layout metaTitle="Reset Password">
      <div className="w-full max-w-3xl">
        <h1 className="mb-6">Reset Password</h1>
        <div className="mb-16">Use this page to reset your password.</div>

        <ResetPasswordForm
          id="reset-password"
          onSubmit={handleSubmit}
          onCancel={handleCancel}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
    </Layout>
  );
};

Profile.propTypes = propTypes;

export default connect(
  state => ({
    resetPassword: state.resetPassword.data,
    profile: state.auth.profile,
    isLoading: state.auth.isLoading || state.resetPassword.isLoading
  }),
  { ...resetPasswordActions }
)(Profile);
