import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as pressActions from '../../../actions/presses';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  deletePress: PropTypes.func.isRequired
};

const DeletePressModal = ({ data, deletePress, ...props }) => (
  <DeleteModal
    id="delete-press-modal"
    title="Press"
    itemTitle={data.title}
    deleteItem={() => deletePress(data.restaurantId, data.id)}
    {...props}
  />
);

DeletePressModal.propTypes = propTypes;

export default connect(
  null,
  pressActions
)(DeletePressModal);
