import React from 'react';
import PropTypes from 'prop-types';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import Agenda from 'react-big-calendar/lib/Agenda';
import moment from 'moment';
import CalendarEvent from './Event';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../css/components/calendar.css';

const localizer = momentLocalizer(moment);

const propTypes = {
  id: PropTypes.string.isRequired,
  events: PropTypes.array.isRequired,
  // components: PropTypes.array,
  views: PropTypes.array,
  messages: PropTypes.array,
  className: PropTypes.string,
  viewableCalendars: PropTypes.array,
  viewPublished: PropTypes.number
};

const defaultProps = {
  // components: {
  //   event: CalendarEvent
  // },
  views: {
    month: true,
    day: Agenda
  },
  messages: {
    month: 'Grid',
    day: 'List'
  },
  className: '',
  viewableCalendars: [],
  viewPublished: 0
};

// WrapCalendarEvent wraps the custom CalendarEvent to allow use to pass in the
// viewableCalendars array to toggle events on and off based on the calendars
// toggle state
// eslint-disable-next-line react/display-name
const WrapCalendarEvent = ({ viewableCalendars, viewPublished }) => props => {
  return (
    <CalendarEvent
      viewableCalendars={viewableCalendars}
      viewPublished={viewPublished}
      {...props}
    />
  );
};

const Calendar = ({
  id,
  events,
  className,
  viewableCalendars,
  viewPublished,
  ...props
}) => (
  <BigCalendar
    popup
    id={id}
    events={events}
    localizer={localizer}
    className={className}
    showMultiDayTimes
    components={{
      event: WrapCalendarEvent({ viewableCalendars, viewPublished })
    }}
    {...props}
  />
);

Calendar.propTypes = propTypes;
Calendar.defaultProps = defaultProps;

export default Calendar;
