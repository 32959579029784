import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_TOKENS_REQUEST = 'FETCH_TOKENS_REQUEST';
export const FETCH_TOKENS_SUCCESS = 'FETCH_TOKENS_SUCCESS';
export const FETCH_TOKENS_FAILURE = 'FETCH_TOKENS_FAILURE';

export const CREATE_TOKEN_REQUEST = 'CREATE_TOKEN_REQUEST';
export const CREATE_TOKEN_SUCCESS = 'CREATE_TOKEN_SUCCESS';
export const CREATE_TOKEN_FAILURE = 'CREATE_TOKEN_FAILURE';

export const DELETE_TOKEN_REQUEST = 'DELETE_TOKEN_REQUEST';
export const DELETE_TOKEN_SUCCESS = 'DELETE_TOKEN_SUCCESS';
export const DELETE_TOKEN_FAILURE = 'DELETE_TOKEN_FAILURE';

export const fetchTokens = (qry, path) => dispatch => {
  dispatch({
    type: FETCH_TOKENS_REQUEST
  });
  return fetch(path || `/tokens?${fromObj(qry)}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_TOKENS_SUCCESS,
        payload: data,
        meta
      });
      return { data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_TOKENS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countTokens = params => dispatch => {
  return fetch(`/tokens${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const createToken = alert => dispatch => {
  dispatch({
    type: CREATE_TOKEN_REQUEST
  });
  return fetch(`/tokens`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(alert))
  })
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: CREATE_TOKEN_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: CREATE_TOKEN_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteToken = id => dispatch => {
  dispatch({
    type: DELETE_TOKEN_REQUEST
  });
  return fetch(`/tokens/${id}`, {
    method: 'DELETE'
  })
    .then(response => {
      dispatch({
        type: DELETE_TOKEN_SUCCESS,
        payload: id
      });
      return id;
    })
    .catch(e => {
      dispatch({
        type: DELETE_TOKEN_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
