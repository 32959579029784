import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

const propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool
};

const defaultProps = {
  className: '',
  onChange: evt => {},
  label: 'Unpublished',
  disabled: false,
  checked: false
};

const SwitchButton = ({
  id,
  checked,
  className,
  onChange,
  label,
  disabled
}) => (
  <label id={id} className={classnames('flex items-center', className)}>
    <Toggle
      checked={checked}
      onChange={onChange}
      icons={false}
      disabled={disabled}
    />
    <span
      id={`${id}-text`}
      className={classnames('ml-2', { 'text-grey-dark': disabled })}
    >
      {label}
    </span>
  </label>
);

SwitchButton.propTypes = propTypes;
SwitchButton.defaultProps = defaultProps;

export default SwitchButton;
