import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import SidebarItem from './SidebarItem';
import HouseIcon from '../common/HouseIcon';
import UsersIcon from '../common/UsersIcon';
import RestaurantIcon from '../common/RestaurantIcon';
import HotelIcon from '../common/HotelIcon';
import ImagesIcon from '../common/ImagesIcon';
import SettingsIcon from '../common/SettingsIcon';
import placeholderPng from '../../assets/placeholder.png';

const propTypes = {
  location: PropTypes.object.isRequired,
  property: PropTypes.object,
  navs: PropTypes.array.isRequired,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool
};

const defaultProps = {
  property: {},
  onClick: () => {},
  isLoading: false
};

// FIXME this probably more appropriate to put in directories as an html: option
const correspondingIcon = title => {
  switch (title) {
    case 'Dashboard':
      return HouseIcon;
    case 'Users':
      return UsersIcon;
    case 'Restaurants':
      return RestaurantIcon;
    case 'Hotels':
      return HotelIcon;
    case 'Media Library':
      return ImagesIcon;
    case 'Settings':
      return SettingsIcon;
    // TODO need icon for Tokens nav item.
    // case 'Tokens':
    // return TokensIcon;
    default:
      return null;
  }
};

/**
 * isDisplayChevron cehcks of the chevron should be displayed for the given path
 *
 * @param {Mult} val (value of the html.displayRightChevron attr from a directory)
 * @param {String} path
 * @returns {Boolean}
 * @api private
 */

const isDisplayChevron = (val, path) => {
  if (typeof val === 'boolean') {
    return val;
  }
  if (typeof val === 'string') {
    return val === path;
  }
  // FIXME support regex and arrays of all these types, currently no use case
  return false;
};

const SidebarNav = ({
  location: { pathname },
  property,
  navs,
  isLoading,
  onClick
}) => {
  if (isLoading) return <div>Loading ...</div>;

  return (
    <div>
      {property.id && (
        <img
          id={`${property.id}-img`}
          alt={property.name}
          src={(property.logo || {}).url || ''}
          className="block object-contain p-6 m-auto max-h-full xs:hidden lg:block"
          onError={e => {
            e.target.src = placeholderPng;
          }}
          data-property-logo
        />
      )}
      {navs.map((nav, i) => {
        const { title, html = {} } = nav;
        const { displayRightChevron } = html;
        const Icon = pathname === '/' && correspondingIcon(title);

        return (
          <SidebarItem
            // eslint-disable-next-line react/no-array-index-key
            key={`sidebar-item-${i}`}
            active={nav.active}
            displayRightChevron={isDisplayChevron(
              displayRightChevron,
              pathname
            )}
            // NOTE the first item in the returned directory is ALWAYS assumed
            // to be the back link. Except at '/', there is no "back" at this
            // point
            isBackLink={i === 0 && pathname !== '/'}
            url={nav.url}
            onClick={onClick}
          >
            <div className="flex items-center">
              {Icon && (
                <Icon
                  id={`nav-icon--${i}`}
                  className="w-5 h-5 -ml-3 mr-3 fill-current"
                />
              )}
              {typeof title === 'function' ? title(property) : title}
            </div>
          </SidebarItem>
        );
      })}
    </div>
  );
};

SidebarNav.propTypes = propTypes;
SidebarNav.defaultProps = defaultProps;

export default compose(
  withRouter,
  connect((state) => ({
    property: state.property.data,
    navs: state.nav.data,
    isLoading: state.nav.isLoading
  }))
)(SidebarNav);
