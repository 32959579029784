import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import qs from 'querystring';
import ExitButton from '../../common/ExitButton';
import GenerateURLForm from './GenerateURLForm';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired
};

const GenerateURLModal = ({
  onClose,
  data: { form, field },
  hotel,
  change // eslint-disable-line no-shadow
}) => {
  return (
    <div id="generate-url-modal">
      <div className="flex justify-center border-b py-2 px-4 border-grey bg-grey-lighter">
        <h2 className="font-light">Generate URL</h2>
        <ExitButton
          id="generate-url-exit"
          onClick={onClose}
          className="absolute"
          divStyle={{ right: '.5rem' }}
          svgStyle={{ fill: 'black' }}
        />
      </div>
      <div className="p-6">
        <div className="font-thin my-6 text-center">
          Fill out below to generate a URL for this promo:
        </div>
        <GenerateURLForm
          id="generate-modal-form"
          initialValues={{
            hotelCode: hotel.ihgHotelCode,
            rateCode: hotel.rateCode,
            localeCode: hotel.localeCode,
            regionCode: hotel.regionCode,
            brandCode: hotel.brandCode
          }}
          onSubmit={data => {
            const base =
              'https://www.ihg.com/kimptonhotels/redirect?path=rates';
            const queryString = qs.stringify(data);
            change(form, field, `${base}&${queryString}`);
            onClose();
          }}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
    </div>
  );
};

GenerateURLModal.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data
  }),
  { change }
)(GenerateURLModal);
