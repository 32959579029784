import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import difference from 'lodash/difference';
import union from 'lodash/union';
import get from 'lodash/get';
import startCase from 'lodash/startCase';
import pluralize from 'pluralize';
import Table from '../common/Table';
import { Tabs, TabList, Tab, TabPill } from '../common/Tabs';
import * as permissionActions from '../../actions/permissions';
import * as propertyActions from '../../actions/properties';
import Paginate from '../Paginate';

const propTypes = {
  properties: PropTypes.array.isRequired,
  permissions: PropTypes.array.isRequired,
  addPermission: PropTypes.func.isRequired,
  removePermission: PropTypes.func.isRequired,
  fetchProperties: PropTypes.func.isRequired,
  countProperties: PropTypes.func.isRequired,
  meta: PropTypes.object
};

const defaultProps = {
  meta: {}
};

const hotelResources = [
  'accommodation',
  'calendar',
  'faq',
  'home_promo',
  'special',
  'tiled_promo',
  'list',
];
const restaurantResources = ['calendar', 'event', 'hour', 'press', 'menu'];

const resources = union(hotelResources, restaurantResources);

const getPropertyId = perm => {
  const type = perm.permissionableType || get(perm, 'permissionable_type');

  if (type === 'PropertyPermission') {
    return parseInt(
      perm.permissionableId || get(perm, 'permissionable_id'),
      10
    );
  }

  return null;
};

const EditUserTable = ({
  properties,
  meta,
  permissions,
  addPermission,
  removePermission,
  fetchProperties,
  countProperties
}) => {
  const [hotelCount, setHotelCount] = useState(0);
  const [restaCount, setRestaCount] = useState(0);
  const [selected, setSelected] = useState(0);

  const propertiesTabsc = [
    {
      qry: { type: 'hotel', archived: false },
      cb: setHotelCount
    },
    {
      qry: { type: 'restaurant', archived: false },
      cb: setRestaCount
    },
  ];

  const fetchFunc = (val, selectedIndex) => {
    const cond = { order_by: 'name:ASC' };
    if (val) {
      cond.name = `i%%${val}`;
    }
    propertiesTabsc.forEach((v, i) => {
      if (i === selectedIndex) {
        fetchProperties({ ...v.qry, ...cond }).then(p => v.cb(p.meta.count));
      } else {
        countProperties({ ...v.qry, ...cond }).then(p => v.cb(p.count));
      }
    });
  };

  useEffect(() => {
    fetchFunc(null, selected);
    return () => {};
  }, [selected]);

  const checkPropertyPermissions = propertyId => {
    const propertyPermissions = permissions
      .filter(perm => {
        const id = getPropertyId(perm);
        return id && id === parseInt(propertyId, 10);
      })
      .map(perm => perm.resource);
    const diff = difference(resources, propertyPermissions);
    return diff.length === 0;
  };
  const checkGlobalPermission = resource => {
    const f = properties.filter(p => {
      return (
        permissions.findIndex(
          permission =>
            permission.resource === resource &&
            getPropertyId(permission) === parseInt(p.id, 10)
        ) > -1
      );
    });
    return f.length === properties.length;
  };
  const checkPermission = (propertyId, resource) => {
    return (
      permissions.findIndex(permission => {
        return (
          getPropertyId(permission) === parseInt(propertyId, 10) &&
          permission.resource === resource
        );
      }) > -1
    );
  };
  const updatePermission = (propertyId, resource, checked) => {
    if (checked) {
      addPermission({ propertyId, resource });
    } else {
      removePermission({ propertyId, resource });
    }
  };
  const handleUpdate = (propertyId, resource) => e => {
    const { checked } = e.target;
    updatePermission(propertyId, resource, checked);
  };
  const handleUpdateColumn = resource => e => {
    const { checked } = e.target;
    if (checked) {
      properties.forEach(p => {
        addPermission({ propertyId: p.id, resource });
      });
    } else {
      properties.forEach(p => {
        removePermission({ propertyId: p.id, resource });
      });
    }
  };
  const handleUpdateProperty = propertyId => e => {
    const { checked } = e.target;
    resources.forEach(resource => {
      updatePermission(propertyId, resource, checked);
    });
  };
  const columns = [
    {
      id: 'propertyName',
      Header: () => <div className="mt-2">Property Name</div>,
      width: 200,
      accessor: data => (
        <div className="flex items-baseline">
          <input
            type="checkbox"
            className="mr-2"
            checked={checkPropertyPermissions(data.id)}
            onChange={handleUpdateProperty(data.id)}
          />
          {data.name}
        </div>
      )
    },
    ...(selected === 0 ? hotelResources : restaurantResources).map(
      resource => ({
        id: resource,
        Header: () => (
          <div className="text-center">
            <div>{pluralize(startCase(resource))}</div>
            <input
              type="checkbox"
              checked={checkGlobalPermission(resource)}
              onChange={handleUpdateColumn(resource, selected)}
            />
          </div>
        ),
        accessor: data => (
          <div className="text-center">
            <input
              type="checkbox"
              checked={checkPermission(data.id, resource)}
              onChange={handleUpdate(data.id, resource)}
            />
          </div>
        ),
        sortable: false
      })
    ),
  ];
  return (
    <>
      <Tabs selectedIndex={selected} onSelect={index => setSelected(index)}>
        <TabList>
          <Tab>
            <span className="mr-2">Hotels</span>
            <TabPill id={0}>{hotelCount}</TabPill>
          </Tab>
          <Tab>
            <span className="mr-2">Restaurants</span>
            <TabPill id={1}>{restaCount}</TabPill>
          </Tab>
          <Paginate meta={meta} fetchHandler={fetchProperties} />
        </TabList>
        <Table id="users-table-hotels" data={properties} columns={columns} />
      </Tabs>
      <Paginate meta={meta} fetchHandler={fetchProperties} />
    </>
  );
};

EditUserTable.propTypes = propTypes;
EditUserTable.defaultProps = defaultProps;

export default compose(
  withRouter,
  connect(
    state => ({
      properties: state.properties.data,
      meta: state.properties.meta
    }),
    { ...permissionActions, ...propertyActions }
  )
)(EditUserTable);
