import { moveBySplice } from '../utils/move_by_splice';

import {
  FETCH_MENUS_REQUEST,
  FETCH_MENUS_FAILURE,
  FETCH_MENUS_SUCCESS,
  FETCH_MENU_REQUEST,
  FETCH_MENU_SUCCESS,
  FETCH_MENU_FAILURE,
  CREATE_MENU_REQUEST,
  CREATE_MENU_SUCCESS,
  CREATE_MENU_FAILURE,
  UPDATE_MENU_REQUEST,
  UPDATE_MENU_SUCCESS,
  UPDATE_MENU_FAILURE,
  DELETE_MENU_REQUEST,
  DELETE_MENU_FAILURE,
  DELETE_MENU_SUCCESS,
  MOVE_MENU,
  SORT_MENUS_REQUEST,
  SORT_MENUS_SUCCESS,
  SORT_MENUS_FAILURE
} from '../actions/menus';

export function menus(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_MENUS_REQUEST:
    case SORT_MENUS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_MENUS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case MOVE_MENU: {
      const { source, target } = payload;
      const data = moveBySplice(source, target, state.data);

      return { ...state, data };
    }

    case SORT_MENUS_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_MENUS_FAILURE:
    case SORT_MENUS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function menu(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_MENU_REQUEST:
    case CREATE_MENU_REQUEST:
    case UPDATE_MENU_REQUEST:
    case DELETE_MENU_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_MENU_SUCCESS:
    case CREATE_MENU_SUCCESS:
    case UPDATE_MENU_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_MENU_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_MENU_FAILURE:
    case CREATE_MENU_FAILURE:
    case UPDATE_MENU_FAILURE:
    case DELETE_MENU_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
