import React from 'react';
import PropTypes from 'prop-types';
import SwitchButton from '../SwitchButton';

const propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired
};

const Switch = ({ id, input, ...props }) => (
  <SwitchButton id={id} {...input} checked={!!input.value} {...props} />
);

Switch.propTypes = propTypes;

export default Switch;
