import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import More, { MoreMenu, MoreMenuItem } from '../common/More';
import { Tab, Tabs, TabList } from '../common/Tabs';
import * as modalActions from '../../actions/modals';
import placeholderPng from '../../assets/placeholder.png';

const propTypes = {
  fetchHandler: PropTypes.func.isRequired,
  updateHandler: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  history: PropTypes.object,
  url: PropTypes.func,
  propertyId: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  qryOpts: PropTypes.oneOf([PropTypes.object, PropTypes.array]).isRequired,
  deleteModalId: PropTypes.string.isRequired
};

const defaultProps = {
  history: {},
  // url will be passed the "selected" qryOpts
  url: qryOpt => ''     /* eslint-disable-line no-unused-vars */
};

// FIXME with pagination this should be implemented using a fetch request to get
// only the N promos that meet the criteria. Which will eliminate the filter
// function requirement as well as be a true representation of a frontend
// integration as you will not be calling a fullset of promos and then filtering
// that down as we are doing here.

const DashboardTile = ({
  history: { push },
  propertyId,
  id,
  title,
  url,
  updateHandler,
  showModal,
  fetchHandler,
  qryOpts,
  deleteModalId
}) => {
  const [selected, setSelected] = useState(-1);
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [tabsSet, setTabsSet] = useState([]);

  /**
   * doFetch wraps the fetchHandler with isLoading and setData handling
   *
   * NOTE This will be passed to the modal as a post delete handler in order to
   * refresh the list since there is no redux reduction here because of the
   * multiple data sets that will be used for this component
   */

  const doFetch = () => {
    setIsLoading(true);
    // FIXME not ideal to be grabbing selected from outer scope
    fetchHandler(propertyId, tabsSet[selected].qry, null, {
      silent: true
    // eslint-disable-next-line no-shadow
    }).then(({ data }) => {
      setIsLoading(false);
      setData(data);
    });
  };

  /**
   * doUpdate wraps the updateHandler to do the update and call doFetch to
   * refresh the list. As noted in doFetch there is no redux reduction here.
   *
   * @param {Object} item
   */

  const doUpdate = item => {
    updateHandler(
      propertyId,
      item.id,
      Object.assign(
        {
          id: item.id,
          archived: !item.archived
        },
        !item.archived && { published: false }
      )
    ).then(() => {
      doFetch();
    });
  };

  useEffect(() => {
    const type = Object.prototype.toString.call(qryOpts);
    if (type === '[object Object]') {
      setTabsSet([{ qry: qryOpts }]);
    }
    if (type === '[object Array]') {
      setTabsSet(qryOpts);
    }

    // setSelected to trigger next useEffect to get query
    setSelected(0);
    return () => {};
  }, []);

  useEffect(() => {
    /* eslint-disable consistent-return */
    if (!fetchHandler || !propertyId) {
      return;
    }
    if (selected < 0) {
      return;
    }
    doFetch();
    return () => {};
  }, [selected]);

  const urlBase = url(tabsSet[selected]);

  return (
    <div className="mb-4" key={`${id}-dashboard-tile`}>
      <div className="mb-12 dashboard-tile">
        <div className="flex flex-col bg-white pt-10" id={`${id}-tile`}>
          <div className="px-4">
            <h3 className="font-normal mb-12">{title}s</h3>
          </div>
          <div className="">
            <Tabs selectedIndex={selected} onSelect={i => setSelected(i)}>
              {tabsSet.length > 0 && (
                <TabList>
                  {tabsSet.map(
                    t =>
                      t.title && (
                        <Tab>
                          <span className="normal-case mr-2">{t.title}</span>
                        </Tab>
                      )
                  )}
                </TabList>
              )}
              {isLoading ? (
                <div className="border-t border-grey p-4">
                  Retrieving {title} {(qryOpts[selected] || {}).title}...
                </div>
              ) : (
                <>
                  {data.length <= 0 && (
                    <div className="border-t border-grey p-4">
                      There are no {title} {(qryOpts[selected] || {}).title}
                    </div>
                  )}
                  {data.map(item => (
                    <div
                      className="border-t border-grey p-4 flex justify-between items-center"
                      key={`${item.id}-item`}
                    >
                      <Link
                        className="flex items-center"
                        to={`${urlBase}/${item.id}/edit`}
                      >
                        <img
                          id={`${item.id}-img`}
                          alt={item.title}
                          src={
                            item.images[0] ? item.images[0].url : placeholderPng
                          }
                          className="mr-4 w-16"
                        />
                        <p className="">{item.title}</p>
                      </Link>
                      <More id={`${item.id}-more`}>
                        <MoreMenu id={`${item.id}-moremenu`}>
                          <MoreMenuItem
                            id={`${item.id}-archive`}
                            onClick={() => {
                              doUpdate(item);
                            }}
                          >
                            {item.archived ? 'Unarchive' : 'Archive'}
                          </MoreMenuItem>
                          <MoreMenuItem
                            id={`${item.id}-clone`}
                            onClick={() =>
                              push(`${urlBase}/clone?selected=${item.id}`)}
                          >
                            Clone
                          </MoreMenuItem>
                          <MoreMenuItem
                            id={`${item.id}-delete`}
                            onClick={() =>
                              showModal(
                                deleteModalId,
                                {
                                  hotelId: propertyId,
                                  id: item.id,
                                  title: item.title
                                },
                                doFetch
                              )}
                          >
                            Delete
                          </MoreMenuItem>
                        </MoreMenu>
                      </More>
                    </div>
                  ))}
                </>
              )}
            </Tabs>
          </div>
          <div className="border-t border-grey flex justify-between items-center">
            <Link className="leading-normal p-4" to={`${urlBase}`}>
              View All >
            </Link>
            <Link to={`${urlBase}/new`} className="mr-4">
              + Add a {(qryOpts[selected] || {}).title} {title}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

DashboardTile.propTypes = propTypes;
DashboardTile.defaultProps = defaultProps;

export default connect(
  state => ({}),
  {
    ...modalActions
  }
)(withRouter(DashboardTile));
