import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  strokeWidth: PropTypes.string
};

const defaultProps = {
  className: undefined,
  style: {},
  strokeWidth: ''
};

const NavIcon = ({ id, ...otherProps }) => (
  <svg
    id={id}
    xmlns="http://www.w3.org/2000/svg"
    className="lg:hidden cursor-pointer fill-current p-3 -ml-3"
    fill="none"
    viewBox="0 0 24 24"
    width="54"
    height="54"
    stroke="currentColor"
    {...otherProps}
  >
    <path strokeWidth="1" d="M4 6h16M4 12h16M4 18h16" />
  </svg>
);

NavIcon.propTypes = propTypes;
NavIcon.defaultProps = defaultProps;

export default NavIcon;
