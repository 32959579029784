import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeleteModal from '../DeleteModal';
import * as homePromoActions from '../../../actions/homePromos';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteHomePromo: PropTypes.func.isRequired
};

const DeleteHomePromoModal = ({ data, deleteHomePromo, ...props }) => (
  <DeleteModal
    id="delete-home-modal"
    title="Promo"
    itemTitle={data.title}
    deleteItem={() => deleteHomePromo(data.hotelId, data.id)}
    {...props}
  />
);

DeleteHomePromoModal.propTypes = propTypes;

export default connect(
  null,
  homePromoActions
)(DeleteHomePromoModal);
