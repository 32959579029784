import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import Input from '../common/Input';
import FormErrorBox from '../common/FormErrorBox';

const propTypes = {
  id: PropTypes.string.isRequired,
  isNew: PropTypes.bool,
  hotels: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  isNew: false,
  error: undefined
};

const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required!';
  }

  return errors;
};

const EditRegionForm = ({
  id,
  isNew,
  handleSubmit,
  hotels,
  isLoading,
  error
}) => (
  <form id={id} onSubmit={handleSubmit}>
    <div className="w-full max-w-3xl bg-white p-10 rounded shadow-md">
      <div
        className={classnames('text-sm font-semibold mb-3', {
          'text-grey': !isNew
        })}
      >
        Region Name*
      </div>
      <Field
        id="regionName"
        className="mb-12"
        name="name"
        component={Input}
        disabled={!isNew}
      />
      <div className="text-sm font-semibold mb-6">
        Select all hotels for this Region:
      </div>
      <div className="flex flex-wrap mb-16">
        {!isLoading &&
          hotels.map(hotel => (
            <div
              key={hotel.id}
              className="sm:w-full md:w-1/2 lg:w-1/3 flex items-centermb-6"
            >
              <Field
                id={hotel.id}
                name={`hotels.${hotel.id}`}
                className="items-start inline-block w-4 mr-2"
                type="checkbox"
                component={Input}
              />
              <label className="inline-block mr-2 mb-6">{hotel.name}</label>
            </div>
          ))}
      </div>
    </div>
    <div className="flex justify-center pt-12 pb-12">
      <button type="submit" className="btn" disabled={!!error}>
        Save
      </button>
    </div>
    {error && <FormErrorBox id="hotels-region-error">{error}</FormErrorBox>}
  </form>
);

EditRegionForm.propTypes = propTypes;
EditRegionForm.defaultProps = defaultProps;

export default compose(
  reduxForm({ form: 'EditRegionForm', validate }),
  connect(state => ({
    hotels: state.properties.data.filter(property => property.type === 'hotel'),
    isLoading: state.properties.isLoading
  }))
)(EditRegionForm);
