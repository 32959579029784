import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import Layout from '../../../../layout';
import promoFlags from '../../../../utils/promo-flag-accom-options';
import modalTitles from '../../../../utils/modal-title-options';
import ctaTitleOptions from '../../../../utils/cta-title-options-accommodations';
import * as propertyActions from '../../../../actions/properties';
import * as accommodationActions from '../../../../actions/accommodations';
import * as sidebarActions from '../../../../actions/sidebar';
import * as imageActions from '../../../../actions/images';
import HotelAccommodationForm from '../../../../components/HotelAccommodationForm';
import FormNavigator from '../../../../components/FormNavigator';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  suite: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  updateAccommodation: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchAccommodation: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired,
  setActiveImage: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'promo', title: 'Promo Flag' },
  { id: 'preview', title: 'Preview + Publish' },
];

const SuiteEdit = ({
  hotel,
  suite,
  images,
  isLoading,
  match: {
    params: { hotelid, id }
  },
  history: { push },
  updateAccommodation,
  fetchProperty,
  fetchAccommodation,
  updateSidebar,
  setCurrentImage,
  setActiveImage
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchAccommodation(hotelid, id);
    return () => {};
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-shadow
    const images = get(suite, 'images', []);
    get(suite, 'images', []).forEach(image => {
      setCurrentImage('hotel-accommodations-image', image);
    });

    // FIXME this is not ideal. But the original setup was using a 1 index vs a
    // 0 index and state management for what is considered active is also
    // incorrect. This will fix for now, but concerns are spread out all over
    // the place.
    if (images.length) {
      const idx = images.findIndex(v => v.sortIndex === 0);
      setActiveImage('hotel-accommodations-image', idx + 1);
    }
    return () => {};
  }, [suite]);

  const handleSubmit = data => {
    const update = {
      ...data,
      startsOn: data.startsOn.toJSON(),
      endsOn: data.endsOn ? data.endsOn.toJSON() : data.endsOn,
      // images are always an array, which is always ordered, iterate and set
      // the sort_index based on that order
      images: images.map(({ assetId, alt, videoUrl }, i) => ({
        assetId,
        alt,
        videoUrl,
        sort_index: i
      })),
      modal1Title:
        // eslint-disable-next-line no-nested-ternary
        data.modal1Title === 'None'
          ? null
          : data.modal1Title === 'Other'
          ? data.modal1TitleOther
          : data.modal1Title,
      modal2Title:
        // eslint-disable-next-line no-nested-ternary
        data.modal2Title === 'None'
          ? null
          : data.modal2Title === 'Other'
          ? data.modal2TitleOther
          : data.modal2Title,
      cta: Object.assign(
        data.cta,
        data.cta.type === 'Other' && { type: data.cta.typeInput }
      ),
      promo_flag:
        // eslint-disable-next-line no-nested-ternary
        data.promoFlag === 'Other' ? data.promoFlagOther : data.promoFlag
    };
    return updateAccommodation(hotelid, id, update)
      .then(() => push(`/hotels/${hotelid}/accommodations/suites`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };
  return (
    <Layout metaTitle="Edit Suite">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to={`/hotels/${hotelid}/accommodations/suites`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Suites
          </Link>
          <h1 className="mb-6">Edit Suite</h1>
          <div className="mb-16">
            Use this page to edit {suite.title} Suite on {hotel.name}.
          </div>
          <HotelAccommodationForm
            id="edit-suites-form"
            category="Suite"
            hotel={hotel}
            initialValues={{
              ...suite,
              // eslint-disable-next-line no-nested-ternary
              modal1Title: !suite.modal1Title
                ? 'None'
                : modalTitles.findIndex(
                    title => title.id === suite.modal1Title
                  ) === -1
                ? 'Other'
                : suite.modal1Title,
              modal1TitleOther:
                modalTitles.findIndex(
                  title => title.id === suite.modal1Title
                ) === -1
                  ? suite.modal1Title
                  : undefined,
              // eslint-disable-next-line no-nested-ternary
              modal2Title: !suite.modal2Title
                ? 'None'
                : modalTitles.findIndex(
                    title => title.id === suite.modal2Title
                  ) === -1
                ? 'Other'
                : suite.modal2Title,
              modal2TitleOther:
                modalTitles.findIndex(
                  title => title.id === suite.modal2Title
                ) === -1
                  ? suite.modal2Title
                  : undefined,
              showmodal2: !!suite.modal2Title,
              cta: suite.cta && {
                ...suite.cta,
                type:
                  ctaTitleOptions.findIndex(
                    title => title.id === get(suite, 'cta.type')
                  ) === -1
                    ? 'Other'
                    : get(suite, 'cta.type'),
                typeInput:
                  ctaTitleOptions.findIndex(
                    title => title.id === get(suite, 'cta.type')
                  ) === -1
                    ? get(suite, 'cta.type')
                    : undefined
              },
              startsOn: suite.startsOn
                ? new Date(suite.startsOn)
                : suite.startsOn,
              endsOn: suite.endsOn ? new Date(suite.endsOn) : suite.endsOn,
              // eslint-disable-next-line no-nested-ternary
              promoFlag: !suite.promoFlag
                ? 'None'
                : promoFlags.findIndex(
                    title => title.id === suite.promoFlag
                  ) === -1
                ? 'Other'
                : suite.promoFlag,
              promoFlagOther:
                promoFlags.findIndex(title => title.id === suite.promoFlag) ===
                -1
                  ? suite.promoFlag
                  : undefined
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
      )}
      <FormNavigator
        id="edit-suites-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

SuiteEdit.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    suite: state.accommodation.data,
    images: get(state, 'images.hotel-accommodations-image.items', [])
      .filter(item => item.file)
      .map(item => ({
        assetId: item.file.id,
        alt: item.alt,
        videoUrl: item.videoUrl
      })),
    isLoading: state.property.isLoading || state.accommodation.isLoading
  }),
  {
    ...propertyActions,
    ...sidebarActions,
    ...accommodationActions,
    ...imageActions
  }
)(SuiteEdit);
