import fetch from '../utils/fetch';
import { convertToJS, convertToAPI } from '../utils/convert';

export const LOGIN_USER_REQUEST = 'LOGIN_USER_REQUEST';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';

export const FETCH_PROFILE_REQUEST = 'FETCH_PROFILE_REQUEST';
export const FETCH_PROFILE_SUCCESS = 'FETCH_PROFILE_SUCCESS';
export const FETCH_PROFILE_FAILURE = 'FETCH_PROFILE_FAILURE';

export const FETCH_PROFILE_PERMISSIONS_REQUEST =
  'FETCH_PROFILE_PERMISSIONS_REQUEST';
export const FETCH_PROFILE_PERMISSIONS_SUCCESS =
  'FETCH_PROFILE_PERMISSIONS_SUCCESS';
export const FETCH_PROFILE_PERMISSIONS_FAILURE =
  'FETCH_PROFILE_PERMISSIONS_FAILURE';

export const UPDATE_PROFILE_REQUEST = 'UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE';

export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';

export const loadAuth = token => ({
  type: LOGIN_USER_SUCCESS,
  payload: token
});

export const loginUser = (username, password) => dispatch => {
  dispatch({
    type: LOGIN_USER_REQUEST
  });
  return fetch('/auth', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ username, password })
  })
    .then(response => {
      dispatch(loadAuth(response.data.token.value));
      return response.data.token.value;
    })
    .catch(e => {
      dispatch({
        type: LOGIN_USER_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const logoutUser = () => (dispatch) => {
  // FIXME
  // dispatch({
  //   type: LOGOUT_USER_REQUEST
  // });
  return fetch(
    `/auth`,
    {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' }
    },
    true
  )
    .then(() => {
      // this automatically redirects to '/', after dispatch... why? what is
      // listening to this state?
      dispatch({ type: LOGOUT_USER_SUCCESS });

      // FIXME should this be using history?
      // window.location = '/';
    })
    .catch(e => {
      // FIXME
      // dispatch({
      //   type: LOGOUT_USER_FAILURE,
      //   payload: e.message
      // });
      return Promise.reject(e);
    });
};

export const fetchProfile = () => dispatch => {
  dispatch({
    type: FETCH_PROFILE_REQUEST
  });
  return fetch('/profile')
    .then(response => {
      const profile = convertToJS(response.data);
      dispatch({
        type: FETCH_PROFILE_SUCCESS,
        payload: profile
      });
      return profile;
    })
    .catch(e => {
      dispatch({
        type: FETCH_PROFILE_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const fetchProfilePermissions = () => dispatch => {
  dispatch({
    type: FETCH_PROFILE_PERMISSIONS_REQUEST
  });
  return fetch(`/profile/permissions?per_page=2000`)
    .then(response => {
      const permissions = convertToJS(response.data);
      dispatch({
        type: FETCH_PROFILE_PERMISSIONS_SUCCESS,
        payload: permissions
      });
      return permissions;
    })
    .catch(e => {
      dispatch({
        type: FETCH_PROFILE_PERMISSIONS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateProfile = update => dispatch => {
  dispatch({
    type: UPDATE_PROFILE_REQUEST
  });
  return fetch(`/profile`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: data
      });
      if ('session' in meta) {
        const { token } = meta.session;
        if (token) {
          dispatch(loadAuth(token));
        }
      }
      return data;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_PROFILE_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const forgotPassword = username => dispatch => {
  dispatch({
    type: FORGOT_PASSWORD_REQUEST
  });
  return fetch('/passwords/forgot', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ email: username })
  })
    .then(response => {
      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: null
      });
      return true;
    })
    .catch(e => {
      dispatch({
        type: FORGOT_PASSWORD_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
