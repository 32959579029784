import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';
import classnames from 'classnames';
import ExitButton from '../../common/ExitButton';
import Dropdown, { DropdownItem } from '../../common/Dropdown';
import Input from '../../common/Input';
import * as imageActions from '../../../actions/images';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const categories = [
  { id: 'calendars', title: 'Calendars' },
  {
    id: 'specials',
    title: 'Specials'
  },
];

const UploadFileModal = ({ onClose, setCurrentImage, data }) => {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps, open, isDragActive } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: acceptedFiles =>
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      )
  });

  const handleUpload = () => {
    setCurrentImage(data.imageEnv, files[0]);
    onClose();
  };
  const className = classnames(
    'dropzone border border-dashed h-48 p-4 rounded-sm w-64',
    { 'border-grey': !isDragActive },
    { 'border-grey-dark': isDragActive }
  );
  return (
    <div id="upload-file-modal">
      <div className="flex justify-center border-b py-2 px-4 border-grey">
        <h2 className="font-light">Upload File</h2>
        <ExitButton
          id="upload-file-exit"
          onClick={onClose}
          className="absolute"
          divStyle={{ right: '.5rem' }}
          svgStyle={{ fill: 'black' }}
        />
      </div>
      <div className="flex justify-center p-6 bg-grey-lighter">
        {files.length === 0 ? (
          <div
            {...getRootProps({
              className
            })}
          >
            <input {...getInputProps()} />
            <div className="flex justify-center mt-8">Drag Files Here</div>
            <div className="flex justify-center text-4xl">+</div>
            <div className="flex justify-center">
              <button
                type="button"
                className="btn bg-grey-light border-grey hover:bg-grey rounded-sm text-black px-4 text-sm"
                onClick={open}
              >
                Select from computer
              </button>
            </div>
          </div>
        ) : (
          <div className="h-48 p-4 w-64">
            <img src={files[0].preview} alt="Preview" className="w-full" />
          </div>
        )}
      </div>
      <div className="flex justify-between p-4">
        <Dropdown
          id="upload-modal-dropdown"
          placeholder="Select a Category"
          style={{ width: 200 }}
        >
          {categories.map(cat => (
            <DropdownItem id={cat.id} key={cat.id}>
              {cat.title}
            </DropdownItem>
          ))}
        </Dropdown>
        <Input
          id="upload-file-input"
          input={{ value: '', onChange: () => {} }}
          placeholder="Add Tags..."
        />
        <button type="button" className="btn btn-clear rounded-sm" onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          className="btn bg-grey-light hover:bg-grey rounded-sm border-grey text-black"
          onClick={handleUpload}
        >
          Upload
        </button>
      </div>
    </div>
  );
};

UploadFileModal.propTypes = propTypes;

export default connect(
  null,
  imageActions
)(UploadFileModal);
