import {
  FETCH_CALENDARS_REQUEST,
  FETCH_CALENDARS_SUCCESS,
  FETCH_CALENDARS_FAILURE,
  CREATE_CALENDAR_REQUEST,
  CREATE_CALENDAR_SUCCESS,
  CREATE_CALENDAR_FAILURE,
  UPDATE_CALENDAR_REQUEST,
  UPDATE_CALENDAR_SUCCESS,
  UPDATE_CALENDAR_FAILURE, /* eslint-disable-line no-unused-vars */ // FIXME why is this not used?
  DELETE_CALENDAR_REQUEST,
  DELETE_CALENDAR_SUCCESS,
  DELETE_CALENDAR_FAILURE
} from '../actions/calendars';

const initialState = {
  data: [],
  isLoading: true,
  error: ''
};

// eslint-disable-next-line default-param-last
export default function calendars(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_CALENDARS_REQUEST:
    case CREATE_CALENDAR_REQUEST:
    case UPDATE_CALENDAR_REQUEST:
    case DELETE_CALENDAR_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_CALENDARS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };
    case CREATE_CALENDAR_SUCCESS:
    case UPDATE_CALENDAR_SUCCESS: {
      const currentIndex = state.data.findIndex(
        calendar => calendar.id === payload.id
      );
      const data =
        currentIndex > -1
          ? state.data
              .slice(0, currentIndex)
              .concat(payload)
              .concat(state.data.slice(currentIndex + 1))
          : state.data.concat(payload);
      return { ...state, isLoading: false, error: '', data };
    }
    case DELETE_CALENDAR_SUCCESS: {
      const currentIndex = state.data.findIndex(
        calendar => calendar.id === payload
      );
      const data =
        currentIndex > -1
          ? state.data
              .slice(0, currentIndex)
              .concat(state.data.slice(currentIndex + 1))
          : state.data;
      return { ...state, isLoading: false, error: '', data };
    }
    case FETCH_CALENDARS_FAILURE:
    case CREATE_CALENDAR_FAILURE:
    case DELETE_CALENDAR_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
