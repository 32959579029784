import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Input from '../common/Input';
import FormSection from '../common/FormSection';
import FormErrorBox from '../common/FormErrorBox';

const validate = values => {
  const errors = {};
  if (!values.name) {
    errors.name = 'Required!';
  }

  return errors;
};

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  error: undefined
};

const HotelFAQCategoryForm = ({ id, handleSubmit, error }) => (
  <form id={id} onSubmit={handleSubmit}>
    <FormSection
      id="name"
      title="FAQ Category Name"
      className="border-b"
      isHorizontal
    >
      <div className="flex-1">
        <div className="text-sm font-bold mb-3">Category Name*</div>
        <Field
          id="category-name"
          name="name"
          component={Input}
          className="mb-6 w-full"
          placeholder="Enter a name for this category..."
        />
      </div>
    </FormSection>
    <div className="flex justify-center my-6">
      <button type="submit" className="btn" disabled={!!error}>
        Save
      </button>
    </div>
    {error && <FormErrorBox id="hotel-faq-cat-error">{error}</FormErrorBox>}
  </form>
);

HotelFAQCategoryForm.propTypes = propTypes;
HotelFAQCategoryForm.defaultProps = defaultProps;

export default reduxForm({ form: 'HotelFAQCategoryForm', validate })(
  HotelFAQCategoryForm
);
