import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_PRESSES_REQUEST = 'FETCH_PRESSES_REQUEST';
export const FETCH_PRESSES_SUCCESS = 'FETCH_PRESSES_SUCCESS';
export const FETCH_PRESSES_FAILURE = 'FETCH_PRESSES_FAILURE';

export const FETCH_PRESS_REQUEST = 'FETCH_PRESS_REQUEST';
export const FETCH_PRESS_SUCCESS = 'FETCH_PRESS_SUCCESS';
export const FETCH_PRESS_FAILURE = 'FETCH_PRESS_FAILURE';

export const CREATE_PRESS_REQUEST = 'CREATE_PRESS_REQUEST';
export const CREATE_PRESS_SUCCESS = 'CREATE_PRESS_SUCCESS';
export const CREATE_PRESS_FAILURE = 'CREATE_PRESS_FAILURE';

export const UPDATE_PRESS_REQUEST = 'UPDATE_PRESS_REQUEST';
export const UPDATE_PRESS_SUCCESS = 'UPDATE_PRESS_SUCCESS';
export const UPDATE_PRESS_FAILURE = 'UPDATE_PRESS_FAILURE';

export const DELETE_PRESS_REQUEST = 'DELETE_PRESS_REQUEST';
export const DELETE_PRESS_SUCCESS = 'DELETE_PRESS_SUCCESS';
export const DELETE_PRESS_FAILURE = 'DELETE_PRESS_FAILURE';

export const MOVE_PRESS = 'MOVE_PRESS';

export const SORT_PRESSES_REQUEST = 'SORT_PRESSES_REQUEST';
export const SORT_PRESSES_SUCCESS = 'SORT_PRESSES_SUCCESS';
export const SORT_PRESSES_FAILURE = 'SORT_PRESSES_FAILURE';

export const fetchPresses = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_PRESSES_REQUEST
  });
  return fetch(path || `/properties/${propertyId}/presses${fromObj(qry, '?')}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_PRESSES_SUCCESS,
        payload: data,
        meta
      });
      return { data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_PRESSES_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countPresses = (propertyId, params) => dispatch => {
  return fetch(`/properties/${propertyId}/presses${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchPress = (propertyId, pressId) => dispatch => {
  dispatch({
    type: FETCH_PRESS_REQUEST
  });
  return fetch(`/properties/${propertyId}/presses/${pressId}`)
    .then(response => {
      const press = convertToJS(response.data);
      dispatch({
        type: FETCH_PRESS_SUCCESS,
        payload: press
      });
      return press;
    })
    .catch(e => {
      dispatch({
        type: FETCH_PRESS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createPress = (propertyId, body) => dispatch => {
  dispatch({
    type: CREATE_PRESS_REQUEST
  });
  return fetch(`/properties/${propertyId}/presses`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(body))
  })
    .then(response => {
      const press = convertToJS(response.data);
      dispatch({
        type: CREATE_PRESS_SUCCESS,
        payload: press
      });
      return press;
    })
    .catch(e => {
      dispatch({
        type: CREATE_PRESS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updatePress = (propertyId, pressId, update) => dispatch => {
  dispatch({
    type: UPDATE_PRESS_REQUEST
  });
  return fetch(`/properties/${propertyId}/presses/${pressId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const press = convertToJS(response.data);
      dispatch({
        type: UPDATE_PRESS_SUCCESS,
        payload: press
      });
      return press;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_PRESS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deletePress = (propertyId, pressId) => dispatch => {
  dispatch({
    type: DELETE_PRESS_REQUEST
  });
  return fetch(`/properties/${propertyId}/presses/${pressId}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch({
        type: DELETE_PRESS_SUCCESS,
        payload: pressId
      });
      return pressId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_PRESS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const movePress = (source, target) => ({
  type: MOVE_PRESS,
  payload: { source, target }
});

export const sortPresses = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_PRESSES_REQUEST
  });
  return fetch(`/properties/${propertyId}/presses/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_PRESSES_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_PRESSES_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
