import {
  FETCH_SHARED_REQUEST,
  FETCH_SHARED_SUCCESS,
  FETCH_SHARED_FAILURE
} from '../actions/shared';

const initialState = {
  data: {},
  isLoading: true,
  error: ''
};

// eslint-disable-next-line default-param-last
export default function shared(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_SHARED_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_SHARED_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };
    case FETCH_SHARED_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
