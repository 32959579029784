import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import Layout from '../../../../layout';
import * as propertyActions from '../../../../actions/properties';
import * as tiledPromoActions from '../../../../actions/tiledPromos';
import * as sidebarActions from '../../../../actions/sidebar';
import HotelMeetingForm from '../../../../components/HotelMeetingForm';
import FormNavigator from '../../../../components/FormNavigator';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  createTiledPromo: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'upload', title: 'Upload a PDF' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'preview', title: 'Preview + Publish' }
];

const Misc2New = ({
  match: {
    params: { hotelid }
  },
  history: { push },
  images,
  createTiledPromo,
  hotel,
  fetchProperty,
  updateSidebar
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    return () => {};
  }, []);

  const handleSubmit = data => {
    let { doesNotEnd, endsOn, pdf } = data;
    if (doesNotEnd) {
      endsOn = new Date(2120, 0, 1);
    }
    /* eslint-disable no-param-reassign */
    delete data.pdf;
    pdf = pdf && pdf.id ? { assetId: pdf.id } : null;

    const body = {
      ...data,
      type: 'misc2',
      pdf,
      startsOn: data.startsOn.toJSON(),
      endsOn: endsOn ? endsOn.toJSON() : endsOn,
      images,
      modal1Title:
        // eslint-disable-next-line no-nested-ternary
        data.modal1Title === 'None'
          ? null
          : data.modal1Title === 'Other'
          ? data.modal1TitleOther
          : data.modal1Title,
      modal2Title:
        // eslint-disable-next-line no-nested-ternary
        data.modal2Title === 'None'
          ? null
          : data.modal2Title === 'Other'
          ? data.modal2TitleOther
          : data.modal2Title,
      cta: Object.assign(
        data.cta,
        data.cta.type === 'Other' && { type: data.cta.typeInput }
      )
    };
    return createTiledPromo(hotelid, body)
      .then(() => push(`/hotels/${hotelid}/tiled/misc2`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };
  return (
    <Layout metaTitle="New Misc2 Promo">
      <div className="w-full max-w-3xl">
        <Link
          to={`/hotels/${hotelid}/tiled/misc2`}
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Misc2 Promos
        </Link>
        <h1 className="mb-6">New Misc2 Promo</h1>
        <div className="mb-16">
          Use this page to create and manage Misc2 Promos on {hotel.name}.
        </div>
        <HotelMeetingForm
          id="new-misc-promo"
          category="Misc2"
          hotel={hotel}
          initialValues={{
            doesNotEnd: true,
            modal1Title: 'None',
            modal2Title: 'None',
            cta: {
              type: 'None',
              opensInNewWindow: true,
              addDatePickerPopup: false,
              advancedPurchaseDays: 0,
              minimumNightStay: 1
            },
            published: false,
            archived: false
          }}
          onSubmit={handleSubmit}
        />
      </div>
      <FormNavigator
        id="new-miscs-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

Misc2New.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    images: get(state, 'images.hotel-promos-image.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt })),
    isLoading: state.property.isLoading
  }),
  { ...propertyActions, ...tiledPromoActions, ...sidebarActions }
)(Misc2New);
