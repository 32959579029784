import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeleteModal from '../DeleteModal';
import * as categoryActions from '../../../actions/categories';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteCategory: PropTypes.func.isRequired
};

const DeleteFAQCategoryModal = ({ data, deleteCategory, ...props }) => (
  <DeleteModal
    id="delete-category-modal"
    title="FAQ Category"
    itemTitle={data.title}
    deleteItem={() => deleteCategory(data.propertyId, data.id)}
    {...props}
  />
);

DeleteFAQCategoryModal.propTypes = propTypes;

export default connect(
  null,
  categoryActions
)(DeleteFAQCategoryModal);
