import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../layout';
import * as assetActions from '../../actions/assets';
import MediaAssetForm from '../../components/MediaAssetForm';

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  asset: PropTypes.object.isRequired,
  fetchAsset: PropTypes.func.isRequired,
  updateAsset: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

const EditMedia = ({
  isLoading,
  asset,
  fetchAsset,
  updateAsset,
  history,
  match: {
    params: { id }
  }
}) => {
  useEffect(() => {
    fetchAsset(id);
  }, []);

  const handleSubmit = data => {
    const tags = data.tags
      ? data.tags.split(', ').map(tag => ({ name: tag }))
      : [];
    const categories = data.categories
      ? data.categories.split(', ').map(cat => ({ name: `cat#${cat}` }))
      : [];
    return updateAsset(data.id, { tags, categories }).then(() =>
      history.push('/media')
    );
  };

  return (
    <Layout metaTitle="Edit Media">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to="/media"
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Media Library
          </Link>
          <h1 className="mb-6">Media Library</h1>
          <div className="mb-16">Use this page to edit media {asset.name}.</div>
          <MediaAssetForm
            id="edit-media-form"
            onSubmit={handleSubmit}
            initialValues={{
              ...asset,
              categories:
                asset.categories &&
                asset.categories.map(cat => cat.name).join(', '),
              tags: asset.tags && asset.tags.map(tag => tag.name).join(', ')
            }}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
      )}
    </Layout>
  );
};

EditMedia.propTypes = propTypes;

export default connect(
  (state, props) => ({
    asset: state.asset.data,
    isLoading: state.asset.isLoading
  }),
  { ...assetActions }
)(EditMedia);
