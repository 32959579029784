import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import ChevronDownIcon from '../ChevronDownIcon';

const propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  isHorizontal: PropTypes.bool,
  children: PropTypes.node
};

const defaultProps = {
  className: '',
  isHorizontal: false,
  children: null
};

const FormSection = ({ id, title, className, isHorizontal, children }) => {
  const [expanded, setExpanded] = useState(true);
  return (
    <Fragment>
      <a href="" className="anchor" id={`${id}-anchor`} name={id}>
        {' '}
      </a>
      <div
        id={id}
        className={classnames(
          'px-3 py-6 bg-white sm:px-4 sm:py-8 lg:p-8 shadow text-grey-darker border-t border-blue-lighter',
          className,
          {
            'flex items-baseline': isHorizontal
          }
        )}
      >
        <div
          id={`${id}-header`}
          role="presentation"
          className={classnames(
            'flex w-full items-center cursor-pointer'
            // {
            //   ' mb-12': expanded,
            //   'w-1/3 mr-4': isHorizontal
            // }
          )}
          onClick={() => setExpanded(!expanded)}
        >
          <div className="mr-3">
            <ChevronDownIcon
              id="edit-chevron"
              style={{
                height: 20,
                transform: expanded && 'rotate(180deg)'
              }}
              fill="#606f7b"
            />
          </div>{' '}
          <h2 className="text-xl font-normal">{title}</h2>
        </div>
        {expanded && (
          <div
            className={classnames(
              'flex w-full pt-6 flex-col flex-wrap xl:flex-row'
              // {
              //   'ml-12 flex-1': isHorizontal
              // }
            )}
          >
            {children}
          </div>
        )}
      </div>
    </Fragment>
  );
};

FormSection.propTypes = propTypes;
FormSection.defaultProps = defaultProps;

export default FormSection;
