import { actions } from 'swcms-authority';

const { READ } = actions;

// scopes provide rules for resources to be given access to other resources. The
// map would read [key]: { is given access if these allow }
const scopes = {
  property: {
    banner: [READ],
    calendar: [READ],
    calendar_event: [READ],
    event: [READ],
    faq: [READ],
    home_promo: [READ],
    hour: [READ],
    press: [READ],
    special: [READ],
    tiled_promo: [READ],
    tout: [READ],
    list: [READ],
    menu: [READ],
    accommodation: [READ]
  },
  calendar: {
    calendar_event: [READ]
  },
  faq_category: {
    faq: [READ]
  },
  tag: {
    asset: [READ]
  },
  alert: {
    can_assign_alerts: [READ]
  }
};

export default scopes;
