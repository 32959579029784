import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import '../../../css/components/list.css';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string
};

const defaultProps = {
  style: undefined,
  className: ''
};

const List = ({ id, children, style, className }) => (
  <ul id={id} style={style} className={classnames('List', className)}>
    {children}
  </ul>
);

List.propTypes = propTypes;
List.defaultProps = defaultProps;

export default List;
