import {
  FETCH_REGIONS_REQUEST,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGIONS_FAILURE,
  CREATE_REGIONS_REQUEST,
  CREATE_REGIONS_SUCCESS,
  CREATE_REGIONS_FAILURE
} from '../actions/regions';

const initialState = {
  data: [],
  isLoading: true,
  error: ''
};

// eslint-disable-next-line default-param-last
export default function regions(state = initialState, { type, payload, meta }) {
  switch (type) {
    case FETCH_REGIONS_REQUEST:
    case CREATE_REGIONS_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_REGIONS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };
    case CREATE_REGIONS_SUCCESS: {
      const currentIndex = state.data.findIndex(
        region => region.id === payload.id
      );
      const data =
        currentIndex > -1
          ? state.data
              .slice(0, currentIndex)
              .concat(payload)
              .concat(state.data.slice(currentIndex + 1))
          : state.data.concat(payload);
      return { ...state, isLoading: false, error: '', data };
    }
    case FETCH_REGIONS_FAILURE:
    case CREATE_REGIONS_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
