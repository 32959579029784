import fetch from '../utils/fetch';
import { convertToJS, convertToAPI } from '../utils/convert';

export const FETCH_PERMISSIONS_REQUEST = 'FETCH_PERMISSIONS_REQUEST';
export const FETCH_PERMISSIONS_SUCCESS = 'FETCH_PERMISSIONS_SUCCESS';
export const FETCH_PERMISSIONS_FAILURE = 'FETCH_PERMISSIONS_FAILURE';

export const UPDATE_PERMISSIONS_REQUEST = 'UPDATE_PERMISSIONS_REQUEST';
export const UPDATE_PERMISSIONS_SUCCESS = 'UPDATE_PERMISSIONS_SUCCESS';
export const UPDATE_PERMISSIONS_FAILURE = 'UPDATE_PERMISSIONS_FAILURE';

export const ADD_PERMISSION = 'ADD_PERMISSION';
export const REMOVE_PERMISSION = 'REMOVE_PERMISSION';

export const REFRESH_PERMISSIONS = 'REFRESH_PERMISSIONS';
export const RESET_PERMISSIONS = 'RESET_PERMISSIONS';

export const fetchPermissions = userId => dispatch => {
  dispatch({
    type: FETCH_PERMISSIONS_REQUEST
  });
  return fetch(`/users/${userId}/permissions?per_page=2000`)
    .then(response => {
      const permissions = convertToJS(response.data);
      dispatch({
        type: FETCH_PERMISSIONS_SUCCESS,
        payload: permissions
      });
      return permissions;
    })
    .catch(e => {
      dispatch({
        type: FETCH_PERMISSIONS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const addPermission = permission => {
  let payload = { ...permission };

  if (typeof permission === 'string') {
    payload = { resource: permission };
  }

  if ('propertyId' in payload) {
    const { propertyId } = payload;
    delete payload.propertyId;

    payload.permissionableId = propertyId;
    payload.permissionableType = 'PropertyPermission';
  }

  // rwx settings
  payload.r = true;
  payload.w = true;
  // NOTE permissions on this level x is always false
  // payload.x = false;

  return {
    type: ADD_PERMISSION,
    payload
  };
};

export const removePermission = permission => {
  let payload = permission;

  if (typeof permission === 'string') {
    payload = { resource: permission };
  }

  return {
    type: REMOVE_PERMISSION,
    payload
  };
};

export const resetPermissions = () => dispatch => {
  dispatch({
    type: RESET_PERMISSIONS,
    payload: []
  });
};

export const updatePermissions = (userId, permissions) => dispatch => {
  dispatch({
    type: UPDATE_PERMISSIONS_REQUEST
  });
  return fetch(`/users/${userId}/permissions`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(permissions))
  })
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: UPDATE_PERMISSIONS_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_PERMISSIONS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const refreshPermissions = () => ({
  type: REFRESH_PERMISSIONS
});
