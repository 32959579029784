import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as calendarEventActions from '../../../actions/calendarEvents';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteCalendarEvent: PropTypes.func.isRequired
};

const DeleteClanedarEventModal = ({ data, deleteCalendarEvent, ...props }) => (
  <DeleteModal
    id="delete-calendar-event-modal"
    title="Calendar Event"
    itemTitle={data.title}
    deleteItem={() =>
      deleteCalendarEvent(data.hotelId, data.id, data.redirectUrl)}
    {...props}
  />
);

DeleteClanedarEventModal.propTypes = propTypes;

export default connect(
  null,
  calendarEventActions
)(DeleteClanedarEventModal);
