import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as hourActions from '../../../actions/hours';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  hour: PropTypes.object.isRequired,
  deleteHour: PropTypes.func.isRequired,
  removeHour: PropTypes.func.isRequired
};

const DeleteHourModal = ({ data, hour, removeHour, deleteHour, ...props }) => (
  <DeleteModal
    id="delete-hour-modal"
    title="Hour"
    itemTitle="this hour"
    deleteItem={() =>
      hour.created
        ? removeHour(data.id)
        : deleteHour(data.restaurantId, data.id)}
    {...props}
  />
);

DeleteHourModal.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hour: state.hours.data.find(hour => hour.id === props.data.id)
  }),
  hourActions
)(DeleteHourModal);
