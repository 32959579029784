import get from 'lodash/get';
import {
  REFRESH_PERMISSIONS,
  FETCH_PERMISSIONS_REQUEST,
  FETCH_PERMISSIONS_SUCCESS,
  FETCH_PERMISSIONS_FAILURE,
  UPDATE_PERMISSIONS_REQUEST,
  UPDATE_PERMISSIONS_SUCCESS,
  UPDATE_PERMISSIONS_FAILURE,
  ADD_PERMISSION,
  REMOVE_PERMISSION,
  RESET_PERMISSIONS
} from '../actions/permissions';

const initialState = {
  data: [],
  isLoading: true,
  error: ''
};

const getPropertyId = perm => {
  const type = perm.permissionableType || get(perm, 'permissionable_type');

  if (type === 'PropertyPermission') {
    return parseInt(
      perm.permissionableId || get(perm, 'permissionable_id'),
      10
    );
  }

  return null;
};

// eslint-disable-next-line default-param-last
export default function userList(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_PERMISSIONS_REQUEST:
    case UPDATE_PERMISSIONS_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_PERMISSIONS_SUCCESS:
    case UPDATE_PERMISSIONS_SUCCESS:
    case RESET_PERMISSIONS:
      return { ...state, isLoading: false, error: '', data: payload };
    case ADD_PERMISSION: {
      const currentIndex = state.data.findIndex(
        permission =>
          getPropertyId(permission) ===
            parseInt(payload.permissionableId, 10) &&
          permission.resource === payload.resource
      );
      const data =
        currentIndex > -1
          ? state.data
              .slice(0, currentIndex)
              .concat(payload)
              .concat(state.data.slice(currentIndex + 1))
          : state.data.concat(payload);
      return { ...state, data };
    }
    case REMOVE_PERMISSION: {
      const currentIndex = state.data.findIndex(
        permission =>
          (getPropertyId(permission) === parseInt(payload.propertyId, 10) &&
            permission.resource === payload.resource) ||
          (!permission.permissionableId &&
            permission.resource === payload.resource)
      );
      const data =
        currentIndex > -1
          ? state.data
              .slice(0, currentIndex)
              .concat(state.data.slice(currentIndex + 1))
          : state.data;
      return { ...state, data };
    }
    case REFRESH_PERMISSIONS:
      return initialState;
    case FETCH_PERMISSIONS_FAILURE:
    case UPDATE_PERMISSIONS_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
