import {
  FETCH_PROPERTIES_REQUEST,
  FETCH_PROPERTIES_SUCCESS,
  FETCH_PROPERTIES_FAILURE,
  FETCH_PROPERTY_REQUEST,
  FETCH_PROPERTY_SUCCESS,
  FETCH_PROPERTY_FAILURE,
  CREATE_PROPERTY_REQUEST,
  CREATE_PROPERTY_FAILURE,
  CREATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_REQUEST,
  UPDATE_PROPERTY_SUCCESS,
  UPDATE_PROPERTY_FAILURE,
  DELETE_PROPERTY_REQUEST,
  DELETE_PROPERTY_SUCCESS,
  DELETE_PROPERTY_FAILURE,
  FETCH_PROPERTY_CACHE_SUCCESS,
  CLEAR_PROPERTY_STATE
} from '../actions/properties';

// properties is the plural namespace reducer
export function properties(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    meta: {},
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_PROPERTIES_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_PROPERTIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        data: payload,
        meta
      };

    case FETCH_PROPERTIES_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

// property is the singular namespace reducer
export function property(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_PROPERTY_REQUEST:
    case CREATE_PROPERTY_REQUEST:
    case DELETE_PROPERTY_REQUEST:
    case UPDATE_PROPERTY_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_PROPERTY_CACHE_SUCCESS:
    case FETCH_PROPERTY_SUCCESS:
    case CREATE_PROPERTY_SUCCESS:
    case UPDATE_PROPERTY_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_PROPERTY_SUCCESS:
    case CLEAR_PROPERTY_STATE:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_PROPERTY_FAILURE:
    case CREATE_PROPERTY_FAILURE:
    case UPDATE_PROPERTY_FAILURE:
    case DELETE_PROPERTY_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
