import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import get from 'lodash/get';
import * as modalActions from '../../actions/modals';
import * as assetActions from '../../actions/assets';
import * as imageActions from '../../actions/images';
import Input from '../common/Input';
import TinyMCE from '../common/form/TinyMCE';
import FormDatePicker from '../common/form/DatePicker';
import FormDropdown from '../common/form/Dropdown';
import FormSection from '../common/FormSection';
import Switch from '../common/form/Switch';
import FormErrorBox from '../common/FormErrorBox';
import promoFlags from '../../utils/promo-flag-options';
import isURL from '../../utils/is-url';
import ctaTitleOptions from '../../utils/cta-title-options';
import { daysFromOne, daysData } from '../../utils/days-options';
import SetAnAsset from '../SetAnAsset';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  hotel: PropTypes.object.isRequired,
  title: PropTypes.string,
  content: PropTypes.string,
  doesNotEnd: PropTypes.bool,
  ctaOption: PropTypes.string,
  promoFlag: PropTypes.string,
  promoFlagOther: PropTypes.string,
  detailTitle: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  datePicker: PropTypes.bool,
  customUrl: PropTypes.string,
  hideFromSpecialOffersPage: PropTypes.bool,
  detailsDatePicker: PropTypes.bool,
  published: PropTypes.bool,
  showTerms: PropTypes.bool,
  showPackage: PropTypes.bool,
  archived: PropTypes.bool,
  firstImage: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  profilePermissions: PropTypes.array.isRequired
};

const defaultProps = {
  title: '',
  content: '',
  doesNotEnd: false,
  ctaOption: '',
  promoFlag: '',
  promoFlagOther: '',
  detailTitle: '',
  datePicker: false,
  customUrl: '',
  hideFromSpecialOffersPage: false,
  detailsDatePicker: false,
  published: false,
  showTerms: false,
  showPackage: false,
  archived: false,
  error: undefined
};

const validate = values => {
  const errors = { cta: {}, details: {} };

  if (!values.title) {
    errors.title = 'Required!';
  }

  if (!values.content) {
    errors.content = 'Required!';
  }

  if (values.showTerms && !values.termsAndConditions) {
    errors.terms = "Terms + Conditions are required if they've been added";
  }

  if (values.showPackage && !values.packageRestrictions) {
    errors.package = "Package Restrictions are required if they've been added";
  }

  if (!values.startsOn) {
    errors.startsOn = 'Required!';
  }

  if (!values.doesNotEnd && !values.endsOn) {
    errors.endsOn = 'End date is required if event ends';
  }

  if (get(values, 'cta.type') === 'Book Now') {
    if (!get(values, 'cta.linkUrl')) {
      errors.cta.linkUrl = 'Required!';
    }
  }

  if (get(values, 'cta.linkUrl') && !isURL(values.cta.linkUrl)) {
    errors.cta.linkUrl = 'CTA Link URL must be valid url';
  }

  if (values.promoFlag === 'Other') {
    if (!values.promoFlagOther) {
      errors.promoFlagOther =
        'Promo Flag title is required if Other is selected';
    }
  }

  if (values['specials-image-form']) {
    errors['specials-image-form'] = errors['specials-image-form'] || {};

    const image = values['specials-image-form'];
    if (!image.asset_id) {
      errors['specials-image-form'].asset_id = 'Image is required';
    }
    if (!image.alt) {
      errors['specials-image-form'].alt = 'Image Alt Tag is required';
    }
  }

  if (values['specials-details-image']) {
    errors['specials-details-image'] = errors['specials-details-image'] || {};

    const image = values['specials-details-image'];
    if (!image.asset_id) {
      errors['specials-details-image'].asset_id = 'Image is required';
    }
    if (!image.alt) {
      errors['specials-details-image'].alt = 'Image Alt Tag is required';
    }
  }

  return errors;
};

const ctaOptions = ['Book Now', 'Offer Details', 'None'].map(opt => ({
  id: opt,
  name: opt
}));

const condfn = tagName => {
  return () => {
    return {
      filetype: 'image',
      category: tagName
    };
  };
};

const HotelSpecialForm = ({
  id,
  handleSubmit,
  hotel,
  showModal,
  title,
  content,
  doesNotEnd,
  ctaOption,
  promoFlag,
  promoFlagOther,
  detailTitle,
  datePicker,
  customUrl,
  hideFromSpecialOffersPage,
  detailsDatePicker,
  published,
  showTerms,
  showPackage,
  archived,
  firstImage,
  change,
  error,
  profilePermissions
}) => {
  const contentString = content || '';

  const canUploadAsset = () => {
    return (
      profilePermissions.findIndex(perm => {
        return (
          perm.resource === '*' &&
          perm.permissionableId === null &&
          perm.permissionableType === null &&
          perm.r &&
          perm.w &&
          perm.x
        );
      }) > -1
    );
  };

  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="title"
        title="Title & Content"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Enter the Title of the Special, Other Title, and Content to use
                for this special.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="font-normal mb-3 text-base">Title*</div>
          <Field
            id="specials-title"
            name="title"
            className="w-full mb-6"
            placeholder="Enter a title..."
            component={Input}
          />
          <div className="font-normal mb-3">Other Title*</div>
          <Field
            id="specials-other-title"
            name="otherTitle"
            className="w-full mb-6"
            placeholder="Enter an Other Title..."
            component={Input}
          />
          <div className="font-normal mb-3 text-base">
            Content for this Special*
          </div>
          <Field
            id="special-content"
            name="content"
            className="w-full mb-6"
            placeholder="Enter the content for this special..."
            component={TinyMCE}
          />
          {showPackage ? (
            <Fragment>
              <div className="font-base mb-4 text-base flex justify-between">
                <div>Package Restrictions</div>
                <button
                  type="button"
                  className="btn-link text-blue no-underline hover:underline"
                  onClick={e => {
                    e.preventDefault();
                    change('showPackage', false);
                    change('packageRestrictions', '');
                  }}
                >
                  - Remove this modal
                </button>
              </div>
              <Field
                id="package-restrictions"
                name="packageRestrictions"
                className="mb-6"
                placeholder="Enter the Package Restrictions here..."
                component={TinyMCE}
              />
            </Fragment>
          ) : (
            <div className="my-6">
              <a
                href="#0"
                className="btn-link text-blue no-underline hover:underline"
                onClick={e => {
                  e.preventDefault();
                  change('showPackage', true);
                }}
              >
                + Add Package Restrictions
              </a>
            </div>
          )}
          {showTerms ? (
            <Fragment>
              <div className="font-normal mt-8 mb-4 text-base flex justify-between">
                <div>Terms + Conditions</div>
                <a
                  href="#0"
                  className="btn-link text-blue no-underline hover:underline"
                  onClick={e => {
                    e.preventDefault();
                    change('showTerms', false);
                    change('termsAndConditions', '');
                  }}
                >
                  - Remove this modal
                </a>
              </div>
              <Field
                id="terms-conditions"
                name="termsAndConditions"
                className="mb-6"
                placeholder="Enter the Terms + Conditions here..."
                component={TinyMCE}
              />
            </Fragment>
          ) : (
            <div className="my-6">
              <a
                href="#0"
                className="btn-link text-blue no-underline hover:underline"
                onClick={e => {
                  e.preventDefault();
                  change('showTerms', true);
                }}
              >
                + Add Terms + Conditions
              </a>
            </div>
          )}
        </div>
      </FormSection>
      <FormSection
        id="date"
        title="Date & Time"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter the Start Date for this special. To set an End Date,
                uncheck 'Special does not end'.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Start Date*</div>
          <Field
            id="starts-on"
            name="startsOn"
            component={FormDatePicker}
            className="mb-6"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MM/dd/yyyy h:mm aa"
            timeCaption="time"
            autoComplete="off"
          />
          <div className="flex items-center">
            <Field
              id="does-not-end"
              type="checkbox"
              name="doesNotEnd"
              className="mr-3"
              component={Input}
              onChange={(_, v) => {
                if (v) {
                  change('endsOn', new Date(2120, 0, 1));
                } else {
                  change('endsOn', null);
                }
              }}
            />
            <div className="text-base font-normal">Special does not end</div>
          </div>
          {!doesNotEnd && (
            <div>
              <div
                className={classnames('text-base font-normal mb-3 pt-6', {
                  'text-grey-light': doesNotEnd
                })}
              >
                End Date*
              </div>
              <Field
                id="ends-on"
                name="endsOn"
                component={FormDatePicker}
                disabled={doesNotEnd}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy h:mm aa"
                timeCaption="time"
                autoComplete="off"
              />
            </div>
          )}
        </div>
      </FormSection>
      <FormSection
        id="image"
        title="Set an Image"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Select an image to use for this special. If you don't see the
                image you are looking for,&nbsp;
                <a
                  href="http://tickets.still-water.com/tickets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  create a ticket here.
                </a>
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <SetAnAsset
            change={change}
            condfn={condfn('cat#Specials')}
            canUploadAsset={canUploadAsset}
            categories={[{ name: 'Home Promos' }]}
            name='specials-image-form'
            id='hotel-special-upload'
          />
        </div>
      </FormSection>
      <FormSection
        id="cta"
        title="CTA (Call to Action)"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>Select a CTA option to use for this special.</i>
            </p>
            <p>
              <i>
                If you'd like to add an Adobe Tracking tag to the link for this
                special, enter it in the Adobe Tracking tag field.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="w-full mb-6">
            <div className="text-base font-normal mb-3">
              Select a CTA Option*
            </div>
            <Field
              id="cta.type"
              name="cta.type"
              data={ctaOptions}
              component={FormDropdown}
              style={{ width: 300 }}
            />
          </div>
          {ctaOption === 'Book Now' && (
            <Fragment>
              <div className="text-base font-normal mb-3">Link URL*</div>
              <div className="flex flex-col mb-6">
                <Field
                  id="cta.linkUrl"
                  name="cta.linkUrl"
                  className="flex-1 mr-2 mb-3"
                  placeholder="Enter a Link URL..."
                  component={Input}
                />
                <div className="flex justify-left">
                  <button
                    type="button"
                    className="btn btn-clear px-6 mr-4"
                    onClick={e => {
                      e.preventDefault();
                      showModal('GenerateURLModal', {
                        form: 'HotelSpecialForm',
                        field: 'cta.linkUrl',
                        hotelId: hotel.id
                      });
                    }}
                  >
                    Generate URL
                  </button>
                  <button
                    type="button"
                    className="btn btn-clear px-6"
                    onClick={e => {
                      e.preventDefault();
                      showModal('GenerateEmailURLModal', {
                        form: 'HotelSpecialForm',
                        field: 'cta.linkUrl',
                        hotelId: hotel.id
                      });
                    }}
                  >
                    Generate Email URL
                  </button>
                </div>
              </div>
              <div className="flex mb-6 items-center">
                <Field
                  id="cta.opensInNewWindow"
                  name="cta.opensInNewWindow"
                  type="checkbox"
                  className="mr-2"
                  component={Input}
                />
                <div className="text-base font-normal">
                  Opens in a New Window
                </div>
              </div>
              <div className="flex mb-6 items-center">
                <Field
                  id="cta.addDatePickerPopup"
                  name="cta.addDatePickerPopup"
                  type="checkbox"
                  className="mr-2"
                  component={Input}
                />
                <div className="text-base font-normal">
                  Add Date Picker Popup
                </div>
              </div>
              <div className="flex mb-6 items-center">
                <Field
                  id="cta.advancedPurchaseDays"
                  name="cta.advancedPurchaseDays"
                  className="mr-2"
                  component={FormDropdown}
                  data={daysData}
                  disabled={!datePicker}
                />
                <div
                  className={classnames('text-base font-normal', {
                    'text-grey': !datePicker
                  })}
                >
                  Advance Purchase Days
                </div>
              </div>
              <div className="flex mb-12 items-center">
                <Field
                  id="cta.minimumNightStay"
                  name="cta.minimumNightStay"
                  className="mr-2"
                  component={FormDropdown}
                  data={daysFromOne}
                  disabled={!datePicker}
                />
                <div
                  className={classnames('text-base font-normal', {
                    'text-grey': !datePicker
                  })}
                >
                  Minimum Night Stay
                </div>
              </div>
            </Fragment>
          )}
          {ctaOption !== 'None' && (
            <Fragment>
              <div className="text-base font-normal mb-3">
                Adobe Tracking Tag
              </div>
              <Field
                id="cta.trackingTag"
                name="cta.trackingTag"
                className="w-full mb-4"
                placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
                component={Input}
              />
              <div className="flex justify-left">
                <button
                  type="button"
                  className="btn btn-clear px-6 mr-4"
                  onClick={e => {
                    e.preventDefault();
                    showModal('GenerateTrackingModal', {
                      form: 'HotelSpecialForm',
                      field: 'cta.trackingTag'
                    });
                  }}
                >
                  Generate Tracking
                </button>
              </div>
            </Fragment>
          )}
        </div>
      </FormSection>
      <FormSection
        id="promo"
        title="Promo Flag"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Set a promo flag for this special from the dropdown. To use a
                custom promo flag, select 'Other' from the dropdown and enter
                the text to display.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Promo Flag:</div>
          <Field
            id="promoFlag"
            name="promoFlag"
            data={promoFlags}
            className="mb-6"
            style={{ width: 300 }}
            component={FormDropdown}
          />
          {promoFlag === 'Other' && (
            <Field
              id="promoFlagOther"
              name="promoFlagOther"
              className="mb-6"
              component={Input}
              placeholder="Enter title for Promo Flag"
            />
          )}
        </div>
      </FormSection>
      <FormSection
        id="details"
        title="Details Page"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter a custom URL and the content to use for this details page.
              </i>
            </p>
            <p>
              <i>
                If you don't want to display this tile in the Specials page,
                select the 'Hide from Special Offers Page' checkbox.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="w-full">
            <div className="text-base font-normal mb-3">Custom URL:</div>
            <Field
              id="details.customUrl"
              name="details.customUrl"
              placeholder="details-page-custom-url"
              className="w-full mb-4"
              component={Input}
            />

            <div className="text-base mb-6">
              https://
              {hotel.url}/exclusive-offer/?p=
              <span className="font-bold">
                {customUrl}
                {!customUrl && id}
              </span>
            </div>
            <div className="flex items-center mb-6">
              <Field
                id="details.hideFromSpecialOffersPage"
                name="details.hideFromSpecialOffersPage"
                component={Input}
                className="mr-2"
                type="checkbox"
              />
              <div className="text-base font-normal">
                Hide from Special Offers Page
              </div>
            </div>
          </div>
          <div className="flex-1">
            <div className="text-base font-normal mb-3">Page Content*:</div>
            <Field
              id="details.content"
              name="details.content"
              className="mb-6"
              placeholder="Enter the content for this Detail Page..."
              component={TinyMCE}
            />
            <div
              className={classnames('flex mb-6 items-center', {
                'text-grey': !showPackage
              })}
            >
              <Field
                id="details.displayPackageRestrictions"
                name="details.displayPackageRestrictions"
                type="checkbox"
                className="mr-2"
                component={Input}
                disabled={!showPackage}
              />
              <div className="text-base font-normal">
                Display Package Restrictions content on page
              </div>
            </div>
            <div
              className={classnames('flex mb-6 items-center', {
                'text-grey': !showTerms
              })}
            >
              <Field
                id="details.displayTermsAndConditions"
                name="details.displayTermsAndConditions"
                type="checkbox"
                className="mr-2"
                component={Input}
                disabled={!showTerms}
              />
              <div className="text-base font-normal">
                Display Terms + Conditions content on page
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full flex-col flex-wrap xl:flex-row mt-5 pt-5 border-t border-dashed border-grey-light">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <h2 className="text-xl font-normal mb-3">Details Page Image</h2>
              <p>
                <i>
                  Select an image to use for this Details Page. If you don't see
                  the image you are looking for,&nbsp;
                  <a
                    href="http://tickets.still-water.com/tickets"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    create a ticket here.
                  </a>
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <SetAnAsset 
              change={change}
              condfn={condfn('cat#Specials Detail')}
              canUploadAsset={canUploadAsset}
              categories={[{ name: 'Home Promos' }]}
              name='specials-details-image'
              id='specials-details-upload'
              header='Select an image for this Details Page*'
          />
          </div>
        </div>
        <div className="flex w-full flex-col flex-wrap xl:flex-row mt-5 pt-5 border-t border-dashed border-grey-light">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <h2 className="text-xl font-normal mb-3">Details Page CTA</h2>
              <p>
                <i>
                  Select a CTA to use for this Details Page. To use a custom
                  CTA, select 'Other' from the dropdown and enter your text.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="text-base font-normal mb-3">
              Details Page CTA Title*
            </div>
            <Field
              id="details.cta.type"
              name="details.cta.type"
              className="mb-6"
              data={ctaTitleOptions}
              style={{ width: 256 }}
              component={FormDropdown}
            />
            {detailTitle === 'Other' && (
              <Field
                id="details.cta.titleInput"
                name="details.cta.titleInput"
                className="mb-6 -mt-3"
                component={Input}
                placeholder="Enter custom title for CTA"
              />
            )}
            {detailTitle !== 'None' && (
              <Fragment>
                <div
                  className={classnames('text-base font-normal mb-3', {
                    'text-grey': detailTitle === 'None'
                  })}
                >
                  CTA Link URL
                </div>
                <div className="flex flex-col mb-6">
                  <Field
                    id="details.cta.linkUrl"
                    name="details.cta.linkUrl"
                    className="flex-1 mr-2 mb-3"
                    component={Input}
                    disabled={detailTitle === 'None'}
                  />
                  <div className="flex justify-left">
                    {(detailTitle === 'Check Availability' ||
                      detailTitle === 'Other') && (
                      <button
                        type="button"
                        className="btn btn-clear px-6 mr-4"
                        onClick={e => {
                          e.preventDefault();
                          showModal('GenerateURLModal', {
                            form: 'HotelSpecialForm',
                            field: 'details.cta.linkUrl',
                            hotelId: hotel.id
                          });
                        }}
                      >
                        Generate URL
                      </button>
                    )}
                    {(detailTitle === 'Inquire Now' ||
                      detailTitle === 'Other') && (
                      <button
                        type="button"
                        className="btn btn-clear px-6"
                        onClick={e => {
                          e.preventDefault();
                          showModal('GenerateEmailURLModal', {
                            form: 'HotelSpecialForm',
                            field: 'details.cta.linkUrl',
                            hotelId: hotel.id
                          });
                        }}
                      >
                        Generate Email URL
                      </button>
                    )}
                  </div>
                </div>
                <div className="flex mb-12 items-center">
                  <Field
                    id="details.cta.opensInNewWindow"
                    name="details.cta.opensInNewWindow"
                    className="mr-2"
                    type="checkbox"
                    component={Input}
                  />
                  <div className="text-base font-normal">
                    Opens in a New Window
                  </div>
                </div>
                {detailTitle !== 'Learn More' && detailTitle !== 'Inquire Now' && (
                  <Fragment>
                    <div className="flex mb-6 items-center">
                      <Field
                        id="details.cta.addDatePickerPopup"
                        name="details.cta.addDatePickerPopup"
                        type="checkbox"
                        className="mr-2"
                        component={Input}
                      />
                      <div className="text-base font-normal">
                        Add Date Picker Popup
                      </div>
                    </div>
                    <div className="flex mb-6 items-center">
                      <Field
                        id="details.cta.advancedPurchaseDays"
                        name="details.cta.advancedPurchaseDays"
                        className="mr-2"
                        component={FormDropdown}
                        data={daysData}
                        disabled={!detailsDatePicker}
                      />
                      <div
                        className={classnames('text-base font-normal', {
                          'text-grey': !detailsDatePicker
                        })}
                      >
                        Advance Purchase Days
                      </div>
                    </div>
                    <div className="flex mb-12 items-center">
                      <Field
                        id="details.cta.minimumNightStay"
                        name="details.cta.minimumNightStay"
                        className="mr-2"
                        component={FormDropdown}
                        data={daysFromOne}
                        disabled={!detailsDatePicker}
                      />
                      <div
                        className={classnames('text-base font-normal', {
                          'text-grey': !detailsDatePicker
                        })}
                      >
                        Minimum Night Stay
                      </div>
                    </div>
                  </Fragment>
                )}
                <div className="text-base font-normal mb-3">
                  Adobe Tracking Tag
                </div>
                <Field
                  id="details.cta.trackingTag"
                  name="details.cta.trackingTag"
                  className="w-full mb-4"
                  placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
                  component={Input}
                />
                <div className="flex justify-left">
                  <button
                    type="button"
                    className="btn btn-clear px-6 mr-4"
                    onClick={e => {
                      e.preventDefault();
                      showModal('GenerateTrackingModal', {
                        form: 'HotelSpecialForm',
                        field: 'details.cta.trackingTag'
                      });
                    }}
                  >
                    Generate Tracking
                  </button>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </FormSection>
      <FormSection
        id="preview"
        title="Preview & Publish"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex w-full flex-col flex-wrap xl:flex-row">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  Here is a preview of your special. When you're ready, toggle
                  the publish status to display it on the live site and hit
                  'Save'.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="text-base font-normal mb-3">Preview:</div>
            {hideFromSpecialOffersPage && (
              <div>This promo is set to be hidden on Special Offers page.</div>
            )}
            {!hideFromSpecialOffersPage && (
              <div
                className="bg-white shadow preview-special"
                style={{
                  width: 400
                }}
              >
                {firstImage.file && (
                  <img
                    alt={firstImage.alt}
                    src={get(firstImage, 'file.url')}
                    className="w-full"
                  />
                )}
                {promoFlag === 'None' ? (
                  ''
                ) : (
                  <div className="ribbon-callout">
                    {promoFlag === 'Other' ? promoFlagOther : promoFlag}
                  </div>
                )}
                <div className="tile_text_box">
                  <h3 className="headline">{title}</h3>
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: contentString }}
                  />
                  <div className="restrictions">
                    {showPackage && (
                      <p className="mt-4">
                        <a href="#0" className="">
                          Package Restrictions
                        </a>
                      </p>
                    )}
                    {showTerms && (
                      <p className="mt-4">
                        <a href="#0" className="">
                          Terms & Conditions
                        </a>
                      </p>
                    )}
                  </div>
                </div>
                {ctaOption === 'None' ? (
                  ''
                ) : (
                  <div className="tile_action_link">
                    <div className="">{ctaOption}</div>
                  </div>
                )}
              </div>
            )}
            <div className="mt-12">
              <div className="text-base font-normal mb-3">Publish Status:</div>
              <Field
                id="published"
                name="published"
                component={Switch}
                label={published ? 'Published' : 'Unpublished'}
                className="mb-12"
                disabled={archived}
              />
              <div className="text-base font-normal mb-3">
                Archive this Special?
              </div>
              <Field
                id="archived"
                name="archived"
                component={Switch}
                label={archived ? 'Archived' : 'Unarchived'}
                className="mb-6"
                onChange={(e, value) => {
                  e.preventDefault();
                  if (value && published) {
                    change('published', false);
                  }
                  change('archived', value);
                }}
              />
            </div>
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && <FormErrorBox id="hotel-special-error">{error}</FormErrorBox>}
    </form>
  );
};

HotelSpecialForm.propTypes = propTypes;
HotelSpecialForm.defaultProps = defaultProps;

const selector = formValueSelector('HotelSpecialForm');

export default compose(
  reduxForm({ form: 'HotelSpecialForm', validate }),
  connect(
    state => ({
      id: selector(state, 'id'),
      title: selector(state, 'title'),
      otherTitle: selector(state, 'otherTitle'),
      content: selector(state, 'content'),
      doesNotEnd: selector(state, 'doesNotEnd'),
      ctaOption: selector(state, 'cta.type'),
      datePicker: selector(state, 'cta.addDatePickerPopup'),
      customUrl: selector(state, 'details.customUrl'),
      hideFromSpecialOffersPage: selector(
        state,
        'details.hideFromSpecialOffersPage'
      ),
      detailsDatePicker: selector(state, 'details.cta.addDatePickerPopup'),
      showTerms:
        selector(state, 'showTerms') || selector(state, 'termsAndConditions'),
      showPackage:
        selector(state, 'showPackage') ||
        selector(state, 'packageRestrictions'),
      promoFlag: selector(state, 'promoFlag'),
      promoFlagOther: selector(state, 'promoFlagOther'),
      detailTitle: selector(state, 'details.cta.type'),
      archived: selector(state, 'archived'),
      published: selector(state, 'published'),
      firstImage: get(state, 'images.specials-image-form.items.0', {}),
      profilePermissions: state.auth.permissions
    }),
    { ...modalActions, ...assetActions, ...imageActions }
  )
)(HotelSpecialForm);
