import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeleteModal from '../DeleteModal';
import * as propertyActions from '../../../actions/properties';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteProperty: PropTypes.func.isRequired
};

const DeleteRestaurantModal = ({ data, deleteProperty, ...props }) => (
  <DeleteModal
    id="delete-restaurant-modal"
    title="Restaurant"
    itemTitle={data.title}
    deleteItem={() => deleteProperty(data.id)}
    {...props}
  />
);

DeleteRestaurantModal.propTypes = propTypes;

export default connect(
  null,
  propertyActions
)(DeleteRestaurantModal);
