import {
  FETCH_FAQS_REQUEST,
  FETCH_FAQS_SUCCESS,
  FETCH_FAQS_FAILURE,
  CLONE_FAQS_REQUEST,
  CLONE_FAQS_SUCCESS,
  CLONE_FAQS_FAILURE,
  SORT_FAQS_REQUEST,
  SORT_FAQS_SUCCESS,
  SORT_FAQS_FAILURE,
  FETCH_FAQ_REQUEST,
  FETCH_FAQ_SUCCESS,
  FETCH_FAQ_FAILURE,
  CREATE_FAQ_REQUEST,
  CREATE_FAQ_SUCCESS,
  CREATE_FAQ_FAILURE,
  UPDATE_FAQ_REQUEST,
  UPDATE_FAQ_SUCCESS,
  UPDATE_FAQ_FAILURE,
  DELETE_FAQ_REQUEST,
  DELETE_FAQ_SUCCESS,
  DELETE_FAQ_FAILURE,
  MOVE_FAQ
} from '../actions/faqs';

export function faqs(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_FAQS_REQUEST:
    case CLONE_FAQS_REQUEST:
    case SORT_FAQS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_FAQS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case MOVE_FAQ: {
      const { source, target } = payload;
      const data = state.data.map(item => {
        if (item.id === source.id) {
          // change item index to new source index
          return { ...item, sortIndex: target.index };
        }

        if (item.id === target.id) {
          // change item index to new target index
          return { ...item, sortIndex: source.index };
        }

        return item;
      });
      return { ...state, data };
    }

    case CLONE_FAQS_SUCCESS:
    case SORT_FAQS_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_FAQS_FAILURE:
    case CLONE_FAQS_FAILURE:
    case SORT_FAQS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function faq(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_FAQ_REQUEST:
    case CREATE_FAQ_REQUEST:
    case UPDATE_FAQ_REQUEST:
    case DELETE_FAQ_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_FAQ_SUCCESS:
    case CREATE_FAQ_SUCCESS:
    case UPDATE_FAQ_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_FAQ_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_FAQ_FAILURE:
    case CREATE_FAQ_FAILURE:
    case UPDATE_FAQ_FAILURE:
    case DELETE_FAQ_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
