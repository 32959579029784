import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as assetActions from '../../../actions/assets';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteAsset: PropTypes.func.isRequired
};

const DeleteAssetModal = ({ data, deleteAsset, ...props }) => (
  <DeleteModal
    id="delete-asset"
    title="Delete Asset"
    itemTitle={data.title}
    deleteItem={() => deleteAsset(data.id, data.redirectUrl)}
    {...props}
  />
);

DeleteAssetModal.propTypes = propTypes;

export default connect(
  null,
  assetActions
)(DeleteAssetModal);
