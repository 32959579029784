import {
  FETCH_GLOBAL_ALERT_SUCCESS,
  FETCH_GLOBAL_ALERT_REQUEST,
  FETCH_GLOBAL_ALERT_FAILURE,
  CREATE_GLOBAL_ALERT_SUCCESS,
  CREATE_GLOBAL_ALERT_REQUEST,
  CREATE_GLOBAL_ALERT_FAILURE,
  DELETE_GLOBAL_ALERT_SUCCESS,
  DELETE_GLOBAL_ALERT_REQUEST,
  DELETE_GLOBAL_ALERT_FAILURE
} from '../actions/globalAlert';

export default function globalAlert(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_GLOBAL_ALERT_REQUEST:
    case CREATE_GLOBAL_ALERT_REQUEST:
    case DELETE_GLOBAL_ALERT_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_GLOBAL_ALERT_SUCCESS:
    case CREATE_GLOBAL_ALERT_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_GLOBAL_ALERT_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_GLOBAL_ALERT_FAILURE:
    case CREATE_GLOBAL_ALERT_FAILURE:
    case DELETE_GLOBAL_ALERT_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
