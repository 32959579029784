import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as accommodationActions from '../../../actions/accommodations';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteAccommodation: PropTypes.func.isRequired
};

const DeleteAccommodationModal = ({ data, deleteAccommodation, ...props }) => (
  <DeleteModal
    id="delete-accommodation-modal"
    title="Accommodation"
    itemTitle={data.title}
    deleteItem={() => deleteAccommodation(data.hotelId, data.id)}
    {...props}
  />
);

DeleteAccommodationModal.propTypes = propTypes;

export default connect(
  null,
  accommodationActions
)(DeleteAccommodationModal);
