import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import Layout from '../../../layout';
import * as sidebarActions from '../../../actions/sidebar';
import * as propertyActions from '../../../actions/properties';
import * as pressActions from '../../../actions/presses';
import RestaurantPressForm from '../../../components/RestaurantPressForm/RestaurantPressForm';
import FormNavigator from '../../../components/FormNavigator';

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'date', title: 'Publication Date' },
  { id: 'upload', title: 'Upload a PDF' },
  { id: 'preview', title: 'Preview + Publish' },
];

class Edit extends Component {
  componentDidMount() {
    const {
      updateSidebar,
      fetchProperty,
      fetchPress,
      match: {
        params: { restaurantId, id }
      }
    } = this.props;
    fetchProperty(restaurantId).then(restaurant => {
      updateSidebar({ titles: { Restaurant: restaurant.name } });
    });
    fetchPress(restaurantId, id);
  }

  handleSubmit = data => {
    const {
      updatePress,
      match: {
        params: { restaurantId, id }
      },
      history: { push }
    } = this.props;

    /* eslint-disable no-param-reassign */
    let { pdf } = data;
    pdf = pdf && pdf.id ? { assetId: pdf.id } : null;

    const press = {
      ...data,
      pdf,
      publishDate: data.publishDate.toJSON()
    };

    return updatePress(restaurantId, id, press)
      .then(() => push(`/restaurants/${restaurantId}/press`))
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  render() {
    const {
      restaurant,
      press,
      isLoading,
      match: {
        params: { restaurantId }
      }
    } = this.props;
    return (
      <Layout metaTitle="Press">
        <div className="w-full max-w-3xl">
          <Link
            to={`/restaurants/${restaurantId}/press`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Press
          </Link>
          <h1 className="mb-4">Edit Press</h1>
          <div className="mb-16">
            Use this page to manage {press.title} Press Item for{' '}
            {isLoading ? 'Restaurant' : restaurant.name}.
          </div>
          <RestaurantPressForm
            id="edit-press-form"
            onSubmit={this.handleSubmit}
            initialValues={{
              ...press,
              publishDate: press.publishDate
                ? new Date(press.publishDate)
                : press.publishDate
            }}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
        <FormNavigator
          id="new-press-navigator"
          sections={sections}
          className="hidden xl:block mt-32 pt-16"
        />
      </Layout>
    );
  }
}

Edit.propTypes = {
  updateSidebar: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updatePress: PropTypes.func.isRequired,
  fetchPress: PropTypes.func.isRequired,
  restaurant: PropTypes.object,
  press: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  match: PropTypes.object,
  history: PropTypes.object
};

Edit.defaultProps = {
  restaurant: {},
  press: {},
  match: {},
  history: {}
};

export default connect(
  (state) => ({
    restaurant: state.property.data,
    press: state.press.data,
    isLoading: state.property.isLoading || state.press.isLoading
  }),
  { ...sidebarActions, ...propertyActions, ...pressActions }
)(Edit);
