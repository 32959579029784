const esc = encodeURIComponent;

/**
 * fromObj takes an object and returns it querystring (less ?)
 *
 * @param {Object} obj (basic key: value json style object)
 * @param {String} pref (a prefix string, might be ? or antoher &)
 * @return {String}
 */

const fromObj = (obj, pref = '') => {
  if (!obj) {
    return '';
  }
  return Object.keys(obj).reduce(
    (memo, k) => `${memo}&${esc(k)}=${esc(obj[k])}`,
    pref
  );
};

export { fromObj };
