import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as tokenActions from '../../../actions/tokens';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteToken: PropTypes.func.isRequired
};

const DeleteTokenModal = ({ data, deleteToken, ...props }) => (
  <DeleteModal
    id="delete-token-modal"
    title="Token"
    itemTitle={data.title || 'name or title unavailable'}
    deleteItem={() => deleteToken(data.id)}
    {...props}
  />
);

DeleteTokenModal.propTypes = propTypes;

export default connect(
  null,
  tokenActions
)(DeleteTokenModal);
