import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import qs from 'querystring';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import matchSorter from 'match-sorter';
import * as propertyActions from '../../../actions/properties';
import * as specialActions from '../../../actions/specials';
import * as sidebarActions from '../../../actions/sidebar';
import Layout from '../../../layout';
import SearchBar from '../../../components/common/SearchBar';
import Input from '../../../components/common/Input';
import {
  Tabs,
  Tab,
  TabList,
  TabPill,
  TabPanel
} from '../../../components/common/Tabs';
import CheckboxList from '../../../components/common/List/CheckboxList';
import { List, ListItem } from '../../../components/common/List';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  hotels: PropTypes.array.isRequired,
  specials: PropTypes.array.isRequired,
  fetchProperties: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchSpecials: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  cloneSpecials: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired
};

const Clone = ({
  hotel,
  hotels,
  specials,
  fetchProperties,
  fetchProperty,
  fetchSpecials,
  updateSidebar,
  cloneSpecials,
  match: {
    url,
    params: { hotelid }
  },
  location: { search },
  history
}) => {
  const [searchSpecials, setSearchSpecials] = useState('');
  const [searchHotels, setSearchHotels] = useState('');
  const [checked, setChecked] = useState({});
  const [specialsChecked, setSpecialsChecked] = useState({});
  useEffect(() => {
    const { selected } = qs.parse(search.slice(1));
    if (selected) {
      setSpecialsChecked({
        ...specialsChecked,
        [selected]: !specialsChecked[selected]
      });
      history.replace(url);
    }
    fetchProperties({ per_page: 200 });
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchSpecials(hotelid, { per_page: 1000 });
    return () => {};
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();

    const ids = Object.keys(specialsChecked)
      .map(v => {
        return specialsChecked[v] ? v : undefined;
      })
      .filter(v => v);

    const toPropertyIds = Object.keys(checked)
      .map(v => {
        return checked[v] ? v : undefined;
      })
      .filter(v => v);

    if (!ids.length || !toPropertyIds.length) {
      // TODO alert or notification that both items and properties to clone to
      // must have at least one item checkd
      return;
    }

    cloneSpecials(hotelid, ids, toPropertyIds).then(() => {
      history.push(`/hotels/${hotelid}/specials`);
    });
  };

  const filteredSpecials = matchSorter(specials, searchSpecials, {
    keys: ['title']
  });
  const currentSpecials = filteredSpecials.filter(special => !special.archived);
  const archivedSpecials = filteredSpecials.filter(special => special.archived);
  const filteredHotels = matchSorter(hotels, searchHotels, { keys: ['name'] });

  return (
    <Layout metaTitle="Clone a Special">
      <div className="w-full max-w-3xl">
        <Link
          to={`/hotels/${hotelid}/specials`}
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Specials
        </Link>
        <h1 className="mb-6">Clone a Special</h1>
        <div className="mb-16">
          Use this page to Clone Specials on {hotel.name}.
        </div>
        <div className="flex flex-wrap justify-between mb-16">
          <div style={{ width: 440 }}>
            <SearchBar
              id="filter-specials"
              className="mb-4"
              placeholder="Filter Specials..."
              value={searchSpecials}
              onChange={({ value }) => setSearchSpecials(value)}
            />
            <Tabs>
              <TabList>
                <Tab>
                  <span className="mr-2">Current</span>
                  <TabPill id="current-pill">{currentSpecials.length}</TabPill>
                </Tab>
                <Tab>
                  <span className="mr-2">Archived</span>
                  <TabPill id="archived-pill">
                    {archivedSpecials.length}
                  </TabPill>
                </Tab>
              </TabList>

              <TabPanel>
                <List id="current-list">
                  {currentSpecials.map(special => (
                    <ListItem id={special.id} key={special.id}>
                      <Input
                        id={special.id}
                        type="checkbox"
                        className="mr-4"
                        input={{
                          checked: !!specialsChecked[special.id],
                          onChange: () =>
                            setSpecialsChecked({
                              ...specialsChecked,
                              [special.id]: !specialsChecked[special.id]
                            })
                        }}
                      />
                      <Link
                        to={`/hotels/${hotelid}/specials/${special.id}/edit`}
                      >
                        {special.title}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
              <TabPanel>
                <List id="archived-list">
                  {archivedSpecials.map(special => (
                    <ListItem id={special.id} key={special.id}>
                      <Input
                        id={special.id}
                        type="checkbox"
                        className="mr-4"
                        input={{
                          checked: !!specialsChecked[special.id],
                          onChange: () =>
                            setSpecialsChecked({
                              ...specialsChecked,
                              [special.id]: !specialsChecked[special.id]
                            })
                        }}
                      />
                      <Link
                        to={`/hotels/${hotelid}/specials/${special.id}/edit`}
                      >
                        {special.title}
                      </Link>
                    </ListItem>
                  ))}
                </List>
              </TabPanel>
            </Tabs>
          </div>
          <div style={{ width: 440 }}>
            <SearchBar
              id="filter-hotels"
              className="mb-16"
              placeholder="Filter Hotels..."
              value={searchHotels}
              onChange={({ value }) => setSearchHotels(value)}
            />
            <CheckboxList
              id="checkbox-hotels"
              title="Hotels"
              data={filteredHotels.map(hotelItem => ({
                id: hotelItem.id,
                name: hotelItem.name,
                displayName: item => (
                  <Link to={`/hotels/${item.id}`} className="mr-4">
                    {item.name}
                  </Link>
                ),
                checked: checked[hotelItem.id],
                onChange: () => {
                  setChecked({
                    ...checked,
                    [hotelItem.id]: !checked[hotelItem.id]
                  });
                }
              }))}
            />
          </div>
        </div>
        <div className="flex justify-end">
          <button type="button" className="btn" onClick={onSubmit}>
            Clone
          </button>
        </div>
      </div>
    </Layout>
  );
};

Clone.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    // FIXME
    hotels: state.properties.data.filter(property => property.type === 'hotel'),
    specials: state.specials.data,
    isLoading:
      state.property.isLoading ||
      state.properties.isLoading ||
      state.specials.isLoading
  }),
  { ...propertyActions, ...specialActions, ...sidebarActions }
)(Clone);
