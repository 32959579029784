import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object
};

const defaultProps = {
  className: undefined,
  style: null
};

const RestaurantIcon = ({ id, className, style, ...otherProps }) => (
  <svg
    id={id}
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
    viewBox="0 0 512 512"
    {...otherProps}
  >
    <path d="M164.852 279.939l61.834-60.251L73.72 71.706c-33.626 32.764-33.626 86.677 0 119.44l91.132 88.793z" />
    <path d="M312.389 241.88c33.636 14.802 80.283 4.232 113.91-29.593 41.222-40.165 49.909-98.303 17.363-128.96-31.465-31.71-91.131-23.245-132.354 16.921-34.718 33.825-45.566 79.276-30.374 110.986-47.739 47.568-211.552 207.173-211.552 207.173L99.759 448l149.71-145.866L399.177 448l30.374-29.593-149.709-145.869 32.547-30.658z" />
  </svg>
);

RestaurantIcon.propTypes = propTypes;
RestaurantIcon.defaultProps = defaultProps;

export default RestaurantIcon;
