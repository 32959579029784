import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

const defaultProps = {
  className: ''
};

const FormErrorBox = ({ id, children, className }) => (
  <div
    id={id}
    className={classnames(
      'mt-6 py-4 px-3 border border-red-dark bg-red-lighter text-red-dark rounded-sm',
      className
    )}
  >
    {children}
  </div>
);

FormErrorBox.propTypes = propTypes;
FormErrorBox.defaultProps = defaultProps;

export default FormErrorBox;
