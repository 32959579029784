import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { submit, SubmissionError } from 'redux-form';
import pick from 'lodash/pick';
import Layout from '../../layout';
import * as userActions from '../../actions/users';
import FormSection from '../../components/common/FormSection';
import EditUserForm from '../../components/EditUserForm';
import EditUserTable from '../../components/EditUserTable';
import * as permissionActions from '../../actions/permissions';

const propTypes = {
  createUser: PropTypes.func.isRequired,
  permissions: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired,
  history: PropTypes.object,
  refreshPermissions: PropTypes.func.isRequired,
  updatePermissions: PropTypes.func.isRequired
};

const defaultProps = {
  history: {}
};

const New = ({
  createUser,
  permissions,
  updatePermissions,
  refreshPermissions,
  history,
  submit // eslint-disable-line no-shadow
}) => {
  useEffect(() => {
    refreshPermissions();
    return () => {};
  }, []);

  const handleSubmit = data => {
    const body = pick(data, ['email', 'firstName', 'lastName', 'password']);

    return createUser({
      ...body,
      generatePassword: data.generatePassword
    })
      .then(user => updatePermissions(user.id, permissions))
      .then(() => {
        history.push('/users');
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout metaTitle="New User">
      <div className="w-full max-w-3xl">
        <Link
          to="/users"
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Users
        </Link>
        <h1 className="mb-6">New User</h1>
        <div className="mb-16">
          Use this page to create new Users and manage permissions.
        </div>
        <div id="details" title="User Details">
          <EditUserForm
            initialValues={{
              generatePasswordText: 'Generate Password'
            }}
            onSubmit={handleSubmit}
          />
        </div>
        <FormSection
          id="permissions"
          title="Assigned Permissions"
          className="border-t mb-12"
        >
          <div className="w-full">
            <EditUserTable permissions={permissions} />
          </div>
        </FormSection>
        <div className="flex justify-center">
          <button
            className="btn"
            type="submit"
            onClick={() => submit('EditUserForm')}
          >
            Create
          </button>
        </div>
      </div>
    </Layout>
  );
};

New.propTypes = propTypes;
New.defaultProps = defaultProps;

export default connect(
  state => ({
    permissions: state.permissions.data
  }),
  { ...userActions, ...permissionActions, submit }
)(New);
