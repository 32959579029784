import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';
import SearchBarContext from './SearchBarContext';
import SearchIcon from '../SearchIcon';

class SearchBar extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleClose = () => this.setState({ isOpen: false });

  // eslint-disable-next-line react/no-unused-class-component-methods
  setIsOpen = v => {
    this.setState({ isOpen: v });
  };

  render() {
    const {
      id,
      className,
      style,
      placeholder,
      value,
      children,
      onChange,
      onInputFocus,
      onClick
    } = this.props;
    const { isOpen } = this.state;
    return (
      <div id={id} className={className} style={style}>
        <div
          id={`${id}-container`}
          className={classnames(
            'md:m-1 lg:m-0 flex rounded-sm bg-grey-light border-2',
            {
              'rounded-b-none': isOpen
            }
          )}
        >
          <input
            id={`${id}-input`}
            className="px-2 py-2 outline-none flex-1"
            placeholder={placeholder}
            value={value}
            onFocus={onInputFocus}
            onChange={e => {
              e.preventDefault();
              if (children) this.setState({ isOpen: true });
              onChange({ id, value: e.target.value });
            }}
            onClick={
              onClick ||
              (e => {
                e.preventDefault();
                if (children) this.setState({ isOpen: true });
                onChange({ id, value: e.target.value });
              })
            }
          />
          <div className="border-grey-light flex items-center p-2 bg-white text-grey">
            <SearchIcon
              id="search-bar-icon"
              className="fill-grey-light text-grey w-6 h-6"
            />
          </div>
        </div>
        {isOpen && (
          <SearchBarContext.Provider value={{ onClose: this.handleClose }}>
            {React.cloneElement(children, {
              style: { width: style.width }
            })}
          </SearchBarContext.Provider>
        )}
      </div>
    );
  }
}

SearchBar.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onInputFocus: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.node
};

SearchBar.defaultProps = {
  className: '',
  style: {},
  value: '',
  placeholder: '',
  onChange: () => {},
  onInputFocus: () => {},
  onClick: () => {},
  children: null
};

const clickOutsideConfig = {
  handleClickOutside: instance => instance.handleClose
};

export { SearchBar };

export default onClickOutside(SearchBar, clickOutsideConfig);
