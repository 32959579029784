// abstractions for tab lits and pagination support

const inputHandlerFunc = (stateHandler, callback, condfn = () => null) => {
  return (val, selected) => {
    stateHandler(val);
    callback(selected, condfn(val));
  };
};

const fetchHandlerFunc = (fetchfn, countfn, tabsc = [], parentId = null) => {
  return (selected, cond = {}) => {
    // if there is no tabsc, assume no tabs and the search is a basic search to
    // one table
    if (tabsc.length === 0) {
      fetchfn(cond);

      return;
    }

    tabsc.forEach((v, i) => {
      const args = [{ ...v.qry, ...cond }];
      if (parentId) {
        args.unshift(parentId);
      }
      if (i === selected) {
        fetchfn.call(null, ...args).then(p => v.cb(p.meta.count));
      } else {
        countfn.call(null, ...args).then(p => v.cb(p.count));
      }
    });
  };
};

/**
 * expose
 */

export { inputHandlerFunc };
export { fetchHandlerFunc };
