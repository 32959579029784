/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import { moveBySplice } from '../../../utils/move_by_splice';
import { onDrop } from '../../../utils/sort-helpers';
import Layout from '../../../layout';
import * as sidebarActions from '../../../actions/sidebar';
import * as categoriesActions from '../../../actions/categories';
import * as propertyActions from '../../../actions/properties';
import {
  Tabs,
  TabList,
  Tab,
  TabPill,
  TabPanel
} from '../../../components/common/Tabs';
import More, { MoreMenu, MoreMenuItem } from '../../../components/common/More';
import { List, ListItem } from '../../../components/common/List';
import EditableLink from '../../../components/common/EditableLink';
import * as modalActions from '../../../actions/modals';
import * as faqActions from '../../../actions/faqs';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  faqs: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  sortFaqs: PropTypes.func.isRequired,
  updateFAQ: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  fetchFAQs: PropTypes.func.isRequired,
  authority: PropTypes.object.isRequired
};

/**
 * FAQsList is a wrapper around the generic List component to allow for proper
 * sorting when requiring multiple groupings on a page. This effectively
 * isolates each FAQ category so sorting is done on the set group of FAQs passed
 * into the component and not the full data list returned from the API (as the
 * category grouping is done post request via a category match map)
 *
 * @param {Object} (prop object, expanded)
 * @returns {Object} (React Component)
 * @api private
 */

// FIXME these need to be propTypes and defaultProps if this is a reusable
// component
const FAQsList = ({
  id = '',
  cat,
  data = [],
  hotelid,
  url = '',
  updateFAQ,
  push,
  showModal,
  sortFaqs,
  len,
  updateCallback = () => {}
}) => {
  const [faqs, setFaqs] = useState([]);

  const moveFAQ = (source, target) =>
    setFaqs(moveBySplice(source, target, faqs));

  // NOTE we watch for len to set the data, else this component does not reload
  // on redux dispatch otherwise
  useEffect(
    () => {
      setFaqs(data);

      return () => {};
    },
    [len]
  );

  if (!faqs.length) {
    return <div className="p-4">No FAQs yet for {cat.name}</div>;
  }

  return (
    <List id={id}>
      {faqs.map((faq, faqIdx) => (
        <ListItem
          dataSortIndex={faq.sortIndex}
          key={faq.id}
          id={faq.id}
          className="justify-between"
          isDraggable
          index={faqIdx}
          moveRow={(drag, hover) => moveFAQ(drag, hover)}
          onDrop={(evt, moved) => {
            if (moved) {
              onDrop(evt, faqs, sortFaqs.bind(null, hotelid), {
                archived: faq.archived,
                category_id: cat.id
              });
            }
          }}
        >
          <Link to={`${url}/${faq.id}/edit`} className="inline-block">
            {faq.question}
          </Link>
          <More id={`${faq.id}-more`}>
            <MoreMenu id={`${faq.id}-moremenu`}>
              <MoreMenuItem
                id={`${faq.id}-archive`}
                onClick={() =>
                  updateFAQ(
                    hotelid,
                    faq.id,
                    Object.assign(
                      {
                        id: faq.id,
                        archived: !faq.archived
                      },
                      !faq.archived && { published: false }
                    )
                  ).then(updateCallback)}
              >
                {faq.archived ? 'Unarchive' : 'Archive'}
              </MoreMenuItem>
              <MoreMenuItem
                id={`${faq.id}-clone`}
                onClick={() => push(`${url}/clone`)}
              >
                Clone
              </MoreMenuItem>
              <MoreMenuItem
                id={`${faq.id}-delete`}
                onClick={() =>
                  showModal(
                    'DeleteFAQModal',
                    {
                      hotelId: hotelid,
                      id: faq.id,
                      title: faq.question
                    },
                    () => {
                      updateCallback();
                    }
                  )}
              >
                Delete
              </MoreMenuItem>
            </MoreMenu>
          </More>
        </ListItem>
      ))}
    </List>
  );
};

const FAQs = ({
  hotel,
  faqs,
  categories,
  match: {
    url,
    params: { hotelid }
  },
  history: { push },
  fetchProperty,
  updateSidebar,
  fetchCategories,
  updateProperty,
  sortFaqs,
  updateFAQ,
  fetchFAQs,
  showModal,
  authority
}) => {
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchCategories(hotelid, undefined, { order_by: 'sort_index:ASC' });
    return () => {};
  }, []);

  useEffect(() => {
    fetchFAQs(hotelid, { order_by: 'sort_index:ASC' });
    return () => {};
  }, [refresh]);

  const faqsURL = get(hotel, 'previewUrls.faqsUrl');
  return (
    <Layout metaTitle="FAQs">
      <div className="w-full max-w-3xl">
        <h1 className="mb-6">FAQs</h1>
        <div className="mb-6">
          Use this page to create and manage FAQs for {hotel.name}.
        </div>
        <div className="mb-12 text-sm flex items-center">
          View live site:{' '}
          <EditableLink
            id="hotel-faqs-link"
            className="ml-3"
            link={faqsURL}
            target="_blank"
            onSubmit={data =>
              updateProperty(hotelid, {
                previewUrls: { ...hotel.previewUrls, faqsUrl: data.link }
              })}
            isEditable={authority.isSuper()}
          />
        </div>
        <div className="flex flex-col md:flex-row mb-6 md:mb-12 md:justify-end">
          <Link
            to={`${url}/categories`}
            className="flex-initial btn-link pb-6 md:pt-4 text-blue hover:underline mr-3 md:mr-6 inline-block"
          >
            Edit FAQ Categories
          </Link>
          <div className="flex flex-row justify-start mb-6 md:mb-0 md:justify-end">
            <Link
              to={`${url}/clone`}
              className="flex-initial btn btn-clear no-underline mr-3 inline-block"
            >
              Clone FAQ
            </Link>
            <Link
              to={`${url}/new`}
              className="flex-initial btn no-underline inline-block"
            >
              + New FAQ
            </Link>
          </div>
        </div>
        {categories.map(cat => {
          const activeFaq = faqs.filter(
            faq => faq.category.id === cat.id && !faq.archived
          );
          const archivedFaq = faqs.filter(
            faq => faq.category.id === cat.id && faq.archived
          );
          return (
            <Tabs key={cat.id}>
              <div className="mb-8">
                <div className="flex justify-between">
                  <h2 className="font-light mb-6">{cat.name}</h2>
                  <div className="flex">
                    <TabList>
                      <Tab>
                        <span className="mr-3">Active</span>
                        <TabPill id={`${cat.id}-active`}>
                          {activeFaq.length}
                        </TabPill>
                      </Tab>
                      <Tab>
                        <span className="mr-3">Archived</span>
                        <TabPill id={`${cat.id}-archived`}>
                          {archivedFaq.length}
                        </TabPill>
                      </Tab>
                    </TabList>
                  </div>
                </div>

                <TabPanel>
                  <FAQsList
                    id={`${cat.id}-active`}
                    cat={cat}
                    data={activeFaq}
                    hotelid={hotelid}
                    url={url}
                    updateFAQ={updateFAQ}
                    updateCallback={() => {
                      setRefresh(refresh + 1);
                    }}
                    push={push}
                    showModal={showModal}
                    sortFaqs={sortFaqs}
                    len={activeFaq.length}
                  />
                </TabPanel>
                <TabPanel>
                  <FAQsList
                    id={`${cat.id}-archived`}
                    cat={cat}
                    data={archivedFaq}
                    hotelid={hotelid}
                    url={url}
                    updateFAQ={updateFAQ}
                    updateCallback={() => {
                      setRefresh(refresh + 1);
                    }}
                    push={push}
                    showModal={showModal}
                    sortFaqs={sortFaqs}
                    len={archivedFaq.length}
                  />
                </TabPanel>
              </div>
            </Tabs>
          );
        })}
      </div>
    </Layout>
  );
};

FAQs.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    faqs: state.faqs.data,
    categories: state.categories.data,
    authority: state.auth.authority,
    isLoading:
      state.property.isLoading ||
      state.faqs.isLoading ||
      state.categories.isLoading
  }),
  {
    ...sidebarActions,
    ...categoriesActions,
    ...modalActions,
    ...propertyActions,
    ...faqActions
  }
)(FAQs);
