import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';

import * as tokenActions from '../../actions/tokens';

import Layout from '../../layout';
import FormNavigator from '../../components/FormNavigator';
import TokensNewForm from '../../components/TokensNewForm';

const propTypes = {
  history: PropTypes.object.isRequired,
  createToken: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const sections = [{ id: 'name', title: 'Name & Property Access' }];

const TokensNew = ({ isLoading, createToken, history: { push } }) => {
  const handleSubmit = data => {
    return createToken(data)
      .then(token => {
        push(`/tokens?id=${token.id}`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };
  return (
    <Layout type="Tokens" metaTitle="New Token">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to="/tokens"
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to API Tokens Listing
          </Link>
          <h1 className="mb-6">New API Token</h1>
          <div className="mb-16">
            This page is used to create a New API Token.
          </div>
          <TokensNewForm id="new-token" onSubmit={handleSubmit} />
        </div>
      )}
      <FormNavigator
        id="new-specials-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

TokensNew.propTypes = propTypes;

export default connect(
  () => ({}),
  { ...tokenActions }
)(TokensNew);
