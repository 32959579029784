import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Layout from '../../../layout';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';

const propTypes = {
  match: PropTypes.object.isRequired,
  restaurant: PropTypes.object.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired
};

const Dashboard = ({
  match: {
    params: { restaurantId }
  },
  restaurant,
  fetchProperty,
  updateSidebar
}) => {
  useEffect(() => {
    fetchProperty(restaurantId).then(item => {
      updateSidebar({ titles: { Restaurant: item.name } });
    });
    return () => {};
  }, []);

  return (
    <Layout metaTitle="Dashboad">
      <div className="w-full max-w-3xl">
        <h1 className="mb-6">{restaurant.name}</h1>
        <div className="mb-6">This is the dashboard for {restaurant.name}.</div>
      </div>
    </Layout>
  );
};

Dashboard.propTypes = propTypes;

export default connect(
  (state) => ({
    restaurant: state.property.data,
    regions: state.regions.data,
    isLoading: state.property.isLoading || state.regions.isLoading
  }),
  { ...propertyActions, ...sidebarActions }
)(Dashboard);
