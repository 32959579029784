import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_PROPERTIES_REQUEST = 'FETCH_PROPERTIES_REQUEST';
export const FETCH_PROPERTIES_SUCCESS = 'FETCH_PROPERTIES_SUCCESS';
export const FETCH_PROPERTIES_FAILURE = 'FETCH_PROPERTIES_FAILURE';

export const FETCH_PROPERTY_REQUEST = 'FETCH_PROPERTY_REQUEST';
export const FETCH_PROPERTY_SUCCESS = 'FETCH_PROPERTY_SUCCESS';
export const FETCH_PROPERTY_FAILURE = 'FETCH_PROPERTY_FAILURE';

export const CREATE_PROPERTY_REQUEST = 'CREATE_PROPERTY_REQUEST';
export const CREATE_PROPERTY_SUCCESS = 'CREATE_PROPERTY_SUCCESS';
export const CREATE_PROPERTY_FAILURE = 'CREATE_PROPERTY_FAILURE';

export const UPDATE_PROPERTY_REQUEST = 'UPDATE_PROPERTY_REQUEST';
export const UPDATE_PROPERTY_SUCCESS = 'UPDATE_PROPERTY_SUCCESS';
export const UPDATE_PROPERTY_FAILURE = 'UPDATE_PROPERTY_FAILURE';

export const DELETE_PROPERTY_REQUEST = 'DELETE_PROPERTY_REQUEST';
export const DELETE_PROPERTY_SUCCESS = 'DELETE_PROPERTY_SUCCESS';
export const DELETE_PROPERTY_FAILURE = 'DELETE_PROPERTY_FAILURE';

export const FETCH_PROPERTY_CACHE_SUCCESS = 'FETCH_PROPERTY_CACHE_SUCCESS';

export const CLEAR_PROPERTY_STATE = 'CLEAR_PROPERTY_STATE';

// if path is provided, it will use that as the as the fetch url instead of
// generating the url through the params options
// eslint-disable-next-line default-param-last
export const fetchProperties = (params = {}, path) => dispatch => {
  dispatch({
    type: FETCH_PROPERTIES_REQUEST
  });
  return fetch(
    path || `/properties${fromObj({ archived: false, ...params }, '?')}`
  )
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_PROPERTIES_SUCCESS,
        payload: data,
        meta
      });
      return { properties: data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_PROPERTIES_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const fetchProperty = (id, nocache = false) => (dispatch, getState) => {
  const {
    property: { data: property }
  } = getState();
  if (property && property.id === id && !nocache) {
    dispatch({
      type: FETCH_PROPERTY_CACHE_SUCCESS,
      payload: property
    });
    return Promise.resolve(property);
  }

  dispatch({
    type: FETCH_PROPERTY_REQUEST
  });
  return fetch(`/properties/${id}`)
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: FETCH_PROPERTY_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: FETCH_PROPERTY_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createProperty = property => dispatch => {
  dispatch({
    type: CREATE_PROPERTY_REQUEST
  });
  return fetch('/properties', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(property))
  })
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: CREATE_PROPERTY_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: CREATE_PROPERTY_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateProperty = (propertyId, update) => dispatch => {
  dispatch({
    type: UPDATE_PROPERTY_REQUEST
  });
  return fetch(`/properties/${propertyId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const property = convertToJS(response.data);
      dispatch({
        type: UPDATE_PROPERTY_SUCCESS,
        payload: property
      });
      return property;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_PROPERTY_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteProperty = propertyId => dispatch => {
  dispatch({
    type: DELETE_PROPERTY_REQUEST
  });
  return fetch(`/properties/${propertyId}`, { method: 'DELETE' })
    .then(() => {
      dispatch({
        type: DELETE_PROPERTY_SUCCESS,
        payload: propertyId
      });
      return propertyId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_PROPERTY_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countProperties = params => dispatch => {
  return fetch(`/properties${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};
