import {
  FETCH_ALERTS_REQUEST,
  FETCH_ALERTS_SUCCESS,
  FETCH_ALERTS_FAILURE,
  FETCH_ALERT_REQUEST,
  FETCH_ALERT_SUCCESS,
  FETCH_ALERT_FAILURE,
  CREATE_ALERT_REQUEST,
  CREATE_ALERT_SUCCESS,
  CREATE_ALERT_FAILURE,
  UPDATE_ALERT_REQUEST,
  UPDATE_ALERT_SUCCESS,
  UPDATE_ALERT_FAILURE,
  DELETE_ALERT_REQUEST,
  DELETE_ALERT_SUCCESS,
  DELETE_ALERT_FAILURE
} from '../actions/alerts';

export function alerts(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_ALERTS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_ALERTS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case FETCH_ALERTS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function alert(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_ALERT_REQUEST:
    case CREATE_ALERT_REQUEST:
    case UPDATE_ALERT_REQUEST:
    case DELETE_ALERT_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_ALERT_SUCCESS:
    case CREATE_ALERT_SUCCESS:
    case UPDATE_ALERT_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_ALERT_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_ALERT_FAILURE:
    case CREATE_ALERT_FAILURE:
    case UPDATE_ALERT_FAILURE:
    case DELETE_ALERT_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
