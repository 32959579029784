import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter as Router } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { DragDropContextProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import Cookies from 'js-cookie';
import './css/index.css';

import {
  loadAuth,
  fetchProfile,
  fetchProfilePermissions
} from './actions/auth';

import store from './store';

import Header from './components/Header';
import Footer from './components/Footer';
import ModalPortal from './components/ModalPortal';
import ScrollToTop from './components/ScrollToTop';

import AppRoutes from './routes';

const propTypes = {
  history: PropTypes.object.isRequired
}

const App = ({ history }) => {
  // because of state change reloads that occur from the start, there is a point
  // in the app's cycle where a user can be "logged in" but have no permissions
  // yet to authorize them access to protected sections. This causes a logged in
  // user to be redirected back to the main page since authorizations happen in
  // that time in PrivateRoute that fail and redirect the user because the
  // permissions fetch has not completed before the page is accessed. Ready will
  // set that state once the full auth states are fetched and set allowing users
  // to directly access bookmarked or shared pages and maintaining proper auth
  // checks.
  const [ready, setReady] = useState(false);

  const token = Cookies.get('token');
  if (token && !ready) {
    store.dispatch(loadAuth(token));
    store
      .dispatch(fetchProfile())
      .then(() => store.dispatch(fetchProfilePermissions()))
      .then(() => setReady(true))
      .catch(err => {
        // FIXME need to handle these errors
        console.log('[error]', err); // eslint-disable-line
      });
  } else if (!ready) {
    // if there is no token and the page is not ready (specifically) then user
    // has not logged in, set to ready and proceed with the page as usually and
    // let authenticated checks kick in and handle routing
    setReady(true);
  }
  if (!ready) {
    // FIXME nice loading landing page with branding, etc...
    return <h1>Loading...</h1>;
  }

  return (
    <Provider store={store}>
      <DragDropContextProvider backend={HTML5Backend}>
        <Router history={history}>
          <Fragment>
            <ScrollToTop />
            <Header />
            <AppRoutes />
            <ModalPortal />
            <Footer />
          </Fragment>
        </Router>
      </DragDropContextProvider>
    </Provider>
  );
};

App.propTypes = propTypes;

export default withRouter(App);
