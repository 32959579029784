import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import ExitButton from '../../common/ExitButton';
import EditCalendarForm from './EditCalendarForm';
import * as calendarActions from '../../../actions/calendars';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  updateCalendar: PropTypes.func.isRequired
};

const EditCalendarModal = ({
  onClose,
  data: { propertyId, id, title },
  updateCalendar
}) => {
  const initialValues = { calendarName: title, calendarId: id };
  return (
    <div id="calendar-event-repeat-modal">
      <div className="flex justify-center border-b py-2 px-4 border-grey bg-grey-lighter">
        <h2 className="font-light">Edit Calendar</h2>
        <ExitButton
          id="calendar-event-repeat-exit"
          onClick={onClose}
          className="absolute"
          divStyle={{ right: '.5rem' }}
          svgStyle={{ fill: 'black' }}
        />
      </div>
      <div className="p-6">
        <EditCalendarForm
          initialValues={initialValues}
          id="edit-calendar-modal-form"
          onSubmit={data => {
            updateCalendar(propertyId, id, { name: data.calendarName })
              .then(() => {
                onClose();
              })
              .catch(e => {
                // FIXME this needs to actually be handled
                console.log('[error]', e); // eslint-disable-line
              });
          }}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
    </div>
  );
};

EditCalendarModal.propTypes = propTypes;

export default connect(
  (state, props) => ({
    property: state.property.data
  }),
  {
    change,
    ...calendarActions
  }
)(EditCalendarModal);
