import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Link, withRouter } from 'react-router-dom';
import Layout from '../../../layout';
import * as regionsActions from '../../../actions/regions';
import * as propertyActions from '../../../actions/properties';
import EditRegionForm from '../../../components/EditRegionForm';

const propTypes = {
  region: PropTypes.object.isRequired,
  hotels: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  history: PropTypes.object
};

const defaultProps = {
  history: {}
};

const RegionsEdit = ({
  region,
  hotels,
  isLoading,
  fetchRegions,
  updateProperty,
  history: { push }
}) => {
  useEffect(() => {
    fetchRegions();
    return () => {};
  }, []);
  const handleSubmit = data => {
    // eslint-disable-next-line no-shadow
    const { hotels } = data;
    const updateHotels = Object.keys(hotels)
      .filter(hotelId => hotels[hotelId])
      .map(hotelId => updateProperty(hotelId, { region_id: region.id }));
    return Promise.all(updateHotels).then(() => {
      push('/hotels/regions');
    });
  };

  return (
    <Layout metaTitle="Edit Region">
      <div className="w-full max-w-3xl">
        <h1 className="mb-8">{isLoading ? 'Region' : region.name}</h1>
        <div className="mb-8">
          Use this page to manage hotels for {!isLoading && region.name} region.
        </div>
        <Link to="/hotels/regions" className="text-black mb-12 inline-block">
          &lt; Back to Regions
        </Link>
        <EditRegionForm
          id="regions-edit"
          initialValues={{
            hotels: hotels.reduce(
              (obj, hotel) => ({
                ...obj,
                [hotel.id]: String(hotel.regionId) === region.id
              }),
              {}
            ),
            ...region
          }}
          onSubmit={handleSubmit}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
    </Layout>
  );
};

RegionsEdit.propTypes = propTypes;
RegionsEdit.defaultProps = defaultProps;

export default compose(
  withRouter,
  connect(
    (state, props) => ({
      region:
        state.regions.data.find(
          region => region.id === props.match.params.id
        ) || {},
      hotels: state.properties.data.filter(
        property => property.type === 'hotel'
      ),
      isLoading: state.regions.isLoading && state.properties.isLoading
    }),
    { ...propertyActions, ...regionsActions }
  )
)(RegionsEdit);
