import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as specialActions from '../../../actions/specials';
import HotelSpecialForm from '../../../components/HotelSpecialForm';
import Layout from '../../../layout';
import promoFlags from '../../../utils/promo-flag-options';
import FormNavigator from '../../../components/FormNavigator';
import * as imageActions from '../../../actions/images';
import ctaTitleOptions from '../../../utils/cta-title-options';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  special: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  detailsImage: PropTypes.object.isRequired,
  updateSpecial: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchSpecial: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired
};

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'date', title: 'Date & Time' },
  { id: 'image', title: 'Set an Image' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'promo', title: 'Promo Flag' },
  { id: 'details', title: 'Details Page' },
  { id: 'preview', title: 'Preview + Publish' },
];

const SpecialsEdit = ({
  match: {
    params: { hotelid, id }
  },
  history: { push },
  updateSpecial,
  hotel,
  special,
  images,
  detailsImage,
  isLoading,
  fetchProperty,
  fetchSpecial,
  updateSidebar,
  setCurrentImage
}) => {
  useEffect(() => {
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchSpecial(hotelid, id);
    return () => {};
  }, []);

  useEffect(() => {
    get(special, 'images', []).forEach(image => {
      setCurrentImage('specials-image-form', image);
    });

    const currentDetailsImage = get(special, 'details.image');
    if (currentDetailsImage) {
      setCurrentImage('specials-details-image', currentDetailsImage);
    }
    return () => {};
  }, [special]);

  const handleSubmit = data => {
    const detailType = get(data, 'details.cta.type');
    const detailTitle =
      detailType === 'Other' ? data.details.cta.titleInput : detailType;
    const body = {
      ...data,
      startsOn: data.startsOn.toJSON(),
      endsOn: data.endsOn ? data.endsOn.toJSON() : data.endsOn,
      promo_flag:
        // eslint-disable-next-line no-nested-ternary
        data.promoFlag === 'Other' ? data.promoFlagOther : data.promoFlag,
      images,
      details: {
        ...data.details,
        image: detailsImage.file
          ? { assetId: detailsImage.file.id, alt: detailsImage.alt }
          : null,
        cta: { ...data.details.cta, type: detailTitle }
      }
    };
    return updateSpecial(hotelid, id, body)
      .then(() => {
        push(`/hotels/${hotelid}/specials`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout type="Specials" metaTitle="Edit Special">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to={`/hotels/${hotel.id}/specials`}
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Specials
          </Link>
          <h1 className="mb-6">{special.title}</h1>
          <div className="mb-16">
            Use this page to edit {special.title} Special for {hotel.name}.
          </div>

          <HotelSpecialForm
            id="edit-specials"
            hotel={hotel}
            initialValues={{
              ...special,
              // eslint-disable-next-line no-nested-ternary
              promoFlag: !special.promoFlag
                ? 'None'
                : promoFlags.findIndex(
                    title => title.id === special.promoFlag
                  ) === -1
                ? 'Other'
                : special.promoFlag,
              promoFlagOther:
                promoFlags.findIndex(
                  title => title.id === special.promoFlag
                ) === -1
                  ? special.promoFlag
                  : undefined,
              details: special.details && {
                ...special.details,
                cta: special.details.cta && {
                  ...special.details.cta,
                  type:
                    ctaTitleOptions.findIndex(
                      title => title.id === get(special, 'details.cta.type')
                    ) === -1
                      ? 'Other'
                      : get(special, 'details.cta.type'),
                  titleInput:
                    ctaTitleOptions.findIndex(
                      title => title.id === get(special, 'details.cta.type')
                    ) === -1
                      ? get(special, 'details.cta.type')
                      : undefined
                }
              },
              startsOn: special.startsOn
                ? new Date(special.startsOn)
                : special.startsOn,
              endsOn: special.endsOn ? new Date(special.endsOn) : special.endsOn
            }}
            onSubmit={handleSubmit}
            enableReinitialize
            keepDirtyOnReinitialize
          />
        </div>
      )}
      <FormNavigator
        id="edit-specials-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

SpecialsEdit.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    special: state.special.data,
    images: get(state, 'images.specials-image-form.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt })),
    detailsImage: get(state, 'images.specials-details-image.items.0', {}),
    isLoading: state.property.isLoading || state.special.isLoading
  }),
  { ...propertyActions, ...specialActions, ...sidebarActions, ...imageActions }
)(SpecialsEdit);
