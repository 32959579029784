import { moveBySplice } from '../utils/move_by_splice';
import {
  FETCH_HOME_PROMOS_REQUEST,
  FETCH_HOME_PROMOS_SUCCESS,
  FETCH_HOME_PROMOS_FAILURE,
  CREATE_HOME_PROMO_REQUEST,
  CREATE_HOME_PROMO_SUCCESS,
  CREATE_HOME_PROMO_FAILURE,
  FETCH_HOME_PROMO_REQUEST,
  FETCH_HOME_PROMO_SUCCESS,
  FETCH_HOME_PROMO_FAILURE,
  UPDATE_HOME_PROMO_REQUEST,
  UPDATE_HOME_PROMO_SUCCESS,
  UPDATE_HOME_PROMO_FAILURE,
  DELETE_HOME_PROMO_REQUEST,
  DELETE_HOME_PROMO_SUCCESS,
  DELETE_HOME_PROMO_FAILURE,
  MOVE_HOME_PROMO,
  CLONE_HOME_PROMOS_REQUEST,
  CLONE_HOME_PROMOS_SUCCESS,
  CLONE_HOME_PROMOS_FAILURE,
  SORT_HOME_PROMOS_REQUEST,
  SORT_HOME_PROMOS_SUCCESS,
  SORT_HOME_PROMOS_FAILURE
} from '../actions/homePromos';

export function homePromos(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_HOME_PROMOS_REQUEST:
    case CLONE_HOME_PROMOS_REQUEST:
    case SORT_HOME_PROMOS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_HOME_PROMOS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case MOVE_HOME_PROMO: {
      const { source, target } = payload;
      const data = moveBySplice(source, target, state.data);

      return { ...state, data };
    }

    case CLONE_HOME_PROMOS_SUCCESS:
    case SORT_HOME_PROMOS_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_HOME_PROMOS_FAILURE:
    case CLONE_HOME_PROMOS_FAILURE:
    case SORT_HOME_PROMOS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function homePromo(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case CREATE_HOME_PROMO_REQUEST:
    case FETCH_HOME_PROMO_REQUEST:
    case UPDATE_HOME_PROMO_REQUEST:
    case DELETE_HOME_PROMO_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_HOME_PROMO_SUCCESS:
    case CREATE_HOME_PROMO_SUCCESS:
    case UPDATE_HOME_PROMO_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_HOME_PROMO_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_HOME_PROMO_FAILURE:
    case CREATE_HOME_PROMO_FAILURE:
    case UPDATE_HOME_PROMO_FAILURE:
    case DELETE_HOME_PROMO_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
