import directories from '../routes/directories';
import pathsFor from '../routes/pathsFor';

export const NAV_UPDATE = 'NAV_UPDATE';

/**
 * setNav sets the nav state for a give react-router match
 *
 * @param {Object} match (react-router match prop)
 * @param {Function} filterfn
 * @returns {Object}
 * @api public
 */

export const setNav = (match, filterfn) => {
  // get the relevant navs for the path
  const payload = pathsFor(match.path, filterfn)(directories);

  return {
    type: NAV_UPDATE,
    payload,
    match
  };
};
