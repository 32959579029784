import React, { Fragment, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import * as navActions from '../actions/nav';

import { canViewFunc } from '../components/SidebarNav/canView';
import Sidebar from '../components/Sidebar';
import Breadcrumb from '../components/Breadcrumb';

import { siteTitle } from '../constants';

const propTypes = {
  children: PropTypes.node.isRequired,
  metaTitle: PropTypes.string.isRequired,
  setNav: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  authority: PropTypes.object.isRequired,
  // FIXME why are we passing property into the layout? Is it for permissions
  // or 404 checks?
  property: PropTypes.object.isRequired
};

const Layout = ({
  children,
  metaTitle,
  match,
  setNav,
  authority,
  property
}) => {
  useEffect(() => {
    const filterfn = canViewFunc(authority).bind(null, property);
    setNav(match, filterfn);

    return () => {};
  }, [match]);

  return (
    <Fragment>
      <Helmet>
        <title>
          {metaTitle} | {siteTitle}
        </title>
      </Helmet>
      <main style={{ minHeight: 'calc(100vh - 69px)' }} className="sm:flex">
        <Sidebar />
        <div className="w-48 hidden lg:block" />
        <div className="flex-1 bg-gray-100">
          <Breadcrumb />
          <div id="body" className="flex justify-center p-4">
            {children}
          </div>
        </div>
      </main>
    </Fragment>
  );
};

Layout.propTypes = propTypes;

export default compose(
  withRouter,
  connect(
    (state) => ({
      authority: state.auth.authority,
      property: state.property.data,
      navs: state.nav.data,
      isLoading: state.nav.isLoading
    }),
    { ...navActions }
  )
)(Layout);
