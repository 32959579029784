import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';

import * as authActions from '../../actions/auth';
import hwl from '../../assets/hwl.jpg';

import LoginForm from './LoginForm';

class Home extends Component {
  handleLogin = data => {
    const {
      loginUser,
      fetchProfile,
      fetchProfilePermissions,
      history,
      location
    } = this.props;
    const { email, password } = data;

    if (email) {
      // super basic example without an actual login flow lol
      // this is more to show how you can use Redux's mapDispatchToProps
      return loginUser(email, password)
        .then(() => fetchProfile())
        .then(() => fetchProfilePermissions())
        .then(() => {
          const from = get(location, 'state.from');
          history.push(from || '/');
        })
        .catch(() => {
          throw new SubmissionError({
            _error: 'Incorrect email and/or password.'
          });
        });
    }
    return null;
  };

  render() {
    return (
      <main style={{ height: 'calc(100vh - 69px)' }} className="flex">
        <div className="w-full max-w-3xl mx-4 md:w-1/2 h-full flex items-center justify-center">
          <div className="w-full max-w-3xl text-grey-darker md:ml-32 mb-24">
            <h1 className="mb-10 font-light">Sign In</h1>
            <LoginForm onSubmit={this.handleLogin} />
            <Link to="/forgot-password">Forgot your password?</Link>
          </div>
        </div>

        <div className="w-1/2 hidden md:block">
          <img
            src={hwl}
            className="h-full"
            style={{ objectFit: 'cover' }}
            alt="Beautiful rooftop view of Hotel"
          />
        </div>
      </main>
    );
  }
}

Home.propTypes = {
  loginUser: PropTypes.func.isRequired,
  fetchProfile: PropTypes.func.isRequired,
  fetchProfilePermissions: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  connect(
    state => ({ email: state.auth.email }),
    authActions
  ),
  withRouter
)(Home);
