import React from 'react';
import Layout from '../../layout';

const Settings = () => (
  <Layout metaTitle="Settings">
    <div className="w-full max-w-3xl">
      <h1 className="mb-8">Settings</h1>
      <div className="mb-12">
        Use this page to manage Settings for this account.
      </div>
    </div>
  </Layout>
);

export default Settings;
