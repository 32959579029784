// If route is only for private use (when user is logged in)

import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  location: PropTypes.object.isRequired,
  resource: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired
};

const defaultProps = {
  component: null
};

const PrivateRoute = ({
  location,
  auth,
  resource,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated) {
          const {
            match: { params, url }
          } = props;
          let authOpts = { resource, r: true };
          if ('hotelid' in params || 'restaurantId' in params) {
            authOpts = {
              ...authOpts,
              permissionableId: parseInt(
                params.hotelid || params.restaurantId,
                10
              ),
              permissionableType: 'PropertyPermission'
            };
          }

          if (
            auth.authority &&
            (auth.authority.can('READ', authOpts) ||
              (url === '/profile' && resource === 'profile'))
          ) {
            if (rest.render) {
              return rest.render(props);
            }

            return <Component key={location.pathname} {...props} auth={auth} />;
          }
        }
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location }
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = propTypes;
PrivateRoute.defaultProps = defaultProps;

export default withRouter(
  connect(state => ({ auth: state.auth }))(PrivateRoute)
);
