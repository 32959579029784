import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';

export const CREATE_USER_REQUEST = 'CREATE_USER_REQUEST';
export const CREATE_USER_FAILURE = 'CREATE_USER_FAILURE';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';

export const fetchUsers = (qry, path) => dispatch => {
  dispatch({
    type: FETCH_USERS_REQUEST
  });
  return fetch(path || `/users?${fromObj(qry)}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_USERS_SUCCESS,
        payload: data,
        meta
      });
      return { users: data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_USERS_FAILURE,
        payload: e
      });
      return Promise.reject(e);
    });
};

export const fetchUser = id => dispatch => {
  dispatch({
    type: FETCH_USER_REQUEST
  });
  return fetch(`/users/${id}`)
    .then(response => {
      const user = convertToJS(response.data);
      dispatch({
        type: FETCH_USER_SUCCESS,
        payload: user
      });
      return user;
    })
    .catch(e => {
      dispatch({
        type: FETCH_USERS_FAILURE,
        payload: e
      });
      return Promise.reject(e);
    });
};

export const updateUserSuccess = update => ({
  type: UPDATE_USER_SUCCESS,
  payload: update
});

export const updateUser = (userId, update) => dispatch => {
  dispatch({
    type: UPDATE_USER_REQUEST
  });
  return fetch(`/users/${userId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const user = convertToJS(response.data);
      dispatch({
        type: UPDATE_USER_SUCCESS,
        payload: user
      });
      return user;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_USER_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createUser = body => dispatch => {
  dispatch({
    type: CREATE_USER_REQUEST
  });
  return fetch('/users', {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(body))
  })
    .then(response => {
      const user = convertToJS(response.data);
      dispatch({
        type: CREATE_USER_SUCCESS,
        payload: user
      });
      return user;
    })
    .catch(e => {
      dispatch({
        type: CREATE_USER_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteUser = userId => dispatch => {
  dispatch({
    type: DELETE_USER_REQUEST
  });
  return fetch(`/users/${userId}`, { method: 'DELETE' })
    .then(() => {
      dispatch({
        type: DELETE_USER_SUCCESS,
        payload: userId
      });
      return userId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_USER_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const fetchPropertyUsers = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_USERS_REQUEST
  });
  return fetch(path || `/properties/${propertyId}/users?${fromObj(qry)}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_USERS_SUCCESS,
        payload: data,
        meta
      });
      return { users: data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_USERS_FAILURE,
        payload: e
      });
      return Promise.reject(e);
    });
};
