import {
  FETCH_CLONE_TO_CATEGORIES_REQUEST,
  FETCH_CLONE_TO_CATEGORIES_SUCCESS,
  FETCH_CLONE_TO_CATEGORIES_FAILURE
} from '../actions/categories';

const initialState = {
  data: [],
  isLoading: true,
  error: ''
};

export default function cloneToCategories(
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload }
) {
  switch (type) {
    case FETCH_CLONE_TO_CATEGORIES_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_CLONE_TO_CATEGORIES_SUCCESS:
      payload.push({
        id: '0',
        name: 'New FAQ Category'
      });

      return { ...state, isLoading: false, error: '', data: payload };
    case FETCH_CLONE_TO_CATEGORIES_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
