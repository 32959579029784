import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import Layout from '../../layout';
import HotelForm from '../../components/HotelForm';
import * as propertyActions from '../../actions/properties';
import * as regionActions from '../../actions/regions';

// prop types
const propTypes = {
  regions: PropTypes.array.isRequired,
  createProperty: PropTypes.func.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  hotelLogo: PropTypes.object.isRequired
};

const New = ({
  createProperty,
  fetchRegions,
  regions,
  history: { push },
  hotelLogo
}) => {
  useEffect(() => {
    fetchRegions();
  }, []);
  const handleSubmit = data => {
    const body = {
      ...data,
      type: 'hotel',
      logo: hotelLogo.file
        ? { assetId: hotelLogo.file.id, alt: hotelLogo.alt }
        : null
    };
    return createProperty(body).then(() => push('/hotels'));
  };
  const regionList = regions.map(region => ({
    id: region.id,
    name: region.name
  }));
  return (
    <Layout metaTitle="New Hotel">
      <div className="w-full max-w-3xl">
        <Link
          to="/hotels/properties"
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to Properties
        </Link>
        <h1 className="mb-6">New Hotel</h1>
        <div className="mb-16">
          Use this page to create and manage a new hotel.
        </div>
        <HotelForm
          id="new-hotel-form"
          onSubmit={handleSubmit}
          regionList={regionList}
        />
      </div>
    </Layout>
  );
};

New.propTypes = propTypes;

export default connect(
  state => ({
    regions: state.regions.data,
    hotelLogo: get(state, 'images.hotel-logo-image.items.0', {})
  }),
  { ...propertyActions, ...regionActions }
)(New);
