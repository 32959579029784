import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as eventActions from '../../../actions/events';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteEvent: PropTypes.func.isRequired
};

const DeleteEventModal = ({ data, deleteEvent, ...props }) => (
  <DeleteModal
    id="delete-event-modal"
    title="Event"
    itemTitle={data.title}
    deleteItem={() => deleteEvent(data.restaurantId, data.id)}
    {...props}
  />
);

DeleteEventModal.propTypes = propTypes;

export default connect(
  null,
  eventActions
)(DeleteEventModal);
