import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as alertActions from '../../../actions/alerts';
import DeleteModal from '../DeleteModal';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteAlert: PropTypes.func.isRequired
};

const DeleteAlertModal = ({ data, deleteAlert, ...props }) => (
  <DeleteModal
    id="delete-alert-modal"
    title="Alert"
    itemTitle={data.title || 'name or title unavailable'}
    deleteItem={() => deleteAlert(data.id)}
    {...props}
  />
);

DeleteAlertModal.propTypes = propTypes;

export default connect(
  null,
  alertActions
)(DeleteAlertModal);
