import React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import Input from '../common/Input';
import FormErrorBox from '../common/FormErrorBox';
import FormSection from '../common/FormSection';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  error: undefined
};

const validateEmail = email => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const validate = values => {
  const errors = {};
  if (!values.firstName) {
    errors.firstName = 'Required!';
  }

  if (!values.lastName) {
    errors.lastName = 'Required!';
  }

  if (!values.email) {
    errors.email = 'Required!';
  } else if (!validateEmail(values.email)) {
    errors.email = 'Email must be in email format';
  }

  if (values.password && values.password !== values.confirmPassword) {
    errors.confirmPassword = 'Password must match confirmation';
  }
  if (values.password && values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
  }

  return errors;
};

const ProfileForm = ({ id, error, handleSubmit, onLogout }) => (
  <form id={id} className="" onSubmit={handleSubmit}>
    <FormSection
      id="username"
      title="User Name"
      className="flex flex-col flex-wrap"
      isHorizontal
    >
      <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
        <div className="font-light mb-3 text-base leading-normal">
          <p>
            <i>Enter the First Name and Last Name for this user.</i>
          </p>
        </div>
      </div>
      <div className="flex-1 xl:flex-initial xl:w-2/3">
        <label htmlFor="firstName">
          <div className="font-normal text-base mb-2">First Name*</div>
          <Field
            id="firstName"
            placeholder="First Name"
            className="w-full mb-6"
            name="firstName"
            component={Input}
          />
        </label>
        <label htmlFor="lastName">
          <div className="font-normal text-base mb-2">Last Name*</div>
          <Field
            id="lastName"
            placeholder="Last Name"
            className="w-full mb-6"
            name="lastName"
            component={Input}
          />
        </label>
      </div>
    </FormSection>
    <FormSection
      id="userlogin"
      title="User Login"
      className="flex flex-col flex-wrap"
      isHorizontal
    >
      <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
        <div className="font-light mb-3 text-base leading-normal">
          <p>
            <i>Enter an Email and Password for this user.</i>
          </p>
        </div>
      </div>
      <div className="flex-1 xl:flex-initial xl:w-2/3">
        <label htmlFor="email">
          <div className="font-normal text-base mb-2">
            Email* (will also be your login)
          </div>
          <Field
            id="email"
            type="email"
            className="w-full mb-6"
            placeholder="email@kimptonhotels.com"
            name="email"
            component={Input}
          />
        </label>
        <label htmlFor="password">
          <div className="font-normal text-base mb-2">Password*</div>
          <Field
            id="password"
            type="password"
            className="w-full mb-6"
            placeholder="**********"
            name="password"
            component={Input}
            autocomplete="new-password"
          />
        </label>
        <label htmlFor="confirmPassword">
          <div className="font-normal text-base mb-2">Confirm Password*</div>
          <Field
            id="confirmPassword"
            type="password"
            className="w-full mb-6"
            placeholder="**********"
            name="confirmPassword"
            component={Input}
          />
        </label>
      </div>
    </FormSection>
    <div className="flex flex-col sm:flex-row justify-center my-10">
      <button
        type="button"
        className="sm:flex-initial btn btn-clear mb-6 sm:mb-0 sm:mr-4"
        onClick={onLogout}
      >
        Log out
      </button>
      <button className="sm:flex-initial btn" type="submit">
        Save
      </button>
    </div>
    {error && <FormErrorBox id="user-form-error">{error}</FormErrorBox>}
  </form>
);

ProfileForm.propTypes = propTypes;
ProfileForm.defaultProps = defaultProps;

export default reduxForm({ form: 'ProfileForm', validate })(ProfileForm);
