export const INITIALIZE_IMAGE = 'INITIALIZE_IMAGE';
export const ADD_IMAGE = 'ADD_IMAGE';
export const REMOVE_IMAGE = 'REMOVE_IMAGE';
export const SET_ACTIVE_IMAGE = 'SET_ACTIVE_IMAGE';
export const SET_CURRENT_IMAGE = 'SET_CURRENT_IMAGE';
export const UPDATE_IMAGE = 'UPDATE_IMAGE';
export const UPDATE_IMAGES_SORT = 'UPDATE_IMAGES_SORT';

export const setImageEnv = (env, defaults = {}) => ({
  type: INITIALIZE_IMAGE,
  payload: defaults,
  meta: { env }
});

export const addImage = (env, image) => ({
  type: ADD_IMAGE,
  payload: image,
  meta: { env }
});

export const removeImage = (env, imageId) => ({
  type: REMOVE_IMAGE,
  payload: imageId,
  meta: { env }
});

export const setActiveImage = (env, imageId) => ({
  type: SET_ACTIVE_IMAGE,
  payload: imageId,
  meta: { env }
});

export const setCurrentImage = (env, image) => ({
  type: SET_CURRENT_IMAGE,
  payload: image,
  meta: { env }
});

export const updateImage = (env, imgId, update) => ({
  type: UPDATE_IMAGE,
  payload: { id: imgId, ...update },
  meta: { env }
});

export const updateImagesSort = (env, oldIndex, newIndex) => ({
  type: UPDATE_IMAGES_SORT,
  payload: { oldIndex, newIndex },
  meta: { env }
});
