import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import auth from './auth';
import * as users from './users';
import sidebar from './sidebar';
import regions from './regions';
import modals from './modals';
import shared from './shared';
import notifications from './notifications';
import * as categories from './categories';
import cloneToCategories from './cloneToCategories';
import images from './images';
import * as properties from './properties';
import permissions from './permissions';
import * as faqs from './faqs';
import * as presses from './presses';
import * as menus from './menus';
import * as events from './events';
import hours from './hours';
import * as tiledPromos from './tiledPromos';
import * as homePromos from './homePromos';
import * as specials from './specials';
import * as assets from './assets';
import tags from './tags';
import calendars from './calendars';
import * as calendarEvents from './calendarEvents';
import * as lists from './lists';
import * as alerts from './alerts';
import * as tokens from './tokens';
import globalAlert from './globalAlert';
import assignedAlerts from './assignedAlerts';
import resetPassword from './resetPassword';
import * as accommodations from './accommodations';
import gotoProperties from './gotoProperties';
import nav from './nav';

export default (history) => combineReducers({
  router: connectRouter(history),
  auth,
  ...users,
  sidebar,
  regions,
  modals,
  shared,
  notifications,
  ...categories,
  cloneToCategories,
  images,
  ...properties,
  permissions,
  ...faqs,
  ...presses,
  ...menus,
  ...events,
  hours,
  ...tiledPromos,
  ...homePromos,
  ...specials,
  ...assets,
  tags,
  calendars,
  ...calendarEvents,
  ...lists,
  ...alerts,
  ...tokens,
  globalAlert,
  assignedAlerts,
  form: formReducer,
  resetPassword,
  ...accommodations,
  gotoProperties,
  nav
});
