import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDrop, useDrag } from 'react-dnd';
import '../../../css/components/list.css';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  isDraggable: PropTypes.bool,
  index: PropTypes.number,
  moveRow: PropTypes.func,
  onDrop: PropTypes.func,
  style: PropTypes.object,
  className: PropTypes.string,
  dataSortIndex: PropTypes.number
};

const defaultProps = {
  isDraggable: false,
  style: undefined,
  className: '',
  index: null,
  moveRow: () => {},
  onDrop: () => {},
  dataSortIndex: 0
};

const ListItem = ({
  id,
  children,
  style,
  className,
  isDraggable,
  index,
  moveRow,
  onDrop,
  dataSortIndex
}) => {
  if (!isDraggable) {
    return (
      <li id={id} style={style} className={classnames('ListItem', className)}>
        {children}
      </li>
    );
  }

  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // save the old index so that we can track that it is "Actually" moved
      if (!('oldIndex' in item)) {
        item.oldIndex = index; // eslint-disable-line no-param-reassign
      }

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      if (id !== item.id)
        moveRow({ id: item.id, index: dragIndex }, { id, index: hoverIndex });
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex; // eslint-disable-line no-param-reassign
    },
    drop: (item, monitor) => {
      const { y } = monitor.getDifferenceFromInitialOffset();
      const direction = y > 0 ? 'down' : 'up';

      onDrop({ id: item.id, direction }, item.index !== item.oldIndex);
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <li
      data-row-index={index}
      data-sort-index={dataSortIndex}
      id={id}
      style={{ ...style, opacity }}
      className={classnames('ListItem draggable', className)}
      ref={ref}
    >
      {children}
    </li>
  );
};

ListItem.propTypes = propTypes;
ListItem.defaultProps = defaultProps;

export default ListItem;
