import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as faqActions from '../../../actions/faqs';
import * as categoriesActions from '../../../actions/categories';
import * as modalActions from '../../../actions/modals';
import Layout from '../../../layout';
import Input from '../../../components/common/Input';
import FormDropdown from '../../../components/common/form/Dropdown';
import { List, ListItem } from '../../../components/common/List';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  hotels: PropTypes.array.isRequired,
  faqs: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchProperties: PropTypes.func.isRequired,
  fetchFAQs: PropTypes.func.isRequired,
  fetchCategories: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  cloneFaqs: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fromCategoryId: PropTypes.string,
  toPropertyId: PropTypes.string,
  toCategoryId: PropTypes.string,
  cloneFaqIds: PropTypes.object,
  cloneToCategories: PropTypes.array,
  showModal: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string
};

const validate = values => {
  const errors = {};

  if (!values.fromCategoryId) {
    errors.fromCategoryId = 'Required';
  }

  if (!values.toPropertyId) {
    errors.toPropertyId = 'Required';
  }

  if (!values.toCategoryId) {
    errors.toCategoryId = 'Required';
  }

  return errors;
};

const CloneableFAQsList = props => {
  // FIXME this needs to be propTypes and defaultProps as this is a reusable
  // component
  // eslint-disable-next-line react/prop-types
  const { faqs, categoryId, faqsChecked, setFAQsChecked } = props;
  // eslint-disable-next-line react/prop-types
  const cloneableFaqs = faqs.filter(faq => faq.category.id === categoryId);
  return (
    <div className="mt-6">
      <br />
      <label>Select the FAQs you want to clone:</label>
      {cloneableFaqs.length > 0 && (
        <>
          <List id="clone-from-faqs-list" className="mt-3">
            {cloneableFaqs.map(faq => (
              <ListItem id={faq.id} key={faq.id}>
                <Input
                  id={faq.id}
                  type="checkbox"
                  className="mr-4"
                  input={{
                    checked: !!faqsChecked[faq.id],
                    onChange: () =>
                      setFAQsChecked({
                        ...faqsChecked,
                        [faq.id]: !faqsChecked[faq.id]
                      })
                  }}
                />
                <label htmlFor={faq.id}>{faq.question}</label>
              </ListItem>
            ))}
          </List>
          <div className="select-all-none">{/* TODO */}</div>
        </>
      )}
      {!cloneableFaqs.length && <div>No FAQs in this category.</div>}
    </div>
  );
};

const Clone = ({
  hotel,
  hotels,
  faqs,
  categories,
  fetchProperties,
  fetchProperty,
  fetchFAQs,
  fetchCategories,
  updateSidebar,
  cloneFaqs,
  match: {
    params: { hotelid }
  },
  history,
  fromCategoryId,
  cloneFaqIds,
  toPropertyId,
  toCategoryId,
  cloneToCategories,
  change,
  showModal,
  error
}) => {
  useEffect(() => {
    fetchProperties({ per_page: 200 });
    fetchProperty(hotelid).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchFAQs(hotelid, { per_page: 1000 });
    fetchCategories(hotelid, { per_page: 1000 });
    return () => {};
  }, []);

  const onSubmit = evt => {
    evt.preventDefault();

    let ids = Object.keys(cloneFaqIds)
      .map(v => {
        return cloneFaqIds[v] ? v : undefined;
      })
      .filter(v => v);
    if (!ids.length) {
      ids = 'all';
    }

    cloneFaqs(hotelid, ids, toPropertyId, toCategoryId).then(() => {
      if (ids === 'all') {
        history.push(`/hotels/${toPropertyId}/faqs`);
      } else {
        history.push(`/hotels/${hotelid}/faqs`);
      }
    });
  };

  const clonableCategories = (data => {
    // NOTE you must create a new array here or selection will rewrite the
    // selection array
    return Array.prototype.concat(
      [
        {
          id: '0',
          name: 'Clone All FAQs'
        },
      ],
      data
    );
  })(categories);

  return (
    <Layout metaTitle="Clone a FAQ">
      <form id="CloneFAQForm" className="w-full max-w-3xl" onSubmit={onSubmit}>
        <Link
          to={`/hotels/${hotelid}/faqs`}
          className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
        >
          &lt; Back to FAQs
        </Link>
        <h1 className="mb-6">Clone a FAQ</h1>
        <div className="mb-16">
          Use this page to Clone FAQs on {hotel.name}.
        </div>
        <div className="section flex">
          <div className="mr-12 w-1/2">
            <h2 className="font-light">{`1. Clone from ${hotel.name}...`}</h2>
          </div>
          <div className="flex-1 self-end">
            <label>Select an FAQ Category</label>
            <Field
              id="faq-from-category"
              name="fromCategoryId"
              className="min-w-full pt-3"
              component={FormDropdown}
              data={clonableCategories}
              onChange={data => {
                // reset on 0 (clone all)
                if (data === '0') {
                  change('cloneFaqIds', {});
                  change('toCategoryId', undefined);
                }
              }}
            />
            {fromCategoryId > 0 && (
              <CloneableFAQsList
                faqs={faqs}
                categoryId={fromCategoryId}
                faqsChecked={cloneFaqIds}
                setFAQsChecked={data => {
                  change('cloneFaqIds', { ...cloneFaqIds, ...data });
                }}
              />
            )}
          </div>
        </div>
        {/* FIXME remove brs, just for dev */}
        <br />
        <div className="section flex border-t pt-10 my-10">
          <div className="mr-12 w-1/2">
            <h2 className="font-light">2. Clone to...</h2>
          </div>
          <div className="flex-1 self-end">
            <label>Select the Hotel to clone to</label>
            <Field
              id="faq-to-property"
              name="toPropertyId"
              className="min-w-full pt-3 mb-6"
              component={FormDropdown}
              data={hotels}
              onChange={(_, v) => {
                change('toPropertyId', v);
                fetchCategories(v, 'CLONE_TO_CATEGORIES');
              }}
            />
            {toPropertyId && fromCategoryId !== '0' && (
              <>
                <br />
                <label>Select the FAQ Category to clone to</label>
                <Field
                  id="faq-to-category"
                  name="toCategoryId"
                  className="min-w-full pt-3"
                  component={FormDropdown}
                  data={cloneToCategories}
                  onChange={(_, v) => {
                    if (v === '0') {
                      showModal('NewFaqCategoryModal', {
                        hotelId: toPropertyId,
                        onClosePostHandler: res => {
                          fetchCategories(
                            res.propertyId,
                            'CLONE_TO_CATEGORIES'
                          );
                          // select the new category
                          change('toCategoryId', res.id);
                        }
                      });
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
        <br />
        <div className="flex justify-end mb-6" disabled={!!error}>
          <button type="submit" className="btn">Clone</button>
        </div>
      </form>
    </Layout>
  );
};

Clone.propTypes = propTypes;
Clone.defaultProps = {
  fromCategoryId: undefined,
  toPropertyId: undefined,
  toCategoryId: undefined,
  cloneFaqIds: {},
  cloneToCategories: [],
  error: undefined
};

const selector = formValueSelector('Clone');

export default compose(
  connect(
    (state, props) => ({
      hotel: state.property.data,
      // FIXME this should not be filtering should get direct from API
      hotels: state.properties.data.filter(
        property =>
          property.type === 'hotel' &&
          property.id !== props.match.params.hotelid
      ),
      faqs: state.faqs.data,
      categories: state.categories.data,
      isLoading:
        state.property.isLoading ||
        state.properties.isLoading ||
        state.faqs.isLoading ||
        state.categories.isLoading,
      fromCategoryId: selector(state, 'fromCategoryId'),
      cloneFaqIds: selector(state, 'cloneFaqIds'),
      toPropertyId: selector(state, 'toPropertyId'),
      toCategoryId: selector(state, 'toCategoryId'),
      cloneToCategories: state.cloneToCategories.data
    }),
    {
      ...propertyActions,
      ...faqActions,
      ...sidebarActions,
      ...categoriesActions,
      ...modalActions
    }
  ),
  reduxForm({ form: 'Clone', validate })
)(Clone);
