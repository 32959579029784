import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Input from '../../common/Input';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired
};

const validate = values => {
  const errors = {};
  if (!values.localeCode) {
    errors.localeCode = 'Required!';
  }

  if (!values.regionCode) {
    errors.regionCode = 'Required!';
  }

  if (!values.brandCode) {
    errors.brandCode = 'Required!';
  }

  return errors;
};

const GenerateURLForm = ({ id, handleSubmit }) => (
  <form id={id} onSubmit={handleSubmit}>
    <div className="flex my-3 justify-center text-grey-darker">
      <div className="mx-3">
        <div className="mb-3">Rate Code</div>
        <Field
          id="rateCode"
          name="rateCode"
          component={Input}
          className="w-full"
          placeholder="XXXXX"
        />
      </div>
      <div className="mx-3">
        <div className="mb-3">Hotel Code*</div>
        <Field
          id="hotelCode"
          name="hotelCode"
          component={Input}
          className="w-full"
          placeholder="XXXXX"
        />
      </div>
    </div>

    <div className="flex my-3 justify-center text-grey-darker">
      <div className="mx-3">
        <div className="mb-3">Locale Code*</div>
        <Field
          id="localeCode"
          name="localeCode"
          component={Input}
          className="w-full"
          placeholder="en"
        />
      </div>
      <div className="mx-3">
        <div className="mb-3">Region Code*</div>
        <Field
          id="regionCode"
          name="regionCode"
          component={Input}
          className="w-full"
          placeholder="1"
        />
      </div>
      <div className="mx-3">
        <div className="mb-3">Brand Code*</div>
        <Field
          id="brandCode"
          name="brandCode"
          component={Input}
          className="w-full"
          placeholder="ki"
        />
      </div>
    </div>
    <div className="flex justify-center my-6">
      <button type="submit" className="btn">
        Generate
      </button>
    </div>
  </form>
);

GenerateURLForm.propTypes = propTypes;

export default reduxForm({ form: 'GenerateURLForm', validate })(
  GenerateURLForm
);
