import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import get from 'lodash/get';
import * as modalActions from '../../actions/modals';
import Input from '../common/Input';
import FormDatePicker from '../common/form/DatePicker';
import TinyMCE from '../common/form/TinyMCE';
import FormDropdown from '../common/form/Dropdown';
import FormSection from '../common/FormSection';
import Switch from '../common/form/Switch';
import FormErrorBox from '../common/FormErrorBox';
import UploadModal from '../UploadModal';
import isURL from '../../utils/is-url';
import ctaTitleOptions from '../../utils/cta-title-options-tiled-promos';
import * as imageActions from '../../actions/images';
import * as assetActions from '../../actions/assets';
import SetAnAsset from '../SetAnAsset';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  firstImage: PropTypes.object.isRequired,
  createAsset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  title: PropTypes.string,
  body: PropTypes.string,
  doesNotEnd: PropTypes.bool,
  ctaOption: PropTypes.string,
  ctaTypeInput: PropTypes.string,
  pdf: PropTypes.object,
  published: PropTypes.bool,
  archived: PropTypes.bool,
  showDescription: PropTypes.bool,
  error: PropTypes.string,
  profilePermissions: PropTypes.array.isRequired
};

const defaultProps = {
  title: '',
  body: '',
  doesNotEnd: false,
  ctaOption: '',
  ctaTypeInput: '',
  published: false,
  showDescription: false,
  pdf: {},
  archived: false,
  error: undefined
};

const validate = values => {
  const errors = { cta: {} };

  if (!values.title) {
    errors.title = 'Required!';
  }

  if (!values.startsOn) {
    errors.startsOn = 'Required!';
  }

  if (!values.doesNotEnd && !values.endsOn) {
    errors.endsOn = 'End date is required if event ends';
  }

  // if (!get(values, 'cta.linkUrl')) {
  //   errors.cta.linkUrl = 'Required!';
  // }

  if (get(values, 'cta.linkUrl') && !isURL(values.cta.linkUrl)) {
    errors.cta.linkUrl = 'CTA Link URL must be valid url';
  }

  if (values.ctaOption === 'Other') {
    if (!values.ctaTypeInput) {
      errors.ctaTypeInput = 'CTA title is required if Other is selected';
    }
  }

  if (values['restaurant-events-image']) {
    errors['restaurant-events-image'] = errors['restaurant-events-image'] || {};

    const image = values['restaurant-events-image'];
    if (!image.asset_id) {
      errors['restaurant-events-image'].asset_id = 'Image is required';
    }
    if (!image.alt) {
      errors['restaurant-events-image'].alt = 'Image Alt Tag is required';
    }
  }

  return errors;
};

const condfn = () => {
  return {
    filetype: 'image',
    category: 'cat#Events'
  };
};

const EditEventForm = ({
  handleSubmit,
  firstImage,
  pdf,
  createAsset,
  title,
  body,
  doesNotEnd,
  ctaOption,
  ctaTypeInput,
  published,
  showDescription,
  archived,
  change,
  error,
  profilePermissions
}) => {
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  const bodyString = body;
  
  const canUploadAsset = () => {
    return (
      profilePermissions.findIndex(perm => {
        return (
          perm.resource === '*' &&
          perm.permissionableId === null &&
          perm.permissionableType === null &&
          perm.r &&
          perm.w &&
          perm.x
        );
      }) > -1
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormSection
        id="title"
        title="Title & Content"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>Enter the Title and content for this Event.</i>
            </p>
            <p>
              <i>
                To display this Event on the homepage, check on 'Display this
                event on Homepage'.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Title*</div>
          <Field
            id="event-title"
            name="title"
            className="w-full mb-6"
            placeholder="Event title"
            component={Input}
          />
          <div className="mb-3">Content for this Event</div>
          <Field
            id="event-body"
            name="body"
            className="mb-6"
            component={TinyMCE}
            placeholder="Enter the content for this event..."
          />
          <div className="flex my-3 items-center">
            <Field
              id="displays-home"
              type="checkbox"
              name="displayOnHomepage"
              className="mr-2"
              component={Input}
            />
            <div>Display this Event on Homepage</div>
          </div>
          {showDescription ? (
            <Fragment>
              <div className="mb-3 mt-6 flex justify-between">
                <div>Content on Homepage</div>
              </div>
              <Field
                id="event-description"
                name="shortDescription"
                className=""
                component={TinyMCE}
                placeholder="Enter the content to be displayed on homepage for this event..."
              />
            </Fragment>
          ) : (
            ''
          )}
        </div>
      </FormSection>
      <FormSection
        id="cta"
        title="CTA (Call to Action)"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>Select a CTA option to use for this Event.</i>
            </p>
            <p>
              <i>
                To use a custom CTA, select 'Other' from the dropdown and enter
                the text to use.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Select a CTA Option*</div>
          <Field
            id="cta.type"
            name="cta.type"
            className="mb-6"
            data={ctaTitleOptions}
            component={FormDropdown}
            style={{ width: 300 }}
          />
          {ctaOption === 'Other' && (
            <Field
              id="cta.typeInput"
              name="cta.typeInput"
              className="mb-6 -mt-3"
              placeholder="Enter a custom CTA title"
              component={Input}
            />
          )}
          <div className="flex-1 sm:w-full">
            <div
              className={classnames('text-base font-normal my-3', {
                'text-grey': ctaOption === 'None'
              })}
            >
              CTA Link URL
            </div>
            <div className="flex flex-col my-3">
              <Field
                id="cta.linkUrl"
                name="cta.linkUrl"
                className="flex-1"
                component={Input}
                disabled={ctaOption === 'None'}
              />
            </div>
            {ctaOption !== 'None' && (
              <Fragment>
                <div className="flex my-3 items-center">
                  <Field
                    id="cta.opensInNewWindow"
                    name="cta.opensInNewWindow"
                    className="mr-2"
                    type="checkbox"
                    component={Input}
                  />
                  <div className="">Opens in a New Window</div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
      </FormSection>
      <FormSection
        id="date"
        title="Date"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter the Start Date for this Event. To set an End Date, uncheck
                'Event does not end'.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Start Date*</div>
          <Field
            id="start-date"
            name="startsOn"
            component={FormDatePicker}
            className="mb-6"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MM/dd/yyyy h:mm aa"
            timeCaption="time"
            autoComplete="off"
          />
          <div className="flex items-center">
            <Field
              id="event-ends"
              type="checkbox"
              name="doesNotEnd"
              className="mr-3"
              component={Input}
              onChange={(_, v) => {
                if (v) {
                  change('endsOn', new Date(2120, 0, 1));
                } else {
                  change('endsOn', null);
                }
              }}
            />
            <div className="">Event does not end</div>
          </div>
          {!doesNotEnd && (
            <div>
              <div
                className={classnames('text-base font-normal mb-3 pt-6', {
                  'text-grey-light': doesNotEnd
                })}
              >
                End Date*
              </div>
              <Field
                id="end-date"
                name="endsOn"
                component={FormDatePicker}
                disabled={doesNotEnd}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy h:mm aa"
                timeCaption="time"
                autoComplete="off"
              />
            </div>
          )}
        </div>
      </FormSection>
      <FormSection
        id="image"
        title="Set an Image"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Select an image to use for this Event. If you don't see the
                image you are looking for,&nbsp;
                <a
                  href="http://tickets.still-water.com/tickets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  create a ticket here.
                </a>
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <SetAnAsset
            change={change}
            condfn={condfn}
            canUploadAsset={canUploadAsset}
            categories={[{ name: 'Events' }]}
            name='restaurant-events-image'
            id='restaurant-event-upload'
            header='Select an image*'
            onlySingle
          />
        </div>
      </FormSection>
      <FormSection
        id="upload"
        title="Upload PDF"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>You can upload a PDF to use for this Event here.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Add a PDF:</div>
          <button
            type="button"
            className="btn btn-clear mb-6"
            onClick={e => {
              e.preventDefault();
              setIsPdfOpen(true);
            }}
          >
            + Upload File
          </button>
          <UploadModal
            id="hotel-special-upload"
            isOpen={isPdfOpen}
            onRequestClose={() => setIsPdfOpen(false)}
            onUploadSuccess={(file) => {
              const { filename, filesize, filetype, altName } = file.meta;
              const tags = get(file, 'meta.tags')
                ? get(file, 'meta.tags')
                    .split(',')
                    .map(tag => ({ name: tag.trim() }))
                : [];
              const postBody = {
                filename,
                filesize,
                filetype,
                name: altName,
                description: file.description,
                tags,
                alt: get(file, 'meta.alt'),
                categories: [{ name: 'Events' }]
              };
              return createAsset(postBody).then(asset => change('pdf', asset));
            }}
          />
          {pdf.name && (
            <div className="flex mt-4">
              <a
                href={pdf.url}
                className="mr-2 text-blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                {pdf.name}
              </a>
              <div
                role="button"
                tabIndex={0}
                className="font-bold text-red mb-6"
                onClick={() => change('pdf', {})}
              >
                X
              </div>
            </div>
          )}
          <div className="flex-1">
            <div className="mb-3">PDF Link Copy</div>
            <Field
              id="pdfLinkCopy"
              name="pdfLinkCopy"
              className="mb-6 w-full"
              placeholder="e.g. View PDF..."
              component={Input}
            />
            <div className="my-3">PDF GA Link:</div>
            <Field
              id="pdfGaLink"
              name="pdfGaLink"
              placeholder="Enter the PDF GA Link..."
              component={Input}
            />
            <div className="text-xs font-light my-3">
              When adding GA tags, please only use the portion between the
              quotation marks as highlighted in the example below:
            </div>
            <div className="text-xs font-light mb-3">
              data-ga=
              <span className="font-medium">
                "type:link;label:external/push/kimptonhotels.com/summer-with-a-little-more-shine;"
              </span>
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection
        id="preview"
        title="Preview + Publish"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex w-full flex-col flex-wrap xl:flex-row">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  Here is a preview of your Event. When you're ready, toggle the
                  publish status to display it on the live site and hit 'Save'.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="text-base font-normal mb-8">Preview</div>
            <div className="bg-grey-lighter px-2 py-3 mb-3 flex">
              {firstImage.file && (
                <img
                  alt={firstImage.alt}
                  src={get(firstImage, 'file.url')}
                  className="mr-2 w-48"
                />
              )}
              <div>
                <div className="font-bold mb-2">{title}</div>
                <div dangerouslySetInnerHTML={{ __html: bodyString }} />

                <p>
                  {ctaOption === 'Other' ? ctaTypeInput : ctaOption}
                  &nbsp;&gt;
                </p>
              </div>
            </div>
            <p>
              <em>
                Preview may look different from live site since each site has
                its own design
              </em>
            </p>
            <div className="mt-12">
              <div className="text-base font-normal mb-3">Publish Status:</div>
              <Field
                id="published"
                name="published"
                component={Switch}
                label={published ? 'Published' : 'Unpublished'}
                className="mb-12"
                disabled={archived}
              />
              <div className="text-base font-normal mb-3">
                Archive this Event?
              </div>
              <Field
                id="archived"
                name="archived"
                component={Switch}
                label={archived ? 'Archived' : 'Unarchived'}
                equalValue="archived"
                className="mb-6"
                onChange={(e, value) => {
                  e.preventDefault();
                  if (value && published) {
                    change('published', false);
                  }
                  change('archived', value);
                }}
              />
            </div>
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && (
        <FormErrorBox id="restaurant-event-error">{error}</FormErrorBox>
      )}
    </form>
  );
};

EditEventForm.propTypes = propTypes;
EditEventForm.defaultProps = defaultProps;

const selector = formValueSelector('EditEventForm');

export default compose(
  connect(
    state => ({
      title: selector(state, 'title'),
      body: selector(state, 'body'),
      doesNotEnd: selector(state, 'doesNotEnd'),
      ctaOption: selector(state, 'cta.type'),
      ctaTypeInput: selector(state, 'cta.typeInput'),
      published: selector(state, 'published'),
      showDescription: selector(state, 'displayOnHomepage'),
      archived: selector(state, 'archived'),
      firstImage: get(state, 'images.restaurant-events-image.items.0', {}),
      pdf: selector(state, 'pdf'),
      profilePermissions: state.auth.permissions
    }),
    { ...modalActions, ...imageActions, ...assetActions }
  ),
  reduxForm({ form: 'EditEventForm', validate })
)(EditEventForm);
