import React from 'react';
import PropTypes from 'prop-types';
import Dropdown, { DropdownItem } from '../Dropdown';

const propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.object.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  data: PropTypes.array,
  placeholder: PropTypes.string
};

const defaultProps = {
  style: {},
  className: '',
  data: [],
  placeholder: ''
};

const FormDropdown = ({
  id,
  input,
  style,
  className,
  data,
  placeholder,
  ...props
}) => (
  <Dropdown
    id={id}
    {...input}
    placeholder={placeholder}
    style={style}
    className={className}
    {...props}
  >
    {data.map(item => (
      <DropdownItem
        key={item.id}
        id={item.id}
        onClick={() => {
          input.onChange(item.id);
        }}
        selected={item.id === input.value}
      >
        {item.name}
      </DropdownItem>
    ))}
  </Dropdown>
);

FormDropdown.propTypes = propTypes;
FormDropdown.defaultProps = defaultProps;

export default FormDropdown;
