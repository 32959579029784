import {
  FETCH_CALENDAR_EVENTS_REQUEST,
  FETCH_CALENDAR_EVENTS_SUCCESS,
  FETCH_CALENDAR_EVENTS_FAILURE,
  FETCH_CALENDAR_EVENT_REQUEST,
  FETCH_CALENDAR_EVENT_SUCCESS,
  FETCH_CALENDAR_EVENT_FAILURE,
  CREATE_CALENDAR_EVENT_REQUEST,
  CREATE_CALENDAR_EVENT_SUCCESS,
  CREATE_CALENDAR_EVENT_FAILURE,
  UPDATE_CALENDAR_EVENT_REQUEST,
  UPDATE_CALENDAR_EVENT_SUCCESS,
  UPDATE_CALENDAR_EVENT_FAILURE,
  DELETE_CALENDAR_EVENT_REQUEST,
  DELETE_CALENDAR_EVENT_SUCCESS,
  DELETE_CALENDAR_EVENT_FAILURE
} from '../actions/calendarEvents';

export function calendarEvents(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_CALENDAR_EVENTS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_CALENDAR_EVENTS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case FETCH_CALENDAR_EVENTS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function calendarEvent(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_CALENDAR_EVENT_REQUEST:
    case CREATE_CALENDAR_EVENT_REQUEST:
    case UPDATE_CALENDAR_EVENT_REQUEST:
    case DELETE_CALENDAR_EVENT_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_CALENDAR_EVENT_SUCCESS:
    case CREATE_CALENDAR_EVENT_SUCCESS:
    case UPDATE_CALENDAR_EVENT_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_CALENDAR_EVENT_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_CALENDAR_EVENT_FAILURE:
    case CREATE_CALENDAR_EVENT_FAILURE:
    case UPDATE_CALENDAR_EVENT_FAILURE:
    case DELETE_CALENDAR_EVENT_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
