import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Input from '../../common/Input';

const propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required!';
  }
  if (!values.email && values.subject) {
    errors.subject = 'Please enter email first and re-enter subject again!';
    // values.subject = '';
  }

  return errors;
};

const GenerateEmailURLForm = ({ handleSubmit }) => (
  <form onSubmit={handleSubmit}>
    <div className="flex my-12 justify-center text-grey-darker">
      <div className="mx-3">
        <div className="mb-3">Email</div>
        <Field
          id="email"
          name="email"
          component={Input}
          className="w-full"
          placeholder="xx@xxx.com"
        />
      </div>
      <div className="mx-3">
        <div className="mb-3">Subject</div>
        <Field
          id="subject"
          name="subject"
          component={Input}
          className="w-full"
          placeholder="Enter your email subject"
        />
      </div>
    </div>
    <div className="flex justify-center my-6">
      <button type="submit" className="btn">
        Generate
      </button>
    </div>
  </form>
);

GenerateEmailURLForm.propTypes = propTypes;

export default reduxForm({ form: 'GenerateEmailURLForm', validate })(
  GenerateEmailURLForm
);
