const promoFlags = [
  'None',
  'Member Exclusive',
  'Website Exclusive',
  'Weekend Promo',
  'Newly Renovated',
  'Other',
].map(opt => ({ id: opt, name: opt }));

export default promoFlags;
