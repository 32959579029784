import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import Layout from '../../../layout';
import * as sidebarActions from '../../../actions/sidebar';
import * as eventActions from '../../../actions/events';
import * as propertyActions from '../../../actions/properties';
import EditEventForm from '../../../components/EditEventForm';
import FormNavigator from '../../../components/FormNavigator';

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'date', title: 'Date' },
  { id: 'image', title: 'Set an Image' },
  { id: 'upload', title: 'Upload PDF' },
  { id: 'preview', title: 'Preview + Publish' },
];

class NewEvent extends Component {
  componentDidMount() {
    const {
      updateSidebar,
      fetchProperty,
      match: {
        params: { restaurantId }
      }
    } = this.props;
    fetchProperty(restaurantId).then(restaurant => {
      updateSidebar({ titles: { Restaurant: restaurant.name } });
    });
  }

  handleSubmit = data => {
    const {
      match: {
        params: { restaurantId }
      },
      createEvent,
      history: { push },
      images
    } = this.props;

    let { doesNotEnd, endsOn, pdf } = data;
    if (doesNotEnd) {
      endsOn = new Date(2120, 0, 1);
    }
    /* eslint-disable no-param-reassign */
    pdf = pdf && pdf.id ? { assetId: pdf.id } : null;

    const event = {
      ...data,
      pdf,
      startsOn: data.startsOn.toJSON(),
      endsOn: endsOn ? endsOn.toJSON() : endsOn,
      cta: Object.assign(
        data.cta,
        data.cta.type === 'Other' && { type: data.cta.typeInput }
      ),
      images
    };

    return createEvent(restaurantId, event)
      .then(() => {
        push(`/restaurants/${restaurantId}/events`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  render() {
    const {
      isLoading,
      restaurant,
      match: {
        params: { restaurantId }
      }
    } = this.props;
    return (
      <Layout metaTitle="Edit Event">
        <div className="w-full max-w-3xl">
          <h1 className="mb-4">New Event</h1>
          <div className="mb-8">
            Use this page to edit Events for{' '}
            {isLoading ? 'Restaurant' : restaurant.name}.
          </div>
          <Link
            to={`/restaurants/${restaurantId}/events`}
            className="text-black mb-10 inline-block"
          >
            &lt; Back to Events
          </Link>
          <EditEventForm
            onSubmit={this.handleSubmit}
            initialValues={{
              doesNotEnd: true,
              cta: {
                type: 'None'
              }
            }}
          />
        </div>
        <FormNavigator
          id="restaurant-event-edit"
          sections={sections}
          className="hidden xl:block mt-32 pt-10"
        />
      </Layout>
    );
  }
}

NewEvent.propTypes = {
  updateSidebar: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  createEvent: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  restaurant: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  match: PropTypes.object,
  history: PropTypes.object
};

NewEvent.defaultProps = {
  match: {},
  history: {}
};

export default connect(
  (state) => ({
    restaurant: state.property.data,
    isLoading: state.property.isLoading,
    images: get(state, 'images.restaurant-events-image.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt }))
  }),
  { ...sidebarActions, ...eventActions, ...propertyActions }
)(NewEvent);
