// If route is only for pulic use (when user is not logged in)
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const propTypes = {
  auth: PropTypes.object.isRequired,
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  location: PropTypes.object.isRequired
};

const PublicRoute = ({ auth, component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      !auth.isAuthenticated ? (
        <Component {...props} auth={auth} />
      ) : (
        <Redirect
          to={{
            pathname: '/',
            state: { from: props.location }
          }}
        />
      )}
  />
);

PublicRoute.propTypes = propTypes;

export default withRouter(
  connect(state => ({ auth: state.auth }))(PublicRoute)
);
