import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import * as alertActions from '../../../actions/alerts';
import HotelAlertForm from '../../../components/HotelAlertForm';
import Layout from '../../../layout';
import FormNavigator from '../../../components/FormNavigator';

const propTypes = {
  history: PropTypes.object.isRequired,
  createAlert: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired
};

const sections = [
  { id: 'name', title: 'Name & Display Type' },
  { id: 'title', title: 'Title & Content' },
  { id: 'save', title: 'Save & Assign' },
];

const AlertsNew = ({ history: { push }, createAlert, isLoading }) => {
  const handleSubmit = data => {
    const alert = {
      ...data
    };
    return createAlert(alert)
      .then(() => {
        push(`/hotels/alerts/listings`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  return (
    <Layout type="Alerts" metaTitle="New Alert">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <Link
            to="/hotels/alerts/Listings"
            className="mb-8 inline-block text-sm text-grey-dark hover:text-grey-darkest no-underline"
          >
            &lt; Back to Alert Listings
          </Link>
          <h1 className="mb-6">New Alert</h1>
          <div className="mb-16">This page is used to create a New Alert.</div>

          <HotelAlertForm
            id="new-alerts"
            initialValues={{
              displayAs: 'modal',
              displayModalAs: 'modal_window',
              modal: {
                opensInNewWindow: false,
                addDatePickerPopup: false,
                advancedPurchaseDays: '1',
                pageDisplayOn: []
              },
              archived: false
            }}
            onSubmit={handleSubmit}
          />
        </div>
      )}
      <FormNavigator
        id="new-specials-navigator"
        sections={sections}
        className="hidden xl:block mt-32 pt-16"
      />
    </Layout>
  );
};

AlertsNew.propTypes = propTypes;

export default connect(
  state => ({}),
  { ...alertActions }
)(AlertsNew);
