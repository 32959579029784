import React, { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SearchBar from '../../../components/common/SearchBar';
import { Tab, Tabs, TabList, TabPill } from '../../../components/common/Tabs';
import Table from '../../../components/common/Table';
import More, { MoreMenu, MoreMenuItem } from '../../../components/common/More';
import Layout from '../../../layout';
import * as alertActions from '../../../actions/alerts';
import * as modalActions from '../../../actions/modals';
import Paginate from '../../../components/Paginate';
import {
  inputHandlerFunc,
  fetchHandlerFunc
} from '../../../utils/fetch-helpers';

const propTypes = {
  alerts: PropTypes.array.isRequired,
  history: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  fetchAlerts: PropTypes.func.isRequired,
  countAlerts: PropTypes.func.isRequired,
  updateAlert: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  meta: PropTypes.object
};

const defaultProps = {
  history: {},
  meta: {}
};

const condfn = val => {
  const cond = { order_by: 'name:ASC' };
  if (val) {
    cond.name = `i%%${val}`;
  }

  return cond;
};

const Alerts = ({
  alerts,
  meta,
  history: { push },
  isLoading,
  fetchAlerts,
  countAlerts,
  updateAlert,
  showModal
}) => {
  const [refresh, setRefresh] = useState(0);
  const [activeCount, setActivCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [selected, setSelected] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const tabsconf = [
    {
      qry: { archived: false },
      cb: setActivCount
    },
    {
      qry: { archived: true },
      cb: setArchivedCount
    }
  ];

  const fetchfn = fetchHandlerFunc(fetchAlerts, countAlerts, tabsconf);

  const searchHandler = inputHandlerFunc(
    setSearchInput,
    useCallback(debounce(fetchfn, 500), []),
    condfn
  );

  useEffect(() => {
    fetchfn(selected, condfn(searchInput));
    return () => {};
  }, [selected, refresh]);

  const columns = [
    {
      id: 'name',
      Header: 'Alert Name',
      accessor: data => (
        <Link to={`/hotels/alerts/${data.id}/edit`}>{data.name}</Link>
      )
    },
    {
      id: 'assignmentCount',
      Header: 'Assignment Count',
      accessor: data => (
        <span className="capitalize">
          {(data.properties && data.properties.length) || 0}
        </span>
      ),
      width: 120
    },
    {
      id: 'more',
      Header: <span />,
      width: 40,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-settings`}
              onClick={() => {
                push(`/hotels/alerts/${data.id}/edit`);
              }}
            >
              Edit
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-archive`}
              onClick={() =>
                updateAlert(data.id, { archived: !data.archived }).then(
                  setRefresh(refresh + 1)
                )
              }
            >
              {data.archived ? 'Unarchive' : 'Archive'}
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={() =>
                showModal(
                  'DeleteAlertModal',
                  {
                    id: data.id,
                    title: data.name
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )
              }
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      )
    }
  ];

  return (
    <Layout metaTitle="Properties">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <h1 className="mb-8">Alert Listings</h1>
          <div className="mb-12">
            Here are all the alerts/messages that are available for use. Please
            delete or archive inactive alerts.
          </div>
          <div className="flex flex-col flex-col-reverse md:flex-row mb-3 sm:mb-6 md:mb-12">
            <SearchBar
              id="alerts-filtering"
              className="flex-1 sm:mr-2 lg:mt-1"
              value={searchInput}
              placeholder="Filter Alerts..."
              onChange={({ value }) => searchHandler(value, selected)}
              onClick={() => {}}
            />
            <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
              <Link
                to="/hotels/alerts/new"
                className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              >
                + New Alert
              </Link>
            </div>
          </div>
          <div>
            <Tabs selectedIndex={selected} onSelect={i => setSelected(i)}>
              <TabList>
                <Tab>
                  <span className="mr-2">Active</span>
                  <TabPill id="active">{activeCount}</TabPill>
                </Tab>
                <Tab>
                  <span className="mr-2">Archived</span>
                  <TabPill id="archived">{archivedCount}</TabPill>
                </Tab>
              </TabList>

              <Table id="alerts" data={alerts} columns={columns} />
            </Tabs>
            <Paginate meta={meta} fetchHandler={fetchAlerts} />
          </div>
        </div>
      )}
    </Layout>
  );
};

Alerts.propTypes = propTypes;
Alerts.defaultProps = defaultProps;

export default connect(
  state => ({
    alerts: state.alerts.data,
    meta: state.alerts.meta,
    isLoading: state.alerts.isLoading
  }),
  { ...alertActions, ...modalActions }
)(Alerts);
