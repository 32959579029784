import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  children: PropTypes.string.isRequired,
  selected: PropTypes.bool
};

const defaultProps = {
  selected: false
};

const DropdownItem = ({ id, children, ...props }) => (
  <div
    id={id}
    role="button"
    tabIndex={0}
    className="p-3 outline-none hover:bg-blue-light"
    {...props}
  >
    {children}
  </div>
);

DropdownItem.propTypes = propTypes;
DropdownItem.defaultProps = defaultProps;

export default DropdownItem;
