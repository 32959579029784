import {
  FETCH_TAGS_REQUEST,
  FETCH_TAGS_SUCCESS,
  FETCH_TAGS_FAILURE
} from '../actions/tags';

const initialState = {
  data: [],
  isLoading: true,
  error: ''
};

// eslint-disable-next-line default-param-last
export default function tags(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_TAGS_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_TAGS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };
    case FETCH_TAGS_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
