import React from 'react';
import PropTypes from 'prop-types';
import ReactTable, { ReactTableDefaults } from 'react-table';
import get from 'lodash/get';
import 'react-table/react-table.css';
import TrGroup from './TrGroup';
import '../../../css/components/table.css';

const propTypes = {
  data: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  isDraggable: PropTypes.bool,
  moveRow: PropTypes.func,
  onDrop: PropTypes.func,
  pageSize: PropTypes.number
};

const defaultProps = {
  isDraggable: false,
  moveRow: () => {},
  onDrop: () => {},
  pageSize: 100
};

const Table = ({ data, columns, moveRow, onDrop, isDraggable, pageSize }) => {
  const TrGroupComponent =
    isDraggable && data.length ? TrGroup : ReactTableDefaults.TrGroupComponent;
  return (
    <ReactTable
      TrGroupComponent={TrGroupComponent}
      data={data}
      columns={columns}
      showPagination={false}
      defaultPageSize={!pageSize ? 100 : pageSize}
      getTrGroupProps={(_, rowInfo) =>
        isDraggable
          ? {
              id: get(rowInfo, 'original.id'),
              index: get(rowInfo, 'index'),
              key: get(rowInfo, 'original.id') || get(rowInfo, 'index'),
              moveRow,
              onDrop
            }
          : { key: get(rowInfo, 'original.id') || get(rowInfo, 'index') }}
      minRows={data.length ? 0 : undefined}
    />
  );
};

Table.propTypes = propTypes;
Table.defaultProps = defaultProps;

export default Table;
