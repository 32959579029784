import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import Layout from '../../../layout';
import HotelSettingsForm from '../../../components/HotelSettingsForm';
import Table from '../../../components/common/Table';
import Paginate from '../../../components/Paginate';
import * as propertyActions from '../../../actions/properties';
import * as sidebarActions from '../../../actions/sidebar';
import * as regionActions from '../../../actions/regions';
import * as imageActions from '../../../actions/images';
import * as userActions from '../../../actions/users';

const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  regions: PropTypes.object.isRequired,
  users: PropTypes.array.isRequired,
  usersMeta: PropTypes.object.isRequired,
  hotelLogo: PropTypes.object.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  updateSidebar: PropTypes.func.isRequired,
  updateProperty: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired,
  fetchPropertyUsers: PropTypes.func.isRequired
};

const Settings = ({
  match: {
    params: { hotelid }
  },
  history: { push },
  hotel,
  regions,
  hotelLogo,
  fetchProperty,
  fetchRegions,
  updateSidebar,
  updateProperty,
  setCurrentImage,
  fetchPropertyUsers,
  users,
  usersMeta
}) => {
  useEffect(() => {
    fetchProperty(hotelid, true).then(property => {
      updateSidebar({ titles: { Hotel: property.name } });
    });
    fetchRegions();
    fetchPropertyUsers(hotelid, { per_page: 30 });
    return () => {};
  }, []);

  useEffect(() => {
    const { logo } = hotel;
    setCurrentImage('hotel-logo-image', logo || {});
    return () => {};
  }, [hotel]);

  const handleSubmit = data => {
    const body = {
      ...data,
      logo: hotelLogo.file
        ? { assetId: hotelLogo.file.id, alt: hotelLogo.alt }
        : null
    };
    return updateProperty(hotelid, body).then(() => push(`/hotels/${hotelid}`));
  };

  const regionList = regions.map(region => ({
    id: parseInt(region.id, 10),
    name: region.name
  }));

  return (
    <Layout metaTitle="Settings">
      <div className="w-full max-w-3xl">
        <h1 className="mb-6">Settings</h1>
        <div className="mb-16">
          Use this page to manage the Settings for {hotel.name}.
        </div>
        <HotelSettingsForm
          id="hotel-settings"
          initialValues={hotel}
          regionList={regionList}
          enableReinitialize
          keepDirtyOnReinitialize
          onSubmit={handleSubmit}
        />
        <hr />
        <h3>Users Associated to {hotel.name}</h3>
        <br />
        <Table
          id="property-suers"
          columns={[
            {
              id: 'name',
              Header: 'Name',
              accessor: data => (
                <Link to={`/users/${data.id}/edit`}>
                  {`${data.firstName} ${data.lastName}`}
                </Link>
              ),
              className: 'text-left'
            },
            { Header: 'Email', accessor: 'email' },
          ]}
          data={users}
        />
        <Paginate
          meta={usersMeta}
          fetchHandler={(_, link) => fetchPropertyUsers(null, null, link)}
        />
      </div>
    </Layout>
  );
};

Settings.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data,
    hotelLogo: get(state, 'images.hotel-logo-image.items.0', {}),
    regions: state.regions.data,
    users: state.users.data,
    usersMeta: state.users.meta,
    // FIXME this is not even being used in here...?
    isLoading:
      state.property.isLoading ||
      state.regions.isLoading ||
      state.users.isLoading
  }),
  {
    ...propertyActions,
    ...sidebarActions,
    ...regionActions,
    ...imageActions,
    ...userActions
  }
)(Settings);
