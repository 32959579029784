import { moveBySplice } from '../utils/move_by_splice';
import {
  FETCH_TILED_PROMOS_REQUEST,
  FETCH_TILED_PROMOS_SUCCESS,
  FETCH_TILED_PROMOS_FAILURE,
  FETCH_TILED_PROMO_REQUEST,
  FETCH_TILED_PROMO_SUCCESS,
  FETCH_TILED_PROMO_FAILURE,
  CREATE_TILED_PROMO_REQUEST,
  CREATE_TILED_PROMO_SUCCESS,
  CREATE_TILED_PROMO_FAILURE,
  UPDATE_TILED_PROMO_REQUEST,
  UPDATE_TILED_PROMO_SUCCESS,
  UPDATE_TILED_PROMO_FAILURE,
  DELETE_TILED_PROMO_REQUEST,
  DELETE_TILED_PROMO_SUCCESS,
  DELETE_TILED_PROMO_FAILURE,
  MOVE_TILED_PROMO,
  CLONE_TILED_PROMOS_REQUEST, /* eslint-disable-line no-unused-vars */ // FIXME why is this not used?
  CLONE_TILED_PROMOS_SUCCESS,
  CLONE_TILED_PROMOS_FAILURE,
  SORT_TILED_PROMOS_REQUEST,
  SORT_TILED_PROMOS_SUCCESS,
  SORT_TILED_PROMOS_FAILURE
} from '../actions/tiledPromos';

export function tiledPromos(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_TILED_PROMOS_REQUEST:
    case SORT_TILED_PROMOS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_TILED_PROMOS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case MOVE_TILED_PROMO: {
      const { source, target } = payload;
      const data = moveBySplice(source, target, state.data);

      return { ...state, data };
    }

    case CLONE_TILED_PROMOS_SUCCESS:
    case SORT_TILED_PROMOS_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_TILED_PROMOS_FAILURE:
    case SORT_TILED_PROMOS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function tiledPromo(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_TILED_PROMO_REQUEST:
    case CREATE_TILED_PROMO_REQUEST:
    case UPDATE_TILED_PROMO_REQUEST:
    case DELETE_TILED_PROMO_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_TILED_PROMO_SUCCESS:
    case CREATE_TILED_PROMO_SUCCESS:
    case UPDATE_TILED_PROMO_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_TILED_PROMO_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_TILED_PROMO_FAILURE:
    case CREATE_TILED_PROMO_FAILURE:
    case UPDATE_TILED_PROMO_FAILURE:
    case DELETE_TILED_PROMO_FAILURE:
    case CLONE_TILED_PROMOS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
