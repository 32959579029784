import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Layout from '../../../layout';
import Table from '../../../components/common/Table';
import {
  Tabs,
  TabPill,
  TabList,
  Tab,
  TabPanel
} from '../../../components/common/Tabs';
import * as regionsActions from '../../../actions/regions';
import Paginate from '../../../components/Paginate';
import { fetchHandlerFunc } from '../../../utils/fetch-helpers';

const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  regions: PropTypes.array.isRequired,
  fetchRegions: PropTypes.func.isRequired,
  meta: PropTypes.object.isRequired
};

const columns = [
  {
    id: 'name',
    Header: 'Region Name',
    accessor: ({ id, name }) => <Link to={`/hotels/regions/${id}`}>{name}</Link>
  },
];

const Regions = ({ regions, meta, isLoading, fetchRegions }) => {
  const fetchfn = fetchHandlerFunc(fetchRegions);

  useEffect(() => {
    fetchfn(null);
    return () => {};
  }, []);
  return (
    <Layout metaTitle="Regions">
      <div className="w-full max-w-3xl">
        <h1 className="mb-16">Regions</h1>
        <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
          <Link
            to="/hotels/regions/new"
            className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
          >
            + New Region
          </Link>
        </div>
        {isLoading ? (
          <div>Loading ...</div>
        ) : (
          <>
            <Tabs>
              <TabList>
                <Tab>
                  <span className="mr-2">Current</span>
                  <TabPill id="current">{regions.length}</TabPill>
                </Tab>
              </TabList>

              <TabPanel>
                <Table id="regions-table" data={regions} columns={columns} />
              </TabPanel>
            </Tabs>
            <Paginate meta={meta} fetchHandler={fetchRegions} />
          </>
        )}
      </div>
    </Layout>
  );
};

Regions.propTypes = propTypes;

export default connect(
  state => ({
    regions: state.regions.data,
    meta: state.regions.meta,
    isLoading: state.regions.isLoading
  }),
  regionsActions
)(Regions);
