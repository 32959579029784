import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';

export const FETCH_CLONE_TO_CATEGORIES_REQUEST =
  'FETCH_CLONE_TO_CATEGORIES_REQUEST';
export const FETCH_CLONE_TO_CATEGORIES_SUCCESS =
  'FETCH_CLONE_TO_CATEGORIES_SUCCESS';
export const FETCH_CLONE_TO_CATEGORIES_FAILURE =
  'FETCH_CLONE_TO_CATEGORIES_FAILURE';

export const FETCH_CATEGORY_REQUEST = 'FETCH_CATEGORY_REQUEST';
export const FETCH_CATEGORY_SUCCESS = 'FETCH_CATEGORY_SUCCESS';
export const FETCH_CATEGORY_FAILURE = 'FETCH_CATEGORY_FAILURE';

export const CREATE_CATEGORY_REQUEST = 'CREATE_CATEGORY_REQUEST';
export const CREATE_CATEGORY_SUCCESS = 'CREATE_CATEGORY_SUCCESS';
export const CREATE_CATEGORY_FAILURE = 'CREATE_CATEGORY_FAILURE';

export const UPDATE_CATEGORY_REQUEST = 'UPDATE_CATEGORY_REQUEST';
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS';
export const UPDATE_CATEGORY_FAILURE = 'UPDATE_CATEGORY_FAILURE';

export const DELETE_CATEGORY_REQUEST = 'DELETE_CATEGORY_REQUEST';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const MOVE_CATEGORY = 'MOVE_CATEGORY';

export const SORT_CATEGORIES_REQUEST = 'SORT_CATEGORIES_REQUEST';
export const SORT_CATEGORIES_SUCCESS = 'SORT_CATEGORIES_SUCCESS';
export const SORT_CATEGORIES_FAILURE = 'SORT_CATEGORIES_FAILURE';

export const fetchCategories = (
  propertyId,
  // eslint-disable-next-line default-param-last
  type = 'CATEGORIES', // FIXME can type be null? This is an odd spot for a default value
  qry,
  path
) => dispatch => {
  dispatch({ type: `FETCH_${type}_REQUEST` });
  return fetch(
    path || `/properties/${propertyId}/faq-categories${fromObj(qry, '?')}`
  )
    .then(response => {
      const categories = response.data.map(convertToJS);
      dispatch({
        type: `FETCH_${type}_SUCCESS`,
        payload: categories
      });
      return categories;
    })
    .catch(e => {
      dispatch({
        type: `FETCH_${type}_FAILURE`,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const fetchCategory = (propertyId, categoryId) => dispatch => {
  dispatch({ type: FETCH_CATEGORY_REQUEST });
  return fetch(`/properties/${propertyId}/faq-categories/${categoryId}`)
    .then(response => {
      const category = convertToJS(response.data);
      dispatch({
        type: FETCH_CATEGORY_SUCCESS,
        payload: category
      });
      return category;
    })
    .catch(e => {
      dispatch({
        type: FETCH_CATEGORY_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createCategory = (propertyId, category) => dispatch => {
  dispatch({ type: CREATE_CATEGORY_REQUEST });
  return fetch(`/properties/${propertyId}/faq-categories`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(category))
  })
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: CREATE_CATEGORY_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: CREATE_CATEGORY_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateCategory = (propertyId, categoryId, update) => dispatch => {
  dispatch({ type: UPDATE_CATEGORY_REQUEST });
  return fetch(`/properties/${propertyId}/faq-categories/${categoryId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const category = convertToJS(response.data);
      dispatch({
        type: UPDATE_CATEGORY_SUCCESS,
        payload: category
      });
      return category;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_CATEGORY_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteCategory = (propertyId, categoryId) => dispatch => {
  dispatch({
    type: DELETE_CATEGORY_REQUEST
  });
  return fetch(`/properties/${propertyId}/faq-categories/${categoryId}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch({
        type: DELETE_CATEGORY_SUCCESS,
        payload: categoryId
      });
      return categoryId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_CATEGORY_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveCategory = (source, target) => ({
  type: MOVE_CATEGORY,
  payload: { source, target }
});

export const sortCategories = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_CATEGORIES_REQUEST
  });
  return fetch(`/properties/${propertyId}/faq-categories/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_CATEGORIES_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_CATEGORIES_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
