import React, { useCallback, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Table from '../../components/common/Table';
import More, { MoreMenu, MoreMenuItem } from '../../components/common/More';
import * as userActions from '../../actions/users';
import * as modalActions from '../../actions/modals';
import Layout from '../../layout';
import SearchBar from '../../components/common/SearchBar';
import Paginate from '../../components/Paginate';
import { inputHandlerFunc, fetchHandlerFunc } from '../../utils/fetch-helpers';

const propTypes = {
  fetchUsers: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  users: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired
};

const defaultProps = {};

const condfn = val => {
  const cond = { order_by: 'email:ASC' };
  if (val) {
    cond.email = `i%%${val}`;
  }

  return cond;
};

const Users = ({ fetchUsers, isLoading, users, meta, showModal, profile }) => {
  const [refresh, setRefresh] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  // NOTE this is an example of non-tab pagination using the abstraction helpers
  // from fetch-helpers.js
  const fetchfn = fetchHandlerFunc(fetchUsers);

  const searchHandler = inputHandlerFunc(
    setSearchInput,
    useCallback(debounce(fetchfn, 500), []),
    condfn
  );

  useEffect(() => {
    fetchfn(null, searchInput);

    return () => {};
    // FIXME refresh refreshes the "current" URL, it needs to refresh the
    // "paginated" url so on page 2 it reload the page 2 list and not the /users
    // main page
  }, [refresh]);

  return (
    <Layout type="Users" metaTitle="Users">
      {isLoading ? (
        <div>Loading ...</div>
      ) : (
        <div className="w-full max-w-3xl">
          <h1 className="mb-8">Users</h1>
          <div className="mb-12">
            <p>Use this page to create and manage Users and permissions.</p>
          </div>
          <div className="flex flex-col flex-col-reverse md:flex-row mb-3 sm:mb-6 md:mb-12">
            <SearchBar
              id="users-search"
              className="flex-1 sm:mr-2 lg:mt-1"
              placeholder="Search user by name or email..."
              value={searchInput}
              onChange={({ value }) => searchHandler(value)}
              onClick={() => {}}
            />
            <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
              <Link
                to="/users/new"
                className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              >
                + New User
              </Link>
            </div>
          </div>
          <div>
            <Paginate meta={meta} fetchHandler={fetchUsers} />
            <Table
              data={users}
              columns={[
                {
                  id: 'name',
                  Header: 'Name',
                  accessor: data => {
                    let url = `/users/${data.id}/edit`;
                    if (data.id === profile.id) {
                      url = '/profile';
                    }
                    return (
                      <Link to={url}>
                        {`${data.firstName} ${data.lastName}`}
                      </Link>
                    );
                  },
                  className: 'text-left'
                },
                { Header: 'Email', accessor: 'email' },
                { Header: 'Role', accessor: 'role', maxWidth: 180 },
                {
                  id: 'more',
                  Header: () => <span />,
                  accessor: data => {
                    if (data.id === profile.id) {
                      return <></>;
                    }
                    return (
                      <More id={`${data.id}-more`}>
                        <MoreMenu id={`${data.id}-moremenu`}>
                          <MoreMenuItem
                            id={`${data.id}-delete`}
                            onClick={
                              () => showModal(
                                'DeleteUserModal',
                                {
                                  id: data.id,
                                  title: `${data.firstName} ${data.lastName}`
                                },
                                () => {
                                  setRefresh(refresh + 1);
                                }
                              )
                            }
                          >
                            Delete
                          </MoreMenuItem>
                        </MoreMenu>
                      </More>
                    );
                  },
                  maxWidth: 50
                },
              ]}
            />
            <Paginate meta={meta} fetchHandler={fetchUsers} />
          </div>
        </div>
      )}
    </Layout>
  );
};

Users.propTypes = propTypes;
Users.defaultProps = defaultProps;

export default connect(
  state => ({
    profile: state.auth.profile,
    users: state.users.data,
    meta: state.users.meta,
    isLoading: state.users.isLoading
  }),
  { ...userActions, ...modalActions }
)(Users);
