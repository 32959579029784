import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import qs from 'querystring';
import ExitButton from '../../common/ExitButton';
import GenerateTrackingForm from './GenerateTrackingForm';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired
};

const GenerateTrackingModal = ({
  onClose,
  data: { form, field },
  change // eslint-disable-line no-shadow
}) => {
  return (
    <div id="generate-tracking-modal">
      <div className="flex justify-center border-b py-2 px-4 border-grey bg-grey-lighter">
        <h2 className="font-light">Generate Adobe Tracking</h2>
        <ExitButton
          id="generate-tracking-exit"
          onClick={onClose}
          className="absolute"
          divStyle={{ right: '.5rem' }}
          svgStyle={{ fill: 'black' }}
        />
      </div>
      <div className="p-6">
        <div className="font-thin my-6 text-center">
          Fill out below to generate Adobe Tracking for this promo:
        </div>
        <GenerateTrackingForm
          id="generate-modal-form"
          initialValues={{}}
          onSubmit={data => {
            const base = 'cm_sp=WEB_IWS-_-KI-_-AMER-_-EN-_-';
            const queryString = qs
              .stringify(data)
              .replace('campaignType=', '')
              .replace('&CampaignName=', '-_-')
              .replace('&mediaPlacement=', '-_-')
              .replace('&variation=', '-_-')
              .replaceAll('%20', ' ');
            change(form, field, `${base}${queryString}`);
            onClose();
          }}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
    </div>
  );
};

GenerateTrackingModal.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data
  }),
  { change }
)(GenerateTrackingModal);
