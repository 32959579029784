import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
// import firebase from 'firebase';
import Modal from 'react-modal';
import App from './App';
import * as serviceWorker from './serviceWorker';

const config = { // eslint-disable-line no-unused-vars
  apiKey: 'AIzaSyA_bd0b7iaX5AjmmklS9QspVDwH6HyE-kI',
  authDomain: 'sw-cms-26b0d.firebaseapp.com',
  databaseURL: 'https://sw-cms-26b0d.firebaseio.com',
  projectId: 'sw-cms-26b0d',
  storageBucket: 'sw-cms-26b0d.appspot.com',
  messagingSenderId: '155994508867'
};
// firebase.initializeApp(config);

Modal.setAppElement('#root');

// NOTE wrap with BrowserRouter to fix -- Uncaught Error: Invariant failed. You
// should not use <route> or withrouter() outside a <router> in React Router
// -- This only appeared after upgrading to webpack 5.
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
