import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import SearchBarContext from './SearchBarContext';

const propTypes = {
  id: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string
};

const defaultProps = {
  onClick: () => {},
  className: ''
};

const SearchBarMenuItem = ({ id, children, onClick, className }) => {
  const { onClose } = useContext(SearchBarContext);
  return (
    <div
      role="button"
      tabIndex={0}
      id={id}
      onClick={e => {
        onClick(e);
        onClose();
      }}
      className={classnames(
        'p-2 hover:bg-blue-light cursor-pointer outline-none text-black',
        className
      )}
    >
      {children}
    </div>
  );
};

SearchBarMenuItem.propTypes = propTypes;
SearchBarMenuItem.defaultProps = defaultProps;

export default SearchBarMenuItem;
