import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_EVENTS_REQUEST = 'FETCH_EVENTS_REQUEST';
export const FETCH_EVENTS_SUCCESS = 'FETCH_EVENTS_SUCCESS';
export const FETCH_EVENTS_FAILURE = 'FETCH_EVENTS_FAILURE';

export const FETCH_EVENT_REQUEST = 'FETCH_EVENT_REQUEST';
export const FETCH_EVENT_SUCCESS = 'FETCH_EVENT_SUCCESS';
export const FETCH_EVENT_FAILURE = 'FETCH_EVENT_FAILURE';

export const CREATE_EVENT_REQUEST = 'CREATE_EVENT_REQUEST';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';
export const CREATE_EVENT_FAILURE = 'CREATE_EVENT_FAILURE';

export const UPDATE_EVENT_REQUEST = 'UPDATE_EVENT_REQUEST';
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS';
export const UPDATE_EVENT_FAILURE = 'UPDATE_EVENT_FAILURE';

export const DELETE_EVENT_REQUEST = 'DELETE_EVENT_REQUEST';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';
export const DELETE_EVENT_FAILURE = 'DELETE_EVENT_FAILURE';

export const MOVE_EVENT = 'MOVE_EVENT';

export const SORT_EVENTS_REQUEST = 'SORT_EVENTS_REQUEST';
export const SORT_EVENTS_SUCCESS = 'SORT_EVENTS_SUCCESS';
export const SORT_EVENTS_FAILURE = 'SORT_EVENTS_FAILURE';

export const fetchEvents = (propertyId, qry, path) => dispatch => {
  dispatch({
    type: FETCH_EVENTS_REQUEST
  });
  return fetch(path || `/properties/${propertyId}/events${fromObj(qry, '?')}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_EVENTS_SUCCESS,
        payload: data,
        meta
      });
      return { data, meta };
    })
    .catch(e => {
      dispatch({
        type: FETCH_EVENTS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const countEvents = (propertyId, params) => dispatch => {
  return fetch(`/properties/${propertyId}/events${fromObj(params, '?')}`)
    .then(response => {
      return response.meta;
    })
    .catch(e => {
      return Promise.reject(e);
    });
};

export const fetchEvent = (propertyId, eventId) => dispatch => {
  dispatch({
    type: FETCH_EVENT_REQUEST
  });
  return fetch(`/properties/${propertyId}/events/${eventId}`)
    .then(response => {
      const event = convertToJS(response.data);
      dispatch({
        type: FETCH_EVENT_SUCCESS,
        payload: event
      });
      return event;
    })
    .catch(e => {
      dispatch({
        type: FETCH_EVENT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const createEvent = (propertyId, event) => dispatch => {
  dispatch({
    type: CREATE_EVENT_REQUEST
  });
  return fetch(`/properties/${propertyId}/events`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(event))
  })
    .then(response => {
      const data = convertToJS(response.data);
      dispatch({
        type: CREATE_EVENT_SUCCESS,
        payload: data
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: CREATE_EVENT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const updateEvent = (propertyId, eventId, update) => dispatch => {
  dispatch({
    type: UPDATE_EVENT_REQUEST
  });
  return fetch(`/properties/${propertyId}/events/${eventId}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI(update))
  })
    .then(response => {
      const event = convertToJS(response.data);
      dispatch({
        type: UPDATE_EVENT_SUCCESS,
        payload: event
      });
      return event;
    })
    .catch(e => {
      dispatch({
        type: UPDATE_EVENT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const deleteEvent = (propertyId, eventId) => dispatch => {
  dispatch({
    type: DELETE_EVENT_REQUEST
  });
  return fetch(`/properties/${propertyId}/events/${eventId}`, {
    method: 'DELETE'
  })
    .then(() => {
      dispatch({
        type: DELETE_EVENT_SUCCESS,
        payload: eventId
      });
      return eventId;
    })
    .catch(e => {
      dispatch({
        type: DELETE_EVENT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const moveEvent = (source, target) => ({
  type: MOVE_EVENT,
  payload: { source, target }
});

export const sortEvents = (propertyId, id, toId, condition = {}) => (
  dispatch
) => {
  dispatch({
    type: SORT_EVENTS_REQUEST
  });
  return fetch(`/properties/${propertyId}/events/sort`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ ids: [id], move_to_id: toId, condition })
  })
    .then(response => {
      dispatch({
        type: SORT_EVENTS_SUCCESS
      });
    })
    .catch(e => {
      dispatch({
        type: SORT_EVENTS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
