import fetch from '../utils/fetch';
import { convertToJS } from '../utils/convert';
import { fromObj } from '../utils/querystrings';

export const FETCH_GOTO_PROPERTIES_REQUEST = 'FETCH_GOTO_PROPERTIES_REQUEST';
export const FETCH_GOTO_PROPERTIES_SUCCESS = 'FETCH_GOTO_PROPERTIES_SUCCESS';
export const FETCH_GOTO_PROPERTIES_FAILURE = 'FETCH_GOTO_PROPERTIES_FAILURE';

export const CLEAR_GOTO_PROPERTIES_SUCCESS = 'CLEAR_GOTO_PROPERTIES_SUCCESS';

// NOTE this purely a duplication for the header go to property search bar. It
// does not seem particularly beneficial to try and implement a higher order
// reducer as decribed here
// https://redux.js.org/recipes/structuring-reducers/reusing-reducer-logic

// if path is provided, it will use that as the as the fetch url instead of
// generating the url through the params options
export const fetchGotoProperties = (params, path) => dispatch => {
  dispatch({
    type: FETCH_GOTO_PROPERTIES_REQUEST
  });
  return fetch(path || `/properties${fromObj(params, '?')}`)
    .then(response => {
      const { data, meta } = convertToJS(response);
      dispatch({
        type: FETCH_GOTO_PROPERTIES_SUCCESS,
        payload: data,
        meta
      });
      return data;
    })
    .catch(e => {
      dispatch({
        type: FETCH_GOTO_PROPERTIES_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const clearGotoProperties = () => dispatch => {
  dispatch({
    type: CLEAR_GOTO_PROPERTIES_SUCCESS,
    payload: [],
    meta: {}
  });
};
