import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import Input from '../../components/common/Input';

const fields = ['email', 'password'];
const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Required!';
  } else if (!/^[A-Z0-9._%+-\\']+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }

  if (!values.password) {
    errors.password = 'Required!';
  } else if (values.password.length < 8) {
    errors.password = 'Password must be at least 8 characters';
  }

  return errors;
};

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  error: PropTypes.object
};

const defaultProps = {
  error: undefined
};

const LoginForm = ({ handleSubmit, error }) => (
  <form onSubmit={handleSubmit} className="my-6">
    <div className="my-4">
      <label htmlFor="login-email">
        <div className="my-2">Email*:</div>
        <Field
          id="login-email"
          type="email"
          name="email"
          className="w-full max-w-xs"
          placeholder="email@company.com"
          component={Input}
        />
      </label>
    </div>
    <div className="my-4">
      <label htmlFor="login-password">
        <div className="my-2">Password*:</div>
        <Field
          id="login-password"
          type="password"
          name="password"
          className="w-full max-w-xs"
          placeholder="**********"
          component={Input}
        />
      </label>
    </div>
    {error && <div className="text-red mb-4">{error}</div>}
    <div className="md:p-3,my-4 flex flex-wrap w-full">
      <button
        id="home-button"
        type="submit"
        className="btn sm:mr-4 md:mr-0 mt-4 w-full max-w-xs"
        disabled={error}
      >
        Sign In
      </button>
    </div>
  </form>
);

LoginForm.propTypes = propTypes;
LoginForm.defaultProps = defaultProps;

export default reduxForm({
  form: 'Login',
  fields,
  validate
})(LoginForm);
