import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import isObject from 'lodash/isObject';
import isDate from 'lodash/isDate';
import curry from 'lodash/curry';

const convert = curry((casing, value) => {
  if (Array.isArray(value)) {
    if (value.some(item => isObject(item) || Array.isArray(item))) {
      return value.map(item => convert(casing, item));
    }
  } else if (isObject(value) && !isDate(value)) {
    return Object.keys(value).reduce(
      (acc, key) =>
        Object.assign({}, acc, { [casing(key)]: convert(casing, value[key]) }),
      {}
    );
  }

  return value;
});

export const convertToAPI = convert(snakeCase);
export const convertToJS = convert(camelCase);
