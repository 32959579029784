import React, { useCallback, useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import { Link } from 'react-router-dom';
import SearchBar from '../../components/common/SearchBar';
import { Tab, Tabs, TabList, TabPill } from '../../components/common/Tabs';
import Table from '../../components/common/Table';
import More, { MoreMenu, MoreMenuItem } from '../../components/common/More';
import Layout from '../../layout';
import * as modalActions from '../../actions/modals';
import * as propertyActions from '../../actions/properties';
import Paginate from '../../components/Paginate';
import { inputHandlerFunc, fetchHandlerFunc } from '../../utils/fetch-helpers';

const propTypes = {
  fetchProperties: PropTypes.func.isRequired,
  countProperties: PropTypes.func.isRequired,
  profilePermissions: PropTypes.func.isRequired,
  restaurants: PropTypes.array.isRequired,
  meta: PropTypes.object.isRequired,
  updateProperty: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  history: PropTypes.object
};

const defaultProps = {
  history: {}
};

const condfn = val => {
  const cond = { order_by: 'name:ASC' };
  if (val) {
    cond.name = `i%%${val}`;
  }

  return cond;
};

const Restaurants = ({
  fetchProperties,
  countProperties,
  restaurants,
  meta,
  history: { push },
  updateProperty,
  showModal,
  profilePermissions
}) => {
  const [refresh, setRefresh] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [archivedCount, setArchivedCount] = useState(0);
  const [selected, setSelected] = useState(0);
  const [searchInput, setSearchInput] = useState('');

  const tabsconf = [
    {
      qry: { type: 'restaurant', archived: false },
      cb: setActiveCount
    },
    {
      qry: { type: 'restaurant', archived: true },
      cb: setArchivedCount
    },
  ];

  const fetchfn = fetchHandlerFunc(fetchProperties, countProperties, tabsconf);

  const searchHandler = inputHandlerFunc(
    setSearchInput,
    useCallback(debounce(fetchfn, 500), []),
    condfn
  );

  useEffect(() => {
    fetchfn(selected, condfn(searchInput));
    return () => change('restaurants', []);
  }, [selected, refresh]);

  // FIXME
  const canAddProperty =
    profilePermissions.findIndex(p => {
      return (
        !p.permissionable_id && !p.permissionable_type && p.resource === '*'
      );
    }) > -1;

  const columns = [
    {
      id: 'name',
      Header: 'Property Name',
      accessor: data => (
        <Link to={`/${data.type}s/${data.id}`}>{data.name}</Link>
      )
    },
    {
      id: 'type',
      Header: 'Property Type',
      width: 200,
      accessor: data => (
        <div className="text-sm leading-tight">{data.type.toUpperCase()}</div>
      )
    },
  ];
  if (canAddProperty) {
    columns.push({
      id: 'more',
      Header: <span />,
      width: 40,
      accessor: data => (
        <More id={`${data.id}-more`}>
          <MoreMenu id={`${data.id}-moremenu`}>
            <MoreMenuItem
              id={`${data.id}-settings`}
              onClick={() => {
                push(`/restaurants/${data.id}/settings`);
              }}
            >
              Settings
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-archive`}
              onClick={() =>
                updateProperty(data.id, {
                  archived: !data.archived
                }).then(setRefresh(refresh + 1))}
            >
              {data.archived ? 'Unarchive' : 'Archive'}
            </MoreMenuItem>
            <MoreMenuItem
              id={`${data.id}-delete`}
              onClick={
                () => showModal(
                  'DeleteRestaurantModal',
                  {
                    id: data.id,
                    title: data.name
                  },
                  () => {
                    setRefresh(refresh + 1);
                  }
                )
              }
            >
              Delete
            </MoreMenuItem>
          </MoreMenu>
        </More>
      )
    });
  }

  return (
    <Layout metaTitle="Restaurants">
      <div className="w-full max-w-3xl">
        <h1 className="mb-16">Restaurants</h1>
        <div className="flex flex-col flex-col-reverse md:flex-row mb-3 sm:mb-6 md:mb-12">
          <SearchBar
            id="restaurants-filtering"
            className="flex-1 sm:mr-2 lg:mt-1"
            value={searchInput}
            placeholder="Filter Restaurants..."
            onChange={({ value }) => searchHandler(value, selected)}
            onClick={() => {}}
          />
          {canAddProperty && (
            <div className="sm:flex sm:flex-1 justify-start mb-6 md:mb-0 md:justify-end">
              <Link
                to="/restaurants/new"
                className="btn no-underline mb-6 sm:mb-0 w-full sm:w-auto"
              >
                + New Restaurant
              </Link>
            </div>
          )}
        </div>
        <div>
          <Tabs selectedIndex={selected} onSelect={index => setSelected(index)}>
            <TabList>
              <Tab>
                <span className="mr-2">Active</span>
                <TabPill id={0}>{activeCount}</TabPill>
              </Tab>
              <Tab>
                <span className="mr-2">Archived</span>
                <TabPill id={1}>{archivedCount}</TabPill>
              </Tab>
              <Paginate meta={meta} fetchHandler={fetchProperties} />
            </TabList>

            <Table id="dashboard-table" data={restaurants} columns={columns} />
          </Tabs>
          <Paginate meta={meta} fetchHandler={fetchProperties} />
        </div>
      </div>
    </Layout>
  );
};

Restaurants.propTypes = propTypes;
Restaurants.defaultProps = defaultProps;

export default connect(
  state => ({
    restaurants: state.properties.data,
    meta: state.properties.meta,
    isLoading: state.properties.isLoading,
    profilePermissions: state.auth.permissions
  }),
  { ...propertyActions, ...modalActions }
)(Restaurants);
