import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';
import history from './utils/history';
import rootReducer from './reducers';
import { CLEAR_PROPERTY_STATE } from './actions/properties';

/**
 * stateUnloadPropertyMiddleware unloads the property state when moving out of a
 * property's section
 */

const stateUnloadPropertyMiddleware = store => next => action => {
  const { type, payload } = action;
  if (type === '@@router/LOCATION_CHANGE') {
    const {
      location: { pathname }
    } = payload;
    if (!pathname.match(/^\/(hotels|restaurants)\/\d+\/?.*/)) {
      const {
        property: {
          data: { id }
        }
      } = store.getState();
      if (id) {
        store.dispatch({ type: CLEAR_PROPERTY_STATE });
      }
    }
  }
  return next(action);
};

export const configureStore = (nodeEnv = 'production') => {
  const middlewares = [thunk];

  // FIXME why was this only logging on development? What happens with logging
  // on production?
  if (nodeEnv === 'development') {
    const logger = createLogger({ collapsed: true });
    middlewares.push(logger);
  }

  middlewares.push(routerMiddleware(history));
  middlewares.push(stateUnloadPropertyMiddleware);

  const store = createStore(
    rootReducer(history),
    composeWithDevTools(applyMiddleware(...middlewares))
  );

  return store;
};

export default configureStore(process.env.NODE_ENV);
