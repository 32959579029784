import {
  FETCH_NOTIFICATIONS_REQUEST,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_FAILURE
} from '../actions/notifications';

const initialState = {
  data: {},
  isLoading: true,
  error: ''
};

// eslint-disable-next-line default-param-last
export default function notifications(state = initialState, { type, payload }) {
  switch (type) {
    case FETCH_NOTIFICATIONS_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };
    case FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
