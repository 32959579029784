import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { SubmissionError } from 'redux-form';
import get from 'lodash/get';
import Layout from '../../../layout';
import * as sidebarActions from '../../../actions/sidebar';
import * as propertyActions from '../../../actions/properties';
import * as eventActions from '../../../actions/events';
import * as imageActions from '../../../actions/images';
import EditEventForm from '../../../components/EditEventForm';
import FormNavigator from '../../../components/FormNavigator';
import ctaTitleOptions from '../../../utils/cta-title-options';

const sections = [
  { id: 'title', title: 'Title & Content' },
  { id: 'cta', title: 'CTA (Call to Action)' },
  { id: 'date', title: 'Date' },
  { id: 'image', title: 'Set an Image' },
  { id: 'upload', title: 'Upload PDF' },
  { id: 'preview', title: 'Preview + Publish' },
];

class EditEvent extends Component { 
  componentDidMount() {
    const {
      updateSidebar,
      fetchProperty,
      fetchEvent,
      setCurrentImage,
      match: {
        params: { restaurantId, id }
      }
    } = this.props;
    fetchProperty(restaurantId).then(restaurant => {
      updateSidebar({ titles: { Restaurant: restaurant.name } });
    });
    fetchEvent(restaurantId, id).then(event => {
      get(event, 'images', []).forEach(image => {
        setCurrentImage('restaurant-events-image', image);
      });
    });
  }

  handleSubmit = data => {
    const {
      match: {
        params: { restaurantId, id }
      },
      updateEvent,
      history: { push },
      images
    } = this.props;

    /* eslint-disable no-param-reassign */
    let { pdf } = data;
    pdf = pdf && pdf.id ? { assetId: pdf.id } : null;

    const body = {
      ...data,
      pdf,
      startsOn: data.startsOn.toJSON(),
      endsOn: data.endsOn ? data.endsOn.toJSON() : data.endsOn,
      cta: Object.assign(
        data.cta,
        data.cta.type === 'Other' && { type: data.cta.typeInput }
      ),
      images
    };
    return updateEvent(restaurantId, id, body)
      .then(() => {
        push(`/restaurants/${restaurantId}/events`);
      })
      .catch(e => {
        throw new SubmissionError({
          _error: e.message
        });
      });
  };

  render() {
    const {
      isLoading,
      restaurant,
      event,
      match: {
        params: { restaurantId }
      }
    } = this.props;
    return (
      <Layout metaTitle="Edit Event">
        <div className="w-full max-w-3xl">
          <h1 className="mb-4">Edit Event</h1>
          <div className="mb-8">
            Use this page to edit Events for{' '}
            {isLoading ? 'Restaurant' : restaurant.name}.
          </div>
          <Link
            to={`/restaurants/${restaurantId}/events`}
            className="text-black mb-10 inline-block"
          >
            &lt; Back to Events
          </Link>
          <EditEventForm
            onSubmit={this.handleSubmit}
            enableReinitialize
            keepDirtyOnReinitialize
            initialValues={{
              ...event,
              cta: event.cta && {
                ...event.cta,
                type:
                  ctaTitleOptions.findIndex(
                    title => title.id === get(event, 'cta.type')
                  ) === -1
                    ? 'Other'
                    : get(event, 'cta.type'),
                typeInput:
                  ctaTitleOptions.findIndex(
                    title => title.id === get(event, 'cta.type')
                  ) === -1
                    ? get(event, 'cta.type')
                    : undefined
              },
              startsOn: event.startsOn
                ? new Date(event.startsOn)
                : event.startsOn,
              endsOn: event.endsOn ? new Date(event.endsOn) : event.endsOn
            }}
          />
        </div>
        <FormNavigator
          id="restaurant-events-edit"
          sections={sections}
          className="hidden xl:block mt-32 pt-10"
        />
      </Layout>
    );
  }
}

EditEvent.propTypes = {
  updateSidebar: PropTypes.func.isRequired,
  fetchProperty: PropTypes.func.isRequired,
  fetchEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
  setCurrentImage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  restaurant: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired,
  match: PropTypes.object,
  history: PropTypes.object
};

EditEvent.defaultProps = {
  match: {},
  history: {}
};

export default connect(
  (state) => ({
    restaurant: state.property.data,
    event: state.event.data,
    isLoading: state.property.isLoading || state.event.isLoading,
    images: get(state, 'images.restaurant-events-image.items', [])
      .filter(item => item.file)
      .map(item => ({ assetId: item.file.id, alt: item.alt }))
  }),
  { ...sidebarActions, ...propertyActions, ...eventActions, ...imageActions }
)(EditEvent);
