import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import get from 'lodash/get';
import * as modalActions from '../../actions/modals';
import * as imageActions from '../../actions/images';
import * as assetActions from '../../actions/assets';
import Input from '../common/Input';
import FormDatePicker from '../common/form/DatePicker';
import TinyMCE from '../common/form/TinyMCE';
import FormDropdown from '../common/form/Dropdown';
import FormSection from '../common/FormSection';
import Switch from '../common/form/Switch';
import FormErrorBox from '../common/FormErrorBox';
import promoFlags from '../../utils/promo-flag-accom-options';
import modalTitles from '../../utils/modal-title-options-accommodations';
import ctaTitleOptions from '../../utils/cta-title-options-accommodations';
import { daysFromOne, daysData } from '../../utils/days-options';
import stringToNone from '../../utils/string-none';
import SetAnAsset from '../SetAnAsset';

const propTypes = {
  hotel: PropTypes.object.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  firstImage: PropTypes.object.isRequired,
  title: PropTypes.string,
  shortDescription: PropTypes.string,
  doesNotEnd: PropTypes.bool,
  ctaOption: PropTypes.string,
  ctaTypeInput: PropTypes.string,
  datePicker: PropTypes.bool,
  promoFlag: PropTypes.string,
  promoFlagOther: PropTypes.string,
  showmodal2: PropTypes.bool,
  modal1Title: PropTypes.string,
  modal1TitleOther: PropTypes.string,
  modal2Title: PropTypes.string,
  modal2TitleOther: PropTypes.string,
  published: PropTypes.bool,
  archived: PropTypes.bool,
  change: PropTypes.func.isRequired,
  error: PropTypes.string,
  profilePermissions: PropTypes.array.isRequired
};

const defaultProps = {
  title: '',
  shortDescription: '',
  doesNotEnd: false,
  ctaOption: '',
  ctaTypeInput: '',
  datePicker: false,
  promoFlag: '',
  promoFlagOther: '',
  modal1Title: '',
  modal1TitleOther: '',
  showmodal2: false,
  modal2Title: '',
  modal2TitleOther: '',
  published: false,
  archived: false,
  error: undefined
};

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Required!';
  }

  if (!values.shortDescription) {
    errors.shortDescription = 'Required!';
  }

  if (!values.startsOn) {
    errors.startsOn = 'Required!';
  }

  if (!values.doesNotEnd && !values.endsOn) {
    errors.endsOn = 'End date is required if event ends';
  }

  if (values.modal1Title === 'Other') {
    if (!values.modal1TitleOther) {
      errors.modal1TitleOther = 'Modal title is required if Other is selected';
    }
  }

  if (values.modal2Title === 'Other') {
    if (!values.modal2TitleOther) {
      errors.modal2TitleOther = 'Modal title is required if Other is selected';
    }
  }

  if (values.promoFlag === 'Other') {
    if (!values.promoFlagOther) {
      errors.promoFlagOther =
        'Promo Flag title is required if Other is selected';
    }
  }

  if (get(values, 'cta.type') === 'Book Now') {
    if (!get(values, 'cta.linkUrl')) {
      errors.linkUrl = 'Required!';
    }
  }

  if (values['hotel-accommodations-image']) {
    const images = values['hotel-accommodations-image'];

    // must prefill the errors so we can index them correct as one may tab back
    // and forth
    errors['hotel-accommodations-image'] = images.map(() => ({}));

    let i = 0;
    const j = images.length;
    for (; i < j; i++) {
      const image = images[i];
      const err = {};
      if (!image.asset_id) {
        err.asset_id = 'Image is required';
      }
      if (!image.alt) {
        err.alt = 'Image Alt Tag is required';
      }
      if (('asset_id' in err) || ('alt' in err)) {
        errors['hotel-accommodations-image'][i] = err;
      }
    }
  }

  return errors;
};

const condfn = () => {
  return {
    filetype: 'image',
    category: 'cat#Accommodations'
  };
};

const HotelAccommodationForm = ({
  hotel,
  handleSubmit,
  showModal,
  firstImage,
  title,
  shortDescription,
  doesNotEnd,
  ctaOption,
  ctaTypeInput,
  datePicker,
  promoFlag,
  promoFlagOther,
  modal1Title,
  modal1TitleOther,
  showmodal2,
  modal2Title,
  modal2TitleOther,
  published,
  archived,
  change,
  error,
  profilePermissions
}) => {
  const descriptionString = shortDescription || '';

  const canUploadAsset = () => {
    return (
      profilePermissions.findIndex(perm => {
        return (
          perm.resource === '*' &&
          perm.permissionableId === null &&
          perm.permissionableType === null &&
          perm.r &&
          perm.w &&
          perm.x
        );
      }) > -1
    );
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormSection
        id="title"
        title="Title & Content"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter the Title and Short Description for this accommodation.
              </i>
            </p>
            <p>
              <i>
                To display a modal, select one from the Modal Title dropdown.
                For a custom Modal Title, select 'Other' and enter the text to
                use.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Title*</div>
          <Field
            id="title"
            name="title"
            className="w-full mb-6"
            placeholder="Enter a title..."
            component={Input}
          />
          <div className="mb-3">Short Description*</div>
          <Field
            id="shortDescription"
            name="shortDescription"
            className="mb-12"
            placeholder="Enter a short description for this accommodation..."
            component={TinyMCE}
          />
          <div className="mb-3">Modal Title</div>
          <Field
            id="modal1Title"
            name="modal1Title"
            data={modalTitles}
            className="mb-6"
            component={FormDropdown}
          />
          {modal1Title === 'Other' && (
            <Fragment>
              <Field
                id="modal1TitleOther"
                name="modal1TitleOther"
                className="mb-6"
                component={Input}
                placeholder="Enter a modal title..."
              />
            </Fragment>
          )}
          <div className="mb-3">Modal Content:</div>
          <Field
            id="modal1Content"
            name="modal1Content"
            className="mb-6"
            placeholder="Enter the content for the modal..."
            component={TinyMCE}
          />
          {showmodal2 ? (
            <Fragment>
              <div className="my-3 flex justify-between">
                <div>Second Modal Title</div>
                <a
                  href="#0"
                  className="btn-link text-blue no-underline hover:underline"
                  onClick={e => {
                    e.preventDefault();
                    change('showmodal2', false);
                    change('modal2Title', 'None');
                    change('modal2Content', '');
                  }}
                >
                  - Remove Second Modal
                </a>
              </div>
              <Field
                id="modal2Title"
                name="modal2Title"
                data={modalTitles}
                className="mb-6"
                component={FormDropdown}
              />
              {modal2Title === 'Other' && (
                <Fragment>
                  <Field
                    id="modal2TitleOther"
                    name="modal2TitleOther"
                    className="mb-6"
                    component={Input}
                    placeholder="Enter a modal title..."
                  />
                </Fragment>
              )}
              <div className="mb-3">Second Modal Content:</div>
              <Field
                id="modal2Content"
                name="modal2Content"
                className="mb-6"
                placeholder="Enter the content for the modal..."
                component={TinyMCE}
              />
            </Fragment>
          ) : (
            <button
              type="button"
              className="btn-link text-blue no-underline hover:underline"
              onClick={e => {
                e.preventDefault();
                change('showmodal2', true);
              }}
            >
              + Add Second Modal
            </button>
          )}
        </div>
      </FormSection>
      <FormSection
        id="date"
        title="Date and Time"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter the Start Date for this Accommodation to become active. To
                take down this Accommodation at a specific time, uncheck 'Does
                not end' and enter the End Date.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Start Date*</div>
          <Field
            id="starts-on"
            name="startsOn"
            component={FormDatePicker}
            className="mb-6"
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="MM/dd/yyyy h:mm aa"
            timeCaption="time"
            autoComplete="off"
          />
          <div className="flex items-center">
            <Field
              id="does-not-end"
              type="checkbox"
              name="doesNotEnd"
              className="mr-3"
              component={Input}
              onChange={(_, v) => {
                if (v) {
                  change('endsOn', new Date(2120, 0, 1));
                } else {
                  change('endsOn', null);
                }
              }}
            />
            <div className="text-base font-normal">Does not end</div>
          </div>
          {!doesNotEnd && (
            <div>
              <div
                className={classnames('text-base font-normal mb-3 pt-6', {
                  'text-grey-light': doesNotEnd
                })}
              >
                End Date*
              </div>
              <Field
                id="ends-on"
                name="endsOn"
                component={FormDatePicker}
                disabled={doesNotEnd}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                dateFormat="MM/dd/yyyy h:mm aa"
                timeCaption="time"
                autoComplete="off"
              />
            </div>
          )}
        </div>
      </FormSection>
      <FormSection
        id="image"
        title="Set an Image"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Select an image to use for this Accommodation. We recommend
                using 4 image slides maximum to help keep load times in check.
                If you don't see the image you are looking for,&nbsp;
                <a
                  href="http://tickets.still-water.com/tickets"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  create a ticket here.
                </a>
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <SetAnAsset
            change={change}
            condfn={condfn}
            canUploadAsset={canUploadAsset}
            categories={[{ name: 'Accommodations' }]}
            name='hotel-accommodations-image'
            id='hotel-accommodations-upload'
            header='Select an image*'
            onlySingle={false}
          />
        </div>
      </FormSection>
      <FormSection id="cta" title="CTA (Call to Action)" className="border-t">
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Select a CTA option to use for this Accommodation. To use a
                custom CTA title, select 'Other' from the dropdown and enter the
                text to display.
              </i>
            </p>
            <p>
              <i>
                If you'd like to add an Adobe Tracking tag to the link for this
                Accommodation, enter it in the Adobe Tracking tag field.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Select a CTA Option*</div>
          <Field
            id="cta.type"
            name="cta.type"
            className="mb-6"
            data={ctaTitleOptions}
            component={FormDropdown}
            style={{ width: 300 }}
          />
          {ctaOption === 'Other' && (
            <Field
              id="cta.typeInput"
              name="cta.typeInput"
              className="mb-12"
              component={Input}
              placeholder="Enter title for CTA"
            />
          )}
          <div className="flex-1">
            <div className="flex-1">
              <div
                className={classnames('text-base font-normal mb-3', {
                  'text-grey': ctaOption === 'None'
                })}
              >
                CTA Link URL
              </div>
              <div className="flex flex-col mb-3">
                <Field
                  id="cta.linkUrl"
                  name="cta.linkUrl"
                  className="flex-1 mr-2 mb-3"
                  component={Input}
                  disabled={ctaOption === 'None'}
                />
                <div className="flex justify-left my-2">
                  <button
                    type="button"
                    className="btn btn-clear px-6 mr-4"
                    onClick={e => {
                      e.preventDefault();
                      showModal('GenerateURLModal', {
                        form: 'HotelAccommodationForm',
                        field: 'cta.linkUrl',
                        hotelId: hotel.id
                      });
                    }}
                  >
                    Generate URL
                  </button>
                  <button
                    type="button"
                    className="btn btn-clear px-6"
                    onClick={e => {
                      e.preventDefault();
                      showModal('GenerateEmailURLModal', {
                        form: 'HotelAccommodationForm',
                        field: 'cta.linkUrl',
                        hotelId: hotel.id
                      });
                    }}
                  >
                    Generate Email URL
                  </button>
                </div>
              </div>
              {ctaOption !== 'None' && (
                <Fragment>
                  <div className="flex mt-4 mb-6 items-center">
                    <Field
                      id="cta.opensInNewWindow"
                      name="cta.opensInNewWindow"
                      className="mr-2"
                      type="checkbox"
                      component={Input}
                    />
                    <div className="">Opens in a New Window</div>
                  </div>
                  {ctaOption !== 'Learn More' &&
                    ctaOption !== 'Inquire Now' &&
                    ctaOption !== 'Take A Tour' && (
                      <Fragment>
                        <div className="flex mb-6 items-center">
                          <Field
                            id="cta.addDatePickerPopup"
                            name="cta.addDatePickerPopup"
                            type="checkbox"
                            className="mr-2"
                            component={Input}
                          />
                          <div className="">Add Date Picker Popup</div>
                        </div>
                        <div className="flex mb-6 items-center">
                          <Field
                            id="cta.advancedPurchaseDays"
                            name="cta.advancedPurchaseDays"
                            className="mr-2"
                            component={FormDropdown}
                            data={daysData}
                            disabled={!datePicker}
                          />
                          <div
                            className={classnames('', {
                              'text-grey': !datePicker
                            })}
                          >
                            Advance Purchase Days
                          </div>
                        </div>
                        <div className="flex mb-12 items-center">
                          <Field
                            id="cta.minimumNightStay"
                            name="cta.minimumNightStay"
                            className="mr-2"
                            component={FormDropdown}
                            data={daysFromOne}
                            disabled={!datePicker}
                          />
                          <div
                            className={classnames('', {
                              'text-grey': !datePicker
                            })}
                          >
                            Minimum Night Stay
                          </div>
                        </div>
                      </Fragment>
                    )}
                  <div className="mb-3">Adobe Tracking Tag</div>
                  <Field
                    id="cta.trackingTag"
                    name="cta.trackingTag"
                    className="w-full mb-4"
                    placeholder="Enter Adobe Tracking output or press 'Generate Tracking' button..."
                    component={Input}
                  />
                  <div className="flex justify-left">
                    <button
                      type="button"
                      className="btn btn-clear px-6 mr-4"
                      onClick={e => {
                        e.preventDefault();
                        showModal('GenerateTrackingModal', {
                          form: 'HotelAccommodationForm',
                          field: 'cta.trackingTag'
                        });
                      }}
                    >
                      Generate Tracking
                    </button>
                  </div>
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection
        id="promo"
        title="Promo Flag"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Set a promo flag for this Accommodation from the dropdown. To
                use a custom promo flag, select 'Other' from the dropdown and
                enter the text to display.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Promo Flag:</div>
          <Field
            id="promoFlag"
            name="promoFlag"
            data={promoFlags}
            className="mb-6"
            component={FormDropdown}
          />
          {promoFlag === 'Other' && (
            <Field
              id="promoFlagOther"
              name="promoFlagOther"
              className="mb-6"
              component={Input}
              placeholder="Enter title for Promo Flag"
            />
          )}
        </div>
      </FormSection>
      <FormSection
        id="preview"
        title="Preview + Publish"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex w-full flex-col flex-wrap xl:flex-row">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  Here is a preview of your Accommodation. When you're ready,
                  toggle the publish status to display it on the live site and
                  hit 'Save'.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="text-base font-normal mb-8">Preview:</div>
            <div
              className="bg-white shadow preview-special"
              style={{
                width: 400
              }}
            >
              {firstImage.file && (
                <img
                  alt={firstImage.alt}
                  src={get(firstImage, 'file.url')}
                  className="w-full"
                />
              )}
              {promoFlag === 'None' ? (
                ''
              ) : (
                <div className="ribbon-callout">
                  {promoFlag === 'Other' ? promoFlagOther : promoFlag}
                </div>
              )}
              <div className="tile_text_box">
                <h3 className="headline">{title}</h3>
                <div
                  className="content"
                  dangerouslySetInnerHTML={{ __html: descriptionString }}
                />
                <div className="restrictions">
                  {stringToNone(modal1Title) && (
                    <p className="">
                      <a href="#0" className="">
                        {modal1Title === 'Other'
                          ? modal1TitleOther
                          : modal1Title}
                      </a>
                    </p>
                  )}
                  {stringToNone(modal2Title) && (
                    <p className="pt-4">
                      <a href="#0" className="">
                        {modal2Title === 'Other'
                          ? modal2TitleOther
                          : modal2Title}
                      </a>
                    </p>
                  )}
                </div>
              </div>
              {stringToNone(ctaOption) && (
                <p className="tile_action_link">
                  {ctaOption === 'Other' ? ctaTypeInput : ctaOption}
                </p>
              )}
            </div>
            <div className="mt-12">
              <div className="text-base font-normal mb-3">Publish Status:</div>
              <Field
                id="published"
                name="published"
                component={Switch}
                label={published ? 'Published' : 'Unpublished'}
                className="mb-12"
                disabled={archived}
              />
              <div className="text-base font-normal mb-3">
                Archive this Accommodation?
              </div>
              <Field
                id="archived"
                name="archived"
                component={Switch}
                label={archived ? 'Archived' : 'Unarchived'}
                className="mb-6"
                onChange={(e, value) => {
                  e.preventDefault();
                  if (value && published) {
                    change('published', false);
                  }
                  change('archived', value);
                }}
              />
            </div>
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && <FormErrorBox id="hotel-meeting-error">{error}</FormErrorBox>}
    </form>
  );
};

HotelAccommodationForm.propTypes = propTypes;
HotelAccommodationForm.defaultProps = defaultProps;

const selector = formValueSelector('HotelAccommodationForm');

export default compose(
  connect(
    (state, props) => ({
      title: selector(state, 'title'),
      shortDescription: selector(state, 'shortDescription'),
      doesNotEnd: selector(state, 'doesNotEnd'),
      ctaOption: selector(state, 'cta.type'),
      ctaTypeInput: selector(state, 'cta.typeInput'),
      promoFlag: selector(state, 'promoFlag'),
      promoFlagOther: selector(state, 'promoFlagOther'),
      modal1Title: selector(state, 'modal1Title'),
      modal1TitleOther: selector(state, 'modal1TitleOther'),
      showmodal2: selector(state, 'showmodal2'),
      modal2Title: selector(state, 'modal2Title'),
      modal2TitleOther: selector(state, 'modal2TitleOther'),
      published: selector(state, 'published'),
      datePicker: selector(state, 'cta.addDatePickerPopup'),
      archived: selector(state, 'archived'),
      firstImage: get(state, 'images.hotel-accommodations-image.items.0', {}),
      profilePermissions: state.auth.permissions
    }),
    { ...modalActions, ...imageActions, ...assetActions }
  ),
  reduxForm({ form: 'HotelAccommodationForm', validate })
)(HotelAccommodationForm);
