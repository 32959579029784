import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Input from '../Input';
import ChevronDownIcon from '../ChevronDownIcon';

const propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  data: PropTypes.array
};

const defaultProps = {
  title: '',
  data: []
};

const CheckboxList = ({ id, title, data }) => {
  const [dropdown, setDropdown] = useState('');
  return (
    <div id={id} className="border border-grey-light shadow rounded-t-sm">
      {title && (
        <div className="p-4 bg-grey-lighter border-b border-grey-light">
          {title}
        </div>
      )}
      <ul className="list-reset">
        {data.map(item => (
          <li key={item.id} className="p-4 border-b border-grey-light bg-white">
            <div className="flex items-center">
              <Input
                id={item.id}
                type="checkbox"
                input={{ checked: item.checked, onChange: item.onChange }}
                className="mr-4"
                disabled={
                  item.disabled ||
                  (Array.isArray(item.children) && item.children.length === 0)
                }
              />
              <div
                role="presentation"
                className="flex cursor-pointer items-center"
                onClick={() =>
                  dropdown === item.id ? setDropdown('') : setDropdown(item.id)}
              >
                {item.displayName ? item.displayName(item) : item.name}
                {item.children && item.children.length > 0 && (
                  <ChevronDownIcon
                    id="region-chevron"
                    style={{
                      height: 16,
                      transform: dropdown === item.id && 'rotate(180deg)'
                    }}
                    fill="black"
                  />
                )}
              </div>
            </div>
            {dropdown === item.id && (
              <ul className="list-reset ml-4">
                {item.children.map(child => (
                  <li key={child.id} className="p-4 flex items-center">
                    <Input
                      id={child.id}
                      name={child.id}
                      type="checkbox"
                      className="mr-4"
                      input={{
                        checked: child.checked,
                        onChange: child.onChange
                      }}
                      disabled={child.disabled}
                    />
                    <span
                      className={classnames({ 'text-grey': child.disabled })}
                    >
                      {child.name}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

CheckboxList.propTypes = propTypes;
CheckboxList.defaultProps = defaultProps;

export default CheckboxList;
