import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  id: PropTypes.string.isRequired,
  fill: PropTypes.string,
  pathClassName: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func
};

const defaultProps = {
  fill: '',
  pathClassName: '',
  height: 16,
  width: 16,
  onClick: () => {}
};

const MoreIcon = ({ id, fill, pathClassName, height, width, onClick }) => (
  <svg
    height={height}
    id={id}
    style={{ enableBackground: 'new 0 0 16 16' }}
    version="1.1"
    viewBox="0 0 16 16"
    width={width}
    onClick={onClick}
  >
    <path
      fill={fill}
      className={pathClassName}
      d="M2,6C0.896,6,0,6.896,0,8s0.896,2,2,2s2-0.896,2-2S3.104,6,2,6z M8,6C6.896,6,6,6.896,6,8s0.896,2,2,2s2-0.896,2-2  S9.104,6,8,6z M14,6c-1.104,0-2,0.896-2,2s0.896,2,2,2s2-0.896,2-2S15.104,6,14,6z"
    />
  </svg>
);

MoreIcon.propTypes = propTypes;
MoreIcon.defaultProps = defaultProps;

export default MoreIcon;
