import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import isURL from '../../../utils/is-url';
import Input from '../Input';
import FormErrorBox from '../FormErrorBox';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  error: ''
};

const validate = values => {
  const errors = {};

  if (!values.link) {
    errors.link = 'Required!';
  }

  if (!isURL(values.link)) {
    errors.link = 'Page must be a valid URL';
  }

  return errors;
};

const EditableLinkForm = ({ id, handleSubmit, onClose, error }) => {
  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
    return () => {};
  }, []);

  return (
    <form id={id} onSubmit={handleSubmit} className="flex">
      <Field
        id={`${id}-link`}
        name="link"
        placeholder="Add new link"
        inputRef={inputEl}
        component={Input}
        style={{ width: 300 }}
      />
      <div className="ml-3 mt-4">
        <button
          type="submit"
          id={`${id}-save`}
          className="px-2 text-blue no-underline hover:underline"
          disabled={!!error}
        >
          Save
        </button>
        <button
          type="button"
          className="px-2 text-red no-underline hover:underline"
          onClick={e => {
            e.preventDefault();
            onClose();
          }}
        >
          {' '}
          Cancel
        </button>
      </div>
      {error && <FormErrorBox id="add-calendar-error">{error}</FormErrorBox>}
    </form>
  );
};

EditableLinkForm.propTypes = propTypes;
EditableLinkForm.defaultProps = defaultProps;

export default reduxForm({ form: 'EditableLinkForm', validate })(
  EditableLinkForm
);
