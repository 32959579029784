import {
  FETCH_GOTO_PROPERTIES_REQUEST,
  FETCH_GOTO_PROPERTIES_SUCCESS,
  FETCH_GOTO_PROPERTIES_FAILURE,
  CLEAR_GOTO_PROPERTIES_SUCCESS
} from '../actions/gotoProperties';

const initialState = {
  data: [],
  isLoading: true,
  error: ''
};

export default function gotoProperties(
  // eslint-disable-next-line default-param-last
  state = initialState,
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_GOTO_PROPERTIES_REQUEST:
      return { ...state, isLoading: true, error: '' };
    case FETCH_GOTO_PROPERTIES_SUCCESS:
    case CLEAR_GOTO_PROPERTIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        data: payload,
        meta
      };
    case FETCH_GOTO_PROPERTIES_FAILURE:
      return { ...state, isLoading: false, error: payload };
    default:
      return state;
  }
}
