import {
  FETCH_USERS_REQUEST,
  FETCH_USERS_FAILURE,
  FETCH_USERS_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_FAILURE,
  CREATE_USER_SUCCESS,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE
} from '../actions/users';

// users is the plural namespace reducer
export function users(
  state = { // eslint-disable-line default-param-last
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_USERS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_USERS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case FETCH_USERS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

// user is the singular namespace reducer
export function user(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_USER_REQUEST:
    case UPDATE_USER_REQUEST:
    case CREATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_USER_SUCCESS:
    case CREATE_USER_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_USER_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_USER_FAILURE:
    case UPDATE_USER_FAILURE:
    case CREATE_USER_FAILURE:
    case DELETE_USER_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
