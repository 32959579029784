import Home from './Home';
import Dashboard from './Dashboard';
import {
  Properties,
  Regions,
  RegionsNew,
  RegionsEdit,
  Alerts,
  AlertsAssignments,
  AlertsNew,
  AlertsEdit,
  NewHotel
} from './Hotels';
import { SharedSpecials, SharedSpecialsNew } from './SharedContent';
import {
  Specials,
  SpecialsNew,
  SpecialsEdit,
  SpecialsClone,
  HotelCalendars,
  HotelCalendarsNew,
  HotelCalendarsEdit,
  HotelCalendarsClone,
  Meetings,
  MeetingsNew,
  MeetingsEdit,
  MeetingsClone,
  Weddings,
  WeddingsNew,
  WeddingsEdit,
  PrivateDining,
  PrivateDiningNew,
  PrivateDiningEdit,
  Misc,
  MiscNew,
  MiscEdit,
  Misc2,
  Misc2New,
  Misc2Edit,
  Misc3,
  Misc3New,
  Misc3Edit,
  GuestRoom,
  GuestRoomNew,
  GuestRoomEdit,
  GuestRoomClone,
  Suite,
  SuiteNew,
  SuiteEdit,
  Accessible,
  AccessibleNew,
  AccessibleEdit,
  MiscAccom,
  MiscAccomNew,
  MiscAccomEdit,
  FAQs,
  FAQsNew,
  FAQsClone,
  FAQsCategories,
  FAQsEdit,
  FAQsCategoriesNew,
  FAQsCategoriesEdit,
  HomePromos,
  HomePromosNew,
  HomePromosEdit,
  HomePromosClone,
  Lists,
  ListsNew,
  ListsEdit,
  ListsClone,
  HotelSettings,
  HotelDashboard
} from './Hotel';
import Users, { New as UsersNew, Edit as UsersEdit } from './Users';
import Restaurants, { RestaurantsNew } from './Restaurants';
import {
  RestaurantPress,
  RestaurantPressNew,
  RestaurantPressEdit,
  RestaurantMenu,
  RestaurantMenuNew,
  RestaurantMenuEdit,
  Events,
  NewEvent,
  EditEvent,
  RestaurantCalendars,
  RestaurantCalendarsNew,
  RestaurantCalendarsEdit,
  RestaurantCalendarsClone,
  RestaurantSettings,
  RestaurantDashboard
} from './Restaurant';
import MediaLibrary, { EditMedia } from './MediaLibrary';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import Settings from './Settings';
import Hours from './Restaurant/Hours';
import Notifications from './Notifications';
import Profile from './Profile';
import NotFound from './404';
import Tokens from './Tokens';
import TokensNew from './Tokens/New';

export {
  Home,
  Dashboard,
  Properties,
  Regions,
  RegionsNew,
  RegionsEdit,
  Alerts,
  AlertsAssignments,
  AlertsNew,
  AlertsEdit,
  NewHotel,
  Specials,
  SpecialsNew,
  SpecialsEdit,
  SpecialsClone,
  HotelCalendars,
  HotelCalendarsNew,
  HotelCalendarsEdit,
  HotelCalendarsClone,
  Meetings,
  MeetingsNew,
  MeetingsEdit,
  MeetingsClone,
  Weddings,
  WeddingsNew,
  WeddingsEdit,
  PrivateDining,
  PrivateDiningNew,
  PrivateDiningEdit,
  Misc,
  MiscNew,
  MiscEdit,
  Misc2,
  Misc2New,
  Misc2Edit,
  Misc3,
  Misc3New,
  Misc3Edit,
  GuestRoom,
  GuestRoomNew,
  GuestRoomEdit,
  GuestRoomClone,
  Suite,
  SuiteNew,
  SuiteEdit,
  Accessible,
  AccessibleNew,
  AccessibleEdit,
  MiscAccom,
  MiscAccomNew,
  MiscAccomEdit,
  FAQs,
  FAQsNew,
  FAQsClone,
  FAQsCategories,
  FAQsEdit,
  FAQsCategoriesNew,
  FAQsCategoriesEdit,
  HomePromos,
  HomePromosNew,
  HomePromosEdit,
  HomePromosClone,
  Lists,
  ListsNew,
  ListsEdit,
  ListsClone,
  HotelSettings,
  HotelDashboard,
  SharedSpecials,
  SharedSpecialsNew,
  Users,
  UsersNew,
  UsersEdit,
  MediaLibrary,
  EditMedia,
  ForgotPassword,
  ResetPassword,
  Settings,
  Restaurants,
  RestaurantsNew,
  RestaurantPress,
  RestaurantPressNew,
  RestaurantPressEdit,
  RestaurantMenu,
  RestaurantMenuNew,
  RestaurantMenuEdit,
  Hours,
  Events,
  NewEvent,
  EditEvent,
  RestaurantCalendars,
  RestaurantCalendarsNew,
  RestaurantCalendarsEdit,
  RestaurantCalendarsClone,
  RestaurantSettings,
  RestaurantDashboard,
  Notifications,
  Profile,
  NotFound,
  Tokens,
  TokensNew
};

export default Home;
