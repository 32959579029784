import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DeleteModal from '../DeleteModal';
import * as faqActions from '../../../actions/faqs';

const propTypes = {
  data: PropTypes.object.isRequired,
  deleteFAQ: PropTypes.func.isRequired
};

const DeleteFAQModal = ({ data, deleteFAQ, ...props }) => (
  <DeleteModal
    id="delete-faq-modal"
    title="FAQ"
    itemTitle={data.title}
    deleteItem={() => deleteFAQ(data.hotelId, data.id)}
    {...props}
  />
);

DeleteFAQModal.propTypes = propTypes;

export default connect(
  null,
  faqActions
)(DeleteFAQModal);
