import React from 'react';
import PropTypes from 'prop-types';
import isPromise from '../../../utils/is-promise';
import ExitButton from '../../common/ExitButton';

const propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  itemTitle: PropTypes.string.isRequired,
  deleteItem: PropTypes.func.isRequired
};

const DeleteModal = ({ id, onClose, title, itemTitle, deleteItem }) => (
  <div id={id}>
    <div className="flex justify-center border-b py-2 px-4 border-grey bg-grey-lighter">
      <h2 className="font-light">Delete {title}</h2>
      <ExitButton
        id="generate-url-exit"
        onClick={onClose}
        className="absolute"
        divStyle={{ right: '.5rem' }}
        svgStyle={{ fill: 'black' }}
      />
    </div>
    <div className="p-6">
      <div className="font-thin mt-6 mb-10 text-center">
        Are you sure you want to delete{' '}
        <span className="font-semibold">{itemTitle}</span>?
      </div>
      <div className="flex items-center justify-center">
        <button type="button" className="btn btn-clear" onClick={onClose}>
          Cancel
        </button>
        <button
          type="button"
          className="btn btn-red ml-3"
          onClick={() => {
            const deleted = deleteItem();
            if (isPromise(deleted)) {
              return deleted.then(() => onClose());
            }
            return onClose();
          }}
        >
          Delete
        </button>
      </div>
    </div>
  </div>
);

DeleteModal.propTypes = propTypes;

export default DeleteModal;
