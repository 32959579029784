import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector } from 'redux-form';

import * as propertyActions from '../../actions/properties';

import FormErrorBox from '../common/FormErrorBox';
import FormSection from '../common/FormSection';
import Input from '../common/Input';
import { Tabs, TabList, Tab, TabPill } from '../common/Tabs';

import Paginate from '../Paginate';

const validate = values => {
  const errors = [];

  if (!values.name) {
    errors.name = 'Required!';
  }

  if (!values.propertyId || values.propertyId.length === 0) {
    errors.propertyId = 'Required!';
  }

  return errors;
};

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  error: null
};

const propertiesTable = ({
  properties,
  meta,
  fetchProperties,
  countProperties,
  handleChecked,
  isChecked
}) => {
  const [hotelCount, setHotelCount] = useState(0);
  const [restaCount, setRestaCount] = useState(0);
  const [selected, setSelected] = useState(0);

  const propertiesTabsc = [
    {
      qry: { type: 'hotel', archived: false },
      cb: setHotelCount
    },
    {
      qry: { type: 'restaurant', archived: false },
      cb: setRestaCount
    },
  ];

  const fetchFunc = (val, selectedIndex) => {
    const cond = { order_by: 'name:ASC' };
    if (val) {
      cond.name = `i%%${val}`;
    }
    propertiesTabsc.forEach((v, i) => {
      if (i === selectedIndex) {
        fetchProperties({ ...v.qry, ...cond }).then(p => v.cb(p.meta.count));
      } else {
        countProperties({ ...v.qry, ...cond }).then(p => v.cb(p.count));
      }
    });
  };

  useEffect(() => {
    fetchFunc(null, selected);
    return () => {};
  }, [selected]);

  return (
    <>
      <Tabs selectedIndex={selected} onSelect={index => setSelected(index)}>
        <TabList>
          <Tab>
            <span className="mr-2">Hotels</span>
            <TabPill id={0}>{hotelCount}</TabPill>
          </Tab>
          <Tab>
            <span className="mr-2">Restaurants</span>
            <TabPill id={1}>{restaCount}</TabPill>
          </Tab>
          <Paginate meta={meta} fetchHandler={fetchProperties} />
        </TabList>
        <ul className="list-reset py-3">
          {properties.map(p => (
            <li className="mb-3">
              <label>
                <input
                  type="checkbox"
                  value={p.id}
                  onChange={handleChecked}
                  checked={isChecked(p.id)}
                  className="mr-2"
                />
                {p.name}
              </label>
            </li>
          ))}
        </ul>
      </Tabs>
      <Paginate meta={meta} fetchHandler={fetchProperties} />
    </>
  );
};

const PropertiesTable = compose(
  connect(
    state => ({
      properties: state.properties.data,
      meta: state.properties.meta
    }),
    { ...propertyActions }
  )
)(propertiesTable);

// NOTE read inner comments about this.
// const Wrapper = ({ propertiesTable }) => {
//   return <div>{propertiesTable}</div>;
// };

const TokensNewForm = ({
  handleSubmit,
  change,
  error
}) => {
  const [ids, setIds] = useState([]);
  const handleChecked = evt => {
    const { checked, value } = evt.target;
    let _ids;
    if (!checked) {
      _ids = ids.filter(v => v !== value);
    } else {
      _ids = [...new Set([ids, value].flat())];
    }
    _ids = _ids.filter(v => v !== '*');
    change('propertyId', _ids);
    setIds(_ids);
  };
  const handleAllProperties = evt => {
    const { checked } = evt.target;
    let _ids;
    if (!checked) {
      _ids = [];
    } else {
      _ids = ['*'];
    }
    change('propertyId', _ids);
    setIds(_ids);
  };
  const isChecked = id => {
    return ids.indexOf(id) >= 0;
  };
  return (
    <form onSubmit={handleSubmit}>
      <FormSection
        id="name"
        title="Set a Name & Associate Property"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter a name for this Token, then select which Property you
                would like to create the token for.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Name*</div>
          <Field
            id="name"
            name="name"
            className="w-full mb-6"
            placeholder="Enter a name for this Token"
            component={Input}
          />
          <div className="mb-3">Property*</div>
          <div style={{ marginBottom: '16px' }}>
            <label>
              <input
                type="checkbox"
                value="*"
                onChange={handleAllProperties}
                checked={isChecked('*')}
                className="mr-2"
              />
              Access to All Properties
            </label>
          </div>
          {/* <Field
            name="propertyId"
            component={
              <Wrapper
                propertiesTable={
                  <PropertiesTable handleChecked={handleChecked} />
                }
              />
            }
          /> */}
          {!isChecked('*') && (
            <>
              <PropertiesTable
                handleChecked={handleChecked}
                isChecked={isChecked}
              />
            </>
          )}
          {/* FIXME use the proper field + custom component to enable proper field errors. However with the above setup, and because the PropertiesTable has XHR calls, that seems to cause an infinite loop. So this is a temporary solution to getting the "required" error to show if properties are not selected. */}
          <Field
            id="propertyId"
            name="propertyId"
            type="hidden"
            component={Input}
          />
          <hint style={{ 'font-style': 'italic' }}>
            This token will only be able to access resources to the selected
            property(ies).
          </hint>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && <FormErrorBox id="hotel-alert-error">{error}</FormErrorBox>}
    </form>
  );
};

TokensNewForm.propTypes = propTypes;
TokensNewForm.defaultProps = defaultProps;

const selector = formValueSelector('TokensNewForm');

export default compose(
  reduxForm({ form: 'TokensNewForm', validate }),
  connect(
    state => ({
      isLoadingProperties: state.properties.isLoading,
      name: selector(state, 'name'),
      propertyId: selector(state, 'propertyId')
    }),
    {}
  )
)(TokensNewForm);
