import { moveBySplice } from '../utils/move_by_splice';
import {
  FETCH_SPECIALS_REQUEST,
  FETCH_SPECIALS_SUCCESS,
  FETCH_SPECIALS_FAILURE,
  FETCH_SPECIAL_REQUEST,
  FETCH_SPECIAL_SUCCESS,
  FETCH_SPECIAL_FAILURE,
  CREATE_SPECIAL_REQUEST,
  CREATE_SPECIAL_SUCCESS,
  CREATE_SPECIAL_FAILURE,
  UPDATE_SPECIAL_REQUEST,
  UPDATE_SPECIAL_SUCCESS,
  UPDATE_SPECIAL_FAILURE,
  DELETE_SPECIAL_REQUEST,
  DELETE_SPECIAL_SUCCESS,
  DELETE_SPECIAL_FAILURE,
  MOVE_SPECIAL,
  CLONE_SPECIALS_REQUEST,
  CLONE_SPECIALS_SUCCESS,
  CLONE_SPECIALS_FAILURE,
  SORT_SPECIALS_REQUEST,
  SORT_SPECIALS_SUCCESS,
  SORT_SPECIALS_FAILURE
} from '../actions/specials';

export function specials(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_SPECIALS_REQUEST:
    case CLONE_SPECIALS_REQUEST:
    case SORT_SPECIALS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_SPECIALS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case MOVE_SPECIAL: {
      const { source, target } = payload;
      const data = moveBySplice(source, target, state.data);

      return { ...state, data };
    }

    case CLONE_SPECIALS_SUCCESS:
    case SORT_SPECIALS_SUCCESS:
      return { ...state, isLoading: false, error: '' };

    case FETCH_SPECIALS_FAILURE:
    case CLONE_SPECIALS_FAILURE:
    case SORT_SPECIALS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function special(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_SPECIAL_REQUEST:
    case CREATE_SPECIAL_REQUEST:
    case UPDATE_SPECIAL_REQUEST:
    case DELETE_SPECIAL_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_SPECIAL_SUCCESS:
    case CREATE_SPECIAL_SUCCESS:
    case UPDATE_SPECIAL_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_SPECIAL_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_SPECIAL_FAILURE:
    case CREATE_SPECIAL_FAILURE:
    case UPDATE_SPECIAL_FAILURE:
    case DELETE_SPECIAL_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
