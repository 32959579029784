import Cookies from 'js-cookie';
import { authfn as authorityFunc } from 'swcms-authority';
import {
  LOGIN_USER_REQUEST,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  LOGOUT_USER_SUCCESS,
  FETCH_PROFILE_REQUEST,
  FETCH_PROFILE_SUCCESS,
  FETCH_PROFILE_FAILURE,
  FETCH_PROFILE_PERMISSIONS_REQUEST,
  FETCH_PROFILE_PERMISSIONS_SUCCESS,
  FETCH_PROFILE_PERMISSIONS_FAILURE,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS, /* eslint-disable-line no-unused-vars */ // FIXME why is this not used?
  FORGOT_PASSWORD_FAILURE
} from '../actions/auth';
import scopes from '../authority_scopes';

const initialState = {
  isAuthenticated: false,
  profile: {},
  permissions: [],
  token: '',
  error: '',
  isLoading: true,
  authority: {
    isSuper: () => false,
    can: () => false
  }
};

// eslint-disable-next-line default-param-last
export default function auth(state = initialState, { type, payload }) {
  switch (type) {
    case LOGIN_USER_REQUEST:
    case FETCH_PROFILE_REQUEST:
    case FETCH_PROFILE_PERMISSIONS_REQUEST:
    case UPDATE_PROFILE_REQUEST:
    case FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        error: '',
        isLoading: true
      };
    case LOGIN_USER_SUCCESS:
      Cookies.set('token', payload);
      return {
        ...state,
        isLoading: false,
        error: '',
        isAuthenticated: true,
        token: payload
      };
    case FETCH_PROFILE_SUCCESS:
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        profile: payload
      };
    case FETCH_PROFILE_PERMISSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: '',
        permissions: payload,
        authority: authorityFunc(payload, scopes)
      };
    case LOGIN_USER_FAILURE:
    case FETCH_PROFILE_FAILURE:
    case FETCH_PROFILE_PERMISSIONS_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false
      };
    case LOGOUT_USER_SUCCESS:
      Cookies.remove('token');
      return initialState;
    default:
      return state;
  }
}
