import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import onClickOutside from 'react-onclickoutside';
import NavIcon from '../../common/NavIcon';
import SidebarNav from '../../SidebarNav';

class NavMenu extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  handleClickOutside = () => this.setState({ isOpen: false });

  render() {
    const { isOpen } = this.state;
    const { logoutUser } = this.props;
    return (
      <div id="nav-icon-container" className="lg:hidden">
        <NavIcon
          id="nav-icon"
          role="button"
          className=""
          onClick={() => this.setState({ isOpen: !isOpen })}
        />
        {isOpen && (
          <nav
            id="mainnav"
            className="w-48 mb-auto min-h-screen pt-1 fixed mainnav z-2 lg:hidden border-t border-solid border-light-grey -ml-3"
          >
            <SidebarNav onClick={this.handleClickOutside} />
            <div className="w-full border-t border-solid border-light-grey p-6 mt-6">
              <Link
                to="/notifications"
                className="no-underline"
                onClick={this.handleClickOutside}
              >
                <div className="w-full text-left px-2 py-3 text-sm text-grey-darker hover:text-black">
                  Notifications
                </div>
              </Link>
              <Link
                to="/profile"
                className="no-underline"
                onClick={this.handleClickOutside}
              >
                <div className="w-full text-left px-2 py-3 text-sm text-grey-darker hover:text-black">
                  Profile
                </div>
              </Link>
              <div
                role="button"
                tabIndex={0}
                className="w-full text-left px-2 py-3 text-sm text-grey-darker hover:text-black"
                onClick={logoutUser}
              >
                Logout
              </div>
            </div>
          </nav>
        )}
      </div>
    );
  }
}

NavMenu.propTypes = {
  logoutUser: PropTypes.func.isRequired
};

const clickOutsideConfig = {
  handleClickOutside: instance => instance.handleClickOutside
};

export { NavMenu };

export default onClickOutside(NavMenu, clickOutsideConfig);
