import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { reduxForm, Field } from 'redux-form';
import timezones from 'compact-timezone-list';
import * as modalActions from '../../actions/modals';
import * as imageActions from '../../actions/images';
import * as assetActions from '../../actions/assets';
import Input from '../common/Input';
import FormErrorBox from '../common/FormErrorBox';
import FormSection from '../common/FormSection';
import FormDropdown from '../common/form/Dropdown';
import SetAnAsset from '../SetAnAsset';

// prop types
const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  regionList: PropTypes.array.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  error: undefined
};

const timezoneList = timezones.map(timezone => ({
  id: timezone.tzCode,
  name: timezone.label
}));

// validate
const validate = values => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Required!';
  }

  if (!values.ihgHotelCode) {
    errors.ihgHotelCode = 'Required!';
  }

  if (!values.url) {
    errors.url = 'Required!';
  }

  if (!values.localeCode) {
    errors.localeCode = 'Required!';
  }

  if (!values.regionCode) {
    errors.regionCode = 'Required!';
  }

  if (!values.brandCode) {
    errors.brandCode = 'Required!';
  }

  if (!values.timezone) {
    errors.timezone = 'Required!';
  }

  return errors;
};

const condfn = () => {
  return {
    filetype: 'image',
    category: 'cat#Hotel Logo'
  };
};

const HotelForm = ({
  id,
  handleSubmit,
  regionList,
  change,
  submit,
  error
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="name"
        title="Hotel Name"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Enter the Name for this Hotel property.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="font-normal mb-3 text-base">Hotel Name*</div>
          <Field
            id="name"
            name="name"
            component={Input}
            className="w-full mb-6"
            placeholder="Enter Name for this hotel"
            style={{ width: 320 }}
          />
        </div>
      </FormSection>
      <FormSection
        id="url"
        title="Hotel URL"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Enter the Site URL to use for this Hotel property.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Site URL*</div>
          <div className="flex items-center">
            <div className="mr-2 mb-6 text-grey-dark text-base">https://</div>
            <div>
              <Field
                id="url"
                name="url"
                component={Input}
                className="w-full mb-3"
                style={{ width: 320 }}
                placeholder="Enter the URL for this hotel"
              />
              <div className="text-sm font-bold">www.example.com</div>
            </div>
          </div>
        </div>
      </FormSection>
      <FormSection
        id="logo"
        title="Set a Hotel Logo"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Set the Hotel Logo to use for this Hotel property. This will
                only display within this CMS and not the live site.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <SetAnAsset
            change={change}
            condfn={condfn}
            canUploadAsset={() => true}
            categories={[{ name: 'Hotel Logo' }]}
            name='hotel-logo-image'
            id='hotel-logo-upload'
            header='Select an image*'
            onlySingle
          />
        </div>
      </FormSection>
      <FormSection
        id="timezone"
        title="Time Zone"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Set the Time Zone for this Hotel property.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Time Zone*</div>
          <Field
            id="timezone"
            name="timezone"
            className="w-full mb-6"
            style={{ width: 320 }}
            component={FormDropdown}
            data={timezoneList}
            placeholder="Select a Time Zone"
          />
        </div>
      </FormSection>
      <FormSection
        id="region"
        title="Region"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>Set the geographical Region for this Hotel property.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Region:</div>
          <Field
            id="regionId"
            name="regionId"
            className="w-full mb-6"
            style={{ width: 320 }}
            placeholder="Select the Region for this hotel"
            component={FormDropdown}
            data={regionList}
          />
        </div>
      </FormSection>

      <FormSection
        id="details"
        title="Hotel Codes"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Here you can set the various Hotel Codes associated with this
                property:
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">5-digit, Hotel Code (XXXXX)*</div>
          <Field
            id="ihgHotelCode"
            name="ihgHotelCode"
            className="mb-6"
            style={{ width: 320 }}
            component={Input}
            placeholder="XXXXX"
          />
          <div className="mb-3">
            Locale Code (Language)* is usually 'en' for English
          </div>
          <Field
            id="localeCode"
            name="localeCode"
            className="mb-6"
            style={{ width: 320 }}
            component={Input}
            placeholder="Enter the Locale Code for this hotel"
          />
          <div className="mb-3">
            Region Code (Country)* is usually '1' for U.S.
          </div>
          <Field
            id="regionCode"
            name="regionCode"
            className="mb-6"
            style={{ width: 320 }}
            component={Input}
            placeholder="Enter the Region Code for this hotel"
          />
          <div className="font-normal mb-3 text-base">
            Brand Code* is usually 'ki' for Kimpton.
          </div>
          <Field
            id="brandCode"
            name="brandCode"
            className="mb-6"
            style={{ width: 320 }}
            component={Input}
            placeholder="Enter the Brand Code for this hotel"
          />
        </div>
      </FormSection>
      <FormSection
        id="analytics"
        title="Analytics"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p>
              <i>
                Set the Google Analytics tracking ID for this Hotel property.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">GA Tracking ID (UA-XXXXXX-XX)</div>
          <Field
            id="gaTrackingID"
            name="gaTrackingID"
            component={Input}
            className="w-full mb-6"
            style={{ width: 320 }}
            placeholder="UA-XXXXXX-XX"
          />
        </div>
      </FormSection>
      <div className="flex justify-center my-12">
        <button
          type="button"
          className="btn btn-clear mr-4"
          onClick={e => {
            e.preventDefault();
            change('archived', true);
            setTimeout(() => {
              submit();
            }, 0);
          }}
        >
          Archive this Hotel
        </button>
        <button
          type="submit"
          className="btn"
          style={{ width: 200 }}
          disabled={!!error}
        >
          Save
        </button>
      </div>
      {error && <FormErrorBox id="hotel-error">{error}</FormErrorBox>}
    </form>
  );
};

HotelForm.propTypes = propTypes;
HotelForm.defaultProps = defaultProps;

export default compose(
  reduxForm({ form: 'HotelForm', validate }),
  connect(
    state => ({}),
    { ...modalActions, ...imageActions, ...assetActions }
  )
)(HotelForm);
