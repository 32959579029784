import {
  FETCH_TOKENS_REQUEST,
  FETCH_TOKENS_SUCCESS,
  FETCH_TOKENS_FAILURE,
  CREATE_TOKEN_REQUEST,
  CREATE_TOKEN_SUCCESS,
  CREATE_TOKEN_FAILURE,
  DELETE_TOKEN_REQUEST,
  DELETE_TOKEN_SUCCESS,
  DELETE_TOKEN_FAILURE
} from '../actions/tokens';

export function tokens(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta }
) {
  switch (type) {
    case FETCH_TOKENS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_TOKENS_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload, meta };

    case FETCH_TOKENS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function token(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case CREATE_TOKEN_REQUEST:
    case DELETE_TOKEN_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case CREATE_TOKEN_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_TOKEN_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case CREATE_TOKEN_FAILURE:
    case DELETE_TOKEN_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
