import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useDrop, useDrag } from 'react-dnd';

const propTypes = {
  id: PropTypes.string,
  index: PropTypes.number,
  moveRow: PropTypes.func,
  onDrop: PropTypes.func
};

const defaultProps = {
  id: '',
  index: null,
  moveRow: () => {},
  onDrop: () => {}
};

const TrGroup = ({ id, index, moveRow, onDrop, ...otherProps }) => {
  const ref = useRef(null);
  const [, drop] = useDrop({
    accept: 'card',
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // save the old index so that we can track that it is "Actually" moved
      if (!('oldIndex' in item)) {
        item.oldIndex = index; // eslint-disable-line no-param-reassign
      }

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      if (id !== item.id)
        moveRow({ id: item.id, index: dragIndex }, { id, index: hoverIndex });
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex; // eslint-disable-line no-param-reassign
    }
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    }),
    end: item => onDrop({ id: item.id }, item.index !== item.oldIndex)
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <div
      data-row-index={index}
      className="rt-tr-group draggable"
      role="rowgroup"
      ref={ref}
      style={{ opacity }}
      {...otherProps}
    />
  );
};

TrGroup.propTypes = propTypes;
TrGroup.defaultProps = defaultProps;

export default TrGroup;
