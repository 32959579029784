import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { change } from 'redux-form';
import qs from 'querystring';
import ExitButton from '../../common/ExitButton';
import GenerateEmailURLForm from './GenerateEmailURLForm';

const propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  change: PropTypes.func.isRequired
};

const GenerateEmailURLModal = ({
  onClose,
  data: { form, field },
  change // eslint-disable-line no-shadow
}) => {
  return (
    <div id="generate-url-modal">
      <div className="flex justify-center border-b py-2 px-4 border-grey bg-grey-lighter">
        <h2 className="font-light">Generate Email URL</h2>
        <ExitButton
          id="generate-url-exit"
          onClick={onClose}
          className="absolute"
          divStyle={{ right: '.5rem' }}
          svgStyle={{ fill: 'black' }}
        />
      </div>
      <div className="p-6">
        <div className="font-thin my-6 text-center">
          Fill out below to generate an Email URL for this promo:
        </div>
        <GenerateEmailURLForm
          id="generate-modal-form"
          onSubmit={data => {
            const base = 'mailto:';
            const emailURL = qs
              .stringify(data)
              .replace('email=', '')
              .replace('%40', '@')
              .replace('&', '?');
            change(form, field, `${base}${emailURL}`);
            onClose();
          }}
          enableReinitialize
          keepDirtyOnReinitialize
        />
      </div>
    </div>
  );
};

GenerateEmailURLModal.propTypes = propTypes;

export default connect(
  (state, props) => ({
    hotel: state.property.data
  }),
  { change }
)(GenerateEmailURLModal);
