import {
  FETCH_ASSETS_REQUEST,
  FETCH_ASSETS_SUCCESS,
  FETCH_ASSETS_FAILURE,
  FETCH_ASSET_REQUEST,
  FETCH_ASSET_SUCCESS,
  FETCH_ASSET_FAILURE,
  CREATE_ASSET_REQUEST,
  CREATE_ASSET_SUCCESS,
  CREATE_ASSET_FAILURE,
  DELETE_ASSET_REQUEST,
  DELETE_ASSET_SUCCESS,
  DELETE_ASSET_FAILURE,
  UPDATE_ASSET_REQUEST,
  UPDATE_ASSET_SUCCESS,
  UPDATE_ASSET_FAILURE
} from '../actions/assets';

export function assets(
  // eslint-disable-next-line default-param-last
  state = {
    data: [],
    isLoading: true,
    error: ''
  },
  { type, payload, meta, append }
) {
  switch (type) {
    case FETCH_ASSETS_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_ASSETS_SUCCESS:
      /* eslint-disable no-case-declarations */
      let _payload_ = payload;
      if (append) {
        const { data } = state;
        _payload_ = [...data, ...payload];
      }
      return { ...state, isLoading: false, error: '', data: _payload_, meta };

    case FETCH_ASSETS_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}

export function asset(
  // eslint-disable-next-line default-param-last
  state = {
    data: {},
    isLoading: true,
    error: ''
  },
  { type, payload }
) {
  switch (type) {
    case FETCH_ASSET_REQUEST:
    case CREATE_ASSET_REQUEST:
    case UPDATE_ASSET_REQUEST:
    case DELETE_ASSET_REQUEST:
      return { ...state, isLoading: true, error: '' };

    case FETCH_ASSET_SUCCESS:
    case CREATE_ASSET_SUCCESS:
    case UPDATE_ASSET_SUCCESS:
      return { ...state, isLoading: false, error: '', data: payload };

    case DELETE_ASSET_SUCCESS:
      return { ...state, isLoading: false, error: '', data: {} };

    case FETCH_ASSET_FAILURE:
    case CREATE_ASSET_FAILURE:
    case UPDATE_ASSET_FAILURE:
    case DELETE_ASSET_FAILURE:
      return { ...state, isLoading: false, error: payload };

    default:
      return state;
  }
}
