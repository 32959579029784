import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import { compose } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash/get';
import * as assetActions from '../../actions/assets';
import * as modalActions from '../../actions/modals';
import UploadModal from '../UploadModal';
import Input from '../common/Input';
import TinyMCE from '../common/form/TinyMCE';
import FormDatePicker from '../common/form/DatePicker';
import FormSwitch from '../common/form/Switch';
import FormSection from '../common/FormSection';
import FormErrorBox from '../common/FormErrorBox';

const propTypes = {
  id: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  title: PropTypes.string,
  publisher: PropTypes.string,
  body: PropTypes.string,
  publishDate: PropTypes.string,
  pdf: PropTypes.object,
  published: PropTypes.bool,
  archived: PropTypes.bool,
  showModal: PropTypes.func.isRequired,
  createAsset: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  error: PropTypes.string
};

const defaultProps = {
  title: '',
  publisher: '',
  body: '',
  publishDate: '',
  pdf: {},
  published: false,
  archived: false,
  error: undefined
};

const validate = values => {
  const errors = {};

  if (!values.title) {
    errors.title = 'Required!';
  }

  if (!values.publishDate) {
    errors.publishDate = 'Required!';
  }

  return errors;
};

const RestaurantPressForm = ({
  id,
  handleSubmit,
  title,
  publisher,
  body,
  publishDate,
  pdf,
  published,
  archived,
  showModal,
  createAsset,
  change,
  error
}) => {
  const [isPdfOpen, setIsPdfOpen] = useState(false);
  return (
    <form id={id} onSubmit={handleSubmit}>
      <FormSection
        id="title"
        title="Title & Content"
        className="flex flex-col flex-wrap"
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                Enter the title, publisher, and content for this Press item.
              </i>
            </p>
            <p>
              <i>
                To display this Press item on the homepage, check on 'Display
                this Press on Homepage'.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Title*</div>
          <Field
            id="press-title"
            name="title"
            className="w-full mb-6"
            placeholder="Enter a title..."
            component={Input}
          />
          <div className="mb-3">Publisher</div>
          <Field
            id="articlePublisher"
            name="publisher"
            className="mb-6"
            placeholder="Enter the Publisher Name..."
            component={Input}
          />
          <div className="mb-3">Content for this Press item</div>
          <Field
            id="body"
            name="body"
            className="mb-6"
            placeholder="Enter a short description for the Press..."
            component={TinyMCE}
          />
          <div className="flex my-3 items-center">
            <Field
              id="displays-home"
              type="checkbox"
              name="displayOnHomepage"
              className="mr-2"
              component={Input}
            />
            <div>Display this Press on Homepage</div>
          </div>
        </div>
      </FormSection>
      <FormSection
        id="cta"
        title="CTA (Call to Action)"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>Enter the URL for this Press item.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Press article URL</div>
          <Field
            id="articleUrl"
            name="url"
            className="w-full mb-6"
            placeholder="Enter the URL for the Press..."
            component={Input}
          />
        </div>
      </FormSection>
      <FormSection
        id="date"
        title="Publication Date"
        className="flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>Enter the publication date for this Press item.</i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="mb-3">Publication Date*</div>
          <Field
            id="press-publishDate"
            name="publishDate"
            component={FormDatePicker}
            className="mb-6"
          />
        </div>
      </FormSection>
      <FormSection
        id="upload"
        title="Upload PDF"
        className="border-t flex flex-col flex-wrap"
        isHorizontal
      >
        <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
          <div className="font-light mb-3 text-base leading-normal">
            <p className="mb-3">
              <i>
                If you'd like to add a PDF for this Press item, upload it here.
              </i>
            </p>
          </div>
        </div>
        <div className="flex-1 xl:flex-initial xl:w-2/3">
          <div className="text-base font-normal mb-3">Add a PDF:</div>
          <button
            type="button"
            className="btn btn-clear mb-6"
            onClick={e => {
              e.preventDefault();
              setIsPdfOpen(true);
            }}
          >
            + Upload File
          </button>
          <UploadModal
            id="press-pdf"
            isOpen={isPdfOpen}
            onRequestClose={() => setIsPdfOpen(false)}
            onUploadSuccess={(file) => {
              const { filename, filesize, filetype, altName } = file.meta;
              const tags = get(file, 'meta.tags')
                ? get(file, 'meta.tags')
                    .split(',')
                    .map(tag => ({ name: tag.trim() }))
                : [];
              const postBody = {
                filename,
                filesize,
                filetype,
                name: altName,
                description: file.description,
                tags,
                alt: get(file, 'meta.alt'),
                categories: [{ name: 'Press' }]
              };
              return createAsset(postBody).then(asset => {
                change('pdf', asset);
              });
            }}
          />
          {pdf.name && (
            <div className="flex mt-4">
              <a
                href={pdf.url.toString()}
                className="mr-2 text-blue"
                target="_blank"
                rel="noopener noreferrer"
              >
                {pdf.name}
              </a>
              <div
                role="button"
                tabIndex={0}
                className="font-bold text-red mb-6"
                onClick={e => {
                  e.preventDefault();
                  showModal(
                    'DeleteAssetModal',
                    {
                      id: pdf.id,
                      title: pdf.name
                    },
                    () => {
                      change('pdf', {});
                    }
                  );
                }}
              >
                X
              </div>
            </div>
          )}
        </div>
      </FormSection>
      <FormSection
        id="preview"
        title="Preview & Publish"
        className="border-t flex flex-col flex-wrap"
      >
        <div className="flex w-full flex-col flex-wrap xl:flex-row">
          <div className="flex-1 pb-6 xl:mr-12 xl:w-1/3">
            <div className="font-light mb-3 text-base leading-normal">
              <p>
                <i>
                  Here is a preview of your Press item. When you're ready,
                  toggle the publish status to display it on the live site and
                  hit 'Save'.
                </i>
              </p>
            </div>
          </div>
          <div className="flex-1 xl:flex-initial xl:w-2/3">
            <div className="text-base font-normal mb-3">Preview:</div>
            <div className="bg-grey-lighter px-2 py-3 mb-3 press-wrapper">
              <p>{title}</p>
              <p>
                {JSON.stringify(publishDate).substr(1, 10)}
                &nbsp;| {publisher}
              </p>
              <br />
              <p>
                <div dangerouslySetInnerHTML={{ __html: body }} />
              </p>
              <br />
              <br />
            </div>
            <p className="mb-12">
              <em>
                Preview may look different from live site since each site has
                its own design
              </em>
            </p>
            <div className="text-base font-normal mb-3">Publish Status:</div>
            <Field
              id="published"
              name="published"
              component={FormSwitch}
              label={published ? 'Published' : 'Unpublished'}
              className="mb-12"
              disabled={archived}
            />
            <div className="text-base font-normal mb-3">
              Archive this Press?
            </div>
            <Field
              id="archived"
              name="archived"
              component={FormSwitch}
              label={archived ? 'Archived' : 'Unarchived'}
              className="mb-12"
              onChange={(e, value) => {
                e.preventDefault();
                if (value && published) {
                  change('published', false);
                }
                change('archived', value);
              }}
            />
          </div>
        </div>
      </FormSection>
      <div className="flex justify-center my-6">
        <button type="submit" className="btn" disabled={!!error}>
          Save
        </button>
      </div>
      {error && (
        <FormErrorBox id="restaurant-press-error">{error}</FormErrorBox>
      )}
    </form>
  );
};

RestaurantPressForm.propTypes = propTypes;
RestaurantPressForm.defaultProps = defaultProps;

const selector = formValueSelector('RestaurantPressForm');

export default compose(
  reduxForm({ form: 'RestaurantPressForm', validate }),
  connect(
    state => ({
      title: selector(state, 'title'),
      publisher: selector(state, 'publisher'),
      body: selector(state, 'body'),
      publishDate: selector(state, 'publishDate'),
      published: selector(state, 'published'),
      archived: selector(state, 'archived'),
      pdf: selector(state, 'pdf')
    }),
    { ...modalActions, ...assetActions }
  )
)(RestaurantPressForm);
