import React from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'redux';
import { connect } from 'react-redux';

const propTypes = {
  match: PropTypes.object,
  breadcrumbs: PropTypes.array.isRequired,
  property: PropTypes.object,
  isLoading: PropTypes.bool
};

const defaultProps = {
  match: {},
  property: {},
  isLoading: false
};

const Breadcrumb = ({ match: { path }, breadcrumbs, property, isLoading }) => {
  if (isLoading) {
    return <></>;
  }

  return (
    <div className="flex flex-wrap mb-6 text-xs text-grey p-2 md:p-4 breadcrumb">
      {breadcrumbs.map((nav, i) => (
        // NOTE navs will not naturally have something like a primary key, so
        // we must use the index here
        // eslint-disable-next-line react/no-array-index-key
        <div className="flex-initial py-2 md:py-0" key={i}>
          {i === 0 && path !== '/' && (
            <>
              <Link to="/" className="no-underline hover:underline">
                {path === '/forgot-password' ||
                path === '/reset-password/:resetToken'
                  ? 'Sign-in'
                  : 'Dashboard'}
              </Link>
              <span className="mx-2">/</span>
            </>
          )}
          {i > 0 && <span className="mx-2">/</span>}
          <Link to={nav.url} className="no-underline hover:underline">
            {typeof nav.title === 'function' ? nav.title(property) : nav.title}
          </Link>
        </div>
      ))}
    </div>
  );
};

Breadcrumb.propTypes = propTypes;
Breadcrumb.defaultProps = defaultProps;

export default compose(
  withRouter,
  connect((state) => ({
    breadcrumbs: state.nav.breadcrumbs,
    property: state.property.data,
    isLoading: state.nav.isLoading || state.property.isLoading
  }))
)(Breadcrumb);
