import fetch from '../utils/fetch';
import { convertToAPI, convertToJS } from '../utils/convert';

export const ASSIGN_ALERT_REQUEST = 'ASSIGN_ALERT_REQUEST';
export const ASSIGN_ALERT_SUCCESS = 'ASSIGN_ALERT_SUCCESS';
export const ASSIGN_ALERT_FAILURE = 'ASSIGN_ALERT_FAILURE';

export const UNASSIGN_ALERT_REQUEST = 'UNASSIGN_ALERT_REQUEST';
export const UNASSIGN_ALERT_SUCCESS = 'UNASSIGN_ALERT_SUCCESS';
export const UNASSIGN_ALERT_FAILURE = 'UNASSIGN_ALERT_FAILURE';

export const FETCH_ASSIGNED_ALERTS_REQUEST = 'FETCH_ASSIGNED_ALERTS_REQUEST';
export const FETCH_ASSIGNED_ALERTS_SUCCESS = 'FETCH_ASSIGNED_ALERTS_SUCCESS';
export const FETCH_ASSIGNED_ALERTS_FAILURE = 'FETCH_ASSIGNED_ALERTS_FAILURE';

export const assignAlert = (propertyId, data) => dispatch => {
  dispatch({
    type: ASSIGN_ALERT_REQUEST
  });
  return fetch('/alerts/assign', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI({ ...data, propertyId }))
  })
    .then(response => {
      const alertable = convertToJS(response.data);
      dispatch({
        type: ASSIGN_ALERT_SUCCESS,
        payload: alertable
      });
      return alertable;
    })
    .catch(e => {
      dispatch({
        type: ASSIGN_ALERT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const unassignAlert = propertyId => dispatch => {
  dispatch({
    type: UNASSIGN_ALERT_REQUEST
  });
  return fetch('/alerts/assign', {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(convertToAPI({ propertyId }))
  })
    .then(response => {
      dispatch({
        type: UNASSIGN_ALERT_SUCCESS,
        payload: propertyId
      });
      return propertyId;
    })
    .catch(e => {
      dispatch({
        type: UNASSIGN_ALERT_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};

export const fetchAssignedAlerts = () => dispatch => {
  dispatch({
    type: FETCH_ASSIGNED_ALERTS_REQUEST
  });
  return fetch(`/alerts/assigned`)
    .then(response => {
      const alert = response.data ? convertToJS(response.data) : {};
      dispatch({
        type: FETCH_ASSIGNED_ALERTS_SUCCESS,
        payload: alert
      });
      return alert;
    })
    .catch(e => {
      dispatch({
        type: FETCH_ASSIGNED_ALERTS_FAILURE,
        payload: e.message
      });
      return Promise.reject(e);
    });
};
