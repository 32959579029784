import fetch from '../utils/fetch';

export const DELETE_SESSION_REQUEST = 'DELETE_SESSION_REQUEST';
export const DELETE_SESSION_FAILURE = 'DELETE_SESSION_FAILURE';
export const DELETE_SESSION_SUCCESS = 'DELETE_SESSION_SUCCESS';

export const deleteSession = userId => dispatch => {
  // dispatch({
  //   type: DELETE_SESSION_REQUEST
  // });
  return fetch(`/users/${userId}/sessions`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' }
  })
    .then(() => {
      // dispatch({
      //   type: DELETE_SESSION_SUCCESS,
      //   payload: userId
      // });
      return userId;
    })
    .catch(e => {
      // dispatch({
      //   type: DELETE_SESSION_FAILURE,
      //   payload: e.message
      // });
      return Promise.reject(e);
    });
};
