import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import classnames from 'classnames';
import MoreIcon from '../MoreIcon';
import MoreContext from './MoreContext';

class More extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false, hover: false };
  }

  handleClick = isOpen => this.setState({ isOpen });

  toggleHover = hover => this.setState({ hover });

  render() {
    const { isOpen, hover } = this.state;
    const { id, style, children } = this.props;
    return (
      <div
        id={id}
        style={style}
        className="cursor-pointer fill-current text-grey"
        onMouseEnter={this.toggleHover.bind(this, true)}
        onMouseLeave={this.toggleHover.bind(this, false)}
      >
        <MoreIcon
          id={`${id}-moreicon`}
          pathClassName={classnames({
            'fill-black': hover || isOpen,
            'fill-grey': !hover && !isOpen
          })}
          onClick={() => this.handleClick(!isOpen)}
        />
        {isOpen && (
          <MoreContext.Provider
            value={{
              onClose: () => {
                this.handleClick(false);
                this.toggleHover(false);
              }
            }}
          >
            {children}
          </MoreContext.Provider>
        )}
      </div>
    );
  }
}

More.propTypes = {
  id: PropTypes.string.isRequired,
  style: PropTypes.object,
  children: PropTypes.node
};

More.defaultProps = {
  style: {},
  children: null
};

const clickOutsideConfig = {
  handleClickOutside: instance => instance.handleClick.bind(instance, false)
};

export { More };

export default onClickOutside(More, clickOutsideConfig);
